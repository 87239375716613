<template>
  <div class="vto__modal" :class="{ '-closed': !modalDisplay }">
    <div class="vto__modal-overlay" @click.self="exit"></div>
    <div class="vto__app" :class="[`vto__app--${country}`]">
      <div id="vto__frame-container" class="vto__frame-container"></div>
      <div class="vto__page vto__loading" :class="{ '-fadeOut': isFrameReady }">
        <OapLoader />
      </div>
      <div v-if="showEmailMyLook" class="vto__page vto__look__actions">
        <div class="vtoFooter">
          <div class="vtoActions">
            <button class="vtoActions__email" @click="handleEmailMyLooks">
              <svg class="icon" role="img" aria-labelledby="vto-email">
                <title id="vto-email">{{ emailMyLookFormPageCtaLabel }}</title>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#email-white" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BUS_EVENT_VTO_ENTER,
  MF_SCRIPT_NAME,
  FRAME_NAME,
  FRAME_CONTAINER_ID,
  VTO_TRIGGER_URL_PARAM,
} from '../settings';

import eventBus from '@loreal/eventbus-js';
import { getCookie } from '../../../../../Foundation/Core/code/Scripts';
import { mixinParseURL } from '../../../../../Foundation/Core/code/Scripts/mixins/parseURL/parseURL';
import { loadscript } from '../../../../../Foundation/Core/code/Scripts/directives/loadscript/loadscript';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';

export default {
  name: 'VtoAppLook',

  components: {
    OapLoader,
  },

  mixins: [mixinParseURL],

  props: {
    scriptUrl: { type: String, required: true },
    shouldSendConsentId: { type: Boolean, default: false },
    userEmail: { type: String, default: '' },
    publisherId: { type: String, required: true },
    productId: { type: String, default: '' },
    country: { type: String, required: true },
    language: { type: String, required: true },
    shareCaptureCount: { type: String, required: true },
    iframeTitle: { type: String, required: true },
    category: { type: String, required: true },
    selectedLookId: { type: String, required: true },
    enableEmailMyLookForm: { type: Boolean, default: false },
    emailMyLookFormPageCtaLabel: { type: String, default: 'Email my results' },
    emailMyLookFormPageUrl: { type: String, default: 'Email my results' },
  },

  data() {
    return {
      // modal
      modalDisplay: false,
      isFrameReady: false,
      selectedLookProducts: null,
      onPhotoDashBoardView: false,
    };
  },

  computed: {
    showEmailMyLook() {
      return this.isFrameReady && this.enableEmailMyLookForm && this.onPhotoDashBoardView;
    },
    selectedLook() {
      return this.selectedLookProducts.filter(
        (look) => look.lookId === Number(this.selectedLookId)
      );
    },
  },

  mounted() {
    /* istanbul ignore next */
    window.initModiface = this.initModiface;

    /* istanbul ignore next */
    this.initBus();

    /* istanbul ignore next */
    if (this.URLHasKey(VTO_TRIGGER_URL_PARAM)) eventBus.emit(BUS_EVENT_VTO_ENTER);
  },

  methods: {
    initBus() {
      /* istanbul ignore next */
      // TODO: Create `.once` polyfill for `mitt()`
      eventBus.on(BUS_EVENT_VTO_ENTER, this.load);
      /* istanbul ignore next */
      eventBus.on(BUS_EVENT_VTO_ENTER, this.enter);
    },

    enter() {
      // toggle modal
      /* istanbul ignore next */
      this.toggleModal();

      // history push state
      /* istanbul ignore next */
      window.history.pushState(null, document.title, `?${VTO_TRIGGER_URL_PARAM}=true`);
    },

    exit() {
      window.history.back();
      this.toggleModal();
    },

    load() {
      eventBus.off(BUS_EVENT_VTO_ENTER, this.load);
      /* istanbul ignore next */
      loadscript.beforeMount(null, {
        value: {
          name: MF_SCRIPT_NAME,
          url: this.scriptUrl,
        },
      });
    },

    frameReady() {
      /* istanbul ignore next */
      setTimeout(() => (this.isFrameReady = true), 300);

      window.MF_CHANNEL_PARENT.getLooks(this.publisherId, this.country, this.language).then(
        (lookList) => {
          this.selectedLookProducts = lookList;
        }
      );

      window.MF_CHANNEL_PARENT.listen('page_change', ({ page }) => {
        this.onPhotoDashBoardView = page === 'photo-dashboard-view' || page === 'before-after-view';
      });
    },

    initModiface() {
      /* istanbul ignore next */
      const MF = window.MF_CHANNEL_PARENT;
      let frameParams = this.getFrameParams();

      if (this.shouldSendConsentId) {
        const id = new URLSearchParams(getCookie('OptanonConsent')).get('consentId');
        frameParams.oneTrustConsentId = id;
        frameParams.oneTrustUserEmail = this.userEmail;
      }

      /* istanbul ignore next */
      MF.addFrame(frameParams);
      /* istanbul ignore next */
      MF.listen('frame_ready', this.frameReady);
      /* istanbul ignore next */
      MF.listen('exit', this.exit);
    },

    getFrameParams() {
      return {
        frameName: FRAME_NAME,
        divId: FRAME_CONTAINER_ID,
        publisherId: this.publisherId,
        category: this.category,
        country: this.country,
        language: this.language,
        shareCaptureCount: this.shareCaptureCount,
        uxWrapper: true,
        useStandardTaggingPlan: true,
        frameAttributes: {
          title: this.iframeTitle,
        },
        selectedLookId: this.selectedLookId,
      };
    },

    handleEmailMyLooks() {
      const { href } = window.location;
      const newUrl = new URL(this.emailMyLookFormPageUrl, href);
      newUrl.searchParams.append('product-id', this.productId);
      newUrl.searchParams.append('products', this.selectedLook[0].upcListInLook.join(','));
      newUrl.searchParams.append('category', this.category);

      window.open(newUrl.href);
    },

    toggleModal() {
      this.modalDisplay = !this.modalDisplay;

      document.body.classList.toggle('noScroll', this.modalDisplay);
      if (!this.modalDisplay) {
        window.MF_CHANNEL_PARENT.send({ event: 'exit', data: {} }, FRAME_NAME);
      }
    },
  },
};
</script>
