import { AnalyticsSocialIntEventObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class SocialIntEventObject extends DataObject {
  constructor(data) {
    super('socialInt', data);

    if (!data.network) {
      Logger.error(
        "[AnalyticsSocialIntEventObjectException] network is required for socialevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsSocialIntEventObjectException(
        "network is required for socialevent (L'Oréal guideline)"
      );
    }

    if (!data.action) {
      Logger.error(
        "[AnalyticsSocialIntEventObjectException] socialaction is required for socialevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsSocialIntEventObjectException(
        "socialaction is required for socialevent (L'Oréal guideline)"
      );
    }

    if (!data.target) {
      Logger.error(
        "[AnalyticsSocialIntEventObjectException] target is required for socialevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsSocialIntEventObjectException(
        "target is required for socialevent (L'Oréal guideline)"
      );
    }

    if (!data.ecommerce) {
      Logger.error(
        "[AnalyticsSocialIntEventObjectException] ecommerce is required for socialevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsSocialIntEventObjectException(
        "ecommerce is required for socialevent (L'Oréal guideline)"
      );
    }
  }
  set ecommerce(ecommerce) {
    this.result.ecommerce = this.setProperty(ecommerce);
  }

  get ecommerce() {
    return this.result.socialNetwork;
  }

  set event_name(event_name) {
    this.result.event_name = this.setProperty(event_name);
  }

  get event_name() {
    return this.result.event_name;
  }

  set network(network) {
    this.result.socialNetwork = this.setProperty(network);
  }

  get network() {
    return this.result.socialNetwork;
  }

  set social_network(social_network) {
    this.result.social_network = this.setProperty(social_network);
  }

  get social_network() {
    return this.result.social_network;
  }

  set action(action) {
    this.result.socialAction = this.setProperty(action);
  }

  get action() {
    return this.result.socialAction;
  }

  set social_action(social_action) {
    this.result.social_action = this.setProperty(social_action);
  }

  get social_action() {
    return this.result.social_action;
  }

  set target(target) {
    this.result.socialTarget = this.setProperty(target);
  }

  get target() {
    return this.result.socialTarget;
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }
}
