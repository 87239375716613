/* istanbul ignore next */
const svgA11yHandlerInit = () => {
  /* Find the element with id "SvgjsSvg1001" that SVG.js adds to the document */
  const SVGjsSVG = document.getElementById('SvgjsSvg1001');

  if (SVGjsSVG) {
    if (SVGjsSVG.getAttribute('aria-hidden') !== undefined) {
      /* To follow the a11y rules we need to add "aria-hidden" attribute to it */
      SVGjsSVG.setAttribute('aria-hidden', true);
    }
  }
};

/* istanbul ignore next */
export const svgA11yHandler = (function () {
  document.addEventListener('DOMContentLoaded', () => {
    /* istanbul ignore next */
    if (window.SVG) {
      setTimeout(() => {
        svgA11yHandlerInit();
      }, 2000);
    }
  });
})();
