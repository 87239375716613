<template>
  <button
    :id="`trigger-${tabItemId}`"
    role="tab"
    class="oap-button -secondary"
    type="button"
    :aria-selected="isSelected"
    :aria-controls="tabItemId"
    @click.prevent="activateTab"
  >
    {{ controlLabel }}
  </button>
</template>

<script>
import { mapActions, mapGetters } from '../helpers';
import { SET_ACTIVE_TAB, GET_ACTIVE_TAB } from '../settings';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'OapTabHeaderItem',
  props: {
    tabComponentId: {
      type: String,
      required: true,
    },
    tabItemId: {
      type: String,
      required: true,
    },
    controlLabel: {
      type: String,
      required: true,
    },
    taggingObject: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters([GET_ACTIVE_TAB]),
    isSelected() {
      return this[GET_ACTIVE_TAB][this.tabComponentId] === this.tabItemId;
    },
  },
  methods: {
    ...mapActions([SET_ACTIVE_TAB]),
    activateTab() {
      this[SET_ACTIVE_TAB]({ parent: this.tabComponentId, tab: this.tabItemId });

      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        ...this.taggingObject,
        label: `tab::${this.controlLabel}`,
      });
    },
  },
};
</script>
