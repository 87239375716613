<template>
  <div>
    <div class="oap-transcript__header">
      <span>{{ header }}</span>
    </div>
    <ul>
      <li v-for="item in transcript" :key="item.title">
        <span>{{ item.time }}</span>
        <p>{{ item.desc }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VideoPlaceholderTranscript',
  props: {
    header: { type: String, default: '' },
    transcript: { type: Array, default: () => [] },
  },
};
</script>
