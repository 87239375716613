<template>
  <div class="oap-accordion">
    <collapsable
      v-for="(collapsable, index) in formattedItems"
      :key="index"
      :current-index="index"
      :is-collapsed="index !== currentIndex"
      tagname="section"
      class="collapsable"
      :identifier="id"
      @collapse="onCollapse"
    >
      <template #indicator>
        <span class="collapsable__indicator"></span>
      </template>
      <template #title>
        <h3 :id="id" class="collapsable__title">
          {{ collapsable.title }}
        </h3>
      </template>
      <template #content>
        <ul class="collapsable__content">
          <li v-for="(item, yIndex) in collapsable.items" :key="yIndex">
            <component
              :is="setButtonType(item)"
              :id="item.id"
              v-tag:useractionevent="item.tag"
              :href="item.isIframe ? null : item.href && item.href !== '' ? item.href : null"
              :class="{ 'newsletter-i-frame-link': item.isIframe }"
              :aria-label="item.title ? `${item.title} page` : null"
            >
              {{ item.title }}
            </component>

            <template v-if="item.isIframe">
              <OapCardPopin :id="item.id">
                <template #popup-content>
                  <div>
                    <OapIframe
                      :title="item.title"
                      :src="item.href"
                      height-screen-large="600"
                      height-screen-small="600"
                    />
                  </div>
                </template>
              </OapCardPopin>
            </template>
          </li>
        </ul>
      </template>
    </collapsable>
  </div>
</template>

<script>
import collapsable from './collapsable.vue';
import OapCardPopin from '../../../../OapCardPopin/code/Scripts/components/OapCardPopin.vue';
import OapIframe from '../../../../OapIframe/code/Scripts/components/oap-iframe.vue';

import { uniqueId } from '../../../../../Foundation/Core/code/Scripts/utilities/uniqueId';

export default {
  name: 'OapAccordion',
  components: {
    collapsable,
    OapCardPopin,
    OapIframe,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    currentIndex: -1,
  }),
  computed: {
    formattedItems() {
      if (this.items) {
        return this.items.map((collapsable) => {
          return {
            ...collapsable,
            items: collapsable.items.map((item) => ({
              ...item,
              id: uniqueId(),
            })),
          };
        });
      }
    },
    id() {
      return uniqueId();
    },
  },
  methods: {
    onCollapse(currentIndex) {
      this.currentIndex = currentIndex;
    },
    setButtonType(item) {
      if (item.isIframe) return 'button';
      if (!item.href || item.href === '') return 'span';
      return 'a';
    },
  },
};
</script>
