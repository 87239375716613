<template>
  <div id="oap-before-after" class="oap-before-after">
    <slot name="title"></slot>
    <p class="oap-before-after__subtitle">{{ subtitle }}</p>

    <div class="oap-before-after__img-container">
      <div class="oap-before-after__img-wrapper">
        <picture>
          <source media="(min-width: 1200px)" :srcset="selectedShade.before.src.lg" />
          <source :srcset="selectedShade.before.src.sm" />
          <img
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            :data-src="selectedShade.before.src.sm"
            :alt="selectedShade.before.altText"
            class="oap-before-after__img oap-before-after__img--before lazyload"
          />
        </picture>
      </div>

      <div
        ref="imgWrapperAfter"
        class="oap-before-after__img-wrapper oap-before-after__img-wrapper--after"
      >
        <picture>
          <source media="(min-width: 1200px)" :srcset="selectedShade.after.src.lg" />
          <source :srcset="selectedShade.after.src.sm" />
          <img
            ref="imgAfter"
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            :data-src="selectedShade.after.src.sm"
            :alt="selectedShade.after.altText"
            class="oap-before-after__img oap-before-after__img--after lazyload"
          />
        </picture>
      </div>

      <div ref="handle" class="handle">
        <div class="circle">
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
          </svg>
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
          </svg>
        </div>
      </div>

      <input
        ref="slider"
        v-model="scrollPosition"
        class="slider"
        type="range"
        min="0"
        max="100"
        :aria-label="sliderAriaLabel"
        @click.once="handleAnalytics('slider')"
      />

      <p ref="labelBefore" class="oap-before-after__label oap-before-after__label--before">
        {{ labelBefore }}
      </p>
      <p ref="labelAfter" class="oap-before-after__label oap-before-after__label--after">
        {{ labelAfter }}
      </p>
    </div>

    <p v-if="hasMultipleShades" class="oap-before-after__shade-label">{{ selectedShade.label }}</p>

    <ul v-if="hasMultipleShades" class="oap-before-after__thumbnails-wrapper">
      <template v-for="(item, index) in shadeData">
        <li
          :class="['oap-before-after__thumbnail', index === selectedShadeIndex && '-selected']"
          @click="selectShade(index)"
        >
          <img
            class="oap-before-after__thumbnail-img lazyload"
            :alt="item.thumbnailAltText"
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            :data-src="item.thumbnail"
          />
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#tick" />
          </svg>
        </li>
      </template>
    </ul>

    <p v-if="footnote" class="oap-before-after__footnote">{{ footnote }}</p>
  </div>
</template>

<script>
import { AnalyticsHandler } from '@Foundation';

export default {
  name: 'OapBeforeAfter',

  props: {
    subtitle: { type: String },
    labelBefore: { type: String },
    labelAfter: { type: String },
    footnote: { type: String },
    shadeData: {
      type: Array,
      required: true,
    },
    taggingData: {
      type: Object,
      default: () => {
        return {
          event_name: 'before_after_module',
          category: '__pageCategory__',
        };
      },
    },
    onSlide: {
      type: Object,
      required: false,
      default: () => {
        return {
          action: 'slider',
        };
      },
    },
    onShadeClick: {
      type: Object,
      required: false,
      default: () => {
        return {
          action: 'shade_suggestion',
        };
      },
    },
    sliderAriaLabel: {
      type: String,
      default: 'Separator for the Before and After image',
    },
  },

  data() {
    return {
      selectedShadeIndex: 0,
      scrollPosition: 50,
    };
  },

  computed: {
    hasMultipleShades() {
      return this.shadeData.length > 1;
    },
    selectedShade() {
      return this.shadeData[this.selectedShadeIndex];
    },
  },

  watch: {
    scrollPosition(val) {
      this.$refs.imgAfter.style.left = `-${val}%`;
      this.$refs.imgWrapperAfter.style.left = `${val}%`;
      this.$refs.handle.style.left = `${val}%`;

      if (val < 30) {
        this.$refs.labelBefore.style.opacity = '0';
      }

      if (val > 30) {
        this.$refs.labelBefore.style.opacity = '1';
      }

      if (val > 70) {
        this.$refs.labelAfter.style.opacity = '0';
      }

      if (val < 70) {
        this.$refs.labelAfter.style.opacity = '1';
      }
    },
  },

  methods: {
    handleAnalytics(trigger) {
      const additionalData = trigger === 'slider' ? this.onSlide : this.onShadeClick;

      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        ...this.taggingData,
        ...additionalData,
        label: this.selectedShade.tagging.eventLabel,
        product_info: this.selectedShade.tagging.productInfo,
      });
    },
    selectShade(index) {
      this.selectedShadeIndex = index;
      this.handleAnalytics(null, 'shade');
    },
  },
};
</script>
