import { nextTick } from 'vue';

let i = 0;

export const scrollCallback = (node, className) => {
  const rect = node.getBoundingClientRect();
  const top =
    (window.scrollY || document.documentElement.scrollTop) -
    (document.documentElement.clientTop || 0);
  /* istanbul ignore else */
  if (rect.bottom < window.innerHeight + 200 || (top <= 200 && i === 0)) {
    i++;
    node.classList.add(className);
  }
  window.removeEventListener('scroll', scrollCallback);
};

export const animateOnInview = {
  name: 'animate-on-inview',
  beforeMount: (el, binding) => {
    const className = binding.value.class; // eslint-disable-line
    /**
     * stop executing if className is missing
     * element.classList.add() will throw an error if className is empty
     */
    if (!className) return;

    // TODO: is this correct or should async/await be used?
    nextTick(() => {
      scrollCallback(el, className);
    });

    window.addEventListener(
      'scroll',
      () => {
        scrollCallback(el, className);
      },
      false
    );
  },
};
