<template>
  <div class="vtoHeader" v-on="$attrs" @click="$attrs.click && $attrs.click()">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VtoHeader',
};
</script>
