export const changeStyleOnHover = {
  name: 'change-style-on-hover',

  beforeMount: function (el, binding) {
    el.addEventListener('mouseover', () => {
      /* istanbul ignore next */
      el.style[binding.arg] = binding.value;
    });

    el.addEventListener('mouseleave', () => {
      /* istanbul ignore next */
      el.style.removeProperty(binding.arg);
    });
  },
};
