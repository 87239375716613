<template>
  <div :class="[triggerTemplate, 'oap-immersive-before-after-video']" :style="containerStyle">
    <video
      ref="video"
      class="oap-immersive-before-after-video__video"
      preload="metadata"
      :src="source"
      :autoplay="autoplay"
      :playsinline="playsinline"
      :muted="muted"
    ></video>
    <div ref="textBeginning" class="oap-immersive-before-after-video__text-beginning">
      <div v-html="startTextTop" />
      <img :src="imageSource" alt="" />
      <div v-html="startTextBottom" />
    </div>
    <div ref="textEnding" class="oap-immersive-before-after-video__text-ending">
      <div v-html="endTextTop" />
      <div v-html="endTextBottom" />
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { once } from '../../../../../Foundation/Core/code/Scripts';
import { uniqueId } from '../../../../../Foundation/Core/code/Scripts/utilities/uniqueId';

const SHOW_TEXT_CLASS = '--is-shown';
const MOBILE_SCREEN_END = 650;

export default {
  name: 'OapImmersiveBeforeAfterVideo',

  props: {
    videoSources: { type: Object, default: () => {} },
    autoplay: { type: Boolean, default: false },
    playsinline: { type: Boolean, default: true },
    muted: { type: Boolean, default: true },
    startTextTop: { type: String, default: '' },
    startTextBottom: { type: String, default: '' },
    endTextTop: { type: String, default: '' },
    endTextBottom: { type: String, default: '' },
    startImageTop: { type: Object, default: () => {} },
    backgroundImage: { type: Object, default: () => {} },

    // scrollTrigger settings
    scrub: { type: Boolean, default: true },
    pin: { type: Boolean, default: true },
    start: { type: String, default: 'top top' },
    end: { type: String, default: '+=150%' },
  },

  data() {
    return {
      timeline: null,
      video: null,
      videoDuration: 0,
    };
  },

  computed: {
    containerStyle() {
      return this.isMobile ? { backgroundImage: `url(${this.backgroundImage.mobile})` } : null;
    },

    isMobile() {
      return window.innerWidth <= MOBILE_SCREEN_END;
    },

    source() {
      return this.isMobile ? this.videoSources.sm : this.videoSources.lg;
    },

    imageSource() {
      return this.isMobile ? this.startImageTop.sm : this.startImageTop.lg;
    },

    triggerTemplate() {
      return `gsap-trigger-${uniqueId()}`;
    },

    trigger() {
      return `.${this.triggerTemplate}`;
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    setTimeout(this.bindAnimation, 0);
    this.onLoadMetadata();
  },

  methods: {
    onLoadMetadata() {
      this.video = this.$refs.video;

      once(this.video, 'loadedmetadata', () => {
        this.videoDuration = this.video.duration;
      });
    },

    bindAnimation() {
      gsap.to(`.${this.$refs.video.className}`, {
        scrollTrigger: {
          trigger: this.trigger,
          scrub: this.scrub,
          pin: this.pin,
          start: this.start,
          end: this.end,
          onUpdate: (event) => {
            let progress = Math.round(event.progress * 100);
            this.$refs.video.currentTime = (this.videoDuration * progress) / 100;

            if (progress >= 10 && progress <= 80) {
              this.$refs.textBeginning.classList.add(SHOW_TEXT_CLASS);
            } else {
              this.$refs.textBeginning.classList.remove(SHOW_TEXT_CLASS);
            }

            if (progress >= 90) {
              this.$refs.textEnding.classList.add(SHOW_TEXT_CLASS);
            } else {
              this.$refs.textEnding.classList.remove(SHOW_TEXT_CLASS);
            }
          },
        },
      });
    },
  },
};
</script>
