<template>
  <div class="indexing">
    <span class="indexing__current">{{ current }}</span>
    <span class="indexing__separator">{{ separator }}</span>
    <span class="indexing__from">{{ from }}</span>
  </div>
</template>

<script>
export default {
  name: 'Indexing',
  props: {
    current: {
      type: String,
      required: false,
    },
    from: {
      type: String,
      required: false,
    },
    separator: {
      type: String,
      required: false,
      default: '/',
    },
  },
};
</script>
