import getValue from './getValue';
import getTagValues from './getTagValues';

/**
 * TODO: Refactor the function to reduce cognitive complexity.
 *
 * Returns all data it can find in the dom for an analytics action.
 * @param {Node} node Parent Node to query for data. Closest [data-tag-wrapper] to Node which triggered the event.
 * @param {Object} data Base data send by the Node which triggered the event. This data will be merged with the data we
 *   find in the Dom.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export default function createDataObjectBasedOnDom(node, data) {
  const dataFromDom = getTagValues(node, ['category', 'action', 'label', 'currency', 'name']);
  const eventName = getValue(node, '[data-tag-event-name]', 'event-name');
  if (
    data.type === 'productClick' ||
    data.type === 'productImpression' ||
    data.type === 'productVariantClick'
  ) {
    const productsNodes = node.querySelectorAll('[data-tag-product-identifier]');
    dataFromDom.products = [];

    for (const product of productsNodes) {
      const productInfo = {};
      const currency = getValue(node, '[data-tag-product-currency]', 'product-currency');
      const productName = getValue(product, '[data-tag-product-name]', 'product-name');
      const productId = getValue(product, '[data-tag-product-id]', 'product-id');
      const productCategory = getValue(product, '[data-tag-product-category]', 'product-category');
      const productBrand = getValue(product, '[data-tag-product-brand]', 'product-brand');
      const productPrice = getValue(product, '[data-tag-product-price]', 'product-price');
      const productVariant = getValue(product, '[data-tag-product-variant]', 'product-variant');
      const productPosition = getValue(product, '[data-tag-product-position]', 'product-position');
      const productList = getValue(product, '[data-tag-product-list]', 'product-list');

      const productDimension35 = getValue(
        product,
        '[data-tag-product-dimension35]',
        'product-dimension35'
      );
      const productDimension36 = getValue(
        product,
        '[data-tag-product-dimension36]',
        'product-dimension36'
      );
      const productDimension37 = getValue(
        product,
        '[data-tag-product-dimension37]',
        'product-dimension37'
      );
      const productDimension38 = getValue(
        product,
        '[data-tag-product-dimension38]',
        'product-dimension38'
      );
      const productDimension39 = getValue(
        product,
        '[data-tag-product-dimension39]',
        'product-dimension39'
      );
      const productDimension40 = getValue(
        product,
        '[data-tag-product-dimension40]',
        'product-dimension40'
      );
      const productDimension48 = getValue(
        product,
        '[data-tag-product-dimension48]',
        'product-dimension48'
      );
      const productDimension49 = getValue(
        product,
        '[data-tag-product-dimension49]',
        'product-dimension49'
      );
      const productDimension50 = getValue(
        product,
        '[data-tag-product-dimension50]',
        'product-dimension50'
      );
      const productDimension152 = getValue(
        product,
        '[data-tag-product-dimension152]',
        'product-dimension152'
      );
      const productDimension153 = getValue(
        product,
        '[data-tag-product-dimension153]',
        'product-dimension153'
      );
      const productDimension154 = getValue(
        product,
        '[data-tag-product-dimension154]',
        'product-dimension154'
      );
      const productDimension91 = getValue(
        product,
        '[data-tag-product-dimension91]',
        'product-dimension91'
      );
      const productDimensioncd176 = getValue(
        product,
        '[data-tag-product-dimensioncd176]',
        'product-dimensioncd176'
      );

      if (currency) {
        dataFromDom.currency = currency;
      }
      if (productName) {
        productInfo.name = productName;
      }
      if (productId) {
        productInfo.id = productId;
      }
      if (productCategory) {
        productInfo.category = productCategory;
      }
      if (productBrand) {
        productInfo.brand = productBrand;
      }
      if (productPrice) {
        productInfo.price = productPrice;
      } else {
        productInfo.price = '0.00';
      }
      if (productVariant) {
        productInfo.variant = productVariant;
      }
      if (productPosition) {
        productInfo.position = productPosition;
      }
      if (productList && data.type === 'productClick') {
        dataFromDom.list = productList;
      }
      if (productList && data.type === 'productImpression') {
        productInfo.list = productList;
      }
      if (productList && data.type === 'productVariantClick') {
        dataFromDom.list = productList;
      }

      if (productDimension35) {
        productInfo.dimension35 = productDimension35;
      }
      if (productDimension36) {
        productInfo.dimension36 = productDimension36;
      }
      if (productDimension37) {
        productInfo.dimension37 = productDimension37;
      }
      if (productDimension38) {
        productInfo.dimension38 = productDimension38;
      }
      if (productDimension39) {
        productInfo.dimension39 = productDimension39;
      }
      if (productDimension40) {
        productInfo.dimension40 = productDimension40;
      }
      if (productDimension48) {
        productInfo.dimension48 = productDimension48;
      }
      if (productDimension49) {
        productInfo.dimension49 = productDimension49;
      }
      if (productDimension50) {
        productInfo.dimension49 = productDimension50;
      }
      if (productDimension152) {
        productInfo.dimension152 = productDimension152;
      }
      if (productDimension153) {
        productInfo.dimension153 = productDimension153;
      }
      if (productDimension154) {
        productInfo.dimension3154 = productDimension154;
      }
      if (productDimension91) {
        productInfo.dimension91 = productDimension91;
      }
      if (productDimensioncd176) {
        productInfo.dimensioncd176 = productDimensioncd176;
      }

      dataFromDom.products.push(productInfo);
    }
  }

  if (data.type === 'promotionClick' || data.type === 'promotionImpression') {
    const promotionNodes = node.querySelectorAll('[data-tag-promotion-identifier]');
    dataFromDom.promotions = [];

    for (const promotion of promotionNodes) {
      const promotionInfo = {};
      const promotionName = getValue(promotion, '[data-tag-promotion-name]', 'promotion-name');
      const promotionId = getValue(promotion, '[data-tag-promotion-id]', 'promotion-id');
      const promotionCreative = getValue(
        promotion,
        '[data-tag-promotion-creative]',
        'promotion-creative'
      );
      const promotionPosition = getValue(
        promotion,
        '[data-tag-promotion-position]',
        'promotion-position'
      );

      if (promotionName) {
        promotionInfo.name = promotionName;
      }
      if (promotionId) {
        promotionInfo.id = promotionId;
      }
      if (promotionCreative) {
        promotionInfo.creative = promotionCreative;
      }
      if (promotionPosition) {
        promotionInfo.position = promotionPosition;
      }

      dataFromDom.promotions.push(promotionInfo);
    }
  }

  if (data.type === 'addToCart') {
    const ecommerce = {};
    // eslint-disable-next-line sonarjs/prefer-object-literal
    const add = {};

    add.products = [];
    const productInfo = {};
    const productName = getValue(node, '[data-tag-product-name]', 'product-name');
    const productId = getValue(node, '[data-tag-product-id]', 'product-id');
    const productCategory = getValue(node, '[data-tag-product-category]', 'product-category');
    const productBrand = getValue(node, '[data-tag-product-brand]', 'product-brand');
    const productPrice = getValue(node, '[data-tag-product-price]', 'product-price');
    const productQuantity = getValue(node, '[data-tag-product-quantity]', 'product-quantity');
    const productVariant = getValue(node, '[data-tag-product-variant]', 'product-variant');
    const productDimension36 = getValue(
      node,
      '[data-tag-product-dimension36]',
      'product-dimension36'
    );
    const productDimension38 = getValue(
      node,
      '[data-tag-product-dimension38]',
      'product-dimension38'
    );
    const productDimension48 = getValue(
      node,
      '[data-tag-product-dimension48]',
      'product-dimension48'
    );
    const productDimension49 = getValue(
      node,
      '[data-tag-product-dimension49]',
      'product-dimension49'
    );
    const productDimension59 = getValue(
      node,
      '[data-tag-product-dimension59]',
      'product-dimension59'
    );
    const productDimension91 = getValue(
      node,
      '[data-tag-product-dimension91]',
      'product-dimension91'
    );
    const productDimensioncd176 = getValue(
      node,
      '[data-tag-product-dimensioncd176]',
      'product-dimensioncd176'
    );

    if (productName) {
      productInfo.name = productName;
    }
    if (productId) {
      productInfo.id = productId;
    }
    if (productPrice) {
      productInfo.price = productPrice;
    } else {
      productInfo.price = 'none';
    }
    if (productBrand) {
      productInfo.brand = productBrand;
    }
    if (productCategory) {
      productInfo.category = productCategory;
    }
    if (productVariant) {
      productInfo.variant = productVariant;
    }
    if (productQuantity) {
      productInfo.quantity = productQuantity;
    }
    if (productDimension36) {
      productInfo.dimension36 = productDimension36;
    }
    if (productDimension38) {
      productInfo.dimension38 = productDimension38;
    }
    if (productDimension48) {
      productInfo.dimension48 = productDimension48;
    }
    if (productDimension49) {
      productInfo.dimension49 = productDimension49;
      var handBuypageURL = new URLSearchParams(window.location.search);
      var handBuylastURL = handBuypageURL.has('utm_medium');
      if (handBuylastURL === true) {
        productInfo.dimension49 = 'activated';
      }
    }
    if (productDimension59) {
      productInfo.dimension59 = productDimension59;
    }
    if (productDimension91) {
      productInfo.dimension91 = productDimension91;
    }
    if (productDimensioncd176) {
      productInfo.dimensioncd176 = productDimensioncd176;
    }

    add.products.push(productInfo);
    const currency = getValue(node, '[data-tag-product-currency]', 'product-currency');
    if (currency) {
      ecommerce.currencyCode = currency;
    }
    ecommerce.add = add;
    dataFromDom.ecommerce = ecommerce;

    dataFromDom.event_name = eventName;

    dataFromDom.productInfo = getValue(node, '[data-tag-product-info]', 'product-info');

    dataFromDom.storeName = getValue(node, '[data-tag-store-name]', 'store-name');

    dataFromDom.step = getValue(node, '[data-tag-step]', 'step');
  }

  if (data.type === 'socialIntEvent') {
    dataFromDom.event_name = eventName;
    dataFromDom.ecommerce = getValue(node, '[data-tag-ecommerce]', 'ecommerce');
    dataFromDom.network = getValue(node, '[data-tag-network]', 'network');
    dataFromDom.social_network = getValue(node, '[data-tag-social-network]', 'social-network');
    dataFromDom.social_action = getValue(node, '[data-tag-social-action]', 'social-action');
    dataFromDom.target = window.location.href;
  }

  if (data.type === 'userActionEvent') {
    const productInformation = getValue(node, '[data-tag-product-info]', 'product-info');
    const productStep = getValue(node, '[data-tag-step]', 'step');
    const clickArea = getValue(node, '[data-tag-click-area]', 'click-area');
    const breadcrumb = getValue(node, '[data-tag-breadcrumb]', 'breadcrumb');
    const articleName = getValue(node, '[data-tag-article-name]', 'article-name');
    const moduleName = getValue(node, '[data-tag-module-name]', 'module-name');
    const ctaName = getValue(node, '[data-tag-cta-name]', 'cta-name');
    const linkUrl = getValue(node, '[data-tag-link-url]', 'link-url');
    const serviceName = getValue(node, '[data-tag-service-name]', 'service-name');
    const serviceCategory = getValue(node, '[data-tag-service-category]', 'service-category');
    const eventDetail = getValue(node, '[data-tag-event-detail]', 'event-detail');

    if (eventName) {
      dataFromDom.event_name = eventName;
    }
    if (clickArea) {
      dataFromDom.click_area = clickArea;
    }

    if (eventDetail) {
      dataFromDom.event_detail = eventDetail;
    }

    if (breadcrumb) {
      dataFromDom.breadcrumb = breadcrumb;
    }

    if (productInformation) {
      dataFromDom.productInfo = productInformation;
    }
    if (productStep) {
      dataFromDom.step = productStep;
    }

    if (articleName) {
      dataFromDom.article_name = articleName;
    }
    if (moduleName) {
      dataFromDom.module_name = moduleName;
    }
    if (ctaName) {
      dataFromDom.cta_name = ctaName;
    }

    if (linkUrl) {
      dataFromDom.link_url = linkUrl;
    }

    if (serviceName) {
      dataFromDom.service_name = serviceName;
    }

    if (serviceCategory) {
      dataFromDom.service_category = serviceCategory;
    }
  }

  return { ...data, ...dataFromDom };
}
