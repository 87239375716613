<!-- eslint-disable vue/no-v-html -->
<template>
  <ul>
    <li v-for="(product, $key) in items" :key="$key" :class="cssClass">
      <oap-card
        v-is-intersecting-x
        type="product"
        :item="product"
        :layouts="['in-slider']"
        :display-review-count="displayReviewCount"
      >
      </oap-card>
    </li>
  </ul>
</template>

<script>
import { tag } from '../../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';
import { srcset } from '../../../../../../Foundation/Core/code/Scripts/directives/srcset/srcset';
import oapCard from '../../../../../OapCard/code/Scripts/components/OapCard.vue';

export default {
  name: 'ProductListModel',
  directives: {
    tag,
    srcset,
  },
  components: {
    oapCard,
  },
  props: {
    displayReviewCount: {
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    cssClass: {
      type: String,
      required: false,
      default: 'oap-slider__item',
    },
  },
};
</script>
