<template>
  <picture>
    <source :media="media" :sizes="sizes" :srcset="desktop" />
    <source :sizes="sizes" :srcset="mobile" />
    <img :src="mobile" :alt="alt" />
  </picture>
</template>

<script>
export default {
  name: 'OapPicture',

  props: {
    alt: { type: String, default: '' },
    media: { type: String, default: '(min-width: 999px)' },
    sizes: { type: String, default: '100vw' },
    mobile: { type: String, required: true },
    desktop: { type: String, required: true },
  },
};
</script>
