<template>
  <div class="vtoBody">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VtoBody',
};
</script>
