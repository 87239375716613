import 'lazysizes';
import { createApp } from 'vue';
import { createStore } from 'vuex';

// Plugins for zone-level customizations
import loadZoneCustomizations from '@loreal/zone-plugin-loader';
import init from '@loreal/sisyphus';

// Sitecore-Vue bridge
import SitecoreVueAdapter from '@Foundation/sitecore/SitecoreVueAdapter';

// Helpers
import { requireAll } from '@Foundation/utilities/helpers';

// Foundation
import Foundation from '@Foundation';
import LoadScriptModule from '@Foundation/loadscript';
import eventBus, { exposeGlobals } from '@loreal/eventbus-js';
import { tag } from '@Foundation/analyticsHandler/directives/tag';

// Feature
import AlertModule from '@Feature/Alert';
import CarouselModule from '../../../../../../Feature/Carousel';
import CookieBanner from '@Feature/PageContentExt';
import HeaderLogo from '@Feature/HeaderLogo';
import Iframepopup from '@Feature/IframePopup';
import ModalModule from '@Feature/Modal';
import NavigationModule from '@Feature/Navigation';
import NewsletterModule from '@Feature/NewsLetter';
import NotificationModule from '@Feature/Notification';
import OapAccountNavigation from '@Feature/OapAccountNavigation';
import OapCard from '@Feature/OapCard';
import OapCardPopin from '@Feature/OapCardPopin';
import OapCarouselControls from '@Feature/CarouselControls';
import OapContrastSwitcher from '@Feature/OapContrastSwitcher';
import OapEventsNavigation from '@Feature/OapEventsNavigation';
import OapFocusTrap from '@Feature/OapFocusTrap';
import OapIframe from '@Feature/OapIframe';
import OapBeautyGeniusWrapper from '@Feature/OapBeautyGeniusWrapper';
import OapBeforeAfter from '@Feature/OapBeforeAfter';
import OapImmersiveBeforeAfter from '@Feature/OapImmersiveBeforeAfter';
import OapImmersiveBeforeAfterVideo from '@Feature/OapImmersiveBeforeAfterVideo';
import OapImmersiveCopyOnVideo from '@Feature/OapImmersiveCopyOnVideo';
import OapImmersiveHotspots from '@Feature/OapImmersiveHotspots';
import OapImmersiveParallax from '@Feature/OapImmersiveParallax';
import OapImmersiveParallaxHero from '@Feature/OapImmersiveParallaxHero';
import OapLoader from '@Feature/OapLoader';
import OapMarkupRenderingDelay from '@Feature/OapMarkupRenderingDelay';
import OapMarquee from '@Feature/OapMarquee';
import OapMediaCarousel from '@Feature/OapMediaCarousel';
import OapMediaCover from '@Feature/OapMediaCover';
import OapPopin from '@Feature/OapPopin';
import OapPopup from '@Feature/OapPopup';
import OapPageNavigation from '@Feature/OapPageNavigation';
import OapPromoBar from '@Feature/OapPromoBar';
import OapScroller from '@Feature/OapScroller';
import OapSlider from '@Feature/OapSlider';
import OapStrikethroughScrolling from '@Feature/OapStrikethroughScrolling';
import OapTabComponent from '@Feature/OapTab';
import OapToastNotification from '@Feature/OapToastNotification';
import OapUsage from '@Feature/OapUsage';
import OapVideoImmersive from '@Feature/OapVideoImmersive';
import OneTrustCookieHelper from '@Feature/OneTrust';
import Overlay from '@Feature/Overlay';
import RevTrax from '@Feature/RevTrax';
import Search from '@Feature/Search';
import SearchSuggestions from '@Feature/SearchSuggestions';
import Sfmc from '@Feature/SFMC';
import Slider from '@Feature/Slider';
import VideoPlaceholder from '@Feature/VideoPlaceholder';
import VirtualTryOn from '@Feature/VirtualTryOn';
import VueModels from '@Feature/VueModels';

import OapShadeFinderCarousel from '@Feature/OapShadeFinderCarousel';
import OapTechDetails from '@Feature/OapTechDetails';
import OapSustainability from '@Feature/OapSustainability';
import AccessibilityStore from '@Foundation/accessibilityStore';
import OapPromoCard from '@Feature/OapPromoCard';

// 3rd party
import Toasted from '@Feature/OapToastNotification/code/plugin';
import { Tabs, Tab } from 'vue3-tabs-component';

exposeGlobals();

const app = createApp({});
// TODO: To be enabled if removing imports for eventBus makes sense
// app.config.globalProperties.eventBus = eventBus;
app.config.compilerOptions.whitespace = 'preserve';
app.config.performance = true;

const VueTabsPlugin = {
  install(app) {
    app.component('Tabs', Tabs);
    app.component('Tab', Tab);
  },
};

// Bootstrap Vue
app.use(Toasted);
app.use(VueTabsPlugin);

// Register directives
Object.entries({
  // ...CommerceConnector.directives,
  ...Foundation.directives,
  ...LoadScriptModule.directives,
  ...NavigationModule.directives,
  ...Search.directives,
  tag,
}).forEach(([name, directive]) => app.directive(name, directive));

// Register component
// TODO: Object.values ???
Object.entries({
  ...AlertModule.components,
  ...CarouselModule.components,
  ...CookieBanner.components,
  ...Foundation.components,
  ...HeaderLogo.components,
  ...Iframepopup.components,
  ...LoadScriptModule.components,
  ...ModalModule.components,
  ...NavigationModule.components,
  ...NewsletterModule.components,
  ...NotificationModule.components,
  ...OapAccountNavigation.components,
  ...OapCard.components,
  ...OapCardPopin.components,
  ...OapCarouselControls.components,
  ...OapContrastSwitcher.components,

  ...OapEventsNavigation.components,
  ...OapFocusTrap.components,
  ...OapIframe.components,
  ...OapBeautyGeniusWrapper.components,
  ...OapBeforeAfter.components,
  ...OapImmersiveBeforeAfter.components,
  ...OapImmersiveBeforeAfterVideo.components,
  ...OapImmersiveCopyOnVideo.components,
  ...OapImmersiveHotspots.components,
  ...OapImmersiveParallax.components,
  ...OapImmersiveParallaxHero.components,
  ...OapLoader.components,
  ...OapMarkupRenderingDelay.components,
  ...OapMarquee.components,
  ...OapMediaCarousel.components,
  ...OapMediaCover.components,

  ...OapPopin.components,
  ...OapPopup.components,
  ...OapPageNavigation.components,
  ...OapPromoBar.components,
  ...OapScroller.components,
  ...OapSlider.components,

  ...OapStrikethroughScrolling.components,
  ...OapTabComponent.components,
  ...OapToastNotification.components,
  ...OapUsage.components,
  ...OapVideoImmersive.components,
  ...OneTrustCookieHelper.components,
  ...Overlay.components,
  ...RevTrax.components,
  ...SearchSuggestions.components,
  ...Sfmc.components,
  ...Slider.components,

  ...VideoPlaceholder.components,
  ...VirtualTryOn.components,
  ...VueModels.components,
  ...OapShadeFinderCarousel.components,
  ...OapTechDetails.components,
  ...OapSustainability.components,
  ...OapPromoCard.components,
}).forEach(([name, component]) => app.component(name, component));

Object.entries({
  ...Foundation.filters,
}).forEach(([name, filter]) => app.filter(name, filter));

const store = createStore({
  plugins: [...VirtualTryOn.plugins],
  modules: {
    // ...FilterModule.modules,
    ...OapTabComponent.modules,
    ...AccessibilityStore.modules,
  },
});

app.use(store);

/**
 * Initialize any zone-level customizations.
 * Every zone/country can create their own Vue components and make them available
 * to their code through the use of a Vue.js Plugin
 *
 * @see https://vuejs.org/guide/reusability/plugins.html#writing-a-plugin
 * */
loadZoneCustomizations(app, store);
// Initialize third party loader
init();

requireAll(require.context('../../../Icons/', true, /\.svg$/));

document.addEventListener('lazybeforeunveil', (event) =>
  eventBus.emit('image.lazybeforeunveil', event)
);
window.RefAppStoreLocator && app.use(window.RefAppStoreLocator);
SitecoreVueAdapter.initializeVueApp(app.mount.bind(app, '#main-container'));
