import { AnalyticsNonInteractiveEventObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class NonInteractiveEventObject extends DataObject {
  constructor(data) {
    super('nievent', data);

    if (!data.category) {
      Logger.error(
        "[AnalyticsNonInteractiveEventObjectException] Category is required for nievent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsNonInteractiveEventObjectException(
        "Category is required for nievent (L'Oréal guideline)"
      );
    }

    if (!data.action) {
      Logger.error(
        "[AnalyticsNonInteractiveEventObjectException] Action is required for nievent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsNonInteractiveEventObjectException(
        "Action is required for nievent (L'Oréal guideline)"
      );
    }

    if (!data.label) {
      Logger.error(
        "[AnalyticsNonInteractiveEventObjectException] Label is required for nievent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsNonInteractiveEventObjectException(
        "Label is required for nievent (L'Oréal guideline)"
      );
    }

    this.result.ecommerce = undefined;
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category);
  }

  get category() {
    return this.result.eventCategory;
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action);
  }

  get action() {
    return this.result.eventAction;
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label);
  }

  get label() {
    return this.result.eventLabel;
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set event_name(event_name) {
    this.result.event_name = this.setProperty(event_name);
  }

  get event_name() {
    return this.result.event_name;
  }

  set service_name(service_name) {
    this.result.service_name = this.setProperty(service_name);
  }

  get service_name() {
    return this.result.service_name;
  }

  set module_name(module_name) {
    this.result.module_name = this.setProperty(module_name);
  }

  get module_name() {
    return this.result.module_name;
  }

  set cta_name(cta_name) {
    this.result.cta_name = this.setProperty(cta_name);
  }

  get cta_name() {
    return this.result.cta_name;
  }

  set link_url(link_url) {
    this.result.link_url = this.setProperty(link_url);
  }

  get link_url() {
    return this.result.link_url;
  }

  set video_title(video_title) {
    this.result.video_title = this.setProperty(video_title);
  }

  get video_title() {
    return this.result.video_title;
  }
}
