<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div>
    <!-- Both products and looks can represent the sub-entity here -->
    <VtoCirclesContainer class="vtoSubentity">
      <VtoCircle
        v-for="{ id, starProductItem, type } in getProductsOfSelectedLine"
        :key="id"
        :entity="setStarProductEntity(starProductItem)"
        :class="{ '-active': id === selectedProductId }"
        @click="selectSubentity(type, id, setStarProductEntity(starProductItem))"
      />
    </VtoCirclesContainer>

    <ul class="vtoTabs scrollbarCustom">
      <li
        v-for="typeId of getTypeIds"
        :key="typeId"
        class="vtoTab"
        :class="{
          '-active': typeId === selectedTypeId,
          '-selected': selectedProducts.hasOwnProperty(typeId),
        }"
        @click="selectType(typeId)"
      >
        {{ productTypesMap[typeId].tabLabel }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from '../store/helpers';
import {
  TAGGING_INFO_SERVICE_CATEGORY,
  TAGGING_INFO_SERVICE_NAME,
  TAGGING_INFO_SERVICE_VERSION,
  TYPE_LOOK,
  TYPE_PRODUCT,
} from '../settings';
import VtoCircle from './VtoCircle.vue';
import VtoCirclesContainer from './VtoCirclesContainer.vue';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'VtoSubentitySelection',

  components: {
    VtoCircle,
    VtoCirclesContainer,
  },

  computed: {
    ...mapState(['productTypesMap', 'selectedTypeId', 'selectedProducts', 'selectedProductId']),
    ...mapGetters(['getTypeIds', 'getProductsOfSelectedLine', 'getCurrentCategoryTaggingTitle']),
  },

  methods: {
    ...mapActions(['selectType', 'selectShade', 'selectLookFamily', 'selectCatalogType']),

    selectSubentity(subentityType, subentityId, productEntity) {
      this.selectCatalogType(subentityType);

      if (subentityType === TYPE_LOOK) {
        this.selectLookFamily(subentityId);

        AnalyticsHandler.getAnalyticsHandler().push({
          event_name: 'select_lookfamily',
          type: 'userActionEvent',
          category: `virtual try on::modiface::${this.getCurrentCategoryTaggingTitle}`,
          action: 'select::lookfamily',
          label: productEntity.label,
          event_detail: productEntity.label,
          service_name: TAGGING_INFO_SERVICE_NAME,
          service_category: TAGGING_INFO_SERVICE_CATEGORY,
          service_version: TAGGING_INFO_SERVICE_VERSION,
        });
      } else if (subentityType === TYPE_PRODUCT) {
        this.selectShade(subentityId);
      }
    },

    setStarProductEntity(starProduct) {
      return {
        label: starProduct?.title,
        swatch: starProduct?.image?.fileName || '',
      };
    },
  },
};
</script>
