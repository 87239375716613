<template>
  <footer class="vtoStickyFooter">
    <button class="oap-button -primary -theme-brand" @click="$emit('buy')">
      <span class="oap-button__label">{{ buyButtonLabel }}</span>
    </button>
    <button
      v-if="shouldRenderSaveButton"
      class="oap-button -primary"
      @click="displayFloatingBar(true)"
    >
      <span class="oap-button__label">{{ saveButtonLabel }}</span>
    </button>
  </footer>
</template>

<script>
import { mapActions } from '../store/helpers';

export default {
  name: 'VtoStickyFooter',

  props: {
    buyButtonLabel: {
      type: String,
      default: '',
    },
    saveButtonLabel: {
      type: String,
      default: '',
    },
    shouldRenderSaveButton: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    ...mapActions(['displayFloatingBar']),
  },
};
</script>
