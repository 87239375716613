import {
  CATEGORY_HAIR_COLOR,
  CATALOG_VIEW_TRANSITION_MAP,
  SELECTION_MODE_BY_CATEGORY,
  PAGE_ENTITY_SELECTION,
  PAGE_CATALOG,
  PAGE_MAIN,
  CATALOG_MODE_SINGLE,
  CATALOG_MODE_SWITCH,
  TYPE_LOOK,
  TYPE_PRODUCT,
  TRANSITION_SWIPE_IN,
  TRANSITION_SWIPE_OUT,
  FACET_STATE,
  VIEW_ENTITY_SELECTION,
  VIEW_SUBENTITY_SELECTION,
  VIEW_LOOK_SELECTION,
} from '../settings';

import routes from '../routes';

const filterSelectedProductLinesShades = (productLinesMap, productItemsMap, selectedProductId) => {
  return productLinesMap[productItemsMap[selectedProductId].productLineId].productItemIds.map(
    (productId) => {
      const { id, name, hex, dark, swatch, upc } = productItemsMap[productId];
      return { id, name, hex, dark, swatch, upc };
    }
  );
};

export default {
  // categories
  /**
   * @returns {boolean}
   */
  isMainCategoryHairColor: ({ mainCategory }) => mainCategory === CATEGORY_HAIR_COLOR,

  /**
   * @returns {string}
   */
  getMainCategory: ({ mainCategory }) => mainCategory,

  /**
   * @returns {object}
   */
  getOppositeCategory: (state) => state.productCategoriesMap[state.oppositeCategory],

  // selection
  /**
   * @returns {object}
   */
  getSelectedProductItem: (state) => state.productItemsMap[state.selectedProductId],

  getSelectedProductItems: ({ productLinesMap, productItemsMap, selectedProducts }) => {
    if (!selectedProducts) {
      return [];
    }

    return Object.keys(selectedProducts).map((key) => {
      const selectedProductId = selectedProducts[key];

      return filterSelectedProductLinesShades(
        productLinesMap,
        productItemsMap,
        selectedProductId
      ).filter((p) => p.id === selectedProductId)[0];
    });
  },

  // return an array of shade objects from selected product line
  /**
   * @returns {Array}
   */
  getSelectedProductLineShades: ({ productLinesMap, productItemsMap, selectedProductId }) => {
    return !selectedProductId
      ? []
      : filterSelectedProductLinesShades(productLinesMap, productItemsMap, selectedProductId);
  },

  /**
   * @returns {Array}
   */
  getSelectedTypeProductLineIds: (state) =>
    state.productTypesMap[state.selectedTypeId].productLineIds,

  /**
   * @returns {Array}
   */
  getProductsOfSelectedLine: (state, getters) =>
    getters.getSelectedTypeProductLineIds.map(
      (selectedTypeProductLineId) => state.productLinesMap[selectedTypeProductLineId]
    ),

  /**
   * @returns {Array}
   */
  getProductsIdsOfSelectedLine: (state, getters) =>
    state.productLinesMap[getters.getSelectedProductItem.productLineId].productItemIds,

  /**
   * @returns {Array}
   */
  getProductCategoryIdsWithoutLooks({ productCategoriesMap, productTypesMap }, getters) {
    return productCategoriesMap[getters.getCatalogCategory].productTypeIds.filter(
      (id) => productTypesMap[id].type !== TYPE_LOOK
    );
  },

  /**
   * @returns {number}
   */
  getSelectedProductsAmount: ({ selectedProducts }) => {
    return Object.keys(selectedProducts).length;
  },

  /**
   * @returns {Object}
   */
  getSelectedProducts: ({ selectedProducts }) => selectedProducts, // Object.keys(selectedProducts).join(',');

  /**
   * @returns {string}
   */
  getSelectedTypeTabLabel: (state) => state.productTypesMap[state.selectedTypeId].tabLabel,

  // catalog
  /**
   * @returns {string|null}
   */
  getCatalogMode: ({ isMainCatalog, mainCategory, catalogType }) => {
    if (!isMainCatalog) {
      return CATALOG_MODE_SWITCH;
    }

    if (catalogType === TYPE_PRODUCT) {
      return SELECTION_MODE_BY_CATEGORY[mainCategory];
    } else if (catalogType === TYPE_LOOK) {
      return TYPE_LOOK;
    }

    return null;
  },

  /**
   * @returns {string|null}
   */
  getCatalogType: ({ catalogType }) => catalogType,

  /**
   * @returns {string}
   */
  getCatalogCategory: ({ isMainCatalog, mainCategory, oppositeCategory }) => {
    return isMainCatalog ? mainCategory : oppositeCategory;
  },

  /**
   * @returns {string}
   */
  getCatalogView: ({ catalogView }) => catalogView,

  /**
   * @returns {string|null}
   */
  getCatalogViewTransition: ({ catalogView }) =>
    CATALOG_VIEW_TRANSITION_MAP[catalogView] || CATALOG_VIEW_TRANSITION_MAP.default,

  /**
   * @returns {string}
   */
  getEntitySelectionView: ({ selectedLookId, selectedTypeId }) => {
    if (selectedTypeId == null) {
      return VIEW_ENTITY_SELECTION;
    } else if (selectedTypeId && !selectedLookId) {
      return VIEW_SUBENTITY_SELECTION;
    } else if (selectedLookId) {
      return VIEW_LOOK_SELECTION;
    }
  },

  getLists: ({ productListsMap, selectedTypeId }) => productListsMap[selectedTypeId],

  /**
   * @returns {Array}
   */
  getTypeIds: (state, getters) =>
    state.productCategoriesMap[getters.getCatalogCategory].productTypeIds,

  /**
   * @returns {Array}
   */
  getSelectedLookFamilyLookIds: (state) =>
    state.productLinesMap[state.selectedLookFamilyId].productItemIds,

  /**
   * @returns {Array}
   */
  getSelectedLookProductIds: (state) => state.productItemsMap[state.selectedLookId].productItemIds,

  /**
   * @returns {Array}
   */
  getSelectedLookProducts: (state, getters) =>
    getters.getSelectedLookProductIds.map((lookId) => state.productItemsMap[lookId]),

  // routing
  /**
   * @returns {string}
   */
  getCurrentRouteComponent: ({ currentPage }) => routes[currentPage].component,

  /**
   * @returns {(string|null)}
   */
  getCurrentRouteTransition: ({ currentPage, previousPage }) => {
    if (currentPage === PAGE_MAIN && previousPage === PAGE_CATALOG) {
      return TRANSITION_SWIPE_IN;
    }
    if (currentPage === PAGE_CATALOG) {
      return TRANSITION_SWIPE_OUT;
    }
    if (currentPage === PAGE_CATALOG && previousPage === PAGE_ENTITY_SELECTION) {
      return TRANSITION_SWIPE_OUT;
    }
    if (currentPage === PAGE_ENTITY_SELECTION && previousPage === PAGE_CATALOG) {
      return TRANSITION_SWIPE_IN;
    }
    return null;
  },

  // tagging
  /**
   * @returns {object}
   */
  getTaggingInfo: (
    _,
    {
      getSelectedProductItem: {
        name,
        price,
        brand,
        subBrand,
        category,
        position,
        dimension35,
        dimension36,
        dimension38,
        dimension48,
        dimension50,
        dimension59,
        dimension91,
        taggingBrand,
      },
    }
  ) => ({
    products: [
      {
        name: subBrand ? `${subBrand} ${name}` : name,
        id: dimension48,
        price: price ? price : 'none',
        brand: taggingBrand, // todo static config ?
        ...(subBrand && { subBrand }),
        category,
        variant: brand,
        position,
        ...(dimension35 && { dimension35 }),
        ...(dimension36 && { dimension36 }),
        dimension38,
        dimension48,
        dimension49: 'activated', // todo static config ?
        dimension50,
        dimension59,
        ...(dimension91 && { dimension91 }),
      },
    ],
  }),

  // facets
  getFacets: (state) => state[FACET_STATE],

  getCurrentFacetsList: (state) =>
    Object.values(state[FACET_STATE]).reduce((acc, list) => [...acc, ...list], []),

  /**
   * @returns {boolean}
   */
  hasFacets: (state, getters) => {
    return getters.getCurrentFacetsList.length > 0;
  },

  /**
   * @returns {boolean}
   */
  isRecommendedProduct: (state, getters) => {
    // todo improvement: currently updated twice on facet updates:
    // 1 hasFacets updates
    // 2 facetedProductIdsCollection updates

    return (
      !getters.hasFacets ||
      state.facetedProductIdsCollection.indexOf(state.selectedProductId) !== -1
    );
  },

  // Appearing circles animation
  /**
   * @returns {boolean}
   */
  isCirclesAnimated: (state) => state.circlesAnimatedStatus,

  /**
   * @returns {Element}
   */
  MCTBButton: () => document.querySelector('.rfp-mctb-common-buynow-header button'),

  /**
   * @returns {Element}
   */
  channelAdvisorButton: () => document.querySelector('.oap-buy-cta__channel-advisor button'),

  /**
   * @returns {Element}
   */
  singleERetailerButton: () => document.querySelector('.oap-single-e-retailer'),

  /**
   * @returns {Element}
   */
  commerceConnector: () => document.querySelector('.commerceConnector'),

  /**
   * @param _
   * @param getters
   * @returns {boolean}
   */
  areAllServicesDisabled: (_, getters) =>
    !getters.MCTBButton &&
    !getters.channelAdvisorButton &&
    !getters.singleERetailerButton &&
    !getters.commerceConnector,

  /**
   * @returns {string}
   */
  getSelectedLookFamilyTitle: (state) =>
    state.productLinesMap[state.selectedLookFamilyId].starProductItem.title,

  /**
   * @returns {string}
   */
  getCurrentCategoryTaggingTitle: (state, getters) => {
    return state.productCategoriesMap[getters.getCatalogCategory].taggingTitle.toLowerCase();
  },
};
