<template>
  <div v-show="started" class="vtoHairDetection vto__page">
    <!-- TODO: use `VtoHeader` template in the `VtoPageHairDetection`-->
    <div class="vtoHeader">
      <div v-show="loading" class="vtoHairDetection__loading">
        <div class="vtoHairDetection__loadingText">{{ dictionary.hairDetection.loadingText }}</div>
        <VtoBackButton @click="retry" />
      </div>

      <div v-show="!loading && !success" class="vtoHairDetection__errorMessage">
        <div class="vtoHairDetection__errorText">{{ dictionary.hairDetection.errorText }}</div>
        <button class="vtoHairDetection__errorButton" @click="retry()">
          {{ dictionary.hairDetection.retryButtonText }}
        </button>
      </div>
    </div>

    <div class="vtoHairDetection__footer">
      <button class="oap-button vtoHairDetection__skipButton" @click="skipHairColorDetection">
        {{ dictionary.hairDetection.skipButtonText }}
      </button>
    </div>
  </div>
</template>

<script>
import { FACET_ATTRIBUTES, VTO_STORE_MODULE_NAMESPACE } from '../settings';

import {
  DO_GO_TO_PAGE_MAIN,
  DO_MF_HAIR_COLOR_DETECTED,
  DO_MF_SEND_EXIT,
  DO_MF_SHOW_FOR_YOU_LOADER,
  DO_UPDATE_FACET,
} from '../store/actions';

import { mapActions, mapState } from '../store/helpers';

import VtoBackButton from './VtoBackButton.vue';

const HAIR_DETECTION_WAITING_DELAY = 4000,
  MF_SHOW_FOR_YOU_LOADER_ACTION = `${VTO_STORE_MODULE_NAMESPACE}/${DO_MF_SHOW_FOR_YOU_LOADER}`,
  MF_HAIR_COLOR_DETECTED_ACTION = `${VTO_STORE_MODULE_NAMESPACE}/${DO_MF_HAIR_COLOR_DETECTED}`;

let unsubscribeAction;

export default {
  name: 'VtoPageHairDetection',

  components: {
    VtoBackButton,
  },

  data() {
    return {
      waitingSince: null,
      started: false,
      loading: true,
      success: false,
      skipped: false,
    };
  },

  computed: {
    ...mapState(['dictionary']),
  },

  methods: {
    ...mapActions([DO_GO_TO_PAGE_MAIN, DO_UPDATE_FACET, DO_MF_SEND_EXIT]),

    startHairColorDetection() {
      this.started = true;
      /* istanbul ignore next */
      this.waitingSince = Date.now();
    },

    detectedHairColorDetection({ hairColorFamily }) {
      // how long we waited since hair color detection started
      /* istanbul ignore next */
      const waited = Date.now() - this.waitingSince;

      /* istanbul ignore next */
      setTimeout(() => {
        if (!this.skipped) this.updateHairColorDetection(hairColorFamily);
      }, Math.max(0, HAIR_DETECTION_WAITING_DELAY - waited));
    },

    updateHairColorDetection(hairColorFamily) {
      // update hairColor related states
      this[DO_UPDATE_FACET]({
        attribute: FACET_ATTRIBUTES.HAIR_COLOR_FAMILY,
        facetIds: hairColorFamily ? [hairColorFamily] : [],
      });

      this.loading = false;
      this.success = hairColorFamily || false;
      // automatically proceed if hairColorFamily has truthy value,
      // else user will proceed manually
      /* istanbul ignore else */
      if (this.success) this.doneHairColorDetection();
    },

    skipHairColorDetection() {
      // Set to bail detected color async callback
      this.skipped = true;
      // Clear state and catalog faceting
      this[DO_UPDATE_FACET]({
        attribute: FACET_ATTRIBUTES.HAIR_COLOR_FAMILY,
        facetIds: [],
      });
      this.doneHairColorDetection();
    },

    doneHairColorDetection() {
      this[DO_GO_TO_PAGE_MAIN]();
    },

    retry() {
      this.started = false;
      this[DO_MF_SEND_EXIT]();
    },
  },

  beforeMount() {
    unsubscribeAction = this.$store.subscribeAction(({ type, payload }) => {
      /* istanbul ignore next */
      if (type === MF_SHOW_FOR_YOU_LOADER_ACTION) this.startHairColorDetection();
      /* istanbul ignore next */
      if (type === MF_HAIR_COLOR_DETECTED_ACTION) this.detectedHairColorDetection(payload);
    });
  },

  destroyed() {
    /* istanbul ignore next */
    unsubscribeAction();
  },
};
</script>
