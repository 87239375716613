<template>
  <div
    class="colorDropdown__content dropdown__dates"
    :class="{
      '-open': expanded,
      '-colorHovered': isColorHovered,
      '-dropdownHovered': isDropdownHovered,
      '-invalid': isValid,
    }"
  >
    <div class="colorDropdown__current">
      <label id="color-label" class="isSrOnly" :for="id || `current-color`">
        <slot name="label" />
      </label>
      <button
        :id="id || `current-color`"
        ref="toggler"
        class="colorDropdown__currentColorButton"
        :aria-expanded="expanded ? 'true' : 'false'"
        :aria-describedby="descriptionId"
        type="button"
        @keyup.esc="close"
        @click="toggle"
        @focus="handleFocus('focus', true)"
        @blur="handleFocus('blur', false)"
      >
        {{ selected.label || dates.find((d) => d.selected === true).label }}
      </button>
      <div class="colorDropdown__border" />
      <div v-show="isColorHovered" class="colorDropdown__currentColor">
        <span class="colorDropdown__currentColorBorder" />
      </div>
    </div>
    <ul class="colorDropdown__list scrollbarCustom" tabindex="-1">
      <li
        v-for="date in dates"
        :key="date.value"
        class="colorDropdown__list__wrapper"
        @click="setSelected(date)"
      >
        <a
          ref="listItems"
          :class="{ '-selected': date.prevSelected }"
          class="colorDropdown__item"
          href="#"
          @keyup.esc="close()"
          @click.prevent="setSelected(date)"
        >
          {{ date.label }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { tag } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';

export default {
  name: 'Dropdown',
  directives: {
    tag,
  },
  props: {
    id: { type: String, required: false, default: null },
    descriptionId: { type: String, required: false, default: null },
    dates: { type: Array, required: true, default: undefined },
    isValid: { type: Boolean, required: true, default: false },
    resetName: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    refSelect: { type: String, default: '' },
  },
  data() {
    return {
      expanded: false,
      selected: false,
      prevSelected: this.dates.find((d) => d.selected === true).value,
      isColorHovered: false,
      isDropdownHovered: false,
      colorHover: null,
      colorSwatch: null,
      timerMouseOut: 800,
      colorDropdownItems: null,
    };
  },
  mounted() {
    /* istanbul ignore next */
    this.$nextTick(() => {
      Array.prototype.forEach.call(this.$refs.listItems, (item) => {
        item.addEventListener('blur', this.dropdownBlurHandler);
      });
      this.$refs.toggler.addEventListener('blur', this.dropdownBlurHandler);
    });

    eventBus.on(`dropdown::${this.resetName}`, this.reset);
  },

  /* istanbul ignore next */
  beforeUnmount() {
    this.$refs.listItems.removeEventListener('blur', this.dropdownBlurHandler);
    this.$refs.toggler.removeEventListener('blur', this.dropdownBlurHandler);
  },

  methods: {
    close() {
      this.expanded = false;
      this.$refs.toggler.focus();
    },
    reset() {
      this.selected = false;
    },
    toggle() {
      //readonly avoid toggling
      if (this.readonly) return;

      this.expanded = !this.expanded;

      /* istanbul ignore next */
      if (this.expanded) {
        this.$refs.listItems[1].focus();
      }
    },
    setSelected(date) {
      this.selected = date;
      this.$emit('input', this.selected.value);
      // eslint-disable-next-line prettier/prettier
      let data = {'inputValue': this.selected.value, 'refField' : this.refSelect };
      eventBus.$emit('selectDropdown', data);

      this.close();
    },
    dropdownBlurHandler() {
      setTimeout(() => {
        let activeElementClass;

        /* istanbul ignore next */
        if (document.documentMode || /Edge/.test(navigator.userAgent)) {
          /* eslint-disable prefer-destructuring */
          activeElementClass = document.activeElement.classList[0] || null;
        } else {
          activeElementClass = document.activeElement.classList.value;
        }

        if (
          !(
            activeElementClass === 'colorDropdown__item' ||
            activeElementClass === this.$refs.toggler.classList.value
          ) &&
          this.expanded === true
        ) {
          this.expanded = false;
        }
      }, 300);
    },
    handleFocus(event, focused) {
      this.$emit(event, focused);
    },
  },
};
</script>
