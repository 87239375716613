import { AnalyticsProductVariantClickObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import ProductObject from './ProductObject';

export default class ProductVariantClickObject extends DataObject {
  constructor(data) {
    super('uaevent', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsProductVariantClickObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${data.category}] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Detail|Variant selection';
    if (data.action) {
      Logger.warn(
        `[AnalyticsProductVariantClickObjectException] Action defaults to "Product Variant Click", currently [${data.action}] was provided`,
        data,
        false
      );
    }

    this.label = 'Product Detail|Variant selection';
    if (data.label) {
      this.label = data.label;
    }

    if (!data.products || data.products.length === 0) {
      Logger.error(
        '[AnalyticsProductVariantClickObjectException] Product is required for productVariantClick',
        data
      );
      throw new AnalyticsProductVariantClickObjectException(
        'Product is required for productVariantClick'
      );
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
    this.result.ecommerce.detail = this.result.ecommerce.detail || {};
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }
  set event_name(event_name) {
    this.result.event_name = this.setProperty(event_name, false);
  }
  set event_detail(value) {
    this.result.event_detail = this.setProperty(value);
  }
  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }
  set productInfo(value) {
    this.result.product_info = this.setProperty(value);
  }

  set products(products) {
    products = products.map((product) => new ProductObject(product).toObject());

    this.initEcommerce();

    this.result.ecommerce.detail.products = products;
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.detail.products) || [];
  }

  set list(list) {
    this.initEcommerce();

    this.result.ecommerce.detail.actionField = { list: this.setProperty(list) };
  }
}
