/**
 * Returns a the content of a data tag on a Dom node.
 * When node doesn't exist, it returns false.
 * If the data tag has no content, it returns the textContent of the Dom node.
 * @param {Node} domNode Node to query for data tag
 * @param {String} field Name of data tag to search. Eg: data-tag-product-id.
 */
export default function getValueFromSelector(domNode, field) {
  if (!domNode) {
    return false;
  }

  if (domNode.getAttribute(`data-tag-${field}`)) {
    return domNode.getAttribute(`data-tag-${field}`);
  }
  return domNode.textContent;
}
