<template>
  <div>
    <LoadScript :src="buildUrl" @loaded="initRevtrax" />
    <div>
      <div
        id="revtraxContent"
        style="width: 100%; height: 100%"
        scrolling="yes"
        allowtransparency="yes"
      ></div>
    </div>
  </div>
</template>
<script>
import LoadScript from '../../../../../Foundation/Core/code/Scripts/loadscript/loadscript.vue';
export default {
  name: 'Revtrax',
  components: {
    LoadScript,
  },
  props: {
    scriptUrl: { type: String, required: true, default: '' },
    channel: { type: String, required: true },
    rxChannel: { type: String, required: true },
    affiliateId: { type: String, required: true },
    merchantId: { type: String, required: true },
    programId: { type: String, required: true },
    sourceCustomerId: { type: String, default: '' },
    customParamList: { type: Array, default: () => [] },
  },
  data() {
    return {
      params: {
        parent: 'revtraxContent',
        rtxuseqs: true,
        channel: this.channel,
        rx_channel: this.rxChannel,
        merchantId: this.merchantId,
        programId: this.programId,
        affiliateId: this.affiliateId,
        source_customer_id: this.sourceCustomerId,
        uid: this.sourceCustomerId,
      },
      buildUrl: '',
    };
  },
  beforeMount() {
    this.customParamList.map(this.setElementsToObject);

    this.buildUrl = `${this.scriptUrl}?${new URLSearchParams(this.params).toString()}`;
  },
  methods: {
    initRevtrax() {
      window.makeFrame();
    },
    setElementsToObject(element) {
      this.params[element.key] = element.value;
    },
  },
};
</script>
