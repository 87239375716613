<template>
  <div class="vto__loading vto__page" :class="{ '-fadeOut': frameReady }">
    <OapLoader />
  </div>
</template>

<script>
import { PAGE_LANDING } from '../settings';
import { DO_GO_TO_PAGE } from '../store/actions';
import { mapState, mapActions } from '../store/helpers';
import OapLoader from '../../../../OapLoader/code/Scripts/components/OapLoader.vue';

export default {
  name: 'VtoPageLoading',

  components: {
    OapLoader,
  },

  computed: {
    ...mapState(['frameReady']),
  },

  methods: {
    ...mapActions([DO_GO_TO_PAGE]),

    tryGoToLanding() {
      /* istanbul ignore else */
      if (this.frameReady) this.goToLanding();
    },

    goToLanding() {
      this[DO_GO_TO_PAGE](PAGE_LANDING);
    },
  },

  watch: {
    frameReady() {
      /* istanbul ignore next */
      setTimeout(() => this.goToLanding(), 300);
    },
  },

  beforeMount() {
    this.tryGoToLanding();
  },
};
</script>
