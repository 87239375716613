export const toggleClass = {
  name: 'toggle-class',

  mounted: (el, { modifiers, value }) => {
    const events = Object.keys(modifiers);
    const { className } = value;
    const trigger = value.trigger ? document.querySelector(value.trigger) : el;
    const target = value.target ? document.querySelector(value.target) : null;

    events.forEach((event) => {
      trigger.addEventListener(event, () => {
        target ? target.classList.toggle(className) : el.classList.toggle(className);
      });
    });
  },
};
