import UserActionEventObject from './UserActionEventObject';
import NonInteractiveEventObject from './NonInteractiveEventObject';
import ProductClickObject from './ProductClickObject';
import VtoDetailObject from './VtoDetailObject';
import ProductVariantClickObject from './ProductVariantClickObject';
import ProductImpressionObject from './ProductImpressionObject';
import PromotionClickObject from './PromotionClickObject';
import PromotionImpressionObject from './PromotionImpressionObject';
import AddToCartObject from './AddToCartObject';
import SocialIntEventObject from './SocialIntEventObject';
import ConditionalEventObject from './ConditionalEventObject';

/**
 * types
 * List of allowed data types
 * @type {object}
 * @member AnalyticsHandler#types
 */
export const TAGGING_TYPES = {
  productClick: ProductClickObject,
  vtoDetail: VtoDetailObject,
  productVariantClick: ProductVariantClickObject,
  productImpression: ProductImpressionObject,
  userActionEvent: UserActionEventObject,
  nonInteractiveEvent: NonInteractiveEventObject,
  promotionClick: PromotionClickObject,
  promotionImpression: PromotionImpressionObject,
  addToCart: AddToCartObject,
  socialIntEvent: SocialIntEventObject,
  conditionalEvent: ConditionalEventObject,
};

export const TAGGING_TYPES_ARRAY = Object.keys(TAGGING_TYPES);
