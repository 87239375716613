import eventBus from '@loreal/eventbus-js';
import { MqHandler } from '../../../../../Foundation/Core/code/Scripts/mqHandler';

/* istanbul ignore next */
const NAVIGATION = '.navigation';
const NAVIGATION_LIST = '.navigationList';
const NAVIGATION_LIST_ACTIVE = '.navigationList.-active';
const LIST_ITEM = '.navigationList__item';
const ACTIVE = '-active';
const BURGER = '.navigation__burger';
const BURGER_ACTIVE = '.navigation__burger.-active';
const TRIGGER = '.navigationList__trigger';
const PANEL = '.navigation__panelWrapper';
const PANEL_ACTIVE = '.navigation__panelWrapper.-active';
const NAVIGATION_BAR = '.navigationBar';
const HEADER_WRAPPER = '.oap-header__wrapper';
const HAS_MOBILE_NAVIGATION = '.oap-has-mobile-navigation';
const NAVIGATION_BACK_LABEL = '.navigation__backLabel';
const ARIA_EXPANDED = 'aria-expanded';
const ARIA_HIDDEN = 'aria-hidden';
const TRIGGER_PADDINGS = 15;
const TOGGLE_PANEL = 'megamenu::togglePanel';
const matchMedia999 = window.matchMedia('(min-width: 999px)').matches;
const matchMedia1025 = window.matchMedia('(min-width: 1025px)').matches;
const matchMedia998 = window.matchMedia('(max-width: 998px)').matches;
const timerAnimation = 500;
const OpeningTimer = 10;
const lastFocusedEl = '.-lastfocusedEl';
const hasMobileNavigation = document.querySelector(HAS_MOBILE_NAVIGATION);
const megamenuLastfocusedEl = '.-megamenuLastfocusedEl';
const MENU_IS_ACTIVE = 'menu--is-active';
const NO_SCROLL = 'noScroll';
const OPAQUE = '-opaque';

export function emitEvent(type, index = null) {
  eventBus.emit(type, index);
}

export function toggleMenu(trigger, target) {
  const body = document.body;
  const activeList = document.querySelector(NAVIGATION_LIST_ACTIVE);
  /* istanbul ignore next */
  if (activeList) {
    body.classList.remove(NO_SCROLL, MENU_IS_ACTIVE);
    document.querySelector(`${BURGER}.-active`).setAttribute(ARIA_EXPANDED, false);
    activeList.classList.remove('-active');
    document.querySelector(`${BURGER}.-active`).classList.remove('-active');
    trigger.setAttribute(ARIA_EXPANDED, false);
    emitEvent('navOpen', { status: false, navId: target });
    document.querySelector('.goToContent').setAttribute(ARIA_HIDDEN, false);
    document.querySelector('main').removeAttribute(ARIA_HIDDEN);
    document.querySelector('footer').removeAttribute(ARIA_HIDDEN);
    document.querySelector('.navigation__burger').setAttribute(ARIA_EXPANDED, false);

    document.querySelector(megamenuLastfocusedEl)?.remove();

    if (hasMobileNavigation) {
      eventBus.emit('closeNav');
    }

    return;
  }

  /* istanbul ignore next */
  if (document.querySelector(NAVIGATION_LIST) && document.querySelector(BURGER)) {
    body.classList.add(NO_SCROLL, MENU_IS_ACTIVE);
    document.querySelector('.goToContent')?.setAttribute(ARIA_HIDDEN, true);
    document.querySelector('main')?.setAttribute(ARIA_HIDDEN, true);
    document.querySelector('footer')?.setAttribute(ARIA_HIDDEN, true);
    trigger.setAttribute(ARIA_EXPANDED, true);
    const focusBurger = () => {
      setTimeout(function () {
        document.querySelector(BURGER_ACTIVE).focus();
      }, 10);
    };
    if (target) {
      const selector = `#${target}${NAVIGATION_LIST}`;
      document.querySelector(selector).classList.add(ACTIVE);
      trigger.classList.add(ACTIVE);
      let selectedsubnaviLastElement = document.createElement('div');
      selectedsubnaviLastElement.tabIndex = '0';
      selectedsubnaviLastElement.className = '-bmagnavLastfocusedEl';
      document.querySelector(selector).appendChild(selectedsubnaviLastElement);
    } else {
      document.querySelector(NAVIGATION_LIST).classList.add(ACTIVE);
      document.querySelector(BURGER).classList.add(ACTIVE);
      if (matchMedia998 || hasMobileNavigation) {
        let selectedsubnaviLastElement = document.createElement('div');
        selectedsubnaviLastElement.tabIndex = '0';
        selectedsubnaviLastElement.className = '-megamenuLastfocusedEl';
        document.querySelector(NAVIGATION_LIST).appendChild(selectedsubnaviLastElement);
        let selectedmegamenuLastfocusedEl = document.querySelector(megamenuLastfocusedEl);
        selectedmegamenuLastfocusedEl.addEventListener('focus', focusBurger);
      }
    }
  }
  trigger.setAttribute(ARIA_EXPANDED, true);
  emitEvent('navOpen', { status: true, navId: target });
}

export function closeMobilePanel(index) {
  const activePanel = document.querySelector(PANEL_ACTIVE);

  /* istanbul ignore next */
  if (activePanel) {
    activePanel.classList.remove(ACTIVE);
    activePanel.querySelector(NAVIGATION_BACK_LABEL).setAttribute(ARIA_EXPANDED, false);
    /* istanbul ignore next */
    if (matchMedia999 && !hasMobileNavigation) {
      activePanel.style.display = 'none';
    } else {
      setTimeout(() => {
        activePanel.style.display = 'none';
      }, timerAnimation);
    }

    const button = activePanel.closest(LIST_ITEM) || document.querySelectorAll([LIST_ITEM])[index];
    close(button);
    window.removeEventListener('keyup', backTabKey);
  }
}

export function close(node) {
  /* istanbul ignore next */
  node.classList.remove(ACTIVE);
  node.querySelector(TRIGGER).setAttribute(ARIA_EXPANDED, false);
  node.querySelector(TRIGGER).focus();
  node.querySelector(PANEL).classList.remove(ACTIVE);
  const hasMobileNavigation = document.querySelector(HAS_MOBILE_NAVIGATION);
  if (document.querySelector(lastFocusedEl)) {
    document.querySelector(lastFocusedEl).remove();
  }

  /* istanbul ignore next */
  if (matchMedia999 && !hasMobileNavigation) {
    node.querySelector(PANEL).style.display = 'none';
    document.body.classList.remove(MENU_IS_ACTIVE);
  } else {
    if (!matchMedia999) {
      setTimeout(() => {
        node.querySelector(PANEL).style.display = 'none';
      }, timerAnimation);
    } else if (hasMobileNavigation) {
      node.querySelector(PANEL).style.display = 'none';
      eventBus.emit('collapse', null);
    }
  }

  node.querySelector(TRIGGER).focus();
  // Traverse tree to find navigation id
  const navId = node.closest(NAVIGATION_LIST).getAttribute('id') || undefined;
  emitEvent('navOpen', { status: false, navId });
}

export function onEscKey(e, node) {
  /* istanbul ignore next */
  if (e.keyCode === 27) {
    close(node);
  }
}

export function backTabKey(e) {
  /* istanbul ignore next */
  const activeElement = document.querySelector(BURGER_ACTIVE);
  if (e.shiftKey && e.keyCode === 9 && activeElement) {
    activeElement.focus();
  }
}

export function onResize(node) {
  /* istanbul ignore next */
  if (document.querySelector(NAVIGATION_LIST_ACTIVE)) {
    document.querySelector(NAVIGATION_LIST).classList.remove('-active');
    document.querySelector(BURGER).classList.remove('-active');
  }

  close(node);
}

/* istanbul ignore next */
export function handleBarScroll() {
  let prevScrollpos = Math.round(window.scrollY);
  let isHiding = false;
  const getHeaderHeight = () => {
    let headerMainHeight = 0;
    let accountNavigationHeight = 0;
    const headerMainChildren = document.querySelectorAll('.oap-header__main > *');
    if (headerMainChildren.length) {
      headerMainChildren.forEach(
        (child) => (headerMainHeight = headerMainHeight + child.offsetHeight)
      );
    }
    const accountNavigation = document.querySelector('.oap-account-navigation');
    if (accountNavigation) {
      accountNavigationHeight = document.querySelector('.oap-account-navigation').offsetHeight;
    }
    return headerMainHeight + accountNavigationHeight;
  };
  let defaultHeightDistance = getHeaderHeight();
  let heightDistance = defaultHeightDistance;

  window.addEventListener('resize', () => {
    defaultHeightDistance = getHeaderHeight();
  });

  const showBar = () => {
    isHiding = false;
    document.body.classList.add('headerIsShown');
  };
  const hideBar = () => {
    if (isHiding) {
      return;
    }
    isHiding = true;
    document.body.classList.remove('headerIsShown');
  };

  showBar();

  window.addEventListener('resize', showBar);
  window.addEventListener('orientationchange', showBar);

  let scrollPos = 0;

  /* istanbul ignore next */
  window.addEventListener('scroll', () => {
    const currentScrollPos = Math.round(window.scrollY);

    if (prevScrollpos >= currentScrollPos) {
      showBar();
    } else if (!document.querySelector(BURGER_ACTIVE)) {
      hideBar();
    }

    const body = document.body;
    if (body.getBoundingClientRect().top > scrollPos) {
      heightDistance = 0;

      document.querySelector(HEADER_WRAPPER).classList.add(OPAQUE);

      if (document.body.getBoundingClientRect().top === 0) {
        if (document.querySelector(HEADER_WRAPPER)) {
          document.querySelector(HEADER_WRAPPER).classList.remove(OPAQUE);
        }
      } else {
        document.querySelector(HEADER_WRAPPER).classList.add(OPAQUE);
      }
    } else {
      heightDistance = defaultHeightDistance;
    }

    if (currentScrollPos < heightDistance) {
      return;
    }

    if (currentScrollPos + window.innerHeight + heightDistance >= body.offsetHeight) {
      return;
    }

    setTimeout(() => {
      prevScrollpos = currentScrollPos;
    }, 100);

    scrollPos = body.getBoundingClientRect().top;
  });
}

export function togglePanel(panelIndex) {
  /* istanbul ignore next */
  eventBus.on(TOGGLE_PANEL, (index) => {
    const items = document.querySelectorAll(LIST_ITEM);
    const active = document.querySelector(`${LIST_ITEM}.${ACTIVE}`);
    const body = document.body;

    // check if the panel and the index of the button match
    // otherwise you bind multiple times
    /* istanbul ignore next */
    if (panelIndex === index) {
      let selectedElement = document.querySelector(`${LIST_ITEM}[data-index="${index}"]`);
      if (!selectedElement) {
        selectedElement = items[index];
      }

      // close menu item after clicking on menu overlay
      eventBus.on('closeNav', () => {
        close(selectedElement);
      });

      // if the currently active item exists and it is not the same as the clicked item, remove the actiev state.
      // this makes sure we can toggle the active state by clicking the current button again.
      // only for small screens
      /* istanbul ignore next */
      if (
        active &&
        active !== selectedElement &&
        !MqHandler.getMqHandler().getMqForSize('small').selected
      ) {
        close(active);
        window.removeEventListener('keyup', onEscKey);
        window.removeEventListener('resize', onResize);
      }

      // if the current button is active, deactivate it.
      /* istanbul ignore next */
      if (selectedElement.classList.contains(ACTIVE)) {
        close(selectedElement);
        window.removeEventListener('keyup', onEscKey);
        window.removeEventListener('resize', onResize);
        body.classList.remove(NO_SCROLL);
        if (document.querySelector(lastFocusedEl)) {
          document.querySelector(lastFocusedEl).remove();
        }
      }
      // if the current button is not active, activate it.
      /* istanbul ignore next */
      else {
        const trigger = selectedElement.querySelector(TRIGGER);
        const bar = selectedElement.closest(NAVIGATION).querySelector(NAVIGATION_BAR);
        const rect = trigger.getBoundingClientRect();

        trigger.setAttribute(ARIA_EXPANDED, true);
        selectedElement.classList.add(ACTIVE);

        setTimeout(() => {
          selectedElement.querySelector(PANEL).classList.add(ACTIVE);
          selectedElement.querySelector(PANEL).style.display = 'block';
          selectedElement.querySelector(NAVIGATION_BACK_LABEL).focus();
          selectedElement.querySelector(NAVIGATION_BACK_LABEL).setAttribute(ARIA_EXPANDED, true);
          body.classList.add(MENU_IS_ACTIVE);

          if (matchMedia998 || hasMobileNavigation) {
            var selectedsubnaviLastElement = document.createElement('div');
            selectedsubnaviLastElement.tabIndex = '0';
            selectedsubnaviLastElement.className = '-lastfocusedEl';
            selectedElement
              .querySelector('.navigation__panelWrapper')
              .appendChild(selectedsubnaviLastElement);

            var selectedLastfocusedEl = selectedElement.querySelector(lastFocusedEl);
            selectedLastfocusedEl.addEventListener('focus', () => {
              setTimeout(() => selectedElement.querySelector(NAVIGATION_BACK_LABEL).focus(), 10);
            });
          }
        }, 10);

        // timeout is needed here because the position is incorrectly calculated
        // when the megamenu is opened the first time
        setTimeout(() => {
          bar.style.width = `${rect.width - TRIGGER_PADDINGS * 2}px`;
          bar.style.left = `${trigger.offsetLeft + TRIGGER_PADDINGS}px`;
          bar.style.bottom = `${TRIGGER_PADDINGS + 2}px`;
        }, 200);

        // Traverse tree to find navigation id
        const navId = selectedElement.closest(NAVIGATION_LIST).getAttribute('id') || undefined;
        emitEvent('navOpen', { status: true, navId });
        body.classList.add(NO_SCROLL);

        /* istanbul ignore next */
        window.addEventListener('keyup', (e) => {
          onEscKey(e, selectedElement);
        });

        /* istanbul ignore next */
        window.addEventListener('orientationchange', () => {
          onResize(selectedElement);
        });

        /* istanbul ignore next */
        if (matchMedia1025) {
          window.addEventListener('resize', () => {
            onResize(selectedElement);
          });
        }
      }
    }
  });
}

export const megamenu = {
  name: 'megamenu',
  mounted: (el, binding) => {
    const { arg, value: data = {} } = binding;

    /* istanbul ignore next */
    switch (arg) {
      case 'burger': {
        const target = binding.value ? binding.value.id : undefined;
        el.addEventListener('click', () => {
          toggleMenu(el, target);
        });
        break;
      }

      case 'togglepanel':
        el.addEventListener('click', (e) => {
          e.preventDefault();

          setTimeout(() => {
            emitEvent(TOGGLE_PANEL, data.index);
            emitEvent('updateScroller');
          }, OpeningTimer);
        });

        el.addEventListener('keydown', (e) => {
          if (e.key === ' ' || e.key === 'Spacebar') {
            e.preventDefault();
            document.querySelector(HEADER_WRAPPER).style.top = 0;
            document.querySelector(HEADER_WRAPPER).classList.add('-scroll');

            setTimeout(() => {
              emitEvent(TOGGLE_PANEL, data.index);
              emitEvent('updateScroller');
            }, OpeningTimer);
          }
        });
        break;

      case 'panel':
        togglePanel(data.index);
        break;

      case 'backreturn':
        el.addEventListener('click', () => {
          closeMobilePanel(data.index);
        });
        if (MqHandler.getMqHandler().getMqForSize('small').selected) {
          el.addEventListener('keyup', backTabKey);
        }
        break;

      case 'barscroll':
        handleBarScroll();
        break;

      default:
        break;
    }
  },
};
