<template>
  <button class="vtoCircle" v-bind="$attrs" @click="$attrs.click && $attrs.click()">
    <span v-if="entity.label" class="vtoCircle__label">
      <span>{{ entity.label }}</span>
    </span>
    <span
      v-if="entity.hex"
      class="vtoCircle__mainColor"
      :style="{ backgroundColor: entity.hex }"
    ></span>
    <svg
      v-if="entity.hex"
      class="vtoCircle__backColor"
      role="img"
      :fill="entity.hex"
      :stroke="entity.hex"
    >
      <title>{{ entity.name }}</title>
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#disc" />
    </svg>
    <span v-if="entity.swatch" class="vtoCircle__swatch">
      <img :src="entity.swatch" alt="" />
    </span>
    <svg v-if="checked" class="vtoCircle__checked" :aria-hidden="true">
      <title>{{ entity.name }}</title>
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#check" />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'VtoCircle',
  props: {
    entity: { type: Object, default: () => {} },
    checked: { type: Boolean, default: false },
  },
};
</script>
