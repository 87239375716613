<template>
  <div class="vtoCompare vto__page">
    <VtoHeader v-if="show">
      <!-- if the user switches to this page from Looks (VtoPageEntitySelection)
         - there is no default 'selectedProduct'  -->
      <VtoHeaderCounter v-if="getSelectedProductsAmount" :count="getSelectedProductsAmount" />
      <VtoProduct v-if="getSelectedProductItem" :product="getSelectedProductItem" />
    </VtoHeader>
  </div>
</template>

<script>
import { DO_MF_SEND_EXIT, DO_OPEN_CATALOG } from '../store/actions';

import { mapActions, mapGetters, mapState } from '../store/helpers';

import VtoHeader from './VtoHeader.vue';
import VtoHeaderCounter from './VtoHeaderCounter.vue';
import VtoProduct from './VtoProduct.vue';

export default {
  name: 'VtoPageCompare',

  components: {
    VtoHeader,
    VtoHeaderCounter,
    VtoProduct,
  },

  computed: {
    ...mapState(['show']),

    ...mapGetters(['getSelectedProductItem', 'getSelectedProductsAmount']),
  },

  methods: {
    ...mapActions([DO_OPEN_CATALOG, DO_MF_SEND_EXIT]),
  },
};
</script>
