import { debounce, intersectionViewportObserver } from '../../utilities';

export const getWidth = () => {
  return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
};

export const getBackground = (backgrounds, sizes, currentWidth = getWidth()) => {
  if (typeof backgrounds === 'string') {
    return backgrounds;
  }
  const lastMatchingKey = sizes.filter((size) => currentWidth >= size).shift();
  return backgrounds[lastMatchingKey];
};

export const updateBackground = (el, backgrounds, sizes) => {
  el.style.backgroundImage = `url('${getBackground(backgrounds, sizes)}')`;
};

export const addLoadedClass = (el, className = 'oap-v-background--lazyloaded', delay = 100) => {
  setTimeout(() => {
    el.classList.add(className);
  }, delay);
};

export const background = {
  name: 'background',

  beforeMount: (el, { value: backgrounds }) => {
    if (backgrounds && Object.keys(backgrounds).length) {
      const keys = Object.keys(backgrounds).reverse();
      const updateBackgroundCallback = updateBackground.bind(null, el, backgrounds, keys);

      el.classList.add('oap-v-background');

      intersectionViewportObserver(el, { checkIsVisible: false }).then(() => {
        updateBackgroundCallback();
        addLoadedClass(el);
        window.addEventListener('resize', debounce(updateBackgroundCallback, 200));
      });
    }
  },
};
