import NewsletterForm from './code/scripts/components/newsletter-form.vue';
import Dropdown from './code/scripts/components/dropdown.vue';
import MaterialInput from './code/scripts/components/input.vue';
import NewsletterSignin from './code/scripts/components/newsletter-signin.vue';

export default {
  components: {
    NewsletterForm,
    NewsletterSignin,
    Dropdown,
    MaterialInput,
  },
};
