import eventBus from '@loreal/eventbus-js';

/**
 * MqHandler will use the eventBus to emit mediaquery changes based on matchMedia listeners.
 * Also has functions to ask for the current media query or to get a matchMedia object by screensize.
 * @class MqHandler
 */
export default class MqHandler {
  /**
   * MqHandler constructor.
   * @property {object} query - MediaQuery Object. Preferably taken from body:before { content: "" }
   * @constructs MqHandler
   */
  constructor(mqs) {
    if (!mqs) {
      console.error('No media queries object given');
      return;
    }
    /**
     * MediaQuery Object.
     * Preferably taken from body:before { content: "" }
     * @type {object}
     * @member MqHandler#mqs
     */
    this.mqs = mqs;

    /**
     * matchMediaItems Object with correctly mapped data.
     * @type {object}
     * @member MqHandler#matchMediaItems
     */
    this.matchMediaItems = this.createWorkingData();
    this.bind();
  }

  /**
   * Creates correct content for matchMediaItems
   */
  createWorkingData() {
    return this.mqs.map((mq) => this.createMatchMediaItem(mq));
  }

  /**
   * @typedef matchMediaItem
   * @type {object}
   * @property {string} query - the mediaquery as a string. For example: "(min-width: 768px) and (max-width: 1023px)"
   * @property {boolean} selected - if this mediaquery is the selected one
   * @property {string} size - The size identifier of the mediaquery. Can be: small, medium, large
   * @property {object} matchMedia - The attached matchMedia object
   */

  /**
   * Creates matchMediaItem
   * @param {object} data - mediaquery data
   * @return {matchMediaItem}
   */
  createMatchMediaItem(data) {
    return {
      query: data.mq,
      selected: data.selected,
      size: data.size,
      matchMedia: window.matchMedia(data.mq),
    };
  }

  /**
   * Binds the matchMedia mediaquery listeners
   */
  /* istanbul ignore next */

  // ignoring because of the fact the test runner can't use the addListener function
  bind() {
    this.matchMediaItems.forEach((mq) => {
      mq.matchMedia.addListener(() => {
        if (mq.matchMedia.matches) {
          this.select(mq.query);
        }
      });
    });
  }

  /**
   * Selects the correct mediaquery based on the current matching matchMedia media query
   * Sends an event over the window.eventBus
   * @param {object} query - matchMediaItems item query string
   */
  select(query) {
    this.unselectAll();
    const selectedMq = this.matchMediaItems.find((mq) => mq.query === query);
    selectedMq.selected = true;

    eventBus.emit('mediaquery::changed', selectedMq);
    return selectedMq;
  }

  /**
   * loops over all matchMediaItems and unselects them
   */
  unselectAll() {
    this.matchMediaItems.forEach((mq) => {
      mq.selected = false;
    });

    // returns for unit testing purposes
    return this.matchMediaItems;
  }

  /**
   * Returns the currently selected matchMediaItems item
   * @return {object} selected matchMediaItems item
   */
  getCurrentMq() {
    return this.matchMediaItems.find((mq) => mq.selected === true);
  }

  /**
   * Returns the matchMediaItems item for a size
   * @param {string} size - can be small, medium, large
   * @return {object} matchMediaItems item as per given size
   */
  getMqForSize(size) {
    return this.matchMediaItems.find((mq) => mq.size === size);
  }

  static getMqHandler() {
    if (!this.$mqHandler) {
      const mqs = JSON.parse(
        window
          .getComputedStyle(document.body, ':before')
          .getPropertyValue('content')
          .replace(/\\/g, '')
          .slice(1, -1)
      );
      this.$mqHandler = new MqHandler(mqs);
    }
    return this.$mqHandler;
  }
}
