let ExperienceEditorVueBridge;

const _isExperienceEditor = () => window?.Sitecore?.PageModes?.PageEditor;

if (_isExperienceEditor()) {
  ExperienceEditorVueBridge = (await import('./ExperienceEditorVueBridge')).default;
}

export default {
  initializeVueApp(initVueApp) {
    ExperienceEditorVueBridge?.persistPlaceholderKeys();
    initVueApp();
    ExperienceEditorVueBridge?.ensurePlaceholderKeys();
  },
};
