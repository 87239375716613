<template>
  <div :class="['oap-badge__item', badge.cssClass]">
    <img
      class="oap-badge__img lazyload"
      :alt="badge.alt"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM88x8AAp0BzdNtlUkAAAAASUVORK5CYII="
      :data-src="badge.src"
    />
  </div>
</template>

<script>
export default {
  name: 'OapBadge',

  props: {
    badge: {
      type: Object,
    },
  },
};
</script>
