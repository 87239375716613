<template>
  <div class="grnr-ppup-button">
    <div v-if="start" @click="handleOpenModal"><slot name="openbutton" /></div>
    <div v-if="showpopup" id="grnr-ppup">
      <div class="grnr-ppup-bckgnd">
        <div class="oap-popup-wrapper oap-popup-wrapper--open" :style="styles">
          <div class="oap-popup-wrapper-inner">
            <OapIframe :src="src" :title="title"> </OapIframe>
            <div class="grnr-cls-ppup" @click="handleCloseModal">
              <slot name="closebutton" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OapIframe from '../../../../../Feature/OapIframe/code/Scripts/components/oap-iframe.vue';
export default {
  name: 'Iframepopup',
  components: {
    OapIframe,
  },
  props: {
    src: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    popupheight: {
      type: String,
      required: false,
    },
    popupwidth: {
      type: String,
      required: false,
    },
    end: {
      type: Boolean,
      required: false,
    },
    start: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      showpopup: false,
    };
  },
  computed: {
    styles: function () {
      return {
        width: this.popupwidth + 'px',
        height: this.popupheight + 'px',
      };
    },
  },
  watch: {
    end: {
      handler() {
        this.getDisplayStatus();
      },
      immediate: true,
    },
  },
  mounted() {
    this.getDisplayStatus();
  },
  methods: {
    getDisplayStatus() {
      if (this.end) {
        this.getQueryParams();
      }
    },
    getQueryParams() {
      const location = window.location.search;
      const queryParams = new URLSearchParams(location);
      const callPopUpParam = queryParams.get('prescripted');
      const dsfservice = document.referrer;
      if (callPopUpParam == 'skindr' && dsfservice !== '') {
        this.handleOpenModal();
      }
    },
    handleOpenModal() {
      this.showpopup = true;
    },
    handleCloseModal() {
      this.showpopup = false;
    },
  },
};
</script>
<style lang="scss">
.tryOnCta__wrapper .grnr-ppup-button {
  margin: 0 auto;
}

#grnr-ppup {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1010;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#grnr-ppup .grnr-ppup-bckgnd {
  background: rgb(0 0 0 / 60%);
  display: flex;
  height: 100%;
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open {
  align-self: center;
  display: flex;
  flex-direction: column;
  height: auto;
  margin: auto;
  position: relative;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  width: 600px;
  padding: 0;
  max-width: 90%;
  max-height: 90%;
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper-inner,
#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper-inner .oap-iframe-wrapper {
  background: #fff;
  height: 100%;
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper-inner iframe {
  width: 100%;
  height: 100%;
  margin: 0;
}

@media (max-width: 550px) {
  #grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open {
    width: 500px;
    max-width: 90%;
  }
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open .grnr-cls-ppup {
  background: #005c43;
  border-radius: 100%;
  height: 28px;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 28px;
  z-index: 2;
  cursor: pointer;
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open .grnr-cls-ppup .grnr-cls-ppup-ln {
  background: #fff;
  height: 2px;
  left: 7px;
  position: absolute;
  top: 13px;
  width: 14px;
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open .grnr-cls-ppup .grnr-cls-ppup-ln.one {
  transform: rotate(45deg);
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open .grnr-cls-ppup .grnr-cls-ppup-ln.two {
  transform: rotate(-45deg);
}

#grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open .grnr-cls-ppup .tryOnCta__button {
  display: block;
  background: none;
  height: 30px;
}

@media (max-width: 550px) {
  #grnr-ppup .grnr-ppup-bckgnd .oap-popup-wrapper--open .grnr-cls-ppup {
    right: 0.5rem;
    top: -0.5rem;
  }
}
</style>
