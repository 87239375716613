/* istanbul ignore next */
import { FACET_CONFIGS, FACET_MAPS, FACET_STATE, PAGE_DEFAULT } from '../settings';

export default {
  // initialization
  frameReady: false,
  loaded: false,
  reset: false,

  // history
  historyDepth: 0,
  originLocation: {},

  // modal
  modalDisplay: false,

  // data
  initialProductId: null,
  mainCategory: null,
  oppositeCategory: '',
  modifaceData: null,
  dictionary: null,
  productCategoriesMap: null,
  productTypesMap: null,
  productLinesMap: null,
  productItemsMap: {},

  productListsMap: {},

  // save/favorite
  enableFavorite: false,
  favoriteSettings: null,

  // routing
  previousPage: null,
  currentPage: PAGE_DEFAULT,

  // selection
  selectedProductId: null,
  selectedProducts: {},

  // looks
  selectedLookFamilyId: null,
  selectedLookId: null,

  // main actions
  actionBuyDisabled: false,
  actionEmailEnabled: false,
  emailButtonText: 'Email your looks',
  emailButtonUrl: '',

  // catalog
  isMainCatalog: true,
  catalogType: null,
  catalogView: null,
  selectedTypeId: null,
  rating: null,

  // hot swap
  hotSwapRemoveTypeId: null,

  // main page UI, based on Modiface's events
  show: false,
  showShade: false,

  // state to base some UI element's display on
  showFloatingBar: false,
  showSnackbar: false,

  // Facets
  [FACET_STATE]: {},
  [FACET_MAPS]: {},
  [FACET_CONFIGS]: {
    perCategory: {},
    presets: {},
  },

  facetedProductIdsCollection: [],

  // Appearing circles animation
  circlesAnimatedStatus: false,
};
