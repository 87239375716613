<template>
  <div ref="trigger" :class="[trigger, 'oap-usage', `-text-${textPosition}`]">
    <video
      v-if="currentVideoSource"
      ref="video"
      class="oap-usage__video"
      preload="metadata"
      loading="lazy"
      :src="currentVideoSource"
      v-bind="{ autoplay: isAnimationEnabled }"
      playsinline
      muted
      loop
      aria-hidden="true"
    ></video>
    <picture v-if="imageSources" aria-hidden="true">
      <source
        media="(min-width: 650px)"
        sizes="100vw"
        srcset="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        :data-srcset="imageSources.lg"
      />
      <img
        class="oap-usage__image lazyload"
        :alt="imageAltText"
        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
        :data-src="imageSources.sm"
      />
    </picture>
    <div v-show="isHighContrastEnabled" class="high-contrast"></div>
    <div class="oap-usage__text" :style="`color: ${textColor};`">
      <div class="oap-usage__title">{{ title }}</div>
      <div class="oap-usage__subtitle">{{ subtitle }}</div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { uniqueId } from '@Foundation/utilities/uniqueId';
import { intersectionViewportObserver, AnalyticsHandler } from '@Foundation';
import { debounce } from '@Foundation';
import { mapGetters } from 'vuex';
import {
  ACCESSIBILITY_STORE_NAMESPACE,
  GET_SITEWIDE_ANIMATIONS,
  GET_CONTRAST_HIGHLIGHT,
} from '@Foundation/accessibilityStore/accessibilityStore';

const MOBILE_SCREEN_END = 650;

const ELEMENTS_TO_ANIMATE = {
  title: '.oap-usage__title',
  subtitle: '.oap-usage__subtitle',
};

const ANIMATED = '-animated';

export default {
  name: 'OapUsage',

  props: {
    videoSources: { type: Object, default: () => {} },
    imageSources: { type: Object, default: () => {} },
    imageAltText: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    textColor: { type: String, default: '#ffffff' },
    textPosition: { type: String, default: 'left' },
    animationDirection: { type: String, default: 'up' },
    impressionTag: {
      type: Object,
      required: false,
      default: () => {
        return {
          type: 'userActionEvent',
          event_name: 'video_interaction',
          action: 'play',
          category: 'video',
          label: 'pick video',
          video_action: 'play',
          video_title: 'pick video',
        };
      },
    },
    titleStart: { type: Number, default: 33 },
    subtitleStart: { type: Number, default: 66 },
    // scrollTrigger settings
    scrub: { type: Boolean, default: true },
    pin: { type: Boolean, default: true },
    start: { type: String, default: 'top top' },
    end: { type: String, default: '+100%' },
  },

  data() {
    return {
      isTitleAnimated: false,
      isSubtitleAnimated: false,
      eventFired: false,
      isOnViewPort: false,
    };
  },

  computed: {
    isMobile() {
      return window.innerWidth <= MOBILE_SCREEN_END;
    },

    videoSource() {
      return this.isMobile ? this.videoSources?.sm : this.videoSources?.lg;
    },

    currentVideoSource() {
      return this.isOnViewPort ? this.videoSource : null;
    },

    trigger() {
      return `gsap-usage-trigger-${uniqueId()}`;
    },
    isAnimationEnabled() {
      return this[GET_SITEWIDE_ANIMATIONS]();
    },
    isHighContrastEnabled() {
      return this[GET_CONTRAST_HIGHLIGHT]();
    },
  },

  watch: {
    isAnimationEnabled(newValue) {
      if (!newValue) this.$refs.video.pause();
      else this.$refs.video.play();
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    if (this.isAnimationEnabled && !window.location.hash) {
      setTimeout(this.bindAnimation, 0);
    } else {
      this.playBounceUpAnimation(100);
    }

    intersectionViewportObserver(this.$el, { checkIsVisible: false, threshold: [0.006] }).then(
      () => {
        this.isOnViewPort = true;
      }
    );
  },

  methods: {
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_SITEWIDE_ANIMATIONS, GET_CONTRAST_HIGHLIGHT]),
    bindAnimation() {
      gsap.to(`.${this.trigger}`, {
        ease: 'ease-in-out',
        scrollTrigger: {
          trigger: `.${this.trigger}`,
          scrub: this.scrub,
          pin: this.pin,
          start: this.start,
          end: this.end,
          onUpdate: (self) => {
            let progress = Math.round(self.progress * 100);

            if (!this.isOnViewPort) return;

            if (this.animationDirection === 'up') {
              debounce(this.playBounceUpAnimation(progress), 300);
            }
          },
        },
      });
    },

    playBounceUpAnimation(progress) {
      if (progress >= this.titleStart && !this.isTitleAnimated) {
        if (!this.eventFired) {
          this.eventFired = true;
          AnalyticsHandler.getAnalyticsHandler().push({
            ...this.impressionTag,
          });
        }
        const title = document.querySelector(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.title}`);

        if (title && !title.classList.contains(ANIMATED)) {
          title.classList.add(ANIMATED);
          if (this.isAnimationEnabled) title.classList.add('-with-animation');
          this.isTitleAnimated = true;
        }
      }

      if (progress >= this.subtitleStart && !this.isSubtitleAnimated) {
        const subtitle = document.querySelector(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.subtitle}`);
        const title = document.querySelector(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.title}`);

        if (subtitle && !subtitle.classList.contains(ANIMATED)) {
          subtitle.classList.add(ANIMATED);
          if (this.isAnimationEnabled) title.classList.add('-with-animation');
          this.isSubtitleAnimated = true;
        }
      }
    },
  },
};
</script>
