import DataObject from './DataObject';
import { debounce } from '../../../../code/Scripts/utilities';
import eventBus from '@loreal/eventbus-js';

export default class ConditionalEventObject extends DataObject {
  constructor(data) {
    super('uaevent', data);

    this.onScroll = this.onScroll.bind(this);
    this.onTimeout = this.onTimeout.bind(this);

    this.result.ecommerce = undefined;
    this.conditionsTrack = [];

    this.generateConditionsTrack();
    this.init();
  }

  /** INTERNAL **/
  init() {
    if (this.conditions) {
      this.attachEvents();
    }
  }

  /**
   * set the conditions
   * @param conditions
   */
  set conditions(conditions) {
    this._conditions = conditions;
  }

  /**
   * get the conditions
   * @returns {*}
   */
  get conditions() {
    return this._conditions;
  }

  /**
   * set conditions track
   * @param track
   */
  set conditionsTrack(track) {
    this._conditionsTrack = track;
  }

  /**
   * find property in data layer
   * @param property
   */
  findPropertyInDataLayer(property) {
    return window.dataLayer.filter((x) => x[property]).length;
  }

  /**
   * get conditions track
   * @returns {*}
   */
  get conditionsTrack() {
    return this._conditionsTrack;
  }

  /** FOR DATA LAYER **/
  set category(category) {
    this.result.eventCategory = this.setProperty(category);
  }

  get category() {
    return this.result.eventCategory;
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action);
  }

  get action() {
    return this.result.eventAction;
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label);
  }

  get label() {
    return this.result.eventLabel;
  }

  set event_name(event_name) {
    this.result.event_name = this.setProperty(event_name);
  }

  get event_name() {
    return this.result.event_name;
  }

  set article_name(article_name) {
    this.result.article_name = this.setProperty(article_name);
  }

  get article_name() {
    return this.result.article_name;
  }

  /** METHODS **/
  /**
   * fill the condition track array
   * based on conditions provided
   */
  generateConditionsTrack() {
    for (const condition in this.conditions) {
      this.conditionsTrack.push({
        key: condition,
        value: false,
      });
    }
  }

  /**
   * update the corresponding condition
   * in condition track
   * @param condition
   */
  updateConditionsTrack(condition) {
    this.conditionsTrack.find((x) => x.key === condition).value = true;
    this.tryPushToDataLayer();
  }

  /**
   * if conditions are met
   * try to push into data layer
   */
  tryPushToDataLayer() {
    const conditionsMetCount = this.conditionsMetCount().all;
    if (conditionsMetCount.length === this.conditionsTrack.length) {
      eventBus.emit('conditions-met');
      eventBus.off('conditions-met');
    }
  }

  /**
   * count the conditions that are met
   * @returns {*}
   */
  conditionsMetCount(condition) {
    return {
      all: this.conditionsTrack.filter((x) => x.value),
      ...(condition && {
        single: this.conditionsTrack.find((x) => x.key === condition).value,
      }),
    };
  }

  /**
   * attach events
   * scroll & timeout
   */
  attachEvents() {
    if (this.conditions.conditionalProperty) {
      this.updateConditionsTrack('conditionalProperty');
    }

    if (this.conditions.pageScrolledInPercentage) {
      window.addEventListener('scroll', debounce(this.onScroll, 100));
    }

    if (this.conditions.userOnPageInSeconds) {
      setTimeout(this.onTimeout, this.conditions.userOnPageInSeconds * 1000);
    }
  }

  /**
   * get the scroll position
   * @returns {number}
   */
  scrollPosition() {
    return Math.round(
      (window.scrollY / (document.documentElement.offsetHeight - window.innerHeight)) * 100
    );
  }

  /** EVENTS **/
  onScroll() {
    if (this.conditionsMetCount('pageScrolledInPercentage').single) {
      window.removeEventListener('scroll', this.onScroll);
      return;
    }

    if (this.scrollPosition() >= this.conditions.pageScrolledInPercentage) {
      this.updateConditionsTrack('pageScrolledInPercentage');
    }
  }

  onTimeout() {
    this.updateConditionsTrack('userOnPageInSeconds');
  }
}
