<template>
  <div>
    <div :class="['oap-events-navigation', { '--opened': isNavigationOpened }]">
      <div class="oap-events-navigation__menu">
        <span class="oap-events-navigation__menu-logo">
          <img :src="logoSrc" :alt="logoAltText" />
        </span>
        <span v-if="selectedNavigationItem" class="oap-events-navigation__menu-label">
          {{ selectedNavigationItem }}
        </span>
        <span class="oap-events-navigation__menu-action" @click="toggleNavigation">
          <img :src="burgerIconSrc" :alt="burgerIconAltText" />
        </span>
      </div>

      <ul class="oap-events-navigation__list">
        <li class="oap-events-navigation__item oap-events-navigation__item--logo">
          <img
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            :srcset="logoSrc"
            :alt="logoAltText"
            class="lazyload"
          />
        </li>

        <li
          v-for="(item, index) in navigationItems"
          :key="index"
          :class="[
            'oap-events-navigation__item',
            { '--selected': item.isSelected },
            { '--calendar': item.isCalendar },
            { '--has-icon': item.icon },
          ]"
        >
          <img
            v-if="item.icon"
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            :srcsrc="item.icon"
            class="oap-events-navigation__item-icon lazyload"
            :alt="item.alt"
          />
          <a
            :href="item.url"
            class="oap-events-navigation__item-link"
            @click="pushAnalytics(item.url, item.title)"
          >
            {{ item.title }}
          </a>
        </li>
      </ul>
    </div>
    <div
      v-if="isNavigationOpened"
      class="oap-events-navigation__overlay"
      @click="toggleNavigation"
    ></div>
  </div>
</template>

<script>
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

const EVENT_PAGE = '.oap-events-page';
const HAS_EVENT_NAVIGATION = '-has-events-navigation';

export default {
  name: 'OapEventsNavigation',

  props: {
    logoSrc: { type: String, default: '' },
    burgerIconOpenSrc: { type: String, default: '' },
    burgerIconCloseSrc: { type: String, default: '' },
	logoAltText: { type: String, default: '' },
    burgerIconOpenAltText: { type: String, default: '' },
    burgerIconCloseAltText: { type: String, default: '' },
    navigationItems: { type: Array, default: () => [] },

    // Analytics
    analyticPageCategory: { type: String, default: '' },
    analyticModuleName: { type: String, default: '' },
  },

  data: () => ({
    selectedNavigationItem: null,
    isNavigationOpened: false,
  }),

  computed: {
    burgerIconSrc() {
      return this.isNavigationOpened ? this.burgerIconCloseSrc : this.burgerIconOpenSrc;
    },
	burgerIconAltText() {
      return this.isNavigationOpened ? this.burgerIconCloseAltText : this.burgerIconOpenAltText;
    },
  },

  created() {
    this.setEventsNavigationData();
  },

  mounted() {
    document.querySelector(EVENT_PAGE)?.classList.add(HAS_EVENT_NAVIGATION);
  },

  methods: {
    setEventsNavigationData() {
      this.navigationItems.map((navItem) => {
        if (window.location.href.includes(navItem.url)) {
          this.selectedNavigationItem = navItem.title;
          navItem.isSelected = true;
        } else {
          navItem.isSelected = false;
        }
      });
    },
    toggleNavigation() {
      this.isNavigationOpened = !this.isNavigationOpened;
    },

    pushAnalytics(url, title) {
      let tag = {
        type: 'userActionEvent',
        category: this.analyticPageCategory,
        action: 'select::cannes menu',
        label: `${title}::${window.location.origin}${url}`,
        event_name: 'body_button_click',
        module_name: this.analyticModuleName,
        cta_name: `${title}`,
        link_url: `${window.location.origin}${url}`,
      };
      try {
        AnalyticsHandler.getAnalyticsHandler().push(tag);
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Could not push to dataLayer', e);
      }
    },
  },
};
</script>
