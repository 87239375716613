import * as settings from './settings';
import VtoPageLoading from './components/VtoPageLoading.vue';
import VtoPageLanding from './components/VtoPageLanding.vue';
import VtoPageHairDetection from './components/VtoPageHairDetection.vue';
import VtoPageEntitySelection from './components/VtoPageEntitySelection.vue';
import VtoPageMain from './components/VtoPageMain.vue';
import VtoPageCompare from './components/VtoPageCompare.vue';
import VtoPageShare from './components/VtoPageShare.vue';
import VtoPageError from './components/VtoPageError.vue';
import VtoPageCatalog from './components/VtoPageCatalog.vue';

// pages are ordered by sequence of appearance
export default {
  [settings.PAGE_DEFAULT]: { component: VtoPageLoading.name },
  [settings.PAGE_LOADING]: { component: VtoPageLoading.name },
  [settings.PAGE_LANDING]: { component: VtoPageLanding.name },
  [settings.PAGE_HAIR_DETECTION]: { component: VtoPageHairDetection.name },
  [settings.PAGE_ENTITY_SELECTION]: { component: VtoPageEntitySelection.name },
  [settings.PAGE_MAIN]: { component: VtoPageMain.name },
  [settings.PAGE_COMPARE]: { component: VtoPageCompare.name },
  [settings.PAGE_SHARE]: { component: VtoPageShare.name },
  [settings.PAGE_ERROR]: { component: VtoPageError.name },
  [settings.PAGE_CATALOG]: { component: VtoPageCatalog.name },
};
