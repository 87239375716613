import { AnalyticsVtoDetailObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import ProductObject from './ProductObject';

export default class VtoDetailObject extends DataObject {
  constructor(data) {
    super('nievent', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsVtoDetailObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${data.category}] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Detail|VTO Initialization';
    if (data.action) {
      Logger.warn(
        `[AnalyticsVtoDetailObjectException] Action always defaults to "VTO Detail" (L'Oréal guideline), currently [${data.action}] was provided`,
        data,
        false
      );
    }

    this.label = 'VtoDetailObject';
    if (data.label) {
      this.label = data.label;
    }

    if (!data.products || data.products.length === 0) {
      Logger.error('[AnalyticsVtoDetailObjectException] Product is required for vtoDetail', data);
      throw new AnalyticsVtoDetailObjectException('Product is required for vtoDetail');
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
    this.result.ecommerce.detail = this.result.ecommerce.detail || {};
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set products(products) {
    products = products.map((product) => new ProductObject(product).toObject());

    this.initEcommerce();

    this.result.ecommerce.detail.products = products;
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.detail.products) || [];
  }
}
