<script>
import { globalResizeObserver } from '@Foundation/utilities/globalResizeObserver';
import {
  PROMO_BAR_BODY_CLASS,
  PROMO_BAR_HEIGHT_CSS_VARIABLE,
  PROMO_BAR_EXPIRE_DATE_STORAGE,
} from '../settings';

/**
 * Direction to scroll
 * @typedef Direction
 * @type {('left'|'right')}
 */

export default {
  name: 'OapPromoBar',

  props: {
    /**
     * @typedef {Object} Autoplay
     * @property {boolean} active
     * @property {number} interval  - Interval in milliseconds
     */
    autoplay: {
      /**
       * @returns {Autoplay}
       */
      default: () => ({
        active: false,
        interval: null,
      }),
      type: Object,
      validator: (obj) => ['active', 'interval'].every((prop) => prop in obj),
    },
    /**
     * @type {number} - Cooldown time in hours
     */
    dismissTime: {
      default: 0,
      type: Number,
    },
    isInfinite: {
      default: true,
      type: Boolean,
    },
    isScrollable: {
      default: false,
      type: Boolean,
    },
    itemSelector: {
      default: null,
      type: String,
    },
    promoBarSelector: {
      default: '.oap-promo-bar',
      type: String,
    },
  },

  data: () => ({
    promoBar: null,
    autoplayFnReference: null,
    intersectionObservers: [],
  }),

  computed: {
    itemsElements() {
      return document.querySelectorAll(this.itemSelector);
    },
  },

  mounted() {
    this.autoDismiss();
    this.promoBar = document.querySelector(this.promoBarSelector);
    this.intersectionViewportObserver(this.promoBar, this.updateBarVisibility);
    this.itemsElements.forEach((item) =>
      this.intersectionViewportObserver(item, this.updateFocusableElementsVisibility)
    );
    this.updateHeightCssVariable();
    globalResizeObserver.observe(this.promoBar, this.updateHeightCssVariable, 200);
    this.setupMouseEvents();
  },

  beforeUnmount() {
    document.body.classList.remove(PROMO_BAR_BODY_CLASS);
    this.unobserveIntersectionObservers();
    globalResizeObserver.unobserve(this.promoBar);
    this.stopAutoplay();
  },

  methods: {
    autoDismiss() {
      const deadline = window?.localStorage.getItem(PROMO_BAR_EXPIRE_DATE_STORAGE);
      if (!deadline) return;

      const now = new Date().getTime();

      if (deadline > now) {
        this.removeFromDom();
      } else {
        window?.localStorage.removeItem(PROMO_BAR_EXPIRE_DATE_STORAGE);
      }
    },

    dismiss() {
      const deadline = new Date().getTime() + this.dismissTime * 60 * 60 * 1000;

      window?.localStorage.setItem(PROMO_BAR_EXPIRE_DATE_STORAGE, deadline);
      this.removeFromDom();
    },

    /**
     * Calculate coordinate to scroll
     * @param {Direction} direction
     * @returns {number}
     */
    getCoordinateToScroll(direction) {
      const { clientWidth, scrollLeft, scrollWidth } = this.promoBar;

      if (this.isInfinite) {
        const scrollLeftMax = scrollWidth - clientWidth;

        const isAtBeginning = scrollLeft === 0;
        const isAtEnd = Math.abs(scrollLeft - scrollLeftMax) <= 1;

        if (isAtBeginning && direction === 'left') {
          return scrollLeftMax;
        }

        if (isAtEnd && direction === 'right') {
          return -scrollLeftMax;
        }
      }

      return direction === 'right' ? clientWidth : -clientWidth;
    },

    intersectionViewportObserver(element, callback) {
      const observer = new IntersectionObserver((entries) => entries.forEach(callback), {
        threshold: 1,
      });

      observer.observe(element);
      this.intersectionObservers.push({ element, observer });
    },

    removeFromDom() {
      if (this.promoBar) this.promoBar.remove();
    },

    setupMouseEvents() {
      this.promoBar.addEventListener('mouseenter', () => {
        this.stopAutoplay();
      });

      this.promoBar.addEventListener('mouseleave', () => {
        this.startAutoplay();
      });
    },

    startAutoplay() {
      const { autoplay, isScrollable } = this;
      const { active, interval } = autoplay;

      if (active && interval && isScrollable) {
        if (this.autoplayFnReference) this.stopAutoplay();

        this.autoplayFnReference = setTimeout(() => {
          this.scroll('right');
        }, interval);
      }
    },

    stopAutoplay() {
      clearTimeout(this.autoplayFnReference);
    },

    /**
     * @param {Direction} direction
     */
    scroll(direction) {
      const coordinate = this.getCoordinateToScroll(direction);

      this.stopAutoplay();
      this.promoBar.scrollBy({ left: coordinate, behavior: 'smooth' });
      this.startAutoplay();
    },

    unobserveIntersectionObservers() {
      this.intersectionObservers.forEach(({ element, observer }) => observer.unobserve(element));
    },

    updateBarVisibility({ intersectionRatio }) {
      if (intersectionRatio === 1) {
        document.body.classList.add(PROMO_BAR_BODY_CLASS);
        this.startAutoplay();
      } else {
        document.body.classList.remove(PROMO_BAR_BODY_CLASS);
        this.stopAutoplay();
      }
    },

    updateFocusableElementsVisibility({ intersectionRatio, target }) {
      const focusableElements = [
        ...target.querySelectorAll('a'),
        ...target.querySelectorAll('button'),
      ];

      if (intersectionRatio === 1) {
        focusableElements.forEach((el) => el.setAttribute('tabindex', '0'));
        target.removeAttribute('aria-hidden');
      } else {
        focusableElements.forEach((el) => el.setAttribute('tabindex', '-1'));
        target.setAttribute('aria-hidden', true);
      }
    },

    updateHeightCssVariable() {
      document.body.style.setProperty(
        PROMO_BAR_HEIGHT_CSS_VARIABLE,
        `${this.promoBar.clientHeight}px`
      );
    },
  },

  render() {
    return this.$slots.default({
      dismiss: this.dismiss,
      scroll: this.scroll,
    });
  },
};
</script>
