<template>
  <section
    ref="trigger"
    :class="[
      trigger,
      'oap-immersive-parallax',
      { '--fade-in-appearing': isFadeIn },
      { '--flipped': isFlipped },
      { '--wide-product': hasWideProduct },
    ]"
  >
    <OapPicture
      v-if="background"
      class="oap-immersive-parallax__background"
      :mobile="background.mobile"
      :desktop="background.desktop"
    />
    <div class="oap-immersive-parallax__images">
      <OapPicture
        v-if="firstImage"
        class="oap-immersive-parallax__first"
        :mobile="firstImage.mobile"
        :desktop="firstImage.desktop"
        :style="`z-index: ${firstImageZIndex}`"
      />
      <OapPicture
        class="oap-immersive-parallax__product"
        :mobile="productImage.mobile"
        :desktop="productImage.desktop"
        :style="`z-index: ${productImageZIndex}`"
      />
      <OapPicture
        v-if="secondImage"
        class="oap-immersive-parallax__second"
        :mobile="secondImage.mobile"
        :desktop="secondImage.desktop"
        :style="`z-index: ${secondImageZIndex}`"
      />
    </div>
    <div class="oap-immersive-parallax__copy">
      <p
        v-if="ontitle"
        class="oap-immersive-parallax__text gsap-text-appearing"
        :style="`color: ${ontitle.color}; order: ${ontitle.order}`"
        v-html="ontitle.text"
      ></p>
      <div
        v-if="title"
        class="oap-immersive-parallax__title gsap-text-appearing"
        :style="`color: ${title.color}; order: ${title.order}`"
        v-html="title.text"
      ></div>
      <div
        v-if="subtitle"
        class="oap-immersive-parallax__subtitle gsap-text-appearing"
        :style="`color: ${subtitle.color}; order: ${subtitle.order}`"
        v-html="subtitle.text"
      ></div>
      <p
        v-if="description"
        class="oap-immersive-parallax__text gsap-text-appearing"
        :style="`color: ${description.color}; order: ${description.order}`"
        v-html="description.text"
      ></p>
      <img class="oap-immersive-parallax__stamp" :src="stamp" alt="" />
    </div>
  </section>
</template>

<script>
import OapPicture from '../../../../OapPicture/code/Scripts/components/OapPicture.vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { uniqueId } from '@Foundation/utilities/uniqueId';

const LARGE_SCREEN_START = 999;
const ELEMENTS_TO_ANIMATE = {
  copy: '.oap-immersive-parallax__copy',
  productImage: '.oap-immersive-parallax__product img',
  firstImage: '.oap-immersive-parallax__first img',
  secondImage: '.oap-immersive-parallax__second img',
  gsapText: '.gsap-text-appearing',
};
const ANIMATION_FUNCTION = 'ease-in-out';
const ANIMATION_LABEL = {
  copy: 'copyLabel',
  productImage: 'productImageLabel',
};
const ANIMATION_TRANSITION = {
  horizontal: 'translate(-50%, 0%) translate(0, 0)',
  vertical: 'translate(0%, -50%) translate(0, 0)',
};

let timeline = null;

export default {
  name: 'OapImmersiveParallax',

  components: {
    OapPicture,
  },

  props: {
    // copy
    ontitle: { type: Object, default: () => {} },
    title: { type: Object, default: () => {} },
    subtitle: { type: Object, default: () => {} },
    description: { type: Object, default: () => {} },

    // images
    stamp: { type: String, default: '' },
    background: { type: Object, default: () => {} },
    firstImage: { type: Object, default: () => {} },
    productImage: { type: Object, required: true },
    secondImage: { type: Object, default: () => {} },

    // scrollTrigger settings
    scrub: { type: Boolean, default: true },
    pin: { type: Boolean, default: true },
    start: { type: String, default: 'top top' },
    end: { type: String, default: '+40%' },

    isFadeIn: { type: Boolean, default: false },
    isFlipped: { type: Boolean, default: false },
    hasWideProduct: { type: Boolean, default: false },
    productImageZIndex: { type: [Number, String], default: 2 },
    firstImageZIndex: { type: [Number, String], default: 3 },
    secondImageZIndex: { type: [Number, String], default: 1 },
  },

  computed: {
    isLargeScreen() {
      return window.innerWidth >= LARGE_SCREEN_START;
    },

    trigger() {
      return `gsap-trigger-${uniqueId()}`;
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    setTimeout(this.bindAnimation, 0);
  },

  methods: {
    bindAnimation() {
      timeline = gsap.timeline({
        ease: ANIMATION_FUNCTION,
        scrollTrigger: {
          trigger: `.${this.trigger}`,
          scrub: this.scrub,
          pin: this.pin,
          start: this.start,
          end: this.end,
        },
      });

      this.isFadeIn ? this.playFadeInAnimation() : this.playBounceUpAnimation();
    },

    playBounceUpAnimation() {
      const textElements = gsap.utils.toArray(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.gsapText}`);

      timeline
        .set(
          this.isLargeScreen ? `.${this.trigger} ${ELEMENTS_TO_ANIMATE.copy}` : textElements,
          this.isLargeScreen ? { y: this.$refs.trigger.offsetHeight } : { marginBottom: 30 }
        )
        .set(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.productImage}`, { y: '100%' })
        .set(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.firstImage}`, { x: -500 })
        .to(
          this.isLargeScreen ? `.${this.trigger} ${ELEMENTS_TO_ANIMATE.copy}` : textElements,
          this.isLargeScreen
            ? { transform: ANIMATION_TRANSITION.vertical } // fix to overwriting position in css
            : { marginBottom: 0 },
          ANIMATION_LABEL.copy
        )
        .to(
          `.${this.trigger} ${ELEMENTS_TO_ANIMATE.productImage}`,
          {
            // fix to overwriting position in CSS
            transform: this.isLargeScreen
              ? ANIMATION_TRANSITION.vertical
              : ANIMATION_TRANSITION.horizontal,
          },
          ANIMATION_LABEL.copy
        )
        .to(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.firstImage}`, { x: 0 }, ANIMATION_LABEL.copy);
    },

    playFadeInAnimation() {
      timeline
        .set(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.productImage}`, { opacity: 0, y: '-20%' })
        .set(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.copy}`, { opacity: 0 })
        .set(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.firstImage}`, {
          opacity: 0,
          transform: 'translate(-40%, 0%) translate(0, 0)',
        })
        .set(`.${this.trigger} ${ELEMENTS_TO_ANIMATE.secondImage}`, {
          opacity: 0,
          transform: 'translate(-60%, 0%) translate(0, 0)',
        })
        .to(
          `.${this.trigger} ${ELEMENTS_TO_ANIMATE.productImage}`,
          { opacity: 1, y: '0%' },
          ANIMATION_LABEL.productImage
        )
        .to(
          `.${this.trigger} ${ELEMENTS_TO_ANIMATE.copy}`,
          { opacity: 1 },
          `${ANIMATION_LABEL.productImage}+=5%`
        )
        .to(
          `.${this.trigger} ${ELEMENTS_TO_ANIMATE.firstImage}`,
          { opacity: 1, transform: ANIMATION_TRANSITION.horizontal },
          `${ANIMATION_LABEL.productImage}+=10%`
        )
        .to(
          `.${this.trigger} ${ELEMENTS_TO_ANIMATE.secondImage}`,
          { opacity: 1, transform: ANIMATION_TRANSITION.horizontal },
          `${ANIMATION_LABEL.productImage}+=10%`
        );
    },
  },
};
</script>
