<template>
  <div ref="root" :class="['oap-marquee', theme, { '-has-enlarged-font': hasEnlargedFont }]">
    <div v-if="headline" class="oap-marquee__headline">{{ headline }}</div>
    <div class="oap-marquee__list">
      <template v-for="time in times">
        <div class="oap-marquee__block">
          <template v-for="(item, index) in items">
            <div class="oap-marquee__item">
              <span v-if="item.text" class="oap-marquee__text">{{ item.text }}</span>
              <img
                v-else
                class="oap-marquee__logo"
                :src="item.logoSrc"
                :alt="itemsAltText[index].altText"
              />
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACCESSIBILITY_STORE_NAMESPACE,
  GET_SITEWIDE_ANIMATIONS,
} from '@Foundation/accessibilityStore/accessibilityStore';

export default {
  name: 'OapMarquee',

  props: {
    headline: { type: String, default: null },
    theme: { type: String, default: null },
    animationDuration: { type: Number, default: 10 },
    hasEnlargedFont: { type: Boolean, default: false },
    items: { type: Array, default: () => [] },
    itemsAltText: { type: Array, default: () => [] },
  },

  data() {
    return {
      times: 1,
    };
  },
  methods: {
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_SITEWIDE_ANIMATIONS]),
  },
  computed: {
    marqueeAnimation() {
      return this[GET_SITEWIDE_ANIMATIONS]()
        ? `marquee ${this.animationDuration}s linear infinite`
        : 'none';
    },
  },
  mounted() {
    this.$nextTick(() => {
      const marqueeItems = this.$refs.root.querySelectorAll('.oap-marquee__item');
      let totalMarqueeItemsWidth = 0;

      if (marqueeItems.length > 0) {
        marqueeItems.forEach((item) => {
          totalMarqueeItemsWidth += item.offsetWidth;
        });

        if (Number.isFinite(totalMarqueeItemsWidth) && totalMarqueeItemsWidth > 0) {
          this.times = Math.ceil(window.innerWidth / totalMarqueeItemsWidth);
          ++this.times;
        }
      }

      document.body.style.setProperty('--marquee-animation', this.marqueeAnimation);
    });
  },
};
</script>
