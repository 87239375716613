export const mixinParseURL = {
  methods: {
    /**
     * URLHasKey
     *
     * parse URL search parameters and look for a given key.
     *
     * /!\ URLSearchParams unsupported on ie.
     *
     * @param {string}   key          key to be found in search param (query string)
     * @param {URL}      [URLObject]  Optional URL object to be parsed instead of window.location
     *
     * @return {boolean}
     */
    URLHasKey(key, URLObject) {
      const url = URLObject || window.location,
        searchString = url.search,
        searchParams = new URLSearchParams(searchString);

      return searchParams.has(key);
    },
  },
};
