<template>
  <ul class="oap-product-shade-list">
    <li v-for="(color, index) in visibleShades" :key="index">
      <div class="oap-product-shade" :style="{ 'background-color': color }">
        <span class="is-sr-only">{{ shades.colorLabel }}: {{ color }}</span>
      </div>
    </li>
    <li v-if="hasMoreShadesAvailable">
      <div class="oap-product-shade oap-product-shade--plus">
        <span class="is-sr-only">{{ shades.moreShadesLabel }}</span>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'OapProductShadeList',

  props: {
    shades: {
      type: Object,
    },
  },

  computed: {
    hasMoreShadesAvailable() {
      return this?.shades?.colors.length >= this.shades.limit;
    },
    // TODO: Might not work as expected and require a rewrite
    visibleShades() {
      return this?.shades?.colors?.slice(0, this.shades.limit);
    },
  },
};
</script>
