<template>
  <div class="vto__modal" :class="{ '-closed': !modalDisplay }">
    <div class="vto__modal-overlay" @click.self="historyBackPdp"></div>
    <div class="vto__app" :class="[`vto__app--${country}`]">
      <div id="vto__frame-container" class="vto__frame-container"></div>
      <transition :name="getCurrentRouteTransition">
        <component :is="getCurrentRouteComponent" />
      </transition>
    </div>
  </div>
</template>

<script>
import {
  BUS_EVENT_VTO_ENTER,
  FRAME_CONTAINER_ID,
  FRAME_NAME,
  MF_SCRIPT_NAME,
  VTO_TRIGGER_URL_PARAM,
} from '../settings';

import { mapActions, mapGetters, mapState } from '../store/helpers';

import {
  DO_DISPLAY_SHADE_OVERLAY,
  DO_ENTER,
  DO_EVENT_TRACKING,
  DO_EXIT,
  DO_FRAME_READY,
  DO_HISTORY_BACK,
  DO_INCREMENT_HISTORY_DEPTH,
  DO_INIT_STORE,
  DO_LOAD_DATA,
  DO_MF_ERROR,
  DO_MF_HAIR_COLOR_DETECTED,
  DO_MF_SHOW_FOR_YOU_LOADER,
} from '../store/actions';

// misc.
import eventBus from '@loreal/eventbus-js';
import { getCookie } from '../../../../../Foundation/Core/code/Scripts';
import { mixinParseURL } from '../../../../../Foundation/Core/code/Scripts/mixins/parseURL/parseURL';
import { loadscript } from '../../../../../Foundation/Core/code/Scripts/directives/loadscript/loadscript';

// components
import VtoPageCatalog from './VtoPageCatalog.vue';
import VtoPageCompare from './VtoPageCompare.vue';
import VtoPageEntitySelection from './VtoPageEntitySelection.vue';
import VtoPageError from './VtoPageError.vue';
import VtoPageHairDetection from './VtoPageHairDetection.vue';
import VtoPageLanding from './VtoPageLanding.vue';
import VtoPageLoading from './VtoPageLoading.vue';
import VtoPageMain from './VtoPageMain.vue';
import VtoPageShare from './VtoPageShare.vue';

export default {
  name: 'VtoApp',

  components: {
    VtoPageCatalog,
    VtoPageEntitySelection,
    VtoPageCompare,
    VtoPageError,
    VtoPageHairDetection,
    VtoPageLanding,
    VtoPageLoading,
    VtoPageMain,
    VtoPageShare,
  },

  mixins: [mixinParseURL],

  props: {
    enableFavorite: { default: false, type: Boolean },
    favoriteSettings: { default: null, type: Object },
    scriptUrl: { type: String, required: true },
    shouldSendConsentId: { type: Boolean, default: false },
    userEmail: { type: String, default: '' },
    publisherId: { type: String, required: true },
    country: { type: String, required: true },
    cmsCountry: { type: String, default: '' },
    language: { type: String, required: true },
    rating: { type: Boolean, required: true },
    shareCaptureCount: { type: String, required: true },
    iframeTitle: { type: String, required: true },

    category: { type: String, required: true },

    // current PDP Product Object
    initialProduct: { type: Object, default: () => null },

    // UPC Array of products to be activated within iframe.
    // Should be empty for customlook category
    upcList: { type: Array, required: true },

    frameParamtext: { type: Object, required: false, default: () => {} },
    haircolorFamilies: { type: Array, default: () => [] },
    enableEmailMyLookForm: { type: Boolean, default: false },
    emailMyLookFormPageCtaLabel: { type: String, default: 'Email my results' },
    emailMyLookFormPageUrl: { type: String, default: 'Email my results' },

    /** this will avoid vto-app to change scrolling behaviour
     * and let new tabs directive (toolbar-tabs.js) handle the behaviour */
    isInZapper: { type: Boolean, default: false },
  },

  computed: {
    ...mapState(['modalDisplay', 'originLocation']),

    ...mapGetters([
      'isMainCategoryHairColor',
      'getCurrentRouteComponent',
      'getCurrentRouteTransition',
      'getSelectedProducts',
      'getSelectedProductItems',
    ]),
  },

  watch: {
    modalDisplay() {
      /* istanbul ignore next */
      if (!this.isInZapper) {
        document.body.classList.toggle('noScroll', this.modalDisplay);
      }
    },
  },

  mounted() {
    this[DO_INIT_STORE](this.$props);
    window.initModiface = this.initModiface;
    this.initListeners();
    this.initBus();

    /* istanbul ignore next */
    if (this.URLHasKey(VTO_TRIGGER_URL_PARAM)) eventBus.emit(BUS_EVENT_VTO_ENTER);
    if (this.URLHasKey('buy')) {
      this.openMCTBModal();
    }
  },

  methods: {
    ...mapActions([
      DO_DISPLAY_SHADE_OVERLAY,
      DO_ENTER,
      DO_EVENT_TRACKING,
      DO_EXIT,
      DO_FRAME_READY,
      DO_HISTORY_BACK,
      DO_INCREMENT_HISTORY_DEPTH,
      DO_INIT_STORE,
      DO_LOAD_DATA,
      DO_MF_ERROR,
      DO_MF_HAIR_COLOR_DETECTED,
      DO_MF_SHOW_FOR_YOU_LOADER,
    ]),

    openMCTBModal() {
      const refAppInstance = document.querySelector('#rfp_component_instance_app');
      if (refAppInstance && this.MCTBButton) {
        this.MCTBButton.click();
      }
    },

    initListeners() {
      window.addEventListener('popstate', () => {
        let originHash = this.originLocation && this.originLocation.hash;
        let currentHash = window.location.hash;

        // popstate doesn't give information about navigation direction
        // In case of hash presence, we check if it matches cached origin
        if (currentHash && currentHash !== originHash) {
          return;
        }

        // Quit VTO and back to previous state
        this.historyBackHandler();
      });
    },

    historyBackPdp() {
      /* istanbul ignore next */
      if (typeof this.frameParamtext === 'undefined') {
        window.history.back();
      }
    },

    historyBackHandler() {
      // decrement internal history depth counter
      // as popstate is caused by a call to back
      this[DO_INCREMENT_HISTORY_DEPTH](-1);
      this[DO_EXIT]();
    },

    initBus() {
      // TODO: Create `.once` polyfill for `mitt()`
      eventBus.on(BUS_EVENT_VTO_ENTER, this.load);
      eventBus.on(BUS_EVENT_VTO_ENTER, this[DO_ENTER]);
    },

    load() {
      eventBus.off(BUS_EVENT_VTO_ENTER, this.load);
      this[DO_LOAD_DATA]();

      /* istanbul ignore next */
      loadscript.beforeMount(null, {
        value: {
          name: MF_SCRIPT_NAME,
          url: this.scriptUrl,
        },
      });
    },

    initModiface() {
      /* istanbul ignore next */
      const MF = window.MF_CHANNEL_PARENT;

      let frameParams = this.getFrameParams();

      if (this.cmsCountry) frameParams.cmsCountry = this.cmsCountry;

      if (typeof this.frameParamtext !== 'undefined' && Object.keys(this.frameParamtext).length) {
        frameParams['service'] = 'qrinstore';
        frameParams.text = this.frameParamtext;
      }

      if (this.shouldSendConsentId) {
        const id = new URLSearchParams(getCookie('OptanonConsent')).get('consentId');
        frameParams.oneTrustConsentId = id;
        frameParams.oneTrustUserEmail = this.userEmail;
      }

      /* istanbul ignore next */
      MF.addFrame(frameParams);

      /* istanbul ignore next */
      MF.listen('frame_ready', this[DO_FRAME_READY]);

      /* istanbul ignore next */
      MF.listen('exit', this[DO_HISTORY_BACK]);

      /* istanbul ignore next */
      MF.listen('event_tracking', this[DO_EVENT_TRACKING]);

      /* istanbul ignore next */
      MF.listen('display_shade_overlay', this[DO_DISPLAY_SHADE_OVERLAY]);

      /* istanbul ignore next */
      MF.listen('show_for_you_loader', this[DO_MF_SHOW_FOR_YOU_LOADER]);

      /* istanbul ignore next */
      MF.listen('hair_color_detected', this[DO_MF_HAIR_COLOR_DETECTED]);

      /* istanbul ignore next */
      MF.listen('error', this[DO_MF_ERROR]);
    },

    getFrameParams() {
      let selectedUpc, selectedCustomLook;
      let frameTitle = this.initialProduct
        ? `${this.iframeTitle}: ${this.initialProduct?.brand} ${this.initialProduct?.subBrand}`
        : this.iframeTitle;

      if (this.initialProduct) {
        selectedUpc = {
          upc: this.initialProduct.upc,
          prodName: `${this.initialProduct.brand} ${this.initialProduct.subBrand}`,
          shadeName: this.initialProduct.name,
          upcUrl: this.initialProduct.url,
        };

        selectedCustomLook = {
          look: [
            {
              category: this.initialProduct.category,
              upc: this.initialProduct.upc,
            },
          ],
        };
      }

      return {
        frameName: FRAME_NAME,
        divId: FRAME_CONTAINER_ID,
        publisherId: this.publisherId,
        category: this.category,
        upcList: this.upcList,
        country: this.country,
        language: this.language,
        shareCaptureCount: this.shareCaptureCount,
        uxWrapper: true,
        useStandardTaggingPlan: true,
        usingHaircolorForYou: this.isMainCategoryHairColor,
        exitHaircolorForYouFailure: false,
        frameAttributes: {
          title: frameTitle,
        },
        selectedUpc: selectedUpc || null,
        selectedCustomLook: selectedCustomLook || null,
      };
    },
  },
};
</script>
