<template>
  <ul
    v-if="show"
    :class="[
      `oap-carousel-controls`,
      type && `oap-carousel-controls--type-${type}`,
      layout && `oap-carousel-controls--layout-${layout}`,
    ]"
  >
    <template v-if="type === 'dots'">
      <li
        v-for="({ id }, index) in items"
        :key="id"
        :class="['oap-carousel-controls__item', intersectingItemIndex === index && '--active']"
      >
        <a
          v-if="id"
          :href="`#${id}`"
          :aria-current="intersectingItemIndex === index"
          :aria-label="setAriaLabel(index)"
          class="oap-carousel-controls__dot"
        ></a>
      </li>
    </template>

    <template v-if="type === 'arrows'">
      <li class="oap-carousel-controls__item">
        <button :disabled="!prevItem" class="oap-carousel-controls__arrow" @click="scrollToPrev">
          <span class="is-sr-only">{{ prevLabel }}</span>
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-prev" />
          </svg>
        </button>
      </li>
      <li class="oap-carousel-controls__item">
        <button :disabled="!nextItem" class="oap-carousel-controls__arrow" @click="scrollToNext">
          <span class="is-sr-only">{{ nextLabel }}</span>
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-next" />
          </svg>
        </button>
      </li>
    </template>
  </ul>
</template>

<script>
import { AnalyticsHandler, debounce, inViewport } from '@Foundation';

let observer;
let parentElement;

const COMPARISON_GRID_CONTROL = 'comparisonGrid__control';

export default {
  name: 'OapCarouselControls',

  props: {
    itemSelector: { type: String, required: true },
    scrollContainerSelector: { type: String, default: '.oap-slider__list' },
    mediaQueryList: { type: String, default: '(min-width: 0px)' },
    mobileScreenWidthEnd: { type: Number, default: 511 },
    type: { type: String, default: 'dots' },
    layout: { type: String, default: '' },
    ariaLabel: { type: String, default: '' },
    nextLabel: { type: String, default: 'Next card' },
    prevLabel: { type: String, default: 'Previous card' },
    isInComparisonGrid: { type: Boolean, default: false },
    forceMobile: { type: Boolean, default: false },
    analytics: { type: Object, default: () => {} },
  },

  data() {
    return {
      show: false,
      items: null,
      scrollContainer: null,
      currentActiveItemIndex: 0,
      intersectingItemIndex: 0,
    };
  },

  computed: {
    prevItem() {
      return this.items[this.currentActiveItemIndex - 1];
    },

    nextItem() {
      if (this.isCurrentItemInViewport) {
        // if current item is in the viewport, decrement the next arrow click
        // so no need to click next button for last 2 iterations
        if (this.currentActiveItemIndex < this.items.length - 2) {
          return this.items[this.currentActiveItemIndex + 1];
        }
        return null;
      } else {
        return this.items[this.currentActiveItemIndex + 1];
      }
    },

    isCurrentItemInViewport() {
      return inViewport(this.items[this.currentActiveItemIndex]);
    },

    scrollItemsWidth() {
      return this.getItems().reduce(
        (previousValue, currentValue) => previousValue + currentValue.clientWidth,
        0
      );
    },

    isMobile() {
      return window.innerWidth <= this.mobileScreenWidthEnd;
    },

    scrollContainerPadding() {
      const element = this.$parent.$el.querySelector(this.scrollContainerSelector);
      const style = window.getComputedStyle(element);
      return parseInt(style.paddingLeft);
    },
  },

  watch: {
    show(isShown) {
      if (this.isInComparisonGrid) {
        isShown ? this.addComparisonGridClass() : this.removeComparisonGridClass();
      }
    },
  },

  mounted() {
    this.items = this.getItems();

    this.scrollContainer = parentElement.querySelector(this.scrollContainerSelector);

    if (this.items && this.items.length > 1) {
      if (this.type === 'dots') {
        observer = new IntersectionObserver(this.updateIntersectingItemIndex, { threshold: 0.9 });
      }

      const mql = window.matchMedia(this.mediaQueryList);
      this.toggleControls(mql);
      mql.addEventListener('change', this.toggleControls);
    }

    if (this.isInComparisonGrid) {
      this.handleResize();
      window.addEventListener('resize', debounce(this.handleResize, 250));
    }
  },

  beforeUnmount() {
    if (this.isInComparisonGrid) {
      window.removeEventListener('resize', this.handleResize);
    }
  },

  methods: {
    getItems() {
      parentElement = this.$el.parentElement || document;

      return Array.from(parentElement.querySelectorAll(this.itemSelector));
    },

    pushAnalytics() {
      if (this.analytics) {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          event_name: this.analytics.event_name,
          action: this.analytics.action,
          category: this.analytics.category,
          label: this.analytics.label,
          product_info: this.analytics.product_info,
        });
      }
    },

    scrollToPrev() {
      if (this.prevItem) {
        if (this.forceMobile && !this.isMobile) {
          this.setSliderStyleLeft(this.prevItem);
        } else {
          this.setSliderScrollLeft(this.prevItem);
        }

        this.currentActiveItemIndex--;

        if (this.currentActiveItemIndex === 0) {
          if (this.forceMobile && !this.isMobile) {
            this.scrollContainer.style.left = 0;
          } else {
            this.scrollContainer.scrollLeft = -document.querySelector(this.itemSelector).offsetLeft;
          }
        }
      }
    },

    scrollToNext() {
      if (this.nextItem) {
        if (this.forceMobile && !this.isMobile) {
          this.setSliderStyleLeft(this.nextItem);
        } else {
          this.setSliderScrollLeft(this.nextItem);
        }
        this.currentActiveItemIndex++;
      }

      this.pushAnalytics();
    },

    setAriaLabel(index) {
      return `${this.ariaLabel} ${parseInt(index) + 1}`;
    },

    toggleControls({ matches }) {
      this.show = matches;
      if (this.type === 'dots') {
        this.show ? this.startObserver() : this.stopObserver();
      }
    },

    updateIntersectingItemIndex([e]) {
      if (e.isIntersecting) {
        this.intersectingItemIndex = this.items.indexOf(e.target);
      }
    },

    startObserver() {
      this.items.forEach((item) => {
        observer.observe(item);
      });
    },

    stopObserver() {
      this.items.forEach((item) => {
        observer.unobserve(item);
      });
    },

    handleResize() {
      this.show = this.isMobile && this.scrollItemsWidth > window.innerWidth;
    },

    addComparisonGridClass() {
      document
        .querySelector(this.scrollContainerSelector)
        .parentElement.classList.add(COMPARISON_GRID_CONTROL);
    },

    removeComparisonGridClass() {
      document
        .querySelector(this.scrollContainerSelector)
        .parentElement.classList.remove(COMPARISON_GRID_CONTROL);
    },

    setSliderStyleLeft(item) {
      this.scrollContainer.style.left = `-${
        item.offsetLeft - (this.layout === 'centered' ? this.scrollContainerPadding : 0)
      }px`;
    },

    setSliderScrollLeft(item) {
      this.scrollContainer.scrollTo({
        left: `${item.offsetLeft - (this.layout === 'centered' ? this.scrollContainerPadding : 0)}`,
      });
    },
  },
};
</script>
