<template>
  <div
    ref="container"
    v-view-position-helper.body="'oap-video-immersive__video'"
    class="oap-video-immersive__container"
  >
    <video
      ref="video"
      class="oap-video-immersive__video"
      src=""
      preload="auto"
      :autoplay="autoplay"
      :playsinline="playsinline"
      :muted="muted"
    ></video>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { once, isSafari, isInstagram } from '../../../../../Foundation/Core/code/Scripts';
import { viewPositionHelper } from '../../../../../Foundation/Core/code/Scripts/directives/view-position-helper/view-position-helper';
import { makeVideoAccessible } from '../../../../../Foundation/Core/code/Scripts/utilities/makeVideoAccessible';

const breakpoints = {
  SM_SCREEN_START: 376,
  LG_SCREEN_START: 481,
};
const videoTypes = {
  WEBM: 'video/webm',
  MP4: 'video/mp4',
};
let video;
let timeline;

export default {
  name: 'OapVideoImmersive',

  directives: {
    viewPositionHelper,
  },

  props: {
    sources: { type: Object, required: true },
    autoplay: { type: Boolean, default: false },
    playsinline: { type: Boolean, default: true },
    muted: { type: Boolean, default: true },
    containerHeightMultiplier: { type: Number, default: 1 },
    isInformative: { type: Boolean, default: false },
    videoTitle: { type: String, default: '' },
  },

  computed: {
    videoSources() {
      const windowInnerWidth = window.innerWidth;
      return windowInnerWidth < breakpoints.LG_SCREEN_START
        ? windowInnerWidth < breakpoints.SM_SCREEN_START
          ? this.sources.xs
          : this.sources.sm
        : this.sources.lg;
    },
  },

  beforeCreate() {
    document.body.style.setProperty('--oap-product-toolbar-opacity-immersive', '0');
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    this.assignVideoSrc();

    this.onLoadMetadata();

    makeVideoAccessible(this.$refs.video, this.isInformative, this.videoTitle);
  },

  methods: {
    assignVideoSrc() {
      video = this.$refs.video;

      // Before downloading the video
      // check what video type is supported by the browser
      const supportedVideoType = video.canPlayType(videoTypes.WEBM)
        ? videoTypes.WEBM
        : videoTypes.MP4;

      let videoSource = this.videoSources.find((source) => source.type === supportedVideoType);

      if (isSafari || isInstagram) {
        // Issue with playback of WebM on Safari on Big Sur macOS and iOS makes us to force to MP4
        videoSource = this.videoSources.find((source) => source.type === videoTypes.MP4);
        // Issues with blobbing video on Safari on iOS makes us use streaming instead
        video.src = videoSource.url;
        video.type = videoSource.type;

        video.play().then(() => {
          video.pause();
        });
      } else {
        this.blobVideo(videoSource);
      }
    },

    blobVideo(videoSource) {
      if (videoSource) {
        fetch(videoSource.url)
          .then((response) => response.blob())
          .then((response) => {
            let blobURL = URL.createObjectURL(response);
            let time = video.currentTime;

            video.src = blobURL;
            video.currentTime = time + 0.01;
          });
      }
    },

    onLoadMetadata() {
      once(video, 'loadedmetadata', () => {
        this.$refs.container.style.height =
          Math.floor(video.duration) * this.containerHeightMultiplier * 1000 + 'px';

        // TODO: implement a loader?

        once(video, 'canplaythrough', () => {
          this.setupGsapTimeline();

          timeline.fromTo(
            video,
            {
              currentTime: 0,
            },
            {
              currentTime: video.duration || 1,
            }
          );
        });
      });
    },

    setupGsapTimeline() {
      timeline = gsap.timeline({
        defaults: { duration: 1 },
        scrollTrigger: {
          trigger: '.oap-video-immersive__container',
          start: 'top top',
          end: 'bottom bottom',
          scrub: true,
        },
      });
    },
  },
};
</script>
