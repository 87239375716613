import { AnalyticsProductImpressionObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import ProductObject from './ProductObject';

export default class ProductImpressionObject extends DataObject {
  constructor(data) {
    if (data.viewPort) {
      super('uaevent', data);
    } else {
      super('nievent', data);
    }
    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsProductImpressionObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${data.category}] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Impressions';
    if (data.action) {
      Logger.warn(
        `[AnalyticsProductImpressionObjectException] Action always defaults to "Product Impressions" (L'Oréal guideline), currently [${data.action}] was provided`,
        data,
        false
      );
    }

    this.label = 'Product Impressions';
    if (data.label) {
      this.label = data.label;
    }

    //this.name = 'Product Impressions';
    if (data.name) {
      this.name = data.name;
    }

    if (data.viewPort !== true && (!data.products || data.products.length === 0)) {
      Logger.error(
        '[AnalyticsProductImpressionObjectException] Product(s) are required for a productImpression',
        data
      );
      throw new AnalyticsProductImpressionObjectException(
        'Product(s) are required for a productImpression'
      );
    }
    if (data.viewPort) {
      this.category = data.category;
      this.action = data.action;
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }

  set name(name) {
    this.result.event_name = this.setProperty(name, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set currency(currency) {
    this.initEcommerce();
    this.result.ecommerce.currencyCode = this.setProperty(currency).toUpperCase();
  }

  set products(products) {
    Object.keys(products).map(function (key) {
      if (!products[key].price) {
        products[key].price = 'none';
      }
    });
    this.initEcommerce();
    this.result.ecommerce.impressions = products.map((product) =>
      new ProductObject(product).toObject()
    );
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.impressions) || [];
  }
}
