import { debounce } from '../../utilities';
import eventBus from '@loreal/eventbus-js';
import { nextTick } from 'vue';

export const getSmallestOffsetHeightOf = (selector) =>
  Math.min(...Array.from(document.querySelectorAll(selector)).map((el) => el.offsetHeight));
export const updateStickyOffsetProperty = (containerNode, itemsSelector) =>
  containerNode.style.setProperty(
    '--sticky-offset',
    `calc(100vh - ${getSmallestOffsetHeightOf(itemsSelector)}px)`
  );

export const stickyOffset = {
  beforeMount: (el, { value }) => {
    const update = updateStickyOffsetProperty.bind(null, el, value);

    // TODO: is this correct or should async/await be used?
    nextTick(() => {
      update();
      window.addEventListener('resize', debounce(update, 200));
    });

    eventBus.on('update-sticky-offset', (payload) => {
      if (payload === value) update();
    });
  },
};
