<template>
  <a class="oap-header__logo-link" href="/">
    <img :src="actualLogo" :alt="actualAlt" class="oap-header__logo-image" />
  </a>
</template>

<script>
export default {
  name: 'HeaderLogo',
  props: {
    logo: { type: String, default: '' },
    mexLogo: { type: String, default: '' },
    alt: { type: String, default: 'Loreal Paris' },
    mexLogoAlt: { type: String, default: 'Loreal Paris' },
    colorsonicLogo: { type: String, default: 'Colorsonic' },
    colorsonicLogoAlt: { type: String, default: 'Colorsonic' },
  },
  data() {
    return {
      actualLogo: '',
      actualAlt: '',
    };
  },
  mounted() {
    const mexClassList = document.body.classList.contains('MEX-ff6600');
    const colorsonicClassList = document.body.classList.contains('colorsonic-page');
    if (mexClassList) {
      this.actualLogo = this.mexLogo;
      this.actualAlt = this.mexLogoAlt;
    } else if (colorsonicClassList) {
      this.actualLogo = this.colorsonicLogo;
      this.actualAlt = this.colorsonicLogoAlt;
    } else {
      this.actualLogo = this.logo;
      this.actualAlt = this.alt;
    }

    if (window.location && window.location.hash) {
      let doc = document;
      const imageLoad = doc.querySelectorAll('.lazyload');
      imageLoad.forEach((element) => {
        window.lazySizes.loader.unveil(element);
      });
      let element = doc.querySelector(window.location.hash);
      let headerOffset = 75;
      if (element) {
        setTimeout(function () {
          let elementPosition = element.getBoundingClientRect().top;
          let offsetPosition = elementPosition + window.scrollY - headerOffset;
          window.scrollTo(0, offsetPosition);
        }, 300);
      }
    }
  },
};
</script>
