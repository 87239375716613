export function makeVideoAccessible(element, isInformative, title) {
  if (element) {
    if (!isInformative) {
      element.setAttribute('tabindex', -1);
      element.setAttribute('aria-hidden', true);
      element.removeAttribute('title');
    } else {
      element.setAttribute('title', title);
    }
  }
}
