<template>
  <div class="oap-tab-header" role="tablist">
    <ul class="oap-tab-header__list -desktop">
      <li
        v-for="heading in headings.tabs"
        :key="heading.id"
        :class="['oap-tab-header__list__item', { '-active': activeItem === heading.id }]"
      >
        <OapTabHeaderItem
          :tab-component-id="tabComponentId"
          :tab-item-id="heading.id"
          :control-label="heading.heading"
          :tagging-object="taggingObject"
        >
        </OapTabHeaderItem>
      </li>
    </ul>
    <div class="oap-tab-header__list -mobile">
      <div v-selectbox class="selectbox -light">
        <div class="selectbox-wrapper">
          <select v-model="activeItem" @change="selectTagging">
            <option v-for="heading in headings.tabs" :key="heading.id" :value="heading.id">
              {{ heading.heading }}
            </option>
          </select>
          <div class="selectbox-labelWrapper">
            <span class="selected-value-label">{{ selectedTabLabel }}</span>
          </div>
          <span class="selectbox-indicator"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OapTabHeaderItem from './oap-tab-header-item.vue';
import selectbox from '../../../../../Foundation/Core/code/Scripts/directives/selectbox/selectbox';
import { mapActions, mapGetters } from '../helpers';
import { GET_ACTIVE_TAB, SET_ACTIVE_TAB } from '../settings';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'OapTabHeader',
  components: {
    OapTabHeaderItem,
  },
  directives: {
    selectbox,
  },
  props: {
    tabComponentId: {
      type: String,
      required: true,
    },
    headings: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
    taggingObject: {
      type: Object,
      default() {
        return {
          category: 'tabbed module',
          action: 'tab component name',
        };
      },
    },
  },
  methods: {
    ...mapActions([SET_ACTIVE_TAB]),
    selectTagging() {
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        ...this.taggingObject,
        label: `select::${this.selectedTabLabel}`,
      });
    },
  },
  computed: {
    ...mapGetters([GET_ACTIVE_TAB]),
    activeItem: {
      get() {
        return this[GET_ACTIVE_TAB][this.tabComponentId];
      },
      set(value) {
        this[SET_ACTIVE_TAB]({ parent: this.tabComponentId, tab: value });
      },
    },
    selectedTabLabel() {
      const activeTab = this[GET_ACTIVE_TAB][this.tabComponentId];
      const tab = this.headings.tabs.filter((tab) => {
        return tab.id === activeTab;
      })[0];

      return tab ? tab.heading : '';
    },
  },
  mounted() {
    this[SET_ACTIVE_TAB]({ parent: this.tabComponentId, tab: this.headings.tabs[0].id });
  },
};
</script>
