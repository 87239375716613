<template>
  <div class="vtoHotSwap vtoHotSwap--haircolor">
    <VtoHeader class="-white -pointer" @click="goToPageMain">
      <VtoHeaderArrow orientation="left" />
      <VtoHeaderLabel :text="dictionary.hotswapHaircolorHeaderLabel" />
    </VtoHeader>

    <div class="vtoLines">
      <VtoHotswapLine :product-line="productLinesMap[getSelectedProductItem.productLineId]" />
    </div>

    <VtoFloatingBar />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from '../store/helpers';

import VtoFloatingBar from './VtoFloatingBar.vue';
import VtoHeader from './VtoHeader.vue';
import VtoHeaderArrow from './VtoHeaderArrow.vue';
import VtoHeaderLabel from './VtoHeaderLabel.vue';
import VtoHotswapLine from './VtoHotswapLine.vue';

export default {
  name: 'VtoHotswapHaircolor',

  components: {
    VtoFloatingBar,
    VtoHeader,
    VtoHeaderArrow,
    VtoHeaderLabel,
    VtoHotswapLine,
  },

  computed: {
    ...mapState(['dictionary', 'productLinesMap']),

    ...mapGetters(['getSelectedProductItem']),
  },

  methods: {
    ...mapActions(['goToPageMain']),
  },
};
</script>
