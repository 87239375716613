<template>
  <OapFocusTrap :is-visible="showPopin">
    <transition name="modal">
      <div v-if="showPopin" class="oap-popin__wrapper" @keyup.esc="closePopin">
        <div class="oap-popin__mask" @click="closePopin"></div>
        <div class="oap-popin__container" :class="{ 'oap-popin__container--small': isPopinSmall }">
          <header class="oap-popin__header">
            <button class="oap-popin__close" @click="closePopin">
              <svg class="icon" role="img" aria-labelledby="close-popin-icon">
                <title id="close-popin-icon">Close</title>
                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
              </svg>
            </button>
          </header>

          <div class="oap-popin__content scrollbarCustom">
            <slot />
            <template v-if="!hasSlot">
              <h2 v-if="popinData.header" class="oap-popin__title">{{ popinData.header }}</h2>
              <p v-if="popinData.text" class="oap-popin__text" v-html="popinData.text"></p>
              <a
                v-if="popinData.button"
                class="oap-button -primary -fluid"
                :href="popinData.button.href"
                download=""
              >
                <span class="oap-button__label">
                  {{ popinData.button.label }}
                </span>
              </a>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </OapFocusTrap>
</template>

<script>
import OapFocusTrap from '../../../../OapFocusTrap/code/Scripts/components/OapFocusTrap';
import eventBus from '@loreal/eventbus-js';

const CLOSE_DELAY = 250;
const OAP_POPIN_IS_SHOWN = 'oap-popin--is-shown';

export default {
  name: 'OapPopin',
  components: {
    OapFocusTrap,
  },
  props: {
    header: { type: String, default: '' },
    text: { type: String, default: '' },
    button: { type: Object, default: () => {} },
    popinId: { type: String, default: 'popin' },
    popinType: { type: String, default: 'default' },
    hideContent: { type: Boolean, default: false },
  },
  data() {
    return {
      showPopin: false,
      popin: null,
    };
  },

  computed: {
    popinData() {
      if (this.popin) {
        const { popinHeader, popinText, popinButton } = this.popin;
        return {
          header: popinHeader,
          text: popinText,
          button: popinButton ? JSON.parse(popinButton) : null,
        };
      }

      return {
        header: this.header,
        text: this.text,
        button: this.button,
      };
    },

    isUsedProps() {
      return !!this.header || !!this.text;
    },

    isPopinSmall() {
      return this.popinType.toLowerCase() === 'small';
    },

    hasSlot() {
      return Object.keys(this.$slots).length && this.$slots.default();
    },
  },

  watch: {
    showPopin(isShown) {
      const body = document.querySelector('body');

      this.hideContent && isShown
        ? body.classList.add(OAP_POPIN_IS_SHOWN)
        : body.classList.remove(OAP_POPIN_IS_SHOWN);
    },
  },

  mounted() {
    eventBus.on(`${this.popinId}:open`, (data) => {
      if (!this.hasSlot && !this.isUsedProps) {
        this.popin = data.currentTarget.dataset;
      }

      this.togglePopin();
    });

    eventBus.on('form:submitted', () => {
      setTimeout(this.closePopin, CLOSE_DELAY);
    });
  },

  methods: {
    togglePopin() {
      this.showPopin = !this.showPopin;
    },

    closePopin() {
      this.showPopin = false;
      this.popin = null;
    },
  },
};
</script>
