<template>
  <div class="vtoHotSwap">
    <VtoHeader class="-white -pointer" @click="onHeaderClick">
      <VtoHeaderArrow orientation="left" />
      <VtoHeaderCounter :count="getSelectedProductsAmount" />
      <VtoHeaderLabel :text="getCurrentHeaderLabel" />
    </VtoHeader>

    <ul class="vtoHotSwap__rack scrollbarCustom">
      <li
        v-for="(typeId, index) of orderedTypeIds"
        :key="`${typeId}-${index}`"
        class="vtoHotSwap__row"
        :class="{
          'vtoHotSwap__row--last-selected': isLastSelectedProduct(typeId),
          'vtoHotSwap__row--is-buying': isBuying && selectedProducts[productTypesMap[typeId].id],
        }"
      >
        <!-- the shorthand for let type = productTypesMap[typeId] -->
        <template v-for="type of [productTypesMap[typeId]]" :key="type.id">
          <transition name="hotSwap">
            <div v-if="selectedProducts[type.id]" class="vtoHotSwap__selectedProduct">
              <VtoProduct
                :product="productItemsMap[selectedProducts[type.id]]"
                @click="selectShade(productItemsMap[selectedProducts[type.id]].id)"
              />
              <VtoActions
                v-if="shouldShowBuyButton(type)"
                class="oap-button -primary -theme-brand vtoHotSwap__buy-button"
                :buy-label="dictionary.buy"
                :selected-product="productItemsMap[selectedProducts[type.id]]"
              />
              <template v-else>
                <VtoHotswapEdit @click="editProductSelection(type.id)" />
                <button
                  class="vtoHotSwap__remove"
                  :class="{ '-current': hotSwapRemoveTypeId === type.id }"
                  @click="addHotSwapRemoveTypeId(type.id)"
                >
                  <transition name="slideIn">
                    <span v-if="hotSwapRemoveTypeId === type.id" class="vtoHotSwap__removeLabel">
                      {{ dictionary.removeProduct }}
                    </span>
                  </transition>

                  <svg class="icon" :class="{ 'is-white': hotSwapRemoveTypeId === type.id }">
                    <use
                      xmlns:xlink="http://www.w3.org/1999/xlink"
                      xlink:href="#icon-remove-black"
                    />
                  </svg>
                </button>
              </template>
            </div>
            <div
              v-else
              key="label"
              class="vtoHotSwap__categoryLabel"
              @click="editProductSelection(type.id)"
            >
              {{ type.hotSwapLabel }}
              <button>
                <svg class="icon">
                  <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-chevron" />
                </svg>
              </button>
            </div>
          </transition>
        </template>
      </li>
      <li
        v-if="hotSwapRemoveTypeId !== null"
        class="vtoHotSwap__rackOverlay"
        @click="resetHotSwapRemoveTypeId"
      ></li>
    </ul>
    <VtoStickyFooter
      v-if="hasProductSelected && !showFloatingBar"
      :should-render-save-button="shouldRenderSaveButton"
      :buy-button-label="buyButtonLabel"
      :save-button-label="dictionary.saveThisLook"
      @buy="isBuying = !isBuying"
    />
    <VtoFloatingBar />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from '../store/helpers';

import {
  DO_ADD_HOTSWAP_REMOVE_TYPE_ID,
  DO_EDIT_PRODUCT_SELECTION,
  DO_GO_TO_PAGE_MAIN,
  DO_MF_SEND_EXIT,
  DO_REMOVE_PRODUCT,
  DO_RESET_HOTSWAP_REMOVE_TYPE_ID,
  DO_SELECT_SHADE,
} from '../store/actions';

import VtoHeader from './VtoHeader.vue';
import VtoHeaderArrow from './VtoHeaderArrow.vue';
import VtoHeaderCounter from './VtoHeaderCounter.vue';
import VtoHeaderLabel from './VtoHeaderLabel.vue';
import VtoHotswapEdit from './VtoHotswapEdit.vue';
import VtoProduct from './VtoProduct.vue';
import VtoStickyFooter from './VtoStickyFooter.vue';
import VtoFloatingBar from './VtoFloatingBar.vue';
import VtoActions from './VtoActions.vue';

export default {
  name: 'VtoHotSwap',

  components: {
    VtoHeader,
    VtoHeaderArrow,
    VtoHeaderLabel,
    VtoHeaderCounter,
    VtoHotswapEdit,
    VtoProduct,
    VtoStickyFooter,
    VtoFloatingBar,
    VtoActions,
  },

  data: () => ({
    isBuying: false,
  }),

  computed: {
    ...mapState([
      'enableFavorite',
      'favoriteSettings',
      'actionEmailEnabled',
      'hotSwapRemoveTypeId',
      'selectedProducts',
      'showFloatingBar',
      'productItemsMap',
      'productTypesMap',
      'dictionary',
    ]),

    ...mapGetters([
      'getCatalogCategory',
      'getProductCategoryIdsWithoutLooks',
      'getSelectedProductsAmount',
      'getSelectedProductItem',
    ]),

    hasProductSelected() {
      return Object.keys(this.selectedProducts)?.length;
    },

    getCurrentHeaderLabel() {
      if (this.hasProductSelected) return this.dictionary.productsInYourLook;
      return this.dictionary.emptyProductHeaderLabel;
    },

    orderedTypeIds() {
      return [
        ...new Set([
          ...Object.keys(this.selectedProducts),
          ...this.getProductCategoryIdsWithoutLooks,
        ]),
      ];
    },

    buyButtonLabel() {
      const { editThisLook, buyThisLook } = this.dictionary;
      return this.isBuying ? editThisLook : buyThisLook;
    },

    shouldRenderSaveButton() {
      return !!((this.favoriteSettings && this.enableFavorite) || this.actionEmailEnabled);
    },
  },

  destroyed() {
    /* istanbul ignore next */
    this[DO_RESET_HOTSWAP_REMOVE_TYPE_ID]();
  },

  methods: {
    ...mapActions([
      DO_ADD_HOTSWAP_REMOVE_TYPE_ID,
      DO_RESET_HOTSWAP_REMOVE_TYPE_ID,
      DO_EDIT_PRODUCT_SELECTION,
      DO_GO_TO_PAGE_MAIN,
      DO_MF_SEND_EXIT,
      DO_REMOVE_PRODUCT,
      DO_SELECT_SHADE,
    ]),

    isLastSelectedProduct(typeId) {
      return (
        this.getSelectedProductItem?.productLineId ===
        this.selectedProducts[this.productTypesMap[typeId]?.id]
      );
    },

    shouldShowBuyButton(type) {
      if (!this.isBuying) return;
      /**
       * hot swap should only handle buy for each product (single e retailer)
       * not depending on PDP
       */
      return this.productItemsMap[this.selectedProducts[type.id]]?.href;
    },

    /* istanbul ignore next */
    onHeaderClick() {
      /* istanbul ignore next */
      if (this.hasProductSelected) this[DO_GO_TO_PAGE_MAIN]();
      /* istanbul ignore next */ else this[DO_MF_SEND_EXIT]();
    },
  },
};
</script>
