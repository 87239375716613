import ArticleSliderModel from './code/Scripts/Components/ArticleSliderModel/article-slider-model.vue';
import ProductSliderModel from './code/Scripts/Components/ProductSliderModel/product-slider-model.vue';
import ProductListModel from './code/Scripts/Components/ProductListModel/product-list-model.vue';

export default {
  components: {
    ArticleSliderModel,
    ProductSliderModel,
    ProductListModel,
  },
};
