import { scrollElementIntoView } from '../../utilities';

export const scrollIntoView = {
  name: 'scroll-into-view',
  beforeMount: (el, binding) => {
    const { element, elementAttribute, scrollTrigger, behavior, delay } = binding.value;
    const elements = el.querySelectorAll(element);

    if (elements.length) {
      elements.forEach((element) => {
        if (scrollTrigger) {
          element.addEventListener(scrollTrigger, () => {
            if (elementAttribute && !element.hasAttribute(elementAttribute)) {
              scrollElementIntoView(element, behavior, delay);
            }
          });
        } else {
          scrollElementIntoView(element, behavior, delay);
        }
      });
    }
  },
};
