<template>
  <nav>
    <slot />
    <!-- //NOSONAR The parent component contains the UL tag. -->
    <li v-show="showSignedElements" ref="signedElements" class="navigationList__item bottom-items">
      <slot name="signed-elements"></slot>
    </li>
  </nav>
</template>

<script>
const NAVIGATION_LIST_CLASS = '.navigationList';

export default {
  name: 'HeadingNavigation',
  data() {
    return {
      showSignedElements: true,
    };
  },
  mounted() {
    const signedElements = this.$refs.signedElements.cloneNode(true);
    this.$el.querySelector(NAVIGATION_LIST_CLASS).append(signedElements);

    this.$nextTick(() => {
      this.showSignedElements = false;
    });
  },
};
</script>
