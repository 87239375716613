<template>
  <div>
    <slot></slot>

    <modal
      :modalidentifier="playerId"
      :class="['videoComponent__modal', { 'videoComponent__modal--has-transcript': hasTranscript }]"
    >
      <div class="embedResponsive--16by9">
        <div v-if="videoId !== ''" :id="playerId" class="embedResponsive__item"></div>
      </div>
      <template v-if="hasTranscript">
        <collapsable
          ref="oapTranscript"
          tagname="section"
          identifier="unique-id-bla"
          class="oap-transcript"
          has-transcript
        >
          <template #indicator>
            <span class="collapsable__indicator"></span>
          </template>
          <template #title>
            <span id="unique-id-bla" class="collapsable__title">View Transcript</span>
          </template>
          <template #content>
            <div class="collapsable__content">
              <video-placeholder-transcript
                :header="videoTranscriptHeader"
                :transcript="videoTranscript"
              />
            </div>
          </template>
        </collapsable>
      </template>
    </modal>
  </div>
</template>

<script>
import { YOUTUBE_SCRIPT_NAME, YOUTUBE_SCRIPT_URL } from './settings';
import { loadscript } from '../../../../../Foundation/Core/code/Scripts/directives/loadscript/loadscript';
import eventBus from '@loreal/eventbus-js';
import { mixinSetupYouTube } from './mixins/setupYouTube';
import { mixinToggleVideoPopin } from './mixins/toggleVideoPopin';
import { mixinTryPlayVideo } from './mixins/tryPlayVideo';
import { mixinSetupPlayerAndBindToEvents } from './mixins/setupPlayerAndBindToEvents';
import modal from '../../../../Modal/code/Scripts/components/modal.vue';
import videoPlaceholderTranscript from './video-placeholder-transcript';
import collapsable from '../../../../Navigation/code/Scripts/components/collapsable';

/* eslint-disable */
export default {
  name: 'video-placeholder-wrapper',

  components: {
    modal,
    videoPlaceholderTranscript,
    collapsable,
  },

  mixins: [
    mixinSetupYouTube,
    mixinToggleVideoPopin,
    mixinTryPlayVideo,
    mixinSetupPlayerAndBindToEvents,
  ],

  data() {
    return {
      videoId: '',
      trigger: null,
      playerId: `player-${Date.now()}`,
      player: null,
      isPlayerSet: false,
      videoTranscript: null,
      videoTranscriptHeader: '',
      isInformative: false,
      videoTitle: '',
    };
  },

  props: {
    triggerClass: { type: String, required: true },
    host: { type: String, default: 'https://www.youtube.com' },
  },

  computed: {
    hasTranscript() {
      return !!this.videoTranscript;
    },
  },

  mounted() {
    const triggers = this.$el.querySelectorAll(this.triggerClass);
    triggers.forEach((trigger) => {
      trigger.addEventListener('click', this.triggerClickHandler);
    });

    /* istanbul ignore next */
    eventBus.on('modal:closed', modalId => {
      if (modalId === this.playerId) {
        this.player.destroy();
        this.player = null;
        this.videoId = '';
        this.videoTranscript = null;
        this.videoTranscriptHeader = '';
        this.isInformative = false;
        this.videoTitle = '';
        this.isPlayerSet = false;

        setTimeout(() => {
          this.trigger.focus();
          this.trigger = null;
        }, 500);
      }
    });
  },

  methods: {
    triggerClickHandler(event) {
      loadscript.beforeMount(null, {
        value: {
          name: YOUTUBE_SCRIPT_NAME,
          url: YOUTUBE_SCRIPT_URL,
        },
      });

      const { dataset } = event.target;

      this.videoId = dataset.videoId;
      this.videoTranscript = dataset.videoTranscript ? JSON.parse(dataset.videoTranscript) : this.videoTranscript;
      this.videoTranscriptHeader = dataset.videoTranscriptHeader
        ? dataset.videoTranscriptHeader : this.videoTranscriptHeader;
      this.isInformative = dataset.videoInformative ? dataset.videoInformative : this.isInformative;
      this.videoTitle = dataset.videoTitle ? dataset.videoTitle : this.videoTitle;
      this.toggleVideoPopin();
      this.trigger = event.target;
    },
  },
};
</script>
