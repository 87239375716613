export const toggle = (state, dataset, img, { currentTarget: el }) => {
  let media;
  state.play = !state.play;
  media = state.play ? 'gif' : 'img';
  img.src = dataset[media].src;
  img.srcset = dataset[media].srcset;
  el.classList.toggle('-paused', !state.play);
};

export const beforeMount = (el) => {
  /* istanbul ignore next */
  const control = el.querySelector('.gif__control'),
    img = el.querySelector('.gif_media'),
    state = { play: true },
    dataset = {
      img: {
        src: img.dataset.placeholder,
        srcset: img.dataset.placeholder,
      },
      gif: {
        src: img.dataset.src,
        srcset: img.dataset.srcset,
      },
    };

  /* istanbul ignore next */
  control.addEventListener('click', toggle.bind(null, state, dataset, img));
};

export const gifA11y = {
  name: 'gifA11y',
  beforeMount,
};
