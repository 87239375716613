<template>
  <div class="vto__landing vto__page"></div>
</template>

<script>
import { mapActions, mapState } from '../store/helpers';
import { DO_UPDATE_FACET } from '../store/actions';
import { FACET_ATTRIBUTES, FACET_CONFIGS } from '../settings';

export default {
  name: 'VtoPageLanding',

  computed: {
    ...mapState([FACET_CONFIGS]),
  },

  methods: {
    ...mapActions([DO_UPDATE_FACET]),
  },

  beforeMount() {
    // reset haircolor facet if attribute not in presets
    if (!this[FACET_CONFIGS].presets[FACET_ATTRIBUTES.HAIR_COLOR_FAMILY]) {
      this[DO_UPDATE_FACET]({
        attribute: FACET_ATTRIBUTES.HAIR_COLOR_FAMILY,
        facetIds: [],
      });
    }
  },
};
</script>
