import Collapsable from './code/Scripts/components/collapsable.vue';
import OapAccordion from './code/Scripts/components/accordion.vue';
import Switcher from './code/Scripts/components/switcher.vue';
import Navigation from './code/Scripts/components/navigation.vue';
import { megamenu } from './code/Scripts/directives/megamenu';

export default {
  components: {
    Switcher,
    Collapsable,
    OapAccordion,
    Navigation,
  },
  directives: {
    megamenu,
  },
};
