<script>
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '@Foundation';
import {
  ACCESSIBILITY_STORE_NAMESPACE,
  GET_CONTRAST_HIGHLIGHT,
} from '@Foundation/accessibilityStore/accessibilityStore';
import { mapGetters } from 'vuex';

export default {
  name: 'OapShadeFinderCarousel',
  props: {
    carouselId: {
      type: String,
      required: true,
    },
    shadeInformation: {
      type: Array,
      required: true,
    },
    taggingLabels: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      activeShadeId: '9da20926-2dac-4faa-a758-7e2bfdf38ce0',
      activeShadeName: 'shade name 1',
      currentIndex: 0,
    };
  },
  computed: {
    navigationTaggingEvent() {
      const shade = this.shadeInformation[this.currentIndex];
      const { shadeName, id, productName } = shade;
      const label = `${productName}::${id}::${shadeName}`;
      return {
        ...this.taggingLabels,
        label: label,
        product_info: label,
        position: this.currentIndex.toString(),
      };
    },
    isHighContrastEnabled() {
      return this[GET_CONTRAST_HIGHLIGHT]();
    },
  },
  mounted() {
    eventBus.on(`${this.carouselId}-pageChange`, this.carouselPaginated);

    setTimeout(() => {
      this.$nextTick(() => {
        this.updateCurrentPage(0, true, false);
      });
    }, 200);
  },
  methods: {
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_CONTRAST_HIGHLIGHT]),
    carouselPaginated(currentPage) {
      if (currentPage === this.currentIndex) return;
      this.updateCurrentPage(currentPage, true, true);
    },
    shadeFinderItemClick(event) {
      event.preventDefault();
      event.stopPropagation();
      const { target } = event;
      const newIndex = Number.parseInt(target.dataset['index']);
      if (newIndex === this.currentIndex) return;
      this.updateCurrentPage(newIndex, false, true);
    },

    updateCurrentPage(current, fromCarousel, fireTaggingEvent) {
      const { id, shadeName } = this.shadeInformation[current];

      this.activeShadeId = id;
      this.activeShadeName = shadeName;
      const currentValue = Number.parseInt(current);
      const direction = currentValue > this.currentIndex ? 'forward' : 'backwards';
      this.currentIndex = currentValue;

      if (!fromCarousel && fireTaggingEvent) this.emitEventTag(current, direction, fromCarousel);
      if (!fromCarousel) eventBus.emit(`${this.carouselId}-gotoPage`, this.currentIndex);
    },
    emitEventTag(currentIndex, direction, fromCarousel) {
      const shade = this.shadeInformation[this.currentIndex];
      const { shadeName, id, productName } = shade;
      const label = `${productName}::${id}::${shadeName}`;
      let action = direction === 'forward' ? 'next' : 'previous';
      if (fromCarousel) action = 'select';
      AnalyticsHandler.getAnalyticsHandler().push({
        ...this.taggingLabels,
        label: label,
        product_info: label,
        position: currentIndex.toString(),
        action: action,
        click_action: action,
      });
    },
  },
  render() {
    return this.$slots.default({
      shadeClick: this.shadeFinderItemClick,
      activeShadeId: this.activeShadeId,
      activeShadeName: this.activeShadeName,
      navigationTaggingEvent: this.navigationTaggingEvent,
      highContrast: this.isHighContrastEnabled,
    });
  },
};
</script>
