<template>
  <div>
    <slot></slot>
    <div
      v-if="placeholder"
      ref="infoPanel"
      :aria-hidden="ariaHidden"
      :class="{ 'videoComponent__infoPanel--transparent': infoPanelText === '' }"
      class="videoComponent__infoPanel"
    >
      <button
        v-if="!hasYoutubeParams && showPlayButton"
        :aria-label="playIconAltText"
        class="videoComponent__playButton"
        @click.once="play"
      >
        <img
          :alt="playIconAltText"
          class="videoComponent__playButtonIcon"
          src="/frontend/static/images/play.svg"
          aria-hidden="true"
        />
      </button>
      <span v-if="infoPanelText" class="videoComponent__infoPanelText">{{ infoPanelText }}</span>
    </div>
    <div>
      <div v-if="videoId !== ''" :id="playerId" class="embedResponsive__item"></div>
    </div>
  </div>
</template>

<script>
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import { YOUTUBE_SCRIPT_NAME, YOUTUBE_SCRIPT_URL } from './settings';
import { loadscript } from '../../../../../Foundation/Core/code/Scripts/directives/loadscript/loadscript';
import { mixinSetupYouTube } from './mixins/setupYouTube';
import { mixinTryPlayVideo } from './mixins/tryPlayVideo';
import { mixinSetupPlayerAndBindToEvents } from './mixins/setupPlayerAndBindToEvents';
import { makeVideoAccessible } from '../../../../../Foundation/Core/code/Scripts/utilities/makeVideoAccessible';
import eventBus from '@loreal/eventbus-js';
import { mapGetters } from 'vuex';
import {
  ACCESSIBILITY_STORE_NAMESPACE,
  GET_SITEWIDE_ANIMATIONS,
} from '@Foundation/accessibilityStore/accessibilityStore';

export default {
  name: 'VideoPlaceholder',

  mixins: [mixinSetupYouTube, mixinTryPlayVideo, mixinSetupPlayerAndBindToEvents],

  props: {
    infoPanelText: { type: String, default: '' },
    playIconAltText: { type: String, default: 'Play video' },
    tagging: { default: null, type: Object },
    videoId: { type: String, default: '' },
    host: { type: String, default: 'https://www.youtube.com' },
    isInformative: { type: Boolean, default: false },
    videoTitle: { type: String, default: '' },
    youtubeExtraParams: { type: Object, default: () => {} },
  },

  data() {
    return {
      ariaHidden: false,
      placeholder: null,
      player: null,
      playerId: `player-${this.videoId}-${Date.now()}`,
    };
  },

  computed: {
    hasYoutubeParams() {
      return this.youtubeExtraParams && Object.keys(this.youtubeExtraParams).length;
    },
    shouldSetupPlayer() {
      return this.videoId !== '' && (!this.placeholder || this.hasYoutubeParams);
    },
    videoIFrame() {
      return this.player.getIframe();
    },
    showPlayButton() {
      return this.videoId !== '' && this.hasPoster && !this.youtubeExtraParams?.autoplay;
    },
    hasPoster() {
      return !!this.$slots.default();
    },
    isAnimationEnabled() {
      return this[GET_SITEWIDE_ANIMATIONS]();
    },
  },

  mounted() {
    this.placeholder = this.$el.querySelector('.videoComponent__placeholder');

    this.$nextTick(async () => {
      /* istanbul ignore next */
      if (this.shouldSetupPlayer) {
        await this.setupPlayer();
        eventBus.on('livestream::play', this.player.playVideo);
        eventBus.on('livestream::pause', this.player.pauseVideo);
        eventBus.on('youtube::state-change', this.animatePlaceholderPanel);
      }
    });
  },

  methods: {
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_SITEWIDE_ANIMATIONS]),
    loadYouTubeScript() {
      loadscript.beforeMount(null, {
        value: {
          name: YOUTUBE_SCRIPT_NAME,
          url: YOUTUBE_SCRIPT_URL,
        },
      });
    },

    play() {
      if (this.tagging) {
        const { action, category, label } = this.tagging;

        AnalyticsHandler.getAnalyticsHandler().push({
          action,
          category,
          label,
          type: 'userActionEvent',
        });
      }

      this.setupPlayer();
    },

    async setupPlayer() {
      this.loadYouTubeScript();
      /* istanbul ignore next */
      return this.setupPlayerAndBindToEvents({
        enablejsapi: 1,
        ...this.youtubeExtraParams,
        autoplay: this.isAnimationEnabled,
      })
        .then(() => {
          if (this.isAnimationEnabled)
            this.tryPlayVideo(this.player, false, 500, this.youtubeExtraParams?.autoplay);
          makeVideoAccessible(this.videoIFrame, this.isInformative, this.videoTitle);
        })
        .then(() => {
          this.animatePlaceholderPanel();
        });
    },

    animatePlaceholderPanel() {
      /* istanbul ignore else */
      if (this.placeholder) {
        this.placeholder.style.display = 'none';
      }

      if (this.$refs?.infoPanel) {
        this.$refs.infoPanel.style.opacity = '0';
        this.$refs.infoPanel.style.left = '100%';
        this.ariaHidden = true;
      }
    },
  },
};
</script>
