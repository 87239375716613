<template>
  <div
    class="vtoHeaderArrow"
    :class="[`-${orientation}`]"
    v-on="$attrs"
    @click="$attrs.click && $attrs.click()"
  ></div>
</template>

<script>
export default {
  name: 'VtoHeaderArrow',
  props: {
    orientation: { type: String, default: 'bottom' },
  },
};
</script>
