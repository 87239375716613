<template>
  <div ref="vtoCirclesContainer" class="vtoCirclesContainer scrollbarCustom">
    <slot />
  </div>
</template>

<script>
import { mapActions, mapGetters } from '../store/helpers';
import {
  CIRCLES_CONTAINER_ANIMATION_CLASS,
  CIRCLES_CONTAINER_ANIMATION_TIMING,
  CIRCLES_CONTAINER_APPEARING_CLASS,
  CIRCLES_ANIMATED_CLASS,
} from '../settings';
import { DO_UPDATE_CIRCLES_ANIMATION_STATUS } from '../store/actions';

export default {
  name: 'VtoCirclesContainer',

  data() {
    return {
      animatedShadeIndex: -1,
    };
  },

  computed: {
    ...mapGetters(['isCirclesAnimated']),
  },

  watch: {
    isCirclesAnimated: {
      handler(status) {
        if (status) return;

        this.$nextTick(() => {
          this.$el.classList.add(CIRCLES_CONTAINER_APPEARING_CLASS);
        });
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions([DO_UPDATE_CIRCLES_ANIMATION_STATUS]),

    animateCircles() {
      let animationIteration = 0;
      this.animatedShadeIndex = 0;

      const timer = setInterval(() => {
        this.slotsItems.forEach((item) => {
          item.classList.remove(CIRCLES_ANIMATED_CLASS);
        });

        this.slotsItems[this.animatedShadeIndex].classList.add(CIRCLES_ANIMATED_CLASS);
        this.animatedShadeIndex++;

        if (this.animatedShadeIndex >= this.slotsItems.length) {
          this.animatedShadeIndex = 0;
          animationIteration++;
        }

        if (animationIteration === 2) {
          clearInterval(timer);
          this.animatedShadeIndex = -1;
        }
      }, CIRCLES_CONTAINER_ANIMATION_TIMING);
    },

    showCircles() {
      setTimeout(() => {
        this.$el.classList.add(CIRCLES_CONTAINER_ANIMATION_CLASS);
      }, CIRCLES_CONTAINER_ANIMATION_TIMING / 2);

      setTimeout(this.animateCircles, CIRCLES_CONTAINER_ANIMATION_TIMING * 2);

      this[DO_UPDATE_CIRCLES_ANIMATION_STATUS](true);
    },
  },

  mounted() {
    this.slotsItems = this.$refs.vtoCirclesContainer.querySelectorAll('.vtoCircle');

    this.$nextTick(() => {
      if (!this.isCirclesAnimated) this.showCircles();
    });
  },
};
</script>
