/*
  TODO: This can be refactored to a single exported class
  customized as per the current requirements, and that inherits from
  the native Error class (or any of its subclasses).

  It will also export an object containing the types of errors.
*/
/**
 * Exception handler: AnalyticsException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsException(message) {
  this.message = message;
  this.name = 'AnalyticsException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsUserActionEventObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsUserActionEventObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsUserActionEventObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsAddToCartObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsAddToCartObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsAddToCartObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsNonInteractiveEventObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsNonInteractiveEventObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsNonInteractiveEventObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductImpressionObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsProductImpressionObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductImpressionObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductClickObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsProductClickObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductClickObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsVtoDetailObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsVtoDetailObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsVtoDetailObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductVariantClickObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsProductVariantClickObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductVariantClickObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsPromotionClickObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsPromotionClickObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsPromotionClickObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsProductObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsProductObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsProductObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsPromotionObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsPromotionObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsPromotionObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsPromotionImpressionObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsPromotionImpressionObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsPromotionImpressionObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsSocialIntEventObjectException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsSocialIntEventObjectException(message) {
  this.message = message;
  this.name = 'AnalyticsSocialIntEventObjectException';
  this.toString = () => `${this.name}: ${this.message}`;
}

/**
 * Exception handler: AnalyticsTagDirectiveException
 * @param {String} message Exception error message.
 * @constructor
 */
export function AnalyticsTagDirectiveException(message) {
  this.message = message;
  this.name = 'AnalyticsTagDirectiveException';
  this.toString = () => `${this.name}: ${this.message}`;
}
