import {
  PAGE_LANDING,
  PAGE_MAIN,
  PAGE_SHARE,
  PAGE_COMPARE,
  PAGE_ENTITY_SELECTION,
  PAGE_ERROR,
  VTO_STORE_MODULE_NAMESPACE,
  CATALOG_MODE_LOOK,
  CATALOG_MODE_SWITCH,
  CATEGORY_HAIR_COLOR,
  FACET_STATE,
  FACET_MAPS,
  LIST_LAYOUT_DEFAULT,
  LIST_LAYOUT_CAROUSEL,
  LIST_LAYOUT_GRID,
} from '../settings';

import { createNamespacedHelpers } from 'vuex';
import { mixinParseURL } from '../../../../../Foundation/Core/code/Scripts/mixins/parseURL/parseURL';

export const { mapState, mapGetters, mapActions } = createNamespacedHelpers(
  VTO_STORE_MODULE_NAMESPACE
);

export const getProductTypeId = (state, productId) =>
  state.productLinesMap[state.productItemsMap[productId].productLineId].productTypeId;

// important part of the routing
export const mapEventsToPage = (
  state,
  { trackingType, pageType, actionType, confirmShareActivation }
) => {
  // Do not delete - use it for routes debugging
  // console.warn('trackingType', trackingType);
  // console.warn('pageType', pageType);
  // console.warn('actionType', actionType);

  if (!state.initialProductId) {
    // entity selection page
    if (pageType === 'tryItOn') return PAGE_ENTITY_SELECTION;
    if (trackingType === 'confirmCancel') return PAGE_ENTITY_SELECTION;
    if (trackingType === 'compareCta' && actionType === 'close') return PAGE_ENTITY_SELECTION;
  }

  // landing page
  if (trackingType === 'exitCta') return PAGE_LANDING;
  if (trackingType === 'closeErrorCta') {
    if (pageType === 'errorLargeImage') return PAGE_LANDING;
    if (pageType === 'errorLargeFile') return PAGE_LANDING;
    if (pageType === 'errorImageType') return PAGE_LANDING;
    if (pageType === 'errorNoFace') return PAGE_LANDING;
    if (pageType === 'errorNoHair') return PAGE_LANDING;
    if (pageType === 'errorNoCamera') return PAGE_LANDING;
  }

  // main page
  if (trackingType === 'liveCameraCta') return PAGE_MAIN;
  if (trackingType === 'uploadPhotoCta') return PAGE_MAIN;
  if (trackingType === 'closeErrorCta') return PAGE_MAIN;
  if (trackingType === 'confirmCancel') return PAGE_MAIN;
  if (trackingType === 'compareCta' && pageType === 'main' && actionType === 'close')
    return PAGE_MAIN;
  if (trackingType === 'confirmShare' && confirmShareActivation === 'cancel share::live camera')
    return PAGE_MAIN;
  if (trackingType === 'confirmShare' && confirmShareActivation === 'cancel share::photo')
    return PAGE_MAIN;

  // share page
  if (trackingType === 'shareCta' && actionType === 'open') return PAGE_SHARE;

  // compare page
  if (trackingType === 'compareCta' && actionType === 'open') return PAGE_COMPARE;

  // error page
  if (trackingType === 'errorEvent') return PAGE_ERROR;

  return false;
};

export const getUPCChange = (state) => {
  const { upc } = state.productItemsMap[state.selectedProductId];
  return {
    event: 'upc_change',
    data: { upc },
  };
};

export const getCustomLookChange = (state) => {
  const look = Object.values(state.selectedProducts).reduce((look, id) => {
    const { upc, category } = state.productItemsMap[id];
    look.push({ upc, category });
    return look;
  }, []);
  return {
    event: 'custom_look_change',
    data: { look },
  };
};

export const isSingleMode = (mainCategory) => mainCategory === CATEGORY_HAIR_COLOR;

export const getSelectedTypeId = (state, getters) => {
  const getOppositeCategoryFirstNonLookTypeId = (state) => {
    const typeId = state.productCategoriesMap[state.oppositeCategory].productTypeIds[0];

    // Since looks should not appear in catalog
    // (they are appearing in and are applied with different flow - from entity selection),
    // we avoid using them as a default selection for opposite category type ID
    if (state.productTypesMap[typeId].type !== CATALOG_MODE_LOOK) {
      return typeId;
    } else {
      return state.productCategoriesMap[state.oppositeCategory].productTypeIds[1];
    }
  };
  const getSelectedProductTypeId = (state) => {
    const lineId = state.productItemsMap[state.selectedProductId].productLineId;
    return state.productLinesMap[lineId].productTypeId;
  };

  return getters.getCatalogMode === CATALOG_MODE_SWITCH
    ? // if switcher context, return first type's id of opposite category
      getOppositeCategoryFirstNonLookTypeId(state)
    : // else get type from current product applied
      getSelectedProductTypeId(state);
};

export const logHaircolorUpcs = (state) => {
  /* istanbul ignore next */
  let upcs = state.productCategoriesMap[CATEGORY_HAIR_COLOR]['productTypeIds'].reduce(
    (acc, typeId) => {
      state.productTypesMap[typeId]['productLineIds'].forEach((lineId) => {
        state.productLinesMap[lineId]['productItemIds'].forEach((itemId) => {
          acc.push(state.productItemsMap[itemId].upc);
        });
      });
      return acc;
    },
    []
  );

  /* istanbul ignore next */
  window.console.log(upcs);
};

export const unique = (array) => Array.from(new Set(array));

export const toggleArrayValue = (array, value) => {
  const newArray = [...array],
    index = newArray.indexOf(value);
  index === -1 ? newArray.push(value) : newArray.splice(index, 1);
  return newArray;
};

export const findObjectKeyByPropertyValue = (map, property, value) =>
  Object.keys(map).find((id) => map[id][property] === value);

export const difference = (array, toRemove) => array.filter((item) => !toRemove.includes(item));

export const distribute = (items, collection) =>
  items.reduce(
    (lists, item) => {
      lists[collection.indexOf(item) > -1 ? 0 : 1].push(item);
      return lists;
    },
    [[], []]
  );

/**
 * @return {string}
 */
export const URLGetParam = (key, URLObject) => {
  const url = URLObject || window.location,
    searchString = url.search,
    searchParams = new URLSearchParams(searchString);

  return searchParams.get(key);
};

export const URLHasKey = mixinParseURL.methods.URLHasKey;

export const getInitialHistoryDepth = (autoTriggered, initialHistoryDepth) => {
  let historyDepthInc = 0;

  initialHistoryDepth = Number(initialHistoryDepth); // Number or NaN

  /* istanbul ignore else */
  if (Number.isSafeInteger(initialHistoryDepth)) historyDepthInc += initialHistoryDepth;
  if (autoTriggered) historyDepthInc++;

  return historyDepthInc;
};

export const getListsFacets = (state, attributes) => {
  const selectedFacets = {},
    unselectedFacets = {};

  attributes.map((attr) => {
    /* istanbul ignore next */
    if (state[FACET_STATE][attr]) {
      selectedFacets[attr] = state[FACET_STATE][attr];
      unselectedFacets[attr] = difference(
        Object.keys(state[FACET_MAPS][attr]),
        state[FACET_STATE][attr]
      );
    }
  });

  return [selectedFacets, unselectedFacets];
};

export const getFacetedProductIdsCollection = (maps, selectedFacets) => {
  return Object.entries(selectedFacets).reduce((acc, [attribute, values]) => {
    values.forEach((name) => {
      const facetValueId = findObjectKeyByPropertyValue(maps[attribute], 'name', name);
      if (facetValueId) acc = [...acc, ...maps[attribute][facetValueId]['productItemIds']];
    });
    return acc;
  }, []);
};

export const getDefaultProductsPerLine = (state, typeId) => {
  return state.productTypesMap[typeId]['productLineIds'].reduce((productPerLine, lineId) => {
    productPerLine[lineId] = state.productLinesMap[lineId]['productItemIds'];
    return productPerLine;
  }, {});
};

export const getFacetedProductsPerLine = (state, typeId, selectedFacets) => {
  const selectedFacetedLines = {},
    unselectedFacetedLines = {},
    facetedProductIdsCollection = getFacetedProductIdsCollection(state[FACET_MAPS], selectedFacets);

  state.productTypesMap[typeId]['productLineIds'].forEach((lineId) => {
    const [selectedProductIds, unselectedProductIds] = distribute(
      state.productLinesMap[lineId]['productItemIds'],
      facetedProductIdsCollection
    );

    /* istanbul ignore next */
    if (selectedProductIds.length) selectedFacetedLines[lineId] = selectedProductIds;
    /* istanbul ignore next */
    if (unselectedProductIds.length) unselectedFacetedLines[lineId] = unselectedProductIds;
  });

  return [selectedFacetedLines, unselectedFacetedLines];
};

export const getDefaultLists = (state, typeId) => [
  {
    typeId,
    layout: LIST_LAYOUT_DEFAULT,
    productsPerLine: getDefaultProductsPerLine(state, typeId),
  },
];

export const getFacetedLists = (state, typeId, productCategoryId, listsFacetAttributes) => {
  const [selectedFacets, unselectedFacets] = getListsFacets(state, listsFacetAttributes),
    [selectedFacetedLines, unselectedFacetedLines] = getFacetedProductsPerLine(
      state,
      typeId,
      selectedFacets
    );
  return [
    // recommended list
    ...(Object.keys(selectedFacetedLines).length > 0
      ? [
          {
            typeId,
            title: state.dictionary['recommendedListTitle'] || null,
            // || state.productTypesMap[typeId].recommendedListTitle
            // || state.productCategoriesMap[productCategoryId].recommendedListTitle

            description: state.dictionary['recommendedListSubTitle'] || null,
            // || state.dictionary[typeId].recommendedListSubTitle
            // || state.dictionary[productCategoryId].recommendedListSubTitle

            layout: LIST_LAYOUT_CAROUSEL,
            facets: selectedFacets,
            productsPerLine: selectedFacetedLines,
          },
        ]
      : []),

    // other list
    ...(Object.keys(unselectedFacetedLines).length > 0
      ? [
          {
            typeId,
            title: state.dictionary['otherProductListTitle'] || null,
            layout: LIST_LAYOUT_GRID,
            facets: unselectedFacets,
            productsPerLine: unselectedFacetedLines,
          },
        ]
      : []),
  ];
};
