import eventBus from '@loreal/eventbus-js';

// This timing should be aligned with css animation
const CSS_ANIMATION_DURATION = 2000;

export const toggleAnimationWithEvent = {
  name: 'toggle-animation-with-event',

  mounted: (el, { value }) => {
    let animationShouldPlay = true;
    let timer;
    const { className, interval, logoAnimationStartEventName, logoAnimationStopEventName } = value;

    eventBus.on(logoAnimationStartEventName, () => {
      animationShouldPlay = true;
      clearInterval(timer);
      playAnimation();
    });

    eventBus.on(logoAnimationStopEventName, () => {
      animationShouldPlay = false;
      el.classList.remove(className);
      clearInterval(timer);
    });

    if (animationShouldPlay) {
      playAnimation();
    }

    function playAnimation() {
      el.classList.add(className);

      timer = setInterval(() => {
        el.classList.remove(className);
        // It's a trick to force the browser to reflow the element's layout.
        // This is necessary because removing and then re-adding the class might not trigger the animation restart otherwise.
        void el.offsetWidth;
        el.classList.add(className);
      }, interval * 1000 + CSS_ANIMATION_DURATION);
    }
  },
};
