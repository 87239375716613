<template>
  <div class="newsletter-form">
    <form
      ref="form"
      novalidate
      class=""
      :action="formAction"
      name="newsletter-form"
      @submit.prevent="validateUser"
    >
      <slot name="nlf-additional-fields" />
      <div
        class="newsletter-form-block newsletter-form-block--radio"
        :class="{ '-has-error': validateState.gender.notDefined }"
      >
        <label class="newsletter-form-label">
          {{ genderDescription }}<span class="required">*</span>
        </label>
        <slot name="gender-options" :form="form"></slot>
        <div class="validation-block">
          <span v-if="validateState.gender.notDefined">Gender must be informed.</span>
        </div>
      </div>
      <div class="newsletter-form-block newsletter-form-block--names">
        <MaterialInput
          ref="firstName"
          v-model="form.firstName"
          ref-input="firstName"
          type="text"
          :required="true"
          name="FirstName"
          :error-messages="firstNameErrorMessage"
          >{{ firstNamePlaceholder }}</MaterialInput
        >
        <MaterialInput
          ref="lastName"
          v-model="form.lastName"
          ref-input="lastName"
          type="text"
          :required="true"
          name="LastName"
          :error-messages="lastNameErrorMessage"
          >{{ lastNamePlaceholder }}</MaterialInput
        >
      </div>
      <div class="newsletter-form-block newsletter-form-block--email">
        <MaterialInput
          ref="email"
          v-model="form.email"
          ref-input="email"
          type="email"
          :required="true"
          name="Email"
          :error-messages="emailErrorMessage"
          >{{ emailPlaceholder }}</MaterialInput
        >
      </div>
      <div class="newsletter-form-block newsletter-form-block--dates">
        <label class="newsletter-form-block__label" for="date-of-birth">
          {{ birthdatePlaceholder }}<span class="required">*</span>
        </label>
        <div class="colorDropdown day">
          <Dropdown
            v-model="form.birthDate.day"
            ref-select="day"
            :dates="date.days"
            :is-valid="validateState.date.invalid || validateState.date.notDefined"
            reset-name="dayReset"
            ><!-- Day --></Dropdown
          >
          <input type="hidden" name="Day" :value="form.birthDate.day" />
        </div>
        <div class="colorDropdown month">
          <Dropdown
            v-model="form.birthDate.month"
            :dates="date.months"
            ref-select="month"
            :is-valid="validateState.date.invalid || validateState.date.notDefined"
            reset-name="monthReset"
            ><!-- Month --></Dropdown
          >
          <input type="hidden" name="Month" :value="form.birthDate.month" />
        </div>
        <div class="colorDropdown year">
          <Dropdown
            v-model="form.birthDate.year"
            :dates="date.years"
            ref-select="year"
            :is-valid="validateState.date.invalid || validateState.date.notDefined"
            reset-name="yearReset"
            ><!-- Year --></Dropdown
          >
          <input type="hidden" name="Year" :value="form.birthDate.year" />
        </div>
        <div class="validation-block">
          <span v-if="validateState.date.invalid">{{ wrongDateError }}</span>
          <span v-if="validateState.date.notDefined">{{ emptyDateError }}</span>
        </div>
      </div>
      <div class="newsletter-form-block newsletter-form-block--left">
        <button
          v-if="showClearAll"
          class="button clear-all"
          type="button"
          tabindex="0"
          @click="clearAllFields"
        >
          {{ clearAllLabel }} <span class="form-clear">X</span>
        </button>
        <button class="button -primary" tabindex="0">{{ submitLabel }}</button>
      </div>
    </form>
  </div>
</template>

<script>
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import eventBus from '@loreal/eventbus-js';

import store from '../store';
import { mapActions } from 'vuex';

import Dropdown from './dropdown.vue';
import MaterialInput from './input.vue';

const NUMBER_OF_YEARS = 85;

export default {
  name: 'NewsletterForm',
  components: {
    Dropdown,
    MaterialInput,
  },

  props: {
    genderDescription: {
      type: String,
      required: true,
    },
    firstNamePlaceholder: {
      type: String,
      required: true,
    },
    lastNamePlaceholder: {
      type: String,
      required: true,
    },
    emailPlaceholder: {
      type: String,
      required: true,
    },
    dayPlaceholder: {
      type: String,
      required: true,
    },
    monthPlaceholder: {
      type: String,
      required: true,
    },
    yearPlaceholder: {
      type: String,
      required: true,
    },
    birthdatePlaceholder: {
      type: String,
      required: true,
    },

    firstNameError: {
      type: String,
      required: true,
    },
    lastNameError: {
      type: String,
      required: true,
    },
    emailError: {
      type: String,
      required: true,
    },
    emptyDateError: {
      type: String,
      required: true,
    },
    wrongDateError: {
      type: String,
      required: true,
    },

    submitLabel: {
      type: String,
      required: true,
    },
    submitErrorMessage: {
      type: String,
      required: true,
    },
    submitSuccessMessage: {
      type: String,
      required: true,
    },
    clearAllLabel: {
      type: String,
      required: true,
    },

    formAction: {
      type: String,
      required: true,
    },
    messageTiming: {
      type: Number,
      required: false,
      default: 30,
    },
  },
  data() {
    return {
      form: {
        firstName: null,
        lastName: null,
        gender: null,
        email: null,
        birthDate: {
          day: null,
          month: null,
          year: null,
        },
      },
      date: {
        days: this.generateDays(),
        months: this.generateMonths(),
        years: this.generateYears(),
      },
      validateState: {
        date: {
          invalid: false,
          notDefined: false,
        },
        firstName: {
          notDefined: false,
        },
        lastName: {
          notDefined: false,
        },
        gender: {
          notDefined: false,
        },
        email: {
          invalid: false,
          notDefined: false,
        },
      },
    };
  },
  computed: {
    showClearAll() {
      return (
        this.form.firstName ||
        this.form.lastName ||
        this.form.gender ||
        this.form.email ||
        this.form.birthDate.day ||
        this.form.birthDate.month ||
        this.form.birthDate.year
      );
    },
    firstNameErrorMessage() {
      const error = [];
      this.validateState.firstName.notDefined && error.push(this.firstNameError);
      return error;
    },
    lastNameErrorMessage() {
      const error = [];
      this.validateState.lastName.notDefined && error.push(this.lastNameError);
      return error;
    },
    emailErrorMessage() {
      const error = [];
      this.validateState.email.notDefined && error.push(this.emailError);
      this.validateState.email.invalid && error.push('Email is not valid.');
      return error;
    },
    dateErrorMessage() {
      const error = [];
      this.validateState.date.notDefined && error.push('Date is required.');
      this.validateState.date.invalid && error.push('Date is not valid.');
      return error;
    },
    genderErrorMessage() {
      const error = [];
      this.validateState.gender.notDefined && error.push('Gender is required.');
      return error;
    },
    isFormValid() {
      return (
        !this.firstNameErrorMessage.length &&
        !this.lastNameErrorMessage.length &&
        !this.emailErrorMessage.length &&
        !this.dateErrorMessage.length &&
        !this.genderErrorMessage.length
      );
    },
  },
  beforeCreate() {
    this.$store.registerModule('NewsletterForm', store);
  },
  mounted() {
    eventBus.on('newsletter-form::form-submited', this.showValidationMessages);
    eventBus.on('inputValue', (value) => {
      let refInput = value.refField;
      this.form[refInput] = value.inputValue;
    });
    eventBus.on('selectDropdown', (value) => {
      let refSelect = value.refField;
      this.form.birthDate[refSelect] = value.inputValue;
    });
    const currentURL = new URL(window.location.href);

    if (currentURL.searchParams.get('email')) {
      this.form['email'] = currentURL.searchParams.get('email');
    }
  },
  methods: {
    ...mapActions('NewsletterForm', ['submitForm']),

    validateUser() {
      let formData = new FormData(this.$refs.form);
      this.validateGender();
      this.validateFirstName();
      this.validateLastName();
      this.validateEmail();
      this.validateDate();

      if (this.isFormValid) {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          ecommerce: 'undefined',
          category: 'registration',
          action: 'newsletter',
          label: 'newsletter-form-page',
        });
        this.submitForm({
          formActionUrl: this.formAction,
          formData,
        });
      }
    },
    showValidationMessages(type) {
      const messages = {
        error: this.submitErrorMessage,
        success: this.submitSuccessMessage,
      };

      this.$toasted[type](messages[type], {
        action: {
          icon: '✕',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      }).goAway(this.messageTiming * 1000);

      if (type === 'error') {
        return;
      }

      this.clearAllFields();
    },
    validateGender() {
      if (!this.form.gender) {
        this.validateState.gender.notDefined = true;
        return false;
      }

      this.validateState.gender.notDefined = false;
      return true;
    },
    validateFirstName() {
      if (!this.form.firstName) {
        this.validateState.firstName.notDefined = true;
        return false;
      }

      this.validateState.firstName.notDefined = false;
      return true;
    },
    validateLastName() {
      if (!this.form.lastName) {
        this.validateState.lastName.notDefined = true;
        return false;
      }

      this.validateState.lastName.notDefined = false;
      return true;
    },
    validateDate() {
      if (!this.form.birthDate.day || !this.form.birthDate.month || !this.form.birthDate.year) {
        this.validateState.date.notDefined = true;
        return false;
      }

      this.validateState.date.notDefined = false;
      // First check for the pattern
      if (
        !/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(
          `${this.form.birthDate.day}/${this.form.birthDate.month}/${this.form.birthDate.year}`
        )
      ) {
        this.validateState.date.invalid = true;
        return false;
      }

      // Check the ranges of month and year
      if (
        this.form.birthDate.year < 1000 ||
        this.form.birthDate.year > 3000 ||
        this.form.birthDate.month == 0 ||
        this.form.birthDate.month > 12
      ) {
        this.validateState.date.invalid = true;
        return false;
      }

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (
        this.form.birthDate.year % 400 == 0 ||
        (this.form.birthDate.year % 100 != 0 && this.form.birthDate.year % 4 == 0)
      )
        monthLength[1] = 29;

      // Check the range of the day
      if (
        this.form.birthDate.day > 0 &&
        this.form.birthDate.day <= monthLength[this.form.birthDate.month - 1]
      ) {
        this.validateState.date.invalid = false;
        return true;
      }

      this.validateState.date.invalid = true;
      return false;
    },
    generateDays() {
      const dObject = [
        {
          selected: true,
          value: undefined,
          label: `${this.dayPlaceholder}`,
        },
      ];

      for (var d = 1; d < 32; d++) {
        dObject.push({
          selected: false,
          value: d,
          label: d,
        });
      }

      return dObject;
    },
    generateMonths() {
      const dObject = [];
      dObject.push({
        selected: true,
        value: undefined,
        label: `${this.monthPlaceholder}`,
      });

      for (var m = 0; m < 12; m++) {
        const date = new Date(2009, m, 10);
        const month = date.toLocaleString(navigator.language, { month: 'long' });
        dObject.push({
          selected: false,
          value: m + 1,
          label: month,
        });
      }

      return dObject;
    },
    generateYears() {
      const d = new Date();
      const currentYear = d.getUTCFullYear();
      const targetYear = currentYear - NUMBER_OF_YEARS;
      const dObject = [];
      dObject.push({
        selected: true,
        value: undefined,
        label: `${this.yearPlaceholder}`,
      });

      for (var y = d.getUTCFullYear(); y > targetYear; y--) {
        dObject.push({
          selected: false,
          value: y,
          label: y,
        });
      }

      return dObject;
    },
    clearAllFields() {
      this.form.firstName = '';
      this.form.lastName = '';
      this.form.email = '';
      this.form.gender = '';
      this.form.birthDate.day = '';
      this.form.birthDate.month = '';
      this.form.birthDate.year = '';
      this.date.day = this.generateDays();
      this.date.month = this.generateMonths();
      this.date.year = this.generateYears();
      let emitArray = [
        'dropdown::dayReset',
        'dropdown::monthReset',
        'dropdown::yearReset',
        'input::firstName',
        'input::lastName',
        'input::email',
      ];
      emitArray.forEach((value) => {
        eventBus.emit(value);
      });
      this.$refs.lastName.reset();
      this.$refs.firstName.reset();
      this.$refs.form.email.reset();

      this.clearValidationMessages();
    },

    clearValidationMessages() {
      this.validateState.date.invalid = false;
      this.validateState.date.notDefined = false;

      this.validateState.firstName.notDefined = false;
      this.validateState.lastName.notDefined = false;

      this.validateState.email.notDefined = false;
      this.validateState.email.invalid = false;

      this.validateState.gender.notDefined = false;
    },

    validateEmail() {
      if (!this.form.email) {
        this.validateState.email.notDefined = true;
        return false;
      } else {
        this.validateState.email.notDefined = false;
      }
      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/gm.test(this.form.email)) {
        this.validateState.email.invalid = true;
        return false;
      } else {
        this.validateState.email.invalid = false;
      }

      this.validateState.email.invalid = false;
      this.validateState.email.notDefined = false;

      return true;
    },
  },
};
</script>
