import { AnalyticsPromotionObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class PromotionObject extends DataObject {
  constructor(data) {
    super(undefined, data);

    if (!this.name && !this.id) {
      Logger.error(
        '[AnalyticsPromotionObjectException] A Promotion Name or a Promotion ID is required for an Analytics Promotion Object',
        data
      );
      throw new AnalyticsPromotionObjectException(
        'A Promotion Name or a Promotion ID is required for an Analytics Promotion Object'
      );
    }
  }

  set name(name) {
    this.result.name = this.setProperty(name);
  }

  get name() {
    return this.result.name;
  }

  set id(id) {
    this.result.id = this.setProperty(id);
  }

  get id() {
    return this.result.id;
  }

  set creative(creative) {
    this.result.creative = this.setProperty(creative);
  }

  get creative() {
    return this.result.creative;
  }

  set position(position) {
    this.result.position = this.setProperty(position);
  }

  get position() {
    return this.result.position;
  }
}
