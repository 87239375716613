<template>
  <div
    class="carousel-slide"
    :style="carouselSlideStyle"
    :aria-hidden="hidden"
    :tabindex="hidden ? '-1' : '0'"
  >
    <slot />
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
export default {
  name: 'Slide',
  data() {
    return {
      width: null,
      padding: this.$parent.slidePadding,
      hidden: false,
      focusableChildren: null,
      slideIndex: null,
    };
  },
  computed: {
    carouselSlideStyle() {
      const styles = {
        padding: `0 ${this.padding}px`,
      };

      if (this.$parent.flexBasis) {
        styles.flexBasis = this.$parent.flexBasis;
      }

      return styles;
    },
  },
  mounted() {
    this.setAria();
    /* istanbul ignore next */
    eventBus.on(`${this.$parent.carouselIdentifier}-pageChange`, (currentPage) => {
      this.updateAria(currentPage);
    });
  },
  methods: {
    setAria() {
      const slides = document
        .getElementById(this.$parent.carouselIdentifier)
        ?.querySelectorAll('.carousel-slide');

      if (!slides) {
        return;
      }

      slides.forEach((slide, i) => {
        if (slide === this.$el) {
          this.slideIndex = i;
        }
      });

      this.$nextTick(() => {
        this.focusableChildren = this.$el.querySelectorAll('a, button');
        this.hidden = this.slideIndex >= this.$parent.currentPerPage;
        this.toggleTabIndex(this.hidden ? '-1' : '0');
      });
    },
    updateAria(currentPage) {
      if (this.slideIndex === null) {
        return;
      }

      const currentSlide = this.slideIndex + 1;
      const fitSize = this.$parent.currentPerPage * this.$parent.pageCount;
      const minIndex = (fitSize / this.$parent.pageCount) * currentPage;
      const maxIndex = (fitSize / this.$parent.pageCount) * (currentPage + 1);
      this.hidden = !(currentSlide > minIndex && currentSlide <= maxIndex);
      this.toggleTabIndex(this.hidden ? '-1' : '0');
    },
    toggleTabIndex(val) {
      this.focusableChildren.forEach((el) => {
        el.setAttribute('tabindex', val);
      });
    },
  },
};
</script>
