<template>
  <section class="oap-tab">
    <a class="oap-tab__skip-link" :href="`#skip-slider-${tabComponentId}`" :title="skipLink.text">
      {{ skipLink.text }}
    </a>
    <slot name="header" />
    <div class="oap-tab__headings">
      <slot name="heading" />
    </div>
    <transition-group name="fade" tag="div" class="oap-tab__content">
      <slot />
    </transition-group>
    <span
      :id="`skip-slider-${tabComponentId}`"
      class="oap-tab__skip-link -skipped"
      :title="skipLink.skippedText"
      >{{ skipLink.skippedText }}</span
    >
  </section>
</template>

<script>
import { uniqueId } from '../../../../../Foundation/Core/code/Scripts/utilities/uniqueId';
import { mapActions } from '../helpers';
import { REGISTER_TAB } from '../settings';

export default {
  name: 'OapTab',
  props: {
    tabComponentId: {
      type: String,
      default: () => uniqueId(),
    },
    skipLink: {
      type: Object,
      default() {
        return {
          text: 'skip tab component',
          skippedText: 'tab component skipped',
        };
      },
    },
  },
  mounted() {
    this[REGISTER_TAB](this.tabComponentId);
  },
  methods: {
    ...mapActions([REGISTER_TAB]),
  },
};
</script>
