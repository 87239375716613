import OapTab from './code/Scripts/components/oap-tab.vue';
import OapTabItem from './code/Scripts/components/oap-tab-item.vue';
import OapTabHeader from './code/Scripts/components/oap-tab-header.vue';
import OapTabHeaderItem from './code/Scripts/components/oap-tab-header-item.vue';

import tabStore from './code/Scripts/store';

import { OAP_TAB_COMPONENT_NAMESPACE } from './code/Scripts/settings';

export default {
  components: {
    OapTab,
    OapTabItem,
    OapTabHeader,
    OapTabHeaderItem,
  },
  modules: {
    [OAP_TAB_COMPONENT_NAMESPACE]: tabStore,
  },
};
