import getValue from './getValue';

/**
 * Extract value from a list of fields and return an object.
 * @param domNode
 * @param fields
 * @param cb
 */
export default function getTagValues(domNode, fields, cb = (o) => o) {
  return fields
    .filter((o) => !!o)
    .map((key) => ({ key, value: getValue(domNode, `[data-tag-${key}]`, key) }))
    .reduce((acc, item) => {
      if (item.value !== undefined && item.value) {
        acc[cb(item.key)] = item.value;
      }
      return acc;
    }, {});
}
