export const MF_SCRIPT_NAME = 'modiface-script',
  FRAME_CONTAINER_ID = 'vto__frame-container',
  FRAME_NAME = 'vto__frame',
  BUS_EVENT_VTO_ENTER = 'vto:enter',
  PRODUCT_LINE_VARIANTS_LIMIT = 7,
  // catalog / product line types
  TYPE_LOOK = 'look',
  TYPE_PRODUCT = 'product',
  // category names
  CATEGORY_HAIR_COLOR = 'haircolor',
  CATEGORY_MAKE_UP = 'customlook',
  SELECTION_MODE_BY_CATEGORY = {
    [CATEGORY_HAIR_COLOR]: 'single',
    [CATEGORY_MAKE_UP]: 'multi',
  },
  // catalog modes
  CATALOG_MODE_LOOK = 'look',
  CATALOG_MODE_MULTI = 'multi',
  CATALOG_MODE_SINGLE = 'single',
  CATALOG_MODE_SWITCH = 'switch',
  // pages (should match modiface page naming)
  PAGE_DEFAULT = 'PAGE_LOADING',
  PAGE_LOADING = 'PAGE_LOADING',
  PAGE_LANDING = 'PAGE_LANDING',
  PAGE_HAIR_DETECTION = 'PAGE_HAIR_DETECTION',
  PAGE_MAIN = 'PAGE_MAIN',
  PAGE_CATALOG = 'PAGE_CATALOG',
  PAGE_ENTITY_SELECTION = 'PAGE_ENTITY_SELECTION',
  PAGE_COMPARE = 'PAGE_COMPARE',
  PAGE_SHARE = 'PAGE_SHARE',
  PAGE_ERROR = 'PAGE_ERROR',
  // sub pages (inner views of the pages)
  VIEW_ENTITY_SELECTION = 'VtoEntitySelection',
  VIEW_SUBENTITY_SELECTION = 'VtoSubentitySelection',
  VIEW_LOOK_SELECTION = 'VtoLookSelection',
  VIEW_CATALOG = 'VtoCatalog',
  VIEW_HOTSWAP = 'VtoHotswap',
  VIEW_HOTSWAP_HAIRCOLOR = 'VtoHotswapHaircolor',
  VIEW_HOTSWAP_LOOKS = 'VtoHotswapLooks',
  // transitions
  TRANSITION_SWIPE_IN = 'swipeIn',
  TRANSITION_SWIPE_OUT = 'swipeOut',
  // Modiface events
  MF_EVENT_CUSTOM_LOOK_CHANGE = 'custom_look_change',
  MF_EVENT_EXIT = 'exit',
  MF_EVENT_MODIFY_CTA_DISPLAY = 'modify_iframe_cta_display',
  // Modiface settings
  MF_CTA_DISPLAY_DEFAULT = {
    // adjust coordinates CTA
    adjustCta: {
      display: true,
    },
    // face beautifier effect CTA
    beautyFilterCta: {
      display: true,
    },
    // before/after compare CTA
    beforeAfterCta: {
      display: true,
    },
    // close button
    closeButton: {
      display: true,
    },
    // favorite CTA
    favoriteCta: {
      display: true,
    },
    // quad compare CTA
    quadCta: {
      display: true,
    },
    // share CTA
    shareCta: {
      display: true,
    },
    // tutorial steps (previous/next) CTA
    tutorialCta: {
      display: true,
    },
    // undo CTA
    undoCta: {
      display: true,
    },
  },
  MF_CTA_SETTINGS = {
    [PAGE_MAIN]: {
      closeButton: {
        display: false,
      },
    },

    [PAGE_HAIR_DETECTION]: {
      beforeAfterCta: {
        display: false,
      },
      closeButton: {
        display: false,
      },
      shareCta: {
        display: false,
      },
    },

    [PAGE_COMPARE]: {
      closeButton: {
        display: false,
      },
    },

    [PAGE_SHARE]: {
      closeButton: {
        display: false,
      },
    },

    [PAGE_CATALOG]: {
      closeButton: {
        display: false,
      },
    },
  },
  VTO_API_URL = '/apioap/vto?',
  VTO_TRIGGER_URL_PARAM = 'tryon',
  VTO_HAIRCOLOR_URL_PARAM = 'haircolor',
  VTO_HISTORY_DEPTH_PARAM = 'hd',
  VTO_STORE_MODULE_NAMESPACE = 'vto',
  ENV_PROD_NAME = 'production',
  // Action
  VTO_URL_PARAM_UTM_SOURCE_KEY = 'utm_source',
  VTO_URL_PARAM_UTM_SOURCE_POS_VALUE = 'pos',
  SET_EMAIL_ENABLED = 'set_email_enabled',
  EMAIL_BUTTON_TEXT = 'email_button_text',
  EMAIL_BUTTON_URL = 'email_button_url',
  // Facets
  FACET_ATTRIBUTES = {
    HAIR_COLOR_FAMILY: 'hairColorFamily',
  },
  // Facets state keys
  FACET_STATE = 'facetState',
  FACET_MAPS = 'facetMaps',
  FACET_CONFIGS = 'facetConfigs',
  LIST_LAYOUT_GRID = 'grid',
  LIST_LAYOUT_CAROUSEL = 'carousel',
  LIST_LAYOUT_DEFAULT = LIST_LAYOUT_GRID,
  FACETS_SETTINGS = [
    {
      attribute: 'hairColorFamily',
      config: {
        perCategory: [CATEGORY_HAIR_COLOR],
        // 'perType': [],
      },
    },
  ],
  TAGGING_INFO_PRODUCT_CLICK_TYPE = 'productClick',
  TAGGING_INFO_PRODUCT_VARIANT_CLICK_TYPE = 'productVariantClick',
  TAGGING_INFO_VTO_DETAIL_TYPE = 'vtoDetail',
  TAGGING_INFO_SERVICE_CATEGORY = 'guide',
  TAGGING_INFO_SERVICE_NAME = 'virtualtryon',
  TAGGING_INFO_SERVICE_VERSION = '1.0',
  // circles animation
  CIRCLES_CONTAINER_ANIMATION_TIMING = 700,
  CIRCLES_CONTAINER_ANIMATION_CLASS = 'vtoCirclesContainer--animate-display',
  CIRCLES_CONTAINER_APPEARING_CLASS = 'vtoCirclesContainer--appearing-left',
  CIRCLES_ANIMATED_CLASS = '-animated';

export const CATALOG_VIEW_TRANSITION_MAP = {
  default: null,
  [VIEW_CATALOG]: TRANSITION_SWIPE_OUT,
  [VIEW_HOTSWAP]: TRANSITION_SWIPE_IN,
  [VIEW_HOTSWAP_HAIRCOLOR]: TRANSITION_SWIPE_IN,
};
