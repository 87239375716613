<template>
  <div class="vtoHeaderLabel">{{ text }}</div>
</template>

<script>
export default {
  name: 'VtoHeaderLabel',
  props: {
    text: { type: String, required: true },
  },
};
</script>
