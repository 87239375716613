import {
  FACET_ATTRIBUTES,
  FACET_CONFIGS,
  FACET_MAPS,
  FACET_STATE,
  PAGE_LANDING,
  SET_EMAIL_ENABLED,
  EMAIL_BUTTON_TEXT,
  EMAIL_BUTTON_URL,
} from '../settings';

// mutation types
export const SET_MAIN_CATEGORY = 'SET_MAIN_CATEGORY',
  SET_OPPOSITE_CATEGORY = 'SET_OPPOSITE_CATEGORY',
  SET_ENABLE_FAVORITE = 'SET_ENABLE_FAVORITE',
  SET_FAVORITE_SETTINGS = 'SET_FAVORITE_SETTINGS',
  SET_RATING = 'SET_RATING',
  SET_MODIFACE_DATA = 'SET_MODIFACE_DATA',
  SET_FRAME_READY = 'SET_FRAME_READY',
  SET_HOTSWAP_REMOVE_TYPE_ID = 'SET_HOTSWAP_REMOVE_TYPE_ID',
  SET_MODAL_DISPLAY = 'SET_MODAL_DISPLAY',
  SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
  SET_INITIAL_PRODUCT_ID = 'SET_INITIAL_PRODUCT_ID',
  SET_REMOVE_SELECTED_PRODUCTS = 'SET_REMOVE_SELECTED_PRODUCTS',
  SET_RESET_SELECTED_LOOK_FAMILY_ID = 'SET_RESET_SELECTED_LOOK_FAMILY_ID',
  SET_RESET_SELECTED_LOOK_ID = 'SET_RESET_SELECTED_LOOK_ID',
  SET_RESET_SELECTED_TYPE_ID = 'SET_RESET_SELECTED_TYPE_ID',
  SET_SELECTED_LOOK_FAMILY_ID = 'SET_SELECTED_LOOK_FAMILY_ID',
  SET_SELECTED_LOOK_ID = 'SET_SELECTED_LOOK_ID',
  SET_SELECTED_PRODUCT_ID = 'SET_SELECTED_PRODUCT_ID',
  SET_SELECTED_PRODUCTS = 'SET_SELECTED_PRODUCTS',
  SET_SELECTED_TYPE_ID = 'SET_SELECTED_TYPE_ID',
  SET_API_DATA = 'SET_API_DATA',
  SET_SHOW_FLOATING_BAR = 'SET_SHOW_FLOATING_BAR',
  SET_SHOW_SHADE = 'SET_SHOW_SHADE',
  SET_SHOW_SNACKBAR = 'SET_SHOW_SNACKBAR',
  SET_MAIN_CATALOG = 'SET_MAIN_CATALOG',
  SET_MAIN_CATALOG_SWITCH = 'SET_MAIN_CATALOG_SWITCH',
  SET_CATALOG_TYPE = 'SET_CATALOG_TYPE',
  SET_CATALOG_VIEW = 'SET_CATALOG_VIEW',
  SET_PRODUCT_LISTS_MAP = 'SET_PRODUCT_LISTS_MAP',
  SET_INCREMENT_HISTORY_DEPTH = 'SET_INCREMENT_HISTORY_DEPTH',
  SET_HISTORY_DEPTH = 'SET_HISTORY_DEPTH',
  SET_ORIGIN_LOCATION = 'SET_ORIGIN_LOCATION',
  SET_RESET_STORE = 'SET_RESET_STORE',
  SET_BUY_DISABLED = 'SET_BUY_DISABLED',
  // Facets
  SET_NEW_FACET = 'SET_NEW_FACET',
  SET_FACET_CONFIG = 'SET_FACET_CONFIG',
  SET_FACET_PRESET = 'SET_FACET_PRESET',
  SET_UPDATE_FACET = 'SET_UPDATE_FACET',
  SET_UPDATE_FACETED_PRODUCTS_COLLECTION = 'SET_UPDATE_FACETED_PRODUCTS_COLLECTION',
  // Appearing circles animation
  SET_CIRCLES_ANIMATION_STATUS = 'SET_CIRCLES_ANIMATION_STATUS';

export default {
  [SET_ENABLE_FAVORITE]: (state, value) => (state.enableFavorite = value),

  [SET_FAVORITE_SETTINGS]: (state, value) => (state.favoriteSettings = value),

  [SET_MAIN_CATEGORY]: (state, value) => (state.mainCategory = value),

  [SET_OPPOSITE_CATEGORY]: (state, value) => (state.oppositeCategory = value),

  [SET_RATING]: (state, value) => (state.rating = value),

  [SET_MODIFACE_DATA]: (state, value) => (state.modifaceData = value),

  [SET_FRAME_READY]: (state) => (state.frameReady = true),

  [SET_HOTSWAP_REMOVE_TYPE_ID]: (state, value) => (state.hotSwapRemoveTypeId = value),

  [SET_MODAL_DISPLAY]: (state, value) => (state.modalDisplay = value),

  [SET_CURRENT_PAGE]: (state, value) => {
    state.previousPage = state.currentPage;
    state.currentPage = value;
  },

  [SET_INITIAL_PRODUCT_ID]: (state, value) => (state.initialProductId = value),

  [SET_SELECTED_PRODUCT_ID]: (state, value) => (state.selectedProductId = value),

  [SET_SELECTED_PRODUCTS]: (state, { typeId, id, singleMode }) => {
    if (singleMode) {
      state.selectedProducts = { [typeId]: id };
    } else {
      state.selectedProducts[typeId] = id;
    }
  },

  [SET_SELECTED_LOOK_FAMILY_ID]: (state, value) => (state.selectedLookFamilyId = value),

  [SET_SELECTED_LOOK_ID]: (state, value) => (state.selectedLookId = value),

  [SET_RESET_SELECTED_LOOK_FAMILY_ID]: (state) => (state.selectedLookFamilyId = null),

  [SET_RESET_SELECTED_LOOK_ID]: (state) => (state.selectedLookId = null),

  [SET_RESET_SELECTED_TYPE_ID]: (state) => {
    state.selectedTypeId = null;
  },

  [SET_REMOVE_SELECTED_PRODUCTS]: (state, typeId) => {
    delete state.selectedProducts[typeId];
  },

  [SET_API_DATA]: (state, value) => {
    state.dictionary = value.dictionary;
    state.productCategoriesMap = value.productCategoriesMap;
    state.productTypesMap = value.productTypesMap;
    state.productLinesMap = value.productLinesMap;
    state.productItemsMap = value.productItemsMap;

    // hairColorFamiliesMap
    state[FACET_MAPS][FACET_ATTRIBUTES.HAIR_COLOR_FAMILY] = value['hairColorFamiliesMap'];
  },

  [SET_PRODUCT_LISTS_MAP]: (state, map) => {
    state.productListsMap = map;
  },

  [SET_MAIN_CATALOG]: (state, bool) => {
    state.isMainCatalog = bool;
  },

  [SET_MAIN_CATALOG_SWITCH]: (state) => {
    state.isMainCatalog = !state.isMainCatalog;
  },

  [SET_CATALOG_TYPE]: (state, type) => {
    state.catalogType = type;
  },

  [SET_CATALOG_VIEW]: (state, view) => {
    state.catalogView = view;
  },

  [SET_SHOW_FLOATING_BAR]: (state, bool) => {
    state.showFloatingBar = bool;
  },

  [SET_SHOW_SHADE]: (state, value) => {
    state.showShade = value.showShade;
    state.show = value.show;
  },

  [SET_SHOW_SNACKBAR]: (state, bool) => {
    state.showSnackbar = bool;
  },

  [SET_SELECTED_TYPE_ID]: (state, id) => {
    state.selectedTypeId = id;
  },

  [SET_INCREMENT_HISTORY_DEPTH]: (state, n) => {
    state.historyDepth += n;
  },

  [SET_HISTORY_DEPTH]: (state, n) => {
    state.historyDepth = n;
  },

  [SET_ORIGIN_LOCATION]: (state, any) => {
    state.originLocation = any;
  },

  [SET_RESET_STORE]: (state) => {
    // Used on DO_ENTER to reinitialize needed values
    state.reset = true;

    // history
    state.historyDepth = 0;

    // snackbar
    state.showSnackbar = false;

    // data
    state.productListsMap = null;

    // routing
    state.previousPage = null;
    state.currentPage = PAGE_LANDING;

    // selection
    state.selectedProductId = null;
    state.selectedProducts = {};

    // catalog
    state.isMainCatalog = true;
    state.catalogView = null;
    state.selectedTypeId = null;
    state.rating = null;

    // hot swap
    state.hotSwapRemoveTypeId = null;

    // main page UI, based on modiface's events
    state.show = false;
    state.showShade = false;

    // Facets
    state.facetedProductIdsCollection = [];
  },

  // Facets
  [SET_NEW_FACET]: (state, attribute) => {
    state[FACET_STATE][attribute] = [];
    state[FACET_MAPS][attribute] = {};
  },

  [SET_FACET_CONFIG]: (state, { attribute, config: { perCategory } }) => {
    /* istanbul ignore else */
    if (perCategory && perCategory.length) {
      perCategory.forEach((categoryId) => {
        state[FACET_CONFIGS].perCategory[categoryId] = [
          ...(state[FACET_CONFIGS].perCategory[categoryId]
            ? state[FACET_CONFIGS].perCategory[categoryId]
            : []),
          ...[attribute],
        ];
      });
    }
  },

  [SET_FACET_PRESET]: (state, { attribute, value }) => {
    state[FACET_CONFIGS].presets[attribute] = value;
  },

  [SET_UPDATE_FACET]: (state, { attribute, facetIds }) => {
    state[FACET_STATE][attribute] = [...facetIds];
  },

  [SET_UPDATE_FACETED_PRODUCTS_COLLECTION]: (state, value) => {
    state.facetedProductIdsCollection = value;
  },

  [SET_BUY_DISABLED]: (state, value) => {
    state.actionBuyDisabled = value;
  },

  [SET_EMAIL_ENABLED]: (state, value) => {
    state.actionEmailEnabled = value;
  },

  [EMAIL_BUTTON_TEXT]: (state, value) => {
    state.emailButtonText = value;
  },
  [EMAIL_BUTTON_URL]: (state, value) => {
    state.emailButtonUrl = value;
  },

  [SET_CIRCLES_ANIMATION_STATUS]: (state, value) => {
    state.circlesAnimatedStatus = value;
  },
};
