import {
  ACTIONS as actions,
  MUTATIONS as mutations,
  GETTERS as getters,
  STATE as state,
  ACCESSIBILITY_STORE_NAMESPACE,
} from './accessibilityStore';

export default {
  modules: {
    [ACCESSIBILITY_STORE_NAMESPACE]: {
      namespaced: true,
      state,
      actions,
      getters,
      mutations,
    },
  },
};
