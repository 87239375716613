<template>
  <OapFocusTrap :is-visible="showAlert">
    <transition name="modal">
      <div v-if="showAlert" class="alert" role="dialog" aria-modal="true">
        <div class="alert__mask">
          <div class="alert__wrapper">
            <div class="alert__container">
              <header class="alert__header">
                <h3 class="alert__title">{{ titleText }}</h3>
                <button class="alert__close" @click="confirm()" @keyup.esc="confirm()">
                  <svg class="icon" role="img" aria-labelledby="close-alert-icon">
                    <title id="close-alert-icon">Close</title>
                    <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
                  </svg>
                </button>
              </header>

              <div class="alert__content scrollbarCustom">
                <slot />
              </div>

              <button class="alert__confirm" @click="confirm()">{{ confirmText }}</button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </OapFocusTrap>
</template>

<script>
import OapFocusTrap from '../../../../OapFocusTrap/code/Scripts/components/OapFocusTrap.vue';
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler';

export default {
  name: 'Alert',

  components: {
    OapFocusTrap,
  },

  props: {
    alertId: { type: String, default: '' },
    analytics: { type: Object, default: () => {} },
    confirmText: { type: String, require: true, default: 'Confirm' },
    shouldClose: { type: Boolean, default: true },
    titleText: { type: String, require: true, default: 'Alert' },
  },

  data() {
    return {
      showAlert: false,
    };
  },

  mounted() {
    /* istanbul ignore next */
    eventBus.on('alert:toggle', (id) => {
      if (this.alertId === id) {
        this.toggle();
      }
    });
  },

  methods: {
    toggle() {
      this.showAlert = !this.showAlert;
    },

    confirm() {
      /* istanbul ignore next */
      if (this.analytics) {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: this.analytics.type,
          category: this.analytics.category,
          action: this.analytics.action,
          label: this.analytics.label,
        });
      }

      /* istanbul ignore else */
      if (this.shouldClose) {
        this.showAlert = false;
      }

      eventBus.emit('alert:confirmed', this.alertId);
    },
  },
};
</script>
