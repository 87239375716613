import includes from 'lodash.includes';
import eventBus from '@loreal/eventbus-js';

const injectedUrls = [];
const bvReviewsContainerClass = '.bvReviews.reviews-container';

export function loaded(binding) {
  const tag = document.createElement('script');
  const head = document.getElementsByTagName('head')[0];

  tag.src = binding.url;
  tag.async = true;

  if (binding.attributes) {
    Object.keys(binding.attributes).forEach((key) => {
      tag.setAttribute(key, binding.attributes[key]);
    });
  }

  head.appendChild(tag);

  /* istanbul ignore next */
  tag.onload = () => fireLoadedEvent(binding.name, binding.url);
}

export function bvReturnUrl() {
  let lengthRW = document.querySelectorAll(bvReviewsContainerClass).length;
  let enableRedirect =
    lengthRW > 0
      ? document.querySelector(bvReviewsContainerClass).getAttribute('data-enableredirect')
      : '';
  let dataReturnUrl =
    lengthRW > 0
      ? document.querySelector(bvReviewsContainerClass).getAttribute('data-returnurl')
      : '';
  if (
    typeof $BV != 'undefined' &&
    enableRedirect.toLowerCase() === 'true' &&
    dataReturnUrl != null
  ) {
    $BV.configure('global', { returnUrl: dataReturnUrl });
  }
}

export function fireLoadedEvent(name, url) {
  eventBus.emit('script.loaded', { name, url });
  bvReturnUrl();
}

export const loadscript = {
  name: 'loadscript',
  beforeMount: (el, binding) => {
    if (binding.value && binding.value.url && !includes(injectedUrls, binding.value.url)) {
      injectedUrls.push(binding.value.url);
      loaded(binding.value);
    }
  },
};
