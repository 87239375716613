import handleEvent from './handleEvent';
import includes from 'lodash.includes';
import { AnalyticsTagDirectiveException } from '../errors/analyticsError';
import { TAGGING_TYPES_ARRAY } from '../models/TaggingTypes';

/**
 * Whitelist of types of Analytics events.
 */
export const typeWhitelist = TAGGING_TYPES_ARRAY.map((s) => s.toLocaleLowerCase());

/**
 * Tag Directive
 * @module v-tag
 */

/**
 * Vue Tag directive declaration.
 */
export const tag = {
  name: 'tag',
  mounted: (el, binding) => {
    const { arg, modifiers, value: data = {} } = binding;
    let eventType = 'click';

    // bail early conditional directives
    if (data.placeholder) return;

    if (!arg) {
      throw new AnalyticsTagDirectiveException(
        `No argument given to v-tag. Example usage: v-tag:event="{category: 'video', action: 'play', label: 'video.mp4'}}". Argument options: ${typeWhitelist.join(
          ', '
        )}`
      );
    }

    if (!includes(typeWhitelist, arg)) {
      throw new AnalyticsTagDirectiveException(
        `v-tag argument [${arg}] to v-tag is wrong. Argument options: ${typeWhitelist.join(', ')}`
      );
    }

    /* istanbul ignore next */
    switch (arg) {
      case 'productimpression':
        data.type = 'productImpression';
        eventType = 'impression';
        break;

      case 'productclick':
        data.type = 'productClick';
        break;
      case 'productvariantclick':
        data.type = 'productVariantClick';
        break;
      case 'promotionclick':
        data.type = 'promotionClick';
        break;

      case 'promotionimpression':
        data.type = 'promotionImpression';
        eventType = 'impression';
        break;

      case 'addtocart':
        data.type = 'addToCart';
        break;

      case 'noninteractiveevent':
        data.type = 'nonInteractiveEvent';
        eventType = 'nievent';
        break;

      case 'conditionalevent':
        data.type = 'conditionalEvent';
        eventType = 'conditional';
        break;

      case 'socialintevent':
        data.type = 'socialIntEvent';
        break;

      default:
        data.type = 'userActionEvent';
        break;
    }

    handleEvent(eventType, el, data, modifiers);
  },
};
