<template>
  <div class="vtoCatalog">
    <VtoHeader class="-white -pointer" @click="catalogPageOrViewChange">
      <VtoHeaderArrow orientation="left" />
      <VtoHeaderLabel :text="dictionary.chooseYourProduct" />
    </VtoHeader>

    <ul ref="vtoTabs" class="vtoTabs scrollbarCustom">
      <li
        v-for="typeId of getProductCategoryIdsWithoutLooks"
        :key="typeId"
        :ref="typeId"
        class="vtoTab"
        :class="{
          '-active': typeId === selectedTypeId,
          '-selected': selectedProducts.hasOwnProperty(typeId),
        }"
        @click="
          selectType(typeId);
          vtoPushTaggingInfo();
        "
      >
        {{ productTypesMap[typeId].tabLabel }}
      </li>
    </ul>

    <div class="vtoLists">
      <div class="vtoLists__scroll scrollbarCustom">
        <div
          v-for="list of getLists"
          :key="list.title"
          class="vtoList"
          :class="[`-${list.layout}`]"
        >
          <p v-if="list.title" class="vtoList__title">{{ list.title }}</p>
          <p v-if="list.description" class="vtoList__description">{{ list.description }}</p>

          <VtoLines v-if="list.layout !== carouselLayout" :list="list" />
          <div v-else class="vtoLines__carouselWrapper scrollbarCustom">
            <VtoLines :list="list" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from '../store/helpers';

import { LIST_LAYOUT_CAROUSEL } from '../settings';

import VtoHeader from './VtoHeader.vue';
import VtoHeaderArrow from './VtoHeaderArrow.vue';
import VtoHeaderLabel from './VtoHeaderLabel.vue';
import VtoLines from './VtoLines.vue';

/* istanbul ignore next */
export default {
  name: 'VtoCatalog',

  components: {
    VtoHeader,
    VtoHeaderArrow,
    VtoHeaderLabel,
    VtoLines,
  },

  computed: {
    ...mapState(['dictionary', 'productTypesMap', 'selectedProducts', 'selectedTypeId']),

    ...mapGetters([
      'getCatalogCategory',
      'getCatalogMode',
      'getCatalogType',
      'getLists',
      'getProductCategoryIdsWithoutLooks',
    ]),

    carouselLayout() {
      return LIST_LAYOUT_CAROUSEL;
    },
  },

  watch: {
    selectedTypeId() {
      this.updateScrollTabsLeft();
    },
  },

  mounted() {
    this.updateScrollTabsLeft();
  },

  methods: {
    ...mapActions(['catalogPageOrViewChange', 'pushTaggingInfoSimple', 'selectType']),

    updateScrollTabsLeft() {
      const offset = 55;

      if (this.$refs[this.selectedTypeId] && this.$refs[this.selectedTypeId][0]) {
        const tabOffset = this.$refs[this.selectedTypeId][0].offsetLeft;
        this.$refs.vtoTabs.scrollLeft = tabOffset ? tabOffset - offset : 0;
      }
    },

    vtoPushTaggingInfo() {
      this.pushTaggingInfoSimple({
        action: 'select::product subcategory',
        event_name: 'select_product_subcategory',
        label: this.productTypesMap[this.selectedTypeId].name,
        event_detail: this.productTypesMap[this.selectedTypeId].name,
        service_name: 'virtualtryon',
        service_category: 'guide',
      });
    },
  },
};
</script>
