<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="vtoLines">
    <template v-for="(productIds, lineId) of list.productsPerLine">
      <div
        v-if="productIds.length"
        :key="lineId"
        class="vtoLine"
        :class="{ '-selected': isLineSelected(productIds, lineId) }"
        @click="onLineClick(productIds)"
      >
        <template v-for="starProductItem of [productLinesMap[lineId].starProductItem]">
          <div class="vtoLine__main">
            <div class="vtoLine__packshot">
              <MediaImage
                :key="`media-${lineId}`"
                :filename="starProductItem.image.fileName"
                :resolutions="starProductItem.image.resolutions"
                :fallback-size="starProductItem.image.fallbackSize"
                :sizes="starProductItem.image.sizes"
                :format="starProductItem.image.format"
                lazy
                aria-hidden
              />
            </div>

            <VtoLineVariants :product-ids="productIds" />

            <p class="vtoLine__title">{{ starProductItem.subCategory }}</p>
            <p class="vtoLine__name">{{ starProductItem.title }}</p>
          </div>

          <div class="vtoLine__footer">
            <VtoRating v-if="rating" :rating="starProductItem.rating" />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {
  CATEGORY_HAIR_COLOR,
  CATEGORY_MAKE_UP,
  TAGGING_INFO_PRODUCT_CLICK_TYPE,
} from '../settings';

import { mapActions, mapState, mapGetters } from '../store/helpers';

import MediaImage from '../../../../MediaImage/code/Scripts/components/MediaImage.vue';
import VtoLineVariants from './VtoLineVariants.vue';
import VtoRating from './VtoRating.vue';

export default {
  name: 'VtoLines',

  components: {
    MediaImage,
    VtoLineVariants,
    VtoRating,
  },

  props: {
    list: { type: Object, required: true },
  },

  computed: {
    ...mapState([
      'dictionary',
      'isMainCatalog',
      'mainCategory',
      'selectedProductId',
      'selectedProducts',
      'productLinesMap',
      'productItemsMap',
      'rating',
    ]),

    ...mapGetters(['getSelectedProductItem']),
  },

  methods: {
    ...mapActions(['pushTaggingInfo', 'pushTaggingInfoSimple', 'selectShade']),

    onLineClick(productIds) {
      this.selectShade(productIds[0]);
      this.vtoPushTaggingInfo();
    },

    isLineSelected(productIds, lineId) {
      const typeId = this.productLinesMap[lineId].productTypeId;
      return productIds.includes(this.selectedProducts[typeId]);
    },

    vtoPushTaggingInfo() {
      /* istanbul ignore else */
      if (this.isMainCatalog === true) {
        if (this.mainCategory === CATEGORY_MAKE_UP) {
          this.pushTaggingInfoSimple({
            action: 'select::product line',
            event_name: 'select_product_line',
            service_name: 'virtualtryon',
            service_category: 'guide',
            label: this.getSelectedProductItem.name,
            event_detail: this.getSelectedProductItem.name,
          });
        } else if (this.mainCategory === CATEGORY_HAIR_COLOR) {
          this.pushTaggingInfo({
            type: TAGGING_INFO_PRODUCT_CLICK_TYPE,
            list: null,
          });
        }
      }
    },
  },
};
</script>
