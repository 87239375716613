<template>
  <a
    :href="socialShareData.url"
    target="_blank"
    class="articleShare__link"
    @click.prevent="
      iconClickHandler(socialShareData.url, socialShareData.type, socialShareData.title)
    "
  >
    <img
      class="articleShare__icon lazyload"
      src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
      :srcset="socialShareData.iconSrc"
      :alt="socialShareData.iconAlt"
    />
    <template v-if="socialShareData.type === 'copyLink'">
      <span
        ref="copyTooltip"
        :class="{ '-show': showCopyTooltip }"
        class="articleShare__copyTooltip"
      >
        {{ copyTooltipText }}
      </span>
    </template>
  </a>
</template>

<script>
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

const SOCIAL_SHARERS = {
  facebook: {
    url: 'https://www.facebook.com/sharer/sharer.php',
    param: 'u',
  },
  twitter: {
    url: 'https://twitter.com/home',
    param: 'status',
  },
};

export default {
  name: 'ArticleShareItem',
  props: {
    socialShareData: {
      type: Object,
      required: true,
    },
    copyTooltipText: {
      type: String,
      default: 'Copied',
    },
  },
  data() {
    return {
      showCopyTooltip: false,
    };
  },
  methods: {
    triggerTooltip() {
      this.showCopyTooltip = true;
      setTimeout(() => {
        this.showCopyTooltip = false;
      }, 1500);
    },

    iconClickHandler(href, type, title) {
      this.pushAnalytics(type, title);

      if (type === 'copyLink') {
        this.triggerTooltip();
        this.copyURLToClipboard(window.location.href);
      } else if (type === 'email') {
        window.location.href = href || `mailto:?&body=${window.location.href}`;
      } else if (type === 'facebook' || type === 'twitter') {
        let url = new URL(SOCIAL_SHARERS[type].url);
        url.searchParams.append(SOCIAL_SHARERS[type].param, window.location.href);

        window.open(href || url.toString(), 'Share', 'menubar=0,resizable=0,width=500,height=500');
      }
    },
    isOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    copyURLToClipboard(url) {
      if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
        const textarea = document.createElement('textarea');
        textarea.textContent = url;
        textarea.style.fontSize = '16px';
        textarea.style.lineHeight = 0;
        textarea.style.height = '0px';
        textarea.style.opacity = 0;
        this.$refs.copyTooltip.appendChild(textarea);

        if (this.isOS()) {
          const range = document.createRange();
          range.selectNodeContents(textarea);
          const selection = window.getSelection();
          selection.removeAllRanges();
          selection.addRange(range);
          textarea.setSelectionRange(0, 999999);
        } else {
          textarea.select();
        }

        /* istanbul ignore next */
        try {
          return document.execCommand('copy');
        } catch (err) {
          console.warn('Copy to clipboard failed', err);
          return false;
        } finally {
          this.$refs.copyTooltip.removeChild(textarea);
        }
      }
    },
    pushAnalytics(type, title) {
      let tag = {
        type: 'userActionEvent',
        category: 'content page',
        action: `share::${type}`,
        label: title,
        event_name: 'social_interaction',
        article_name: title,
        social_network: type,
        social_action: 'share',
      };

      try {
        AnalyticsHandler.getAnalyticsHandler().push(tag);
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Could not push to dataLayer', tag);
      }
    },
  },
};
</script>
