import { debounce } from '../../utilities/timing';

export const collapsibleList = {
  name: 'collapsible-list',

  mounted: (el) => {
    const HIDDEN_CLASS = '-hidden';
    const OPENED_CLASS = '-opened';
    const state = { open: false };
    const items = Array.from(el.querySelectorAll('li'));
    const toggleButton = el.querySelector('[data-ref-v-collapsible-list-toggle]');
    const toggleContainer = items.pop();

    const render = () => {
      let lastLineTop;
      let lineTop = -1;
      let lineIndex = 0;
      const limit = 2;

      const adjust = (index) => {
        items[index].classList.add(HIDDEN_CLASS);

        return toggleContainer.offsetTop <= lastLineTop || adjust(index - 1);
      };

      toggleContainer.classList.add(HIDDEN_CLASS);

      items.forEach((item) => item.classList.toggle(HIDDEN_CLASS, !state.open));

      items.some((item, index) => {
        let itemTop;

        if (!state.open) {
          item.classList.remove(HIDDEN_CLASS);
        }
        itemTop = item.offsetTop;

        if (lineTop < itemTop) {
          lineIndex++;
          lastLineTop = lineTop;
          lineTop = itemTop;
        }

        if (lineIndex > limit) {
          toggleButton.classList.toggle(OPENED_CLASS, state.open);
          toggleContainer.classList.remove(HIDDEN_CLASS);

          return state.open || adjust(index);
        }
      });
    };

    toggleButton.addEventListener('click', () => {
      state.open = !state.open;
      toggleButton.setAttribute('aria-expanded', state.open);
      render();
    });

    render();

    window.addEventListener('resize', debounce(render, 200));
  },
};
