import { debounce } from '../../utilities/timing';

export const recommendedProducts = {
  // eslint-disable-line
  name: 'recommended-products',
  beforeMount: (el) => {
    const resizeTimer = 100;
    const timeoutTimer = 200;
    const maxWidthLimit = 580;
    const itemToBeObserved = el.querySelectorAll('.slider__content');

    const updateSliderPosition = () => {
      const sliderFirstTile = el.querySelector('.slider__item');
      const simplebarTrackHorizontal = el.querySelector('.simplebar-track.horizontal');

      const articleHeading = document.querySelector('.articleHeader__heading');

      let articleHeadingLeft =
        articleHeading.getBoundingClientRect().x || articleHeading.getBoundingClientRect().left;

      if (articleHeadingLeft > maxWidthLimit) {
        articleHeadingLeft = maxWidthLimit;
      }

      sliderFirstTile.style.marginLeft = `${articleHeadingLeft}px`;
      simplebarTrackHorizontal.style.transform = `translateX(${articleHeadingLeft}px)`;
    };

    setTimeout(() => {
      updateSliderPosition();
    }, timeoutTimer);

    window.addEventListener(
      'resize',
      debounce(() => {
        updateSliderPosition();
      }, resizeTimer)
    );

    const observer = new MutationObserver(() => {
      updateSliderPosition();
    });

    observer.observe(itemToBeObserved[1], { childList: true });
  },
};
