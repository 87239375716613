<template>
  <div ref="slider">
    <scroller data-simplebar :scale-factor="scaleFactor" @scrollX="onScroll">
      <slot></slot>
    </scroller>
  </div>
</template>

<script>
import anime from 'animejs';
import eventBus from '@loreal/eventbus-js';
import { MqHandler } from '../../../../../Foundation/Core/code/Scripts/mqHandler';
import scroller from '../../../../../Foundation/Core/code/Scripts/scroller/scroller.vue';

export default {
  name: 'Slider',
  components: {
    scroller,
  },
  props: {
    activate: { type: Boolean, required: false, default: false },
    scaleFactor: { type: Number, required: false, default: 0.35 },
    isFadedOut: { type: Boolean, required: false, default: false },
    forceSmall: { type: Boolean, required: false, default: false },
    parentComponent: { type: String, required: false, default: '' },
  },
  data() {
    return {
      faded: false,
      scrollTimeout: null,
      slides: false,
      smallScreen: MqHandler.getMqHandler().getMqForSize('small').selected,
      positions: [],
      windowWidth: false,
      slideWidth: false,
      closest: false,
      widthDivider: 2,
      nodeToActivate: false,
      currentIndex: 0,
      scrollDistance: 80,
      scrollDistanceSmall: 30,
      scrollableContent: null,
      uniqueId: `${Date.now()}__${Math.random() * 10000}-slider`,
      shouldEmitScrollEvent: false,
    };
  },
  computed: {
    behaveAsSmallScreen() {
      return this.forceSmall || this.smallScreen;
    },
  },
  watch: {
    faded() {
      if (this.faded) {
        if (this.$el.parentElement.previousElementSibling) {
          if (this.behaveAsSmallScreen) {
            this.$el.parentElement.classList.add('isArrowFadedOut');
          }
          this.$el.parentElement.previousElementSibling.classList.add('isFadedOut');

          if (this.$el.parentElement.nextElementSibling) {
            this.$el.parentElement.nextElementSibling.classList.add('isFadedOut');
          }
        }
      } else if (this.$el.parentElement.previousElementSibling) {
        if (this.behaveAsSmallScreen) {
          this.$el.parentElement.classList.remove('isArrowFadedOut');
        }
        this.$el.parentElement.previousElementSibling.classList.remove('isFadedOut');

        if (this.$el.parentElement.nextElementSibling) {
          this.$el.parentElement.nextElementSibling.classList.remove('isFadedOut');
        }
      }
    },
    currentIndex(newValue, oldValue) {
      if (newValue !== oldValue) {
        eventBus.emit(`${this.parentComponent}::childMoved`, {
          newIndex: this.currentIndex,
          trigger: this.uniqueId,
        });
      }
    },
  },
  mounted() {
    this.slides = this.$refs.slider.querySelectorAll('.slider__item');
    this.slidesAnchors = this.$refs.slider.querySelectorAll('.slider__item a');
    this.setAriaHidden();

    eventBus.on('mediaquery::changed', (mq) => {
      this.smallScreen = mq.size === 'small';
    });

    window.addEventListener('resize', this.handleWindowResize);

    document.addEventListener('sliderGoTo', (e) => {
      this.goTo(e.detail);
    });

    this.scrollableContent = this.$refs.slider.querySelector('.simplebar-content');

    if (this.parentComponent !== '') {
      eventBus.on(`${this.parentComponent}::gotoPage`, ({ index, element }) => {
        this.moveSliderTo(index, element);
      });
    }
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    handleWindowResize() {
      this.moveSliderTo(this.currentIndex, '.slider__item');
    },
    moveSliderTo(index, elem) {
      const element = this.$refs.slider.querySelector(elem);

      if (element) {
        const elementWidth = element.getBoundingClientRect().width;
        this.goTo(elementWidth * index);
      }
    },
    onScroll() {
      if (this.ignoreListener) {
        return;
      }

      this.scrollLeft = this.scrollableContent.scrollLeft;

      if (this.scrollLeft < 20 && this.behaveAsSmallScreen && this.isFadedOut) {
        this.faded = false;
      } else if (
        this.scrollLeft > this.scrollDistanceSmall &&
        this.behaveAsSmallScreen &&
        this.isFadedOut
      ) {
        this.faded = true;
      }

      if (this.scrollLeft <= 15 && !this.behaveAsSmallScreen) {
        this.faded = false;
      } else if (this.scrollLeft > this.scrollDistance && !this.behaveAsSmallScreen) {
        this.faded = true;
      }

      if (this.scrollTimeout === null) {
        this.findActive();
      } else {
        clearTimeout(this.scrollTimeout);
      }

      this.scrollTimeout = setTimeout(this.findActive, 100);
    },

    findActive(animate = true) {
      if (!this.slides[0]) {
        return;
      }

      if (this.stopAnimating) {
        return;
      }

      const getClosestInArray = (array, num) => {
        let index = 0;
        let minDiff = 1000;
        let result;

        for (index in array) {
          if (Object.prototype.hasOwnProperty.call(array, index)) {
            const match = Math.abs(num - array[index]);

            if (match < minDiff) {
              minDiff = match;
              result = array[index];
            }
          }
        }

        return result;
      };

      this.positions = [].slice
        .call(this.slides)
        .map((slide) => slide.getBoundingClientRect().left);
      this.windowWidth = window.innerWidth;
      this.slideWidth = this.slides[0].clientWidth;
      this.closest = getClosestInArray(this.positions, this.slideWidth / this.widthDivider);

      //avoid slide update if closest not found
      if (this.closest) {
        this.currentIndex = this.positions.indexOf(this.closest);
        this.nodeToActivate = this.slides[this.positions.indexOf(this.closest)];

        this.handleSmallScreenAnimation(animate);
      }
    },

    handleSmallScreenAnimation(animate) {
      if (this.behaveAsSmallScreen && this.activate) {
        this.setActive();
        this.animateIfClosest(animate);
      }
    },

    animateIfClosest(animate) {
      if (this.positions.indexOf(this.closest) !== 0 && animate) {
        this.animateTo();
      }
    },

    setActive() {
      Array.from(this.slides).forEach((slide) => {
        slide.classList.remove('-active');
      });
      if (!this.nodeToActivate.classList.contains('-active')) {
        this.nodeToActivate.classList.add('-active');
      }

      this.setAriaHidden();
    },

    setAriaHidden() {
      const ariaHiddenAttribute = 'aria-hidden';
      const slides = Array.from(this.slides) || [];
      const slidesAnchorTag = Array.from(this.slidesAnchors) || [];

      slides.forEach((slide) => slide.setAttribute(ariaHiddenAttribute, 'true'));
      if (slidesAnchorTag) {
        slidesAnchorTag.forEach((slidesAnchor) => slidesAnchor.setAttribute('tabindex', '-1'));
      }

      if (this.nodeToActivate) {
        this.nodeToActivate.removeAttribute(ariaHiddenAttribute);
        const activeAnchortags = this.nodeToActivate.querySelectorAll('a');
        if (activeAnchortags) {
          activeAnchortags.forEach((activeAnchortag) =>
            activeAnchortag.removeAttribute('tabindex')
          );
        }
      } else {
        slides[0] && slides[0].removeAttribute(ariaHiddenAttribute);
        const firstslides = slides[0] ? slides[0].querySelectorAll('a') : '';
        if (firstslides) {
          firstslides.forEach((firstslide) => firstslide.removeAttribute('tabindex'));
        }
      }
    },

    goTo(to) {
      this.scrollableContent.scrollLeft = to;
      this.scrollLeft = to;
      setTimeout(this.findActive, 300);
    },

    animateTo() {
      if (this.stopAnimating) {
        return;
      }

      this.stopAnimating = true;
      anime({
        targets: this.scrollableContent,
        scrollLeft: this.scrollLeft + this.closest - (this.windowWidth - this.slideWidth) / 2,
        duration: 200,
        easing: 'easeInSine',
        complete: () => {
          this.scrollLeft = this.scrollableContent.scrollLeft;
          this.stopAnimating = false;
        },
      });
    },
  },
};
</script>
