<template>
  <p class="vtoHeaderDescription">{{ text }}</p>
</template>

<script>
export default {
  name: 'VtoHeaderDescription',
  props: {
    text: { type: String, required: true },
  },
};
</script>
