import eventBus from '@loreal/eventbus-js';

export const resizeListener = {
  name: 'resize-listener',
  beforeMount: (el, { value }) => {
    const resizeObserver = new ResizeObserver(() => {
      eventBus.emit(value.eventName, value.payload);
    });

    resizeObserver.observe(el);
  },
};
