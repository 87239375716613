<template>
  <div :class="['oap-popup-wrapper', { 'oap-popup-wrapper--open': !hide }]">
    <div class="oap-popup-overlay" @click="$emit('closed', true)" />
    <transition name="slide">
      <div
        v-if="!hide"
        ref="oapPopup"
        :class="['oap-popup', { 'oap-popup--aside': aside }]"
        role="dialog"
        aria-modal="true"
        aria-labelledby="oap-popup-description"
      >
        <header id="oap-popup-description">
          <slot v-if="$slots.heading" name="heading" />
          <span v-else class="isSrOnly">
            {{ title }}
          </span>
        </header>
        <slot></slot>
        <slot name="close" />
        <h2 id="oap-popup-title" class="isSrOnly" v-text="title" />
        <p id="oap-popup-description" class="isSrOnly" v-text="description" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'OapPopup',

  props: {
    description: {
      default: '',
      type: String,
    },
    open: {
      type: Boolean,
      required: true,
    },
    showClose: {
      type: Boolean,
      required: false,
    },
    hideOverflow: {
      type: Boolean,
      required: false,
      default: true,
    },
    aside: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      lastFocused: null,
      triggerBtn: null,
      hide: true,
    };
  },

  watch: {
    open: function (opened) {
      this.removeEvents();
      this.handleOpenModal(opened);
      this.handleCloseModal(opened);
    },
  },

  mounted() {
    this.hide = !this.open;
  },

  methods: {
    handleOpenModal(open) {
      if (!open) {
        return;
      }

      this.hide = false;
      this.triggerBtn = document.activeElement;
      this.setOverflow('hidden');
      this.addEvents();
      this.focusFirstElement();
    },

    handleCloseModal(open) {
      if (open) {
        return;
      }

      this.setOverflow('inherit');
      this.hide = true;

      if (this.triggerBtn) {
        this.triggerBtn.focus();
        this.removeEvents();
      }
    },

    addEvents() {
      document.addEventListener('focusin', this.changeFocus);
      document.addEventListener('keyup', this.closeModal);
    },

    removeEvents() {
      document.removeEventListener('focusin', this.changeFocus);
      document.removeEventListener('keyup', this.closeModal);
    },

    focusFirstElement() {
      /* istanbul ignore next */
      this.$nextTick(() => {
        const focusable = this.getFocusableElements(this.$refs.oapPopup);
        return focusable[0] ? focusable[0].focus() : null;
      });
    },

    closeModal(e) {
      if (e.key !== 'Escape') {
        return;
      }

      this.$emit('closed', true);
      this.setOverflow('inherit');
    },

    setOverflow(overflow) {
      return overflow === 'hidden'
        ? document.body.classList.add('noScroll')
        : document.body.classList.remove('noScroll');
    },

    changeFocus() {
      let newFocused = document.activeElement;
      /* istanbul ignore next */
      if (newFocused !== this.lastFocused || !newFocused) {
        const focusable = this.getFocusableElements(this.$refs.oapPopup);
        newFocused = this.getNewFocusable(focusable, newFocused);
        this.$nextTick(() => {
          this.lastFocused = newFocused;
          newFocused.focus();
        });
      }
    },

    getNewFocusable(focusable, newFocused) {
      if (focusable.indexOf(newFocused) > -1) {
        return newFocused;
      }
      return this.lastFocused === focusable[0] ? focusable[focusable.length - 1] : focusable[0];
    },

    getFocusableElements(element) {
      if (!element) {
        return;
      }
      /* istanbul ignore next */
      return [].slice.call(
        element.querySelectorAll(
          `button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])`
        )
      );
    },
  },
};
</script>
