<template>
  <div :class="['oap-video-popin videoComponent', { '-landscape': landscape }]">
    <modal
      :modalidentifier="playerId"
      :class="['videoComponent__modal', { 'videoComponent__modal--has-transcript': hasTranscript }]"
    >
      <div class="embedResponsive--16by9">
        <div v-if="videoId !== ''" :id="playerId" class="embedResponsive__item"></div>
      </div>
      <slot name="transcript"></slot>
    </modal>
  </div>
</template>

<script>
import { YOUTUBE_SCRIPT_NAME, YOUTUBE_SCRIPT_URL } from './settings';
import { loadscript } from '@Foundation/directives/loadscript/loadscript';
import eventBus from '@loreal/eventbus-js';
import { mixinSetupYouTube } from './mixins/setupYouTube';
import { mixinTryPlayVideo } from './mixins/tryPlayVideo';
import { mixinToggleVideoPopin } from './mixins/toggleVideoPopin';
import { mixinSetupPlayerAndBindToEvents } from './mixins/setupPlayerAndBindToEvents';
import modal from '../../../../Modal/code/Scripts/components/modal.vue';
import { HERO_VIDEO_BACKGROUND_PLAYING } from '@Feature/Filter/code/Scripts/constants/eventNames';

export default {
  name: 'OapVideoPopin',

  components: {
    modal,
  },

  mixins: [
    mixinSetupPlayerAndBindToEvents,
    mixinSetupYouTube,
    mixinToggleVideoPopin,
    mixinTryPlayVideo,
  ],

  props: {
    videoId: { type: String, default: '' },
    host: { type: String, default: 'https://www.youtube.com' },
    hasTranscript: { type: Boolean, default: false },
    isInformative: { type: Boolean, default: false },
    videoTitle: { type: String, default: '' },
    autoOpen: { type: Boolean, default: false },
    youtubeExtraParams: { type: Object, default: () => {} },
    landscape: { type: Boolean, default: false },
    popinId: { type: String, default: '' },
  },

  data() {
    return {
      playerId: `player-${this.videoId}-${Date.now()}`,
      player: null,
      isPlayerSet: false,
    };
  },

  computed: {
    eventIdModifier() {
      return this.popinId ? `:${this.popinId}` : '';
    },
  },

  created() {
    // fix autoplay YT video when data property has value -1
    // when the player first loads a video, it will broadcast an unstarted (-1)
    eventBus.on('youtube::state-change', (event) => {
      if (event.data === -1 && this.youtubeExtraParams?.autoplay) {
        this.player.playVideo();
      }
    });
  },

  mounted() {
    /* istanbul ignore else */
    if (this.videoId !== '') {
      /* istanbul ignore else */
      if (this.autoOpen) {
        this.setupPlayerAndToggleVideoPopin();
      }

      this.listenToOpenEvent();
    }

    /* istanbul ignore next */
    eventBus.on('modal:closed', (modalId) => {
      if (modalId === this.playerId) {
        this.player.destroy();
        eventBus.emit('livestream::play');
      }
      eventBus.emit(HERO_VIDEO_BACKGROUND_PLAYING, true);
    });
  },

  methods: {
    setupPlayerAndToggleVideoPopin() {
      if (!this.player) {
        this.loadYouTubeScript();
      }

      this.toggleVideoPopin(this.youtubeExtraParams);
    },

    loadYouTubeScript() {
      loadscript.beforeMount(null, {
        value: {
          name: YOUTUBE_SCRIPT_NAME,
          url: YOUTUBE_SCRIPT_URL,
        },
      });
    },

    listenToOpenEvent() {
      /* istanbul ignore next */
      eventBus.on(`modal:video-popin:open${this.eventIdModifier}`, () => {
        this.setupPlayerAndToggleVideoPopin();
        eventBus.emit('livestream::pause');
        eventBus.emit(HERO_VIDEO_BACKGROUND_PLAYING, false);
      });
    },
  },
};
</script>
