/* esint-disable */
import scrollbarWidth from 'scrollbarwidth';
import { throttle } from '../utilities/timing';
// TODO: remove and replace with native browser API
import ResizeObserver from 'resize-observer-polyfill';

export default class SimpleBar {
  constructor(element, options) {
    this.el = element;
    this.flashTimeout;
    this.contentEl;
    this.scrollContentEl;
    this.dragOffset = { x: 0, y: 0 };
    this.isEnabled = { x: true, y: true };
    this.isVisible = { x: false, y: false };
    this.scrollOffsetAttr = { x: 'scrollLeft', y: 'scrollTop' };
    this.sizeAttr = { x: 'offsetWidth', y: 'offsetHeight' };
    this.scrollSizeAttr = { x: 'scrollWidth', y: 'scrollHeight' };
    this.offsetAttr = { x: 'left', y: 'top' };
    this.globalObserver;
    this.mutationObserver;
    this.resizeObserver;
    this.currentAxis;
    this.scrollbarWidth;
    this.options = Object.assign({}, SimpleBar.defaultOptions, options);
    this.isRtl = this.options.direction === 'rtl';
    this.classNames = this.options.classNames;
    this.offsetSize = 20;
    this.parentNodeWidth = null;
    this.parentNodeParentId = 'selectedFilters__wrapper';
    this.isVTO = options.isVTO;
    this.recalculate = throttle(this.recalculate.bind(this), 1000);

    this.init();
  }

  static get defaultOptions() {
    return {
      autoHide: false,
      classNames: {
        content: 'simplebar-content',
        scrollContent: 'simplebar-scroll-content',
        scrollbar: 'simplebar-scrollbar',
        track: 'simplebar-track'
      },
      direction: 'ltr',
      timeout: 1000,
      scaleFactor: 1,
      parentNode: null
    };
  }

  init() {
    // Save a reference to the instance, so we know this DOM node has already been instancied
    this.el.SimpleBar = this;

    this.initDOM();

    // Calculate content size
    this.hideNativeScrollbar();
    this.render();

    this.initListeners();
  }

  initDOM() {
    // Prepare DOM
    this.scrollContentEl = document.createElement('div');
    this.contentEl = document.createElement('div');

    this.scrollContentEl.classList.add(this.classNames.scrollContent);
    this.contentEl.classList.add(this.classNames.content);

    while (this.el.firstChild) {
      this.contentEl.appendChild(this.el.firstChild);
    }

    this.scrollContentEl.appendChild(this.contentEl);
    this.el.appendChild(this.scrollContentEl);

    if (!this.trackX || !this.trackY) {
      const track = document.createElement('div');
      const scrollbar = document.createElement('div');

      track.classList.add(this.classNames.track);
      scrollbar.classList.add(this.classNames.scrollbar);

      if (!this.options.autoHide) {
        scrollbar.classList.add('visible');
      }

      track.appendChild(scrollbar);

      this.trackX = track.cloneNode(true);
      this.trackX.classList.add('horizontal');

      this.trackY = track.cloneNode(true);
      this.trackY.classList.add('vertical');

      this.el.insertBefore(this.trackX, this.el.firstChild);
      this.el.insertBefore(this.trackY, this.el.firstChild);
    }

    this.scrollbarX = this.trackX.querySelector(`.${this.classNames.scrollbar}`);
    this.scrollbarY = this.trackY.querySelector(`.${this.classNames.scrollbar}`);

    this.updateTrackSize(true);

    this.el.setAttribute('data-simplebar', 'init');
  }

  initListeners() {
    // Event listeners
    if (this.options.autoHide) {
      this.el.addEventListener('mouseenter', this.onMouseEnter);
    }

    if (this.options.parentNode !== null) {
      this.parentNodeWidth = this.options.parentNode.$el.offsetWidth
    }

    this.el.addEventListener('mousedown', this.onMouseDown);

    this.contentEl.addEventListener('scroll', this.onScrollX);
    this.scrollContentEl.addEventListener('scroll', this.onScrollY);

    // Browser zoom triggers a window resize
    window.addEventListener('resize', this.onWindowResize);

    // MutationObserver is IE11+
    if (typeof MutationObserver !== 'undefined') {
      // create an observer instance
      this.mutationObserver = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (this.isChildNode(mutation.target) || mutation.addedNodes.length) {
            this.recalculate();
          }
        });
      });

      // pass in the target node, as well as the observer options
      this.mutationObserver.observe(this.el, {
        attributes: true,
        childList: true,
        characterData: true,
        subtree: true
      });
    }

    this.resizeObserver = new ResizeObserver(this.recalculate);
    this.resizeObserver.observe(this.el);
  }

  /**
   * Recalculate scrollbar
   */
  recalculate() {
    this.render();
  }

  render(withScaleFactor = false) {
    this.updateTrackSize(withScaleFactor);

    this.contentSizeX = this.contentEl[this.scrollSizeAttr['x']];
    this.contentSizeY = this.contentEl[this.scrollSizeAttr['y']] - (this.scrollbarWidth || this.offsetSize);

    this.trackXSize = this.trackX[this.sizeAttr['x']] > 0 ? this.trackX[this.sizeAttr['x']] : this.scrollContentEl[this.sizeAttr['x']];
    this.trackYSize = this.trackY[this.sizeAttr['y']];

    // Set isEnabled to false if scrollbar is not necessary (content is shorter than wrapper)
    this.getEnabled('x');
    //this.getEnabled('y');

    this.resizeScrollbar('x');
    //this.resizeScrollbar('y');

    this.positionScrollbar('x');
    //this.positionScrollbar('y');

    this.toggleTrackVisibility('x');
    //this.toggleTrackVisibility('y');
  }

  updateTrackSize(withScaleFactor) {
    const trackXSize = (this.trackX[this.sizeAttr['x']] > 0) ? this.trackX[this.sizeAttr['x']]: this.scrollContentEl[this.sizeAttr['x']];
    if (withScaleFactor) {
      this.trackX.style.width = `${trackXSize * this.options.scaleFactor}px`;
    }
    else {
      this.trackX.style.width =  `${trackXSize}px`;
    }
  }

  /**
   * Resize scrollbar
   */
  resizeScrollbar(axis = 'y') {
    let scrollbar;
    let contentSize;
    let trackSize;

    // if (!this.isEnabled[axis]) {
    //   return;
    // }

    if (axis === 'x') {
      scrollbar = this.scrollbarX;
      contentSize = this.contentSizeX;
      trackSize = this.trackXSize;
    } else {
      // 'y'
      scrollbar = this.scrollbarY;
      contentSize = this.contentSizeY;
      trackSize = this.trackYSize;
    }
    
    let scrollbarRatio = trackSize / (contentSize * this.options.scaleFactor);
    this.handleSize = scrollbarRatio * 100;
    if (axis === 'x') {  
      let scrolWidth =   this.handleSize;  
      scrollbar.style.width = `${scrolWidth}%`;
    } else {
      scrollbar.style.height = `${this.handleSize}%`;
    }
  }

  getEnabled(axis = 'y') {
    // Be careful: this is a hack for Selected Filters component
    if (this.options.parentNode.$parent.$el.id === this.parentNodeParentId) {
      const parentNodeUpdatedWidth = this.options.parentNode.$el.offsetWidth - 6;
      if (axis === 'x' && (this.parentNodeWidth < parentNodeUpdatedWidth)) {
        this.parentNodeWidth = parentNodeUpdatedWidth;
        this.trackXSize = parentNodeUpdatedWidth;
        this.isEnabled['x'] = true;
      }
    }

    if (axis === 'x' && this.options.parentNode.$parent.$el.id !== this.parentNodeParentId) {
      this.isEnabled['x'] = this.trackXSize < this.contentSizeX;
    }    

    if (axis === 'y') {
      this.isEnabled['y'] = this.trackYSize < this.contentSizeY;
    }
    let checkScrollSize = (this.isVTO && (this.contentSizeX > 377)) ? 150  : 98;
    if (this.handleSize >= checkScrollSize) {
      this.isEnabled['x'] = false;
      this.isEnabled['y'] = false;
    }
  }

  positionScrollbar(axis = 'y') {
    let scrollbar;
    let scrollOffset;
    let contentSize;
    let trackSize;

    if (axis === 'x') {
      scrollbar = this.scrollbarX;
      scrollOffset = this.contentEl[this.scrollOffsetAttr[axis]]; // Either scrollTop() or scrollLeft().
      contentSize = this.contentSizeX;
      trackSize = this.trackXSize;
    } else {
      // 'y'
      scrollbar = this.scrollbarY;
      scrollOffset = this.scrollContentEl[this.scrollOffsetAttr[axis]]; // Either scrollTop() or scrollLeft().
      contentSize = this.contentSizeY;
      trackSize = this.trackYSize;
    }
    const handleSizePx = (this.handleSize * trackSize) / 100
    let scrollPercent = scrollOffset / (contentSize - (trackSize/this.options.scaleFactor));
    let handleOffset = ~~((trackSize - handleSizePx) * scrollPercent);

    if (this.isEnabled[axis]) {
      if (axis === 'x') {
        scrollbar.style.transform = `translate3d(${handleOffset}px, 0, 0)`;
      } else {
        scrollbar.style.transform = `translate3d(0, ${handleOffset}px, 0)`;
      }
    }
  }

  toggleTrackVisibility(axis = 'y') {
    let track = axis === 'y' ? this.trackY : this.trackX;
    if (this.isEnabled[axis]) {
      track.style.visibility = 'visible';
      this.el.classList.add('scrollBar--visible');
    } else {
      track.style.visibility = 'hidden';
      this.el.classList.remove('scrollBar--visible');
    }
  }

  hideNativeScrollbar() {
    // Recalculate scrollbarWidth in case it's a zoom
    this.scrollbarWidth = scrollbarWidth();

    this.scrollContentEl.style[this.isRtl ? 'paddingLeft' : 'paddingRight'] = `${this.scrollbarWidth || this.offsetSize}px`;
    this.scrollContentEl.style.marginBottom = `-${this.scrollbarWidth * 2 || this.offsetSize}px`;
    this.contentEl.style.paddingBottom = `${this.scrollbarWidth || this.offsetSize}px`;

    if (this.scrollbarWidth !== 0) {
      this.contentEl.style[this.isRtl ? 'marginLeft' : 'marginRight'] = `-${this.scrollbarWidth}px`;
    }
  }

  /**
   * On scroll event handling
   */
  onScrollX = () => {
    if (!this.scrollXTicking) {
      window.requestAnimationFrame(this.scrollX);
      this.scrollXTicking = true;
    }
  }

  onScrollY = () => {
    if (!this.scrollYTicking) {
      window.requestAnimationFrame(this.scrollY);
      this.scrollYTicking = true;
    }
  }

  scrollX = () => {
    this.showScrollbar('x');
    this.positionScrollbar('x');
    this.scrollXTicking = false;
  }

  scrollY = () => {
    this.showScrollbar('y');
    this.positionScrollbar('y');
    this.scrollYTicking = false;
  }

  /**
   * On mouseenter event handling
   */
  onMouseEnter = () => {
    this.showScrollbar('x');
    this.showScrollbar('y');
  }

  onWindowResize = () => {
    this.hideNativeScrollbar();
  }

  /**
   * Show scrollbar
   */
  showScrollbar(axis = 'y') {
    let scrollbar;

    // Scrollbar already visible
    if (this.isVisible[axis]) {
      return;
    }

    if (axis === 'x') {
      scrollbar = this.scrollbarX;
    } else {
      // 'y'
      scrollbar = this.scrollbarY;
    }

    if (this.isEnabled[axis]) {
      scrollbar.classList.add('visible');
      this.isVisible[axis] = true;
    }

    if (!this.options.autoHide) {
      return;
    }

    this.flashTimeout = window.setTimeout(this.hideScrollbars, this.options.timeout);
  }

  /**
   * Hide Scrollbar
   */
  hideScrollbars = () => {
    this.scrollbarX.classList.remove('visible');
    this.scrollbarY.classList.remove('visible');

    this.isVisible.x = false;
    this.isVisible.y = false;

    window.clearTimeout(this.flashTimeout);
  }

  onMouseDown = (e) => {
    const draggerY = this.scrollbarY.getBoundingClientRect();
    const draggerX = this.scrollbarX.getBoundingClientRect();
    const paddingForScroll = 30;

    if (e.pageX >= draggerY.left && e.clientX <= draggerY.left + draggerY.width && e.clientY >= draggerY.top && e.clientY <= draggerY.top + draggerY.height) {
      e.preventDefault();
      this.onDrag(e, 'y');
    }

    if (e.pageX >= draggerX.left &&
        e.clientX <= draggerX.left + draggerX.width &&
        e.clientY >= draggerX.top - paddingForScroll &&
        e.clientY <= draggerX.top + draggerX.height + paddingForScroll) {
      e.preventDefault();
      this.onDrag(e, 'x');
    }
  }

  /**
   * on scrollbar handle drag
   */
  onDrag(e, axis = 'y') {
    // Preventing the event's default action stops text being
    // selectable during the drag.
    e.preventDefault();

    const scrollbar = axis === 'y' ? this.scrollbarY : this.scrollbarX;

    // Measure how far the user's mouse is from the top of the scrollbar drag handle.
    const eventOffset = axis === 'y' ? e.pageY : e.pageX;

    this.dragOffset[axis] = eventOffset - scrollbar.getBoundingClientRect()[this.offsetAttr[axis]];
    this.currentAxis = axis;

    this.el.style.cursor = 'grabbing';
    this.el.style.cursor = '-moz-grabbing'
    this.el.style.cursor = '-webkit-grabbing'

    document.addEventListener('mousemove', this.drag);
    document.addEventListener('mouseup', this.onEndDrag);
  }

  /**
   * Drag scrollbar handle
   */
  drag = (e) => {
    let eventOffset, track, scrollEl;

    e.preventDefault();

    if (this.currentAxis === 'y') {
      eventOffset = e.pageY;
      track = this.trackY;
      scrollEl = this.scrollContentEl;
    } else {
      eventOffset = e.pageX;
      track = this.trackX;
      scrollEl = this.contentEl;
    }

    // Calculate how far the user's mouse is from the top/left of the scrollbar (minus the dragOffset).
    let dragPos = eventOffset - track.getBoundingClientRect()[this.offsetAttr[this.currentAxis]] - this.dragOffset[this.currentAxis];

    // Convert the mouse position into a percentage of the scrollbar height/width.
    let dragPerc = dragPos / track[this.sizeAttr[this.currentAxis]];

    // Scroll the content by the same percentage.
    let scrollPos = dragPerc * this.contentEl[this.scrollSizeAttr[this.currentAxis]];

    scrollEl[this.scrollOffsetAttr[this.currentAxis]] = scrollPos;
  }

  /**
   * End scroll handle drag
   */
  onEndDrag = () => {
    this.el.style.cursor = "default";

    document.removeEventListener('mousemove', this.drag);
    document.removeEventListener('mouseup', this.onEndDrag);
  }

  /**
   * Getter for original scrolling element
   */
  getScrollElement(axis = 'y') {
    return axis === 'y' ? this.scrollContentEl : this.contentEl;
  }

  /**
   * Getter for content element
   */
  getContentElement() {
    return this.contentEl;
  }

  removeListeners() {
    // Event listeners
    if (this.options.autoHide) {
      this.el.removeEventListener('mouseenter', this.onMouseEnter);
    }

    this.scrollContentEl.removeEventListener('scroll', this.onScrollY);
    this.contentEl.removeEventListener('scroll', this.onScrollX);

    this.mutationObserver.disconnect();
    this.resizeObserver.disconnect();
  }

  /**
   * UnMount mutation observer and delete SimpleBar instance from DOM element
   */
  unMount() {
    this.removeListeners();
    this.el.SimpleBar = null;
  }

  /**
   * Recursively walks up the parent nodes looking for this.el
   */
  isChildNode(el) {
    if (el === null) return false;
    if (el === this.el) return true;

    return this.isChildNode(el.parentNode);
  }
}
