<template>
  <section ref="trigger" class="oap-immersive-copy-on-video">
    <video ref="video" src="" preload="auto" autoplay playsinline muted loop></video>
    <h2
      ref="copyTop"
      class="oap-immersive-copy-on-video__copy--top"
      :style="`color: ${copyTopColor}`"
      v-html="copyTop"
    ></h2>
    <h2
      ref="toAnimate"
      class="oap-immersive-copy-on-video__copy--bottom"
      :style="`color: ${copyBottomColor}`"
      v-html="copyBottom"
    ></h2>
  </section>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { isSafari } from '@Foundation';

const largeScreenStart = 481; // same as in oap-video-immersive.vue

const videoTypes = {
  WEBM: 'video/webm',
  MP4: 'video/mp4',
};

export default {
  name: 'OapImmersiveCopyOnVideo',
  props: {
    sources: { type: Object, required: true },
    copyTop: { type: String, default: '' },
    copyTopColor: { type: String, default: '#000000' },
    copyBottom: { type: String, default: '' },
    copyBottomColor: { type: String, default: '#000000' },

    // scrollTrigger settings
    scrub: { type: Boolean, default: true },
    pin: { type: Boolean, default: true },
    start: { type: String, default: 'top top' },
    end: { type: String, default: '+=50%' },
  },
  computed: {
    videoSources() {
      return window.innerWidth < largeScreenStart ? this.sources.sm : this.sources.lg;
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    this.assignVideoSrc();

    setTimeout(this.bindAnimation, 0);
  },
  methods: {
    assignVideoSrc() {
      // to have a more shorthand name - let's just assign a ref to a variable
      const video = this.$refs.video;

      // before downloading the video
      // check what video type is supported by the browser
      const supportedVideoType = video.canPlayType(videoTypes.WEBM)
        ? videoTypes.WEBM
        : videoTypes.MP4;

      // Safari doesn't play well with WebM, so we need to use MP4 instead
      let videoSource = isSafari
        ? this.videoSources.find((source) => source.type === videoTypes.MP4)
        : this.videoSources.find((source) => source.type === supportedVideoType);

      video.src = videoSource.url;
      video.type = videoSource.type;
    },
    bindAnimation() {
      // these values are needed to calculate the final bottom copy position
      const copyTop = document.querySelector(`.${this.$refs.copyTop.className}`);
      const copyBottomOffsetHeight = this.$refs.toAnimate.offsetHeight;
      const copyBottomInitialBottomPosition = 150;

      gsap.to(`.${this.$refs.toAnimate.className}`, {
        scrollTrigger: {
          trigger: this.$refs.trigger,
          scrub: this.scrub,
          pin: this.pin,
          start: this.start,
          end: this.end,
        },
        y: `-${
          this.$refs.video.offsetHeight -
          (copyTop.offsetTop +
            copyTop.offsetHeight +
            copyBottomOffsetHeight +
            copyBottomInitialBottomPosition)
        }`,
      });
    },
  },
};
</script>
