<template>
  <div class="vtoHotSwap vtoHotSwap--looks">
    <VtoHeader class="-white -pointer" @click="onHeaderClick">
      <VtoHeaderArrow orientation="left" />
      <VtoHeaderDescription :text="headerDescriptionText" />
      <VtoHeaderLabel :text="headerLabelText" />
    </VtoHeader>

    <ul class="vtoHotSwap__rack scrollbarCustom">
      <li class="vtoHotSwap__row vtoHotSwap__row--look">
        <div class="vtoHotSwap__row-placeholder">
          <img alt="" :src="imagePlaceholder" />
        </div>

        <div class="vtoHotSwap__row-copy">
          <p class="vtoHotSwap__look-name">{{ headerLabelText }}</p>
          <p class="vtoHotSwap__look-label">{{ dictionary.productsBehindThisLook }}</p>
        </div>

        <VtoHotswapSave icon="#icon-favorite-white" />

        <button
          class="vtoHotSwap__remove"
          :class="{ '-current': showRemoveLabel }"
          @click="toggleShowRemoveLabel"
        >
          <transition name="slideIn">
            <span
              v-if="showRemoveLabel"
              class="vtoHotSwap__removeLabel"
              @click="onRemoveLabelClick"
            >
              {{ dictionary.removeLook }}
            </span>
          </transition>

          <svg class="icon">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-remove-white" />
          </svg>
        </button>
      </li>
      <li v-for="product in getSelectedLookProducts" class="vtoHotSwap__row">
        <div :key="product.id" class="vtoHotSwap__selectedProduct">
          <VtoProduct :product="product" />
          <VtoActions
            class="oap-button -primary -theme-brand vtoHotSwap__buy-button"
            :buy-label="dictionary.buy"
            :selected-product="product"
          />
        </div>
      </li>
      <li
        v-if="showRemoveLabel"
        class="vtoHotSwap__rackOverlay"
        @click="toggleShowRemoveLabel"
      ></li>
    </ul>

    <VtoFloatingBar />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from '../store/helpers';

import VtoActions from './VtoActions.vue';
import VtoFloatingBar from './VtoFloatingBar.vue';
import VtoHeader from './VtoHeader.vue';
import VtoHeaderArrow from './VtoHeaderArrow.vue';
import VtoHeaderDescription from './VtoHeaderDescription.vue';
import VtoHeaderLabel from './VtoHeaderLabel.vue';
import VtoHotswapSave from './VtoHotswapSave.vue';
import VtoProduct from './VtoProduct.vue';

export default {
  name: 'VtoHotswapLooks',

  components: {
    VtoActions,
    VtoFloatingBar,
    VtoHeader,
    VtoHeaderArrow,
    VtoHeaderLabel,
    VtoHeaderDescription,
    VtoHotswapSave,
    VtoProduct,
  },

  data: () => ({
    showRemoveLabel: false,
  }),

  computed: {
    ...mapState([
      'dictionary',
      'productItemsMap',
      'productLinesMap',
      'selectedLookId',
      'selectedLookFamilyId',
    ]),

    ...mapGetters(['getSelectedLookProducts']),

    imagePlaceholder() {
      return this.productItemsMap[this.selectedLookId].imageHotSwap;
    },

    headerDescriptionText() {
      return this.productLinesMap[this.selectedLookFamilyId].starProductItem?.title;
    },

    headerLabelText() {
      return this.productItemsMap[this.selectedLookId].name;
    },
  },

  methods: {
    ...mapActions(['goToPageEntitySelection', 'mfSendRemoveCustomLook', 'resetSelectedLookId']),

    onHeaderClick() {
      this.goToPageEntitySelection();
    },

    onRemoveLabelClick() {
      this.resetSelectedLookId();
      this.mfSendRemoveCustomLook();
      this.toggleShowRemoveLabel();
      this.goToPageEntitySelection();
    },

    toggleShowRemoveLabel() {
      this.showRemoveLabel = !this.showRemoveLabel;
    },
  },
};
</script>
