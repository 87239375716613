<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div>
    <VtoBackButton :label="dictionary.backToMenu" @click="resetSelectedLookId" />

    <VtoCirclesContainer class="vtoLooks">
      <VtoCircle
        v-for="lookId in getSelectedLookFamilyLookIds"
        :key="lookId"
        :checked="lookId === selectedLookId"
        :entity="{
          swatch: productItemsMap[lookId].image,
        }"
        :class="{
          '-active': lookId === selectedLookId,
          '-light': !productItemsMap[lookId].dark,
          '-dark': productItemsMap[lookId].dark,
        }"
        @click="selectLookId(lookId)"
      />
    </VtoCirclesContainer>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from '../store/helpers';
import VtoBackButton from './VtoBackButton.vue';
import VtoCircle from './VtoCircle.vue';
import VtoCirclesContainer from './VtoCirclesContainer.vue';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import {
  TAGGING_INFO_SERVICE_CATEGORY,
  TAGGING_INFO_SERVICE_NAME,
  TAGGING_INFO_SERVICE_VERSION,
} from '../settings';

export default {
  name: 'VtoLookSelection',

  components: {
    VtoBackButton,
    VtoCircle,
    VtoCirclesContainer,
  },

  computed: {
    ...mapState(['dictionary', 'productItemsMap', 'selectedLookId']),
    ...mapGetters([
      'getSelectedLookFamilyLookIds',
      'getSelectedLookFamilyTitle',
      'getCurrentCategoryTaggingTitle',
    ]),
  },

  watch: {
    selectedLookId: {
      handler(lookId) {
        AnalyticsHandler.getAnalyticsHandler().push({
          event_name: 'select_look',
          type: 'userActionEvent',
          category: `virtual try on::modiface::${this.getCurrentCategoryTaggingTitle}`,
          action: `choose::look::${this.getSelectedLookFamilyTitle}`,
          label: `${this.productItemsMap[lookId].name}::${lookId}`,
          event_detail: `${this.productItemsMap[lookId].name}::${lookId}::${this.getSelectedLookFamilyTitle}`,
          service_name: TAGGING_INFO_SERVICE_NAME,
          service_category: TAGGING_INFO_SERVICE_CATEGORY,
          service_version: TAGGING_INFO_SERVICE_VERSION,
        });
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['resetSelectedLookId', 'selectLookId']),
  },
};
</script>
