export function selectboxListener(el) {
  const select = el.querySelector('select');
  const selectedValueNode = el.querySelector('.selected-value');

  /* istanbul ignore else */
  if (selectedValueNode) {
    /* istanbul ignore next */
    select.addEventListener('change', () => {
      /* istanbul ignore next */
      selectedValueNode.innerText = select.value;
    });

    selectedValueNode.innerText = select.value;
  }
}

export default {
  name: 'selectbox',
  mounted: (el) => {
    /* istanbul ignore next */
    selectboxListener(el);
  },
};
