<template>
  <div :class="['oap-page-navigation', { '-has-desktop-view': hasDesktopView }]">
    <ul ref="pageNavigationList" class="oap-page-navigation__list">
      <li
        v-for="(item, index) in newFilteredItems"
        :key="index"
        :class="['oap-page-navigation__item', { '-is-button': item.isButton === true }]"
        @click.prevent="scrollToSection(item)"
      >
        <a
          :class="{
            '-active': activeElement === cleanAnchor(item.anchor),
          }"
          :href="item.anchor"
        >
          {{ item.title }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { debounce } from '@Foundation';
import { AnalyticsHandler } from '@Foundation';

const SCROLL_INDENT_FOR_NAVIGATION = 20; // 20px spacing between navigation and scrollable element

const OBSERVER_OPTIONS = {
  rootMargin: '10px 10px 10px 10px',
  threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
};

export default {
  name: 'OapPageNavigationForColorsonic',

  props: {
    items: { type: Array, default: () => [] },
    scrollToTop: { type: Boolean, default: true },
    hasDesktopView: { type: Boolean, default: false },
    taggingData: { type: Object, required: true },
  },

  data() {
    return {
      activeSection: null,
      scrollPosition: 0,
      observerPool: [],
      sortedPool: [],
      intersectionPool: {},
    };
  },
  computed: {
    activeElement() {
      return this.sortedPool[0]?.id;
    },
    newFilteredItems() {
      return this.items.filter((item) => item.anchor.startsWith('#'));
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);
    window.addEventListener('scroll', debounce(this.filterPool, 300));
    window.addEventListener('resize', this.centerActiveNavigationItem);
    window.addEventListener('orientationchange', this.centerActiveNavigationItem);
    window.addEventListener('scrollend', this.centerActiveNavigationItem);

    this.$nextTick(() => {
      this.filterFocusableElements();
    });
  },
  methods: {
    intersectionObserverCallback(entries, observer) {
      entries.forEach((entry) => {
        const { target } = entry;
        this.$nextTick(() => {
          this.intersectionPool[target.id] = {
            intersectionRatio: entry.intersectionRatio,
            id: target.id,
            offsetTop: target.getBoundingClientRect().top,
            isIntersecting: entry.isIntersecting,
          };
        });
      });
    },
    filterPool() {
      this.sortedPool = [];
      const keys = Object.keys(this.intersectionPool);
      keys.forEach((key) => {
        if (this.intersectionPool[key].isIntersecting)
          this.sortedPool.push(this.intersectionPool[key]);
      });
      this.sortedPool = this.sortedPool.sort((a, b) => {
        if (a.intersectionRatio === b.intersectionRatio) {
          return b.offsetTop - a.offsetTop;
        } else {
          return b.intersectionRatio - a.intersectionRatio;
        }
      });
    },
    filterFocusableElements() {
      this.items.forEach((element) => {
        if (element.anchor.startsWith('#')) {
          const domElement = document.querySelector(element.anchor);
          const observer = new IntersectionObserver(
            this.intersectionObserverCallback,
            OBSERVER_OPTIONS
          );
          observer.observe(domElement);
          this.observerPool.push(observer);
        }
      });
    },
    scrollToSection(item) {
      const sectionId = item.anchor;

      const label = sectionId.startsWith('#') ? item.title : `${item.title}::${item.anchor}`;

      AnalyticsHandler.getAnalyticsHandler().push({
        ...this.taggingData,
        label: label,
        cta_name: item.title,
      });

      if (!sectionId.startsWith('#')) {
        window.location.href = sectionId;
      }

      const targetSection = document.getElementById(this.cleanAnchor(sectionId));

      if (targetSection) {
        const header = document.querySelector('header');
        const pageNavigation = document.querySelector('.oap-page-navigation');
        const topOffset =
          header.getBoundingClientRect().height + pageNavigation.getBoundingClientRect().height;

        const allTriggers = ScrollTrigger.getAll();

        allTriggers.forEach((trigger) => {
          trigger.disable(false);
        });
        this.$nextTick(() => {
          gsap.to(window, {
            duration: 1,
            scrollTo: {
              y: sectionId,
              offsetY: topOffset,
            },
            onStart: () => {},
            onComplete: () => {
              setTimeout(() => {
                const allTriggers = ScrollTrigger.getAll();

                allTriggers.forEach((trigger) => {
                  trigger.enable(true);
                });
              }, 1000);
            },
          });
        });
      }
    },
    centerActiveNavigationItem() {
      const pageNavigationList = this.$refs.pageNavigationList;

      if (pageNavigationList && this.activeSection) {
        const activeNavigationItem = pageNavigationList.querySelector('.-active');

        if (activeNavigationItem) {
          const navigationWidth = pageNavigationList.offsetWidth;
          const activeNavigationItemWidth = activeNavigationItem.offsetWidth;
          const scrollLeft =
            activeNavigationItem.offsetLeft - (navigationWidth - activeNavigationItemWidth) / 2;

          pageNavigationList.scrollTo({
            left: scrollLeft,
            behavior: 'smooth',
          });
        }
      }
    },
    cleanAnchor(anchor) {
      return anchor.replace(/#/g, '');
    },
  },
};
</script>
