<template>
  <div ref="mediaCover" class="oap-media-cover">
    <div class="oap-media-cover__wrapper">
      <img v-if="!playing" v-srcset="posterSrcset" class="lazyload" alt="" />
      <transition v-if="video && playing" name="oap-media-cover__fade-in">
        <div class="oap-media-cover__video">
          <video ref="video" :src="video" playsinline muted loop></video>
        </div>
      </transition>
    </div>
    <slot />
    <div v-if="hasBrackets" class="oap-media-cover__brackets">
      <div class="oap-media-cover__brackets-top"></div>
      <div class="oap-media-cover__brackets-bottom"></div>
    </div>
  </div>
</template>

<script>
const MD_SCREEN_START = 650,
  WINDOW_INNER_WIDTH = window.innerWidth,
  INTERSECTION_TIMEOUT = 300,
  INTERSECTION_CLASS = 'is-intersecting';

import { srcset } from '../../../../../Foundation/Core/code/Scripts/directives/srcset/srcset';

export default {
  name: 'OapMediaCover',

  directives: {
    srcset,
  },

  props: {
    posterSrcset: { type: Object, default: () => {} },
    video: { type: String, default: '' },
    hasBrackets: { type: Boolean, default: true },
  },

  data() {
    return {
      playing: false,
    };
  },

  mounted() {
    this.$refs.mediaCover.addEventListener('mouseover', this.playVideo);
    this.$refs.mediaCover.addEventListener('mouseout', this.pauseVideo);

    if (this.video) {
      this.$nextTick(() => {
        if (WINDOW_INNER_WIDTH < MD_SCREEN_START) {
          this.observeIntersection();
        }
      });
    }
  },

  beforeUnmount() {
    this.$refs.mediaCover.removeEventListener('mouseover', this.playVideo);
    this.$refs.mediaCover.removeEventListener('mouseout', this.pauseVideo);
  },

  methods: {
    playVideo() {
      this.playing = true;
      this.$nextTick(() => {
        this.$refs.video.play();
      });
    },

    pauseVideo() {
      this.playing = false;
      this.$refs.video.pause();
    },

    observeIntersection() {
      const observer = new MutationObserver(() => {
        setTimeout(() => {
          this.$refs.mediaCover.classList.contains(INTERSECTION_CLASS)
            ? this.playVideo()
            : this.pauseVideo();
        }, INTERSECTION_TIMEOUT);
      });

      observer.observe(this.$refs.mediaCover, { attributes: true });
    },
  },
};
</script>
