<template>
  <div ref="oap-favorite" class="oap-favorite">
    <button
      v-show="showButton"
      :class="['oap-favorite__button', additionalButtonClasses]"
      @click="handleFavorite"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <slot>
        <span v-if="displayIconLayout" class="oap-favorite__icon">
          <svg class="oap-favorite__icon-image" role="img">
            <title v-text="label" />
            <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="iconHref" />
          </svg>
        </span>

        <span v-if="displayIconAndLabelLayout" class="oap-favorite__icon">
          <svg class="oap-favorite__icon-image" role="img">
            <title v-text="label" />
            <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="iconHref" />
          </svg>
        </span>

        <span v-if="!displayIconLayout" class="oap-button -secondary" v-text="label"> </span>
      </slot>
    </button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { intersectionViewportObserver } from '../../../../../Foundation/Core/code/Scripts/utilities';

export default {
  name: 'OapFavorite',

  props: {
    additionalButtonClasses: {
      type: Array,
      default: () => [],
    },
    apiEndpoints: {
      required: true,
      type: Object,
      validator: (obj) => ['add', 'all', 'remove'].every((prop) => prop in obj),
    },
    displayIconLayout: {
      default: false,
      type: Boolean,
    },
    displayIconAndLabelLayout: {
      default: false,
      type: Boolean,
    },
    item: {
      required: true,
      type: [Array, Object],
      validator: (item) => {
        const validateFormat = (item) => ['ItemId'].every((prop) => prop in item);

        if (Array.isArray(item)) {
          return item.every((item) => validateFormat(item));
        }

        return validateFormat(item);
      },
    },
    labels: {
      required: true,
      type: Object,
      validator: (obj) => ['add', 'remove'].every((prop) => prop in obj),
    },
    loginUrl: {
      required: true,
      type: String,
    },
    /**
     * Which DOM Elements should be removed when a item is unsaved.
     * Useful for favorited items carousel, for example.
     * @property {string} item - Removed when associate item is unsaved
     * @property {string} wrapper - Removed when all items are unsaved
     */
    nodesToRemove: {
      default: null,
      type: Object,
    },
    overwriteItemType: {
      default: null,
      type: Number,
    },
    returnPage: {
      default: null,
      type: String,
    },
    taggingData: {
      default: null,
      type: Object,
    },
    toastTimeout: {
      required: true,
      type: Number,
    },
    iconDefault: {
      type: String,
      default: '#favorite-heart-empty',
    },
    iconHovered: {
      type: String,
      default: '#favorite-heart-outlined',
    },
    iconFavorited: {
      type: String,
      default: '#favorite-heart-full',
    },
  },

  data() {
    return {
      hover: false,
    };
  },

  computed: {
    ...mapGetters('oapFavoriteStore', ['favoritedItems', 'showButton']),

    iconHref() {
      return this.isFavorited
        ? this.iconFavorited
        : this.hover
        ? this.iconHovered
        : this.iconDefault;
    },

    isFavorited() {
      if (!this.favoritedItems) return false;
      if (Array.isArray(this.item)) return false;

      return this.favoritedItems.some((item) => {
        const hasSameId = item.ItemId.toLowerCase() === this.item.ItemId.toLowerCase();
        const hasSameType = item.Type === this.item.Type;

        return hasSameId && hasSameType;
      });
    },

    itemTreated() {
      const { item, overwriteItemType } = this;

      const treatItem = (item) => {
        if (overwriteItemType) {
          item.Type = overwriteItemType;
        }

        return item;
      };

      if (Array.isArray(item)) {
        return item.map((item) => treatItem(item));
      }

      return treatItem(item);
    },

    label() {
      const { add, remove } = this.labels;

      return !this.isFavorited ? add : remove;
    },
  },

  watch: {
    isFavorited(favorited) {
      if (!favorited && this.nodesToRemove?.item) this.removeItemFromDom();
    },
  },

  mounted() {
    this.setupStore({
      apiEndpoints: this.apiEndpoints,
      loginUrl: this.loginUrl,
      toastTimeout: this.toastTimeout,
    });

    intersectionViewportObserver(this.$el, { threshold: 1 }).then(() => {
      this.fetchInitialFavoritedItems();
    });

    this.defineRole();
  },

  methods: {
    ...mapActions('oapFavoriteStore', [
      'addFavorite',
      'fetchInitialFavoritedItems',
      'removeFavorite',
      'setupStore',
    ]),

    checkWrapperRemoval(wrapperElement) {
      const itemSelector = this.nodesToRemove.item;
      const itemElementsLength = wrapperElement.querySelectorAll(itemSelector).length;

      if (itemElementsLength < 1) {
        wrapperElement.remove();
      }
    },

    handleFavorite() {
      const { itemTreated, returnPage, taggingData } = this;

      if (this.isFavorited) {
        this.removeFavorite({ item: itemTreated, returnPage, taggingData });
        return;
      }

      this.addFavorite({ item: itemTreated, returnPage, taggingData });
    },

    removeItemFromDom() {
      const itemElement = this.$el.closest(this.nodesToRemove.item);
      const wrapperElement = this.$el.closest(this.nodesToRemove.wrapper);

      if (!itemElement) return;

      this.$destroy();
      itemElement.remove();

      if (wrapperElement) this.checkWrapperRemoval(wrapperElement);
    },

    defineRole() {
      const component = this.$refs['oap-favorite'];
      const hasShareWrapper = component.closest('.articleShare__wrapper');
      if (hasShareWrapper) component.setAttribute('role', 'list-item');
    },
  },
};
</script>
