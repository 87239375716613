<template>
  <ul ref="linesList" class="oap-strikethrough-list">
    <li v-for="(item, index) in singleLineTexts" :key="index">
      {{ item }}
    </li>
  </ul>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { intersectionViewportObserver } from '@Foundation';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler';

export default {
  name: 'OapStrikethroughScrolling',

  props: {
    singleLineTexts: { type: Array, default: () => {} },
    containerSelector: { type: String, default: '.oap-sustainability' },

    // scrollTrigger settings
    scrub: { type: Boolean, default: true },
    pin: { type: Boolean, default: true },
    start: { type: String, default: 'top top' },
    end: { type: String, default: 'center 0%' },
    animationsEnabled: { type: Boolean, required: true, default: true },
    impressionTag: {
      type: Object,
      required: false,
      default: () => {
        return {
          type: 'userActionEvent',
          event_name: 'product_info_impression',
          category: 'product_sustainability',
          action: 'list of products',
        };
      },
    },
  },

  data() {
    return {
      tagFired: false,
      isOnViewport: false,
      strikethroughDelay: 100,
    };
  },

  computed: {
    scrollPoints() {
      return Math.floor(100 / this.singleLineTexts.length);
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    if (this.animationsEnabled && !window.location.hash) {
      setTimeout(this.bindAnimation, 0);
    } else {
      this.strikethroughDelay = 0;
      this.$nextTick(() => {
        this.strikethroughElement(this.singleLineTexts.length - 1);
      });
    }

    intersectionViewportObserver(this.$el, { checkIsVisible: false, threshold: [0.006] }).then(
      () => {
        this.isOnViewport = true;
      }
    );
  },

  methods: {
    bindAnimation() {
      gsap.to(this.containerSelector, {
        ease: 'ease-in-out',
        scrollTrigger: {
          trigger: this.containerSelector,
          scrub: this.scrub,
          pin: this.pin,
          start: this.start,
          end: this.end,
          onUpdate: (self) => {
            let progress = Math.round(self.progress * 100);

            if (!this.isOnViewport) return;

            if (progress >= 10) {
              if (!this.tagFired) {
                this.tagFired = true;
                AnalyticsHandler.getAnalyticsHandler().push({
                  ...this.impressionTag,
                });
              }

              let strikethroughIndex = Math.floor(progress / this.scrollPoints);

              if (strikethroughIndex < this.singleLineTexts.length) {
                this.strikethroughElement(strikethroughIndex);
              }
            }
          },
        },
      });
    },

    strikethroughElement(index) {
      for (let i = 0; i <= index; i++) {
        if (!this.$refs.linesList.children[i].classList.contains('-strikethrough')) {
          setTimeout(() => {
            this.$refs.linesList.children[i].classList.add('-strikethrough');
            if (this.animationsEnabled)
              this.$refs.linesList.children[i].classList.add('with-animation');
          }, index * this.strikethroughDelay);
        }
      }
    },
  },
};
</script>
