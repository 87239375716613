<template>
  <div class="vtoBackButton" v-on="$attrs" @click="$attrs.click && $attrs.click()">
    <span v-if="label" class="vtoBackButton__label">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'VtoBackButton',
  props: {
    label: { type: String },
  },
};
</script>
