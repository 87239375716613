<template>
  <div class="oap-iframe-wrapper">
    <iframe
      class="oap-iframe"
      :src="iframeSrc"
      :height="computedHeight"
      :title="title ? title : 'frame'"
    />
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { intersectionViewportObserver } from '../../../../../Foundation/Core/code/Scripts/utilities/intersectionViewportObserver';
import { MqHandler } from '../../../../../Foundation/Core/code/Scripts/mqHandler';

export default {
  name: 'OapIframe',

  props: {
    heightScreenLarge: {
      type: String,
      default: '',
    },
    heightScreenSmall: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      iframeSrc: undefined,
      isLargeScreen: false,
    };
  },

  computed: {
    computedHeight() {
      return this.isLargeScreen ? this.heightScreenLarge : this.heightScreenSmall;
    },
  },

  mounted() {
    intersectionViewportObserver(this.$el, {
      checkIsVisible: false,
      threshold: [0.006],
    }).then(() => {
      this.iframeSrc = this.src;
    });

    this.checkAndWatchScreenSize();

    window.addEventListener('load', () => {
      this.$el.querySelector('.oap-iframe').setAttribute('scrolling', 'yes');
    });
  },

  methods: {
    checkAndWatchScreenSize() {
      this.isLargeScreen = MqHandler.getMqHandler().getMqForSize('large').selected;

      eventBus.on('mediaquery::changed', (mq) => {
        this.isLargeScreen = mq.size === 'large';
      });
    },
  },
};
</script>
