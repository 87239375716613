<template>
  <div :class="className">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'OapMarkupRenderingDelay',

  props: {
    className: { type: String, default: '' },
  },
};
</script>
