<template>
  <div
    ref="overlay"
    class="overlay"
    :class="{ 'overlay--open': isOverlayOpen }"
    @click="closeOverlay"
  ></div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';

const OVERLAY_ACTIVE = 'overlay--active';

export default {
  name: 'Overlay',

  props: {
    parentNav: {
      type: String,
      required: false,
      default: undefined,
    },
  },

  data() {
    return {
      overlayOpen: false,
      navId: this.parentNav,
    };
  },

  computed: {
    isOverlayOpen() {
      return this.overlayOpen && this.navId === this.parentNav;
    },
  },

  mounted() {
    eventBus.on('navOpen', (value) => {
      this.closeNav(value);
    });
  },

  methods: {
    /**
     * This function closes the overlay when clicking on it
     * @function
     */
    closeOverlay() {
      this.overlayOpen = false;
      eventBus.emit('closeNav', this.overlayOpen);
      const oBody = document.querySelector('body');
      oBody.classList.remove(OVERLAY_ACTIVE);
      oBody.classList.remove('noScroll');

      if (document.querySelector('.navigationList.-active')) {
        document.querySelector('.navigationList.-active').classList.remove('-active');
      }

      if (document.querySelector('.navigation__panelWrapper.-active')) {
        document.querySelector('.navigation__panelWrapper.-active').classList.remove('-active');
        document.querySelector('.navigationList__item.-active').classList.remove('-active');
      }

      if (document.querySelector('.navigation__burger.-active')) {
        document.querySelector('.navigation__burger.-active').classList.remove('-active');
      }
    },
    /**
     * This function closes the nav when clicking on overlay
     * @function
     * @param value
     */
    closeNav(value) {
      const { status, navId } = value;
      this.overlayOpen = status;
      this.navId = navId;
      const oBody = document.querySelector('body');
      if (this.overlayOpen === false) {
        oBody.classList.remove(OVERLAY_ACTIVE);
      } else {
        oBody.classList.add(OVERLAY_ACTIVE);
      }
    },
  },
};
</script>
