<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <!-- Both categories and looks categories can represent the entity here -->
  <VtoCirclesContainer v-if="!selectedTypeId" class="vtoEntity">
    <VtoCircle
      v-for="(typeId, index) in getTypeIds"
      :key="index"
      :entity="{
        label: productTypesMap[typeId].tabLabel,
        swatch: productTypesMap[typeId].swatch,
      }"
      @click="selectType(typeId) && vtoPushTaggingInfo(productTypesMap[typeId])"
    />
  </VtoCirclesContainer>
</template>

<script>
import { mapActions, mapState, mapGetters } from '../store/helpers';
import VtoCircle from './VtoCircle.vue';
import VtoCirclesContainer from './VtoCirclesContainer.vue';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import {
  TAGGING_INFO_SERVICE_CATEGORY,
  TAGGING_INFO_SERVICE_NAME,
  TAGGING_INFO_SERVICE_VERSION,
  TYPE_LOOK,
} from '../settings';

export default {
  name: 'VtoEntitySelection',

  components: {
    VtoCircle,
    VtoCirclesContainer,
  },

  computed: {
    ...mapState(['productTypesMap', 'selectedTypeId']),
    ...mapGetters(['getTypeIds', 'getCurrentCategoryTaggingTitle']),
  },

  methods: {
    ...mapActions(['selectType']),

    vtoPushTaggingInfo(category) {
      if (category.type === TYPE_LOOK) {
        AnalyticsHandler.getAnalyticsHandler().push({
          event_name: 'select_look_category',
          type: 'userActionEvent',
          category: `virtual try on::modiface::${this.getCurrentCategoryTaggingTitle}`,
          action: 'select::lookcategory',
          label: category.name,
          event_detail: category.name,
          service_name: TAGGING_INFO_SERVICE_NAME,
          service_category: TAGGING_INFO_SERVICE_CATEGORY,
          service_version: TAGGING_INFO_SERVICE_VERSION,
        });
      }
    },
  },
};
</script>
