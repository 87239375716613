/**
 *  config object example:
 *  const config = {
      method: 'POST',
      body: {
        "key1": "value1",
        "key2": "value2"
      },
      headers: {'Content-Type': 'application/json'},
      credentials: 'include',
      cache: 'no-store'
    };
 */

export const fetchData = (apiUrl, config, callback) => {
  const checkStatus = (response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  };
  const parseJSON = (response) => response.json();

  fetch(apiUrl, config)
    .then(checkStatus)
    .then(parseJSON)
    .then((data) => callback(data))
    .catch((error) => console.warn(error));
};

export const mixinFetchData = {
  methods: {
    fetchData,
  },
};
