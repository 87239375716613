import { convertVariable, isVariable } from '../utils/analyticsUtils';

export default class DataObject {
  constructor(event, data) {
    this.variables = (window.wsf && window.wsf.AnalyticsVariablesDynamic) || {};
    this.result = {};
    if (event) {
      this.result.event = event;
    }
    // copy data
    this.from(data);
  }

  setProperty(property, toLower = true) {
    let res = isVariable(property) ? convertVariable(property, this.variables) : property?.trim();

    if (toLower) {
      res = res?.toLowerCase();
    }

    return res;
  }

  from(data) {
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        this[key] = data[key];
      }
    });
  }

  toObject() {
    return this.result;
  }
}
