const defaultConfig = {
  delay: 1000,
  cookieName: 'test',
};

class ShowOnCookieExpiration {
  constructor(el, config) {
    this.el = el;
    this.config = config;

    this.initTimer();
  }

  set config(newConfig) {
    this._config = { ...defaultConfig, ...newConfig };
  }

  get cookie() {
    return document.cookie.indexOf(this._config.cookieName) >= 0;
  }

  initTimer() {
    this._timer = setInterval(() => {
      if (this.cookie && !this.el.classList.contains('-hidden')) {
        this.el.classList.add('-hidden');
      } else if (!this.cookie && this.el.classList.contains('-hidden')) {
        this.el.classList.remove('-hidden');
        this.destroy();
      } else if (!this.cookie && !this.el.classList.contains('-hidden')) {
        this.destroy();
      }
    }, this._config.delay);
  }

  destroy() {
    clearInterval(this._timer);
  }
}

export const showOnCookieExpiration = {
  name: 'show-on-cookie-expiration',
  beforeMount: (el, binding) => (el.__timeCookie__ = new ShowOnCookieExpiration(el, binding.value)),
  unmounted: (el) => el.__timeCookie__.destroy(),
};
