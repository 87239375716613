import eventBus from '@loreal/eventbus-js';

/* istanbul ignore next */
document.addEventListener('lazybeforeunveil', (event) =>
  eventBus.emit('image.lazybeforeunveil', event)
);

export const imageloaded = {
  name: 'imageloaded',
  beforeMount: (el) => {
    const lazyImage = el.querySelector('.lazyload');

    eventBus.on('image.lazybeforeunveil', (event) => {
      /* istanbul ignore else */
      if (event.target === lazyImage) {
        el.classList.remove('image__loading');
      }
    });
  },
};
