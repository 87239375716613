import Carousel from './code/Scripts/components/carousel/carousel.vue';
import Navigation from './code/Scripts/components/navigation/navigation.vue';
import Pagination from './code/Scripts/components/pagination/pagination.vue';
import Slide from './code/Scripts/components/slide/slide.vue';
import Indexing from './code/Scripts/components/indexing/indexing.vue';

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Indexing,
  },
};
