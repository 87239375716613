<template>
  <div v-if="loaded">
    <slot />
  </div>
</template>
<script>
import eventBus from '@loreal/eventbus-js';
import InjectScript from './injectscript';

/* istanbul ignore next */
export default {
  name: 'LoadScript',
  props: {
    name: {
      type: String,
      default: undefined,
    },
    src: {
      type: String,
      required: true,
    },
    integrity: {
      type: String,
      default: undefined,
    },
    crossorigin: {
      type: String,
      default: undefined,
    },
    async: {
      type: Boolean,
      default: true,
    },
    attributes: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    /* istanbul ignore next */
    return {
      loaded: false,
    };
  },
  created() {
    /* istanbul ignore else */
    if (this.src && !InjectScript.hasScript(this.src)) {
      this.injectTheScript();
    } else if (InjectScript.hasScript(this.src)) {
      this.$emit('loaded');
      eventBus.emit(`script.loaded.${this.name}`, {
        name: this.name,
        url: this.src,
      });
    }
  },
  methods: {
    injectTheScript() {
      InjectScript.injectScript(this.src, {
        async: this.async,
        integrity: this.integrity,
        crossorigin: this.crossorigin,
        ...(this.attributes || {}),
      }).then(() => {
        this.loaded = true;
        this.$emit('loaded');

        /* istanbul ignore else */
        if (this.name) {
          eventBus.emit(`script.loaded.${this.name}`, {
            name: this.name,
            url: this.src,
          });
        }
      });
    },
  },
};
</script>
