<template>
  <div v-show="shouldShow" class="oap-search-suggestions">
    <p v-if="hasNoSuggestions" class="oap-search-suggestions-list__empty">{{ defaultLabel }}</p>

    <template v-for="(collection, key) of collections" v-else>
      <div v-if="collection?.list?.length" :key="key" class="oap-search-suggestions-list__wrapper">
        <p v-if="collection.title" class="oap-search-suggestions-list__title">
          {{ collection.title }}
        </p>

        <ul class="oap-search-suggestions-list__list">
          <li
            v-for="(item, key) of collection.list"
            :key="key"
            class="oap-search-suggestions-list__item"
          >
            <svg v-if="collection.icon" class="icon glass" aria-hidden="true">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#search"></use>
            </svg>
            <a
              :href="`${suggestionsAction}?q=${removeTagWrapper(item)}&autocompletion=true`"
              class="oap-search-suggestions-list__link"
              v-html="item"
            ></a>
            <svg class="icon arrow" aria-hidden="true">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-search"></use>
            </svg>
          </li>
        </ul>
      </div>
    </template>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { throttle, LOCAL_STORAGE_AVAILABLE } from '../../../../../Foundation/Core/code/Scripts';
import { fetchData } from '../../../../../Foundation/Core/code/Scripts/mixins/fetchData/fetchData';

// Settings
const SEARCH_INPUT_SELECTOR = '.oap-search__input';
const SEARCH_FORM_SELECTOR = '.oap-search__form';
const SEARCH_CHECKBOX_SELECTOR = '.oap-search__checkbox';
const SEARCH_SUGGESTIONS_THROTTLING = 1000;
const RECENT_SEARCHES_STORAGE_KEY = 'recent_searches';

export default {
  name: 'SearchSuggestions',

  props: {
    suggestionsAction: { type: String, required: true },
    suggestionsApiUrl: { type: String, required: true },
    urlOrigin: { type: String, default: window.location.origin },
    defaultLabel: { type: String, required: true },
    recommendedSearchesTitle: { type: String, required: true },
    recentSearchesTitle: { type: String, required: true },
    minQueryChar: { type: Number, required: true },
    maxRecentSearchItems: { type: Number, required: true },
    activeSearchQuery: { type: String },
  },

  data() {
    return {
      recentSearchesList: [],
      suggestionsList: [],
      searchQueryValue: null,
      isActive: true,
    };
  },

  computed: {
    hasNoSuggestions() {
      return [...this.suggestionsList, ...this.recentSearchesList].length === 0;
    },

    shouldShow() {
      return (
        this.isActive && this.searchQueryValue && this.searchQueryValue.length >= this.minQueryChar
      );
    },

    collections() {
      return [
        { list: this.suggestionsList, title: this.recommendedSearchesTitle, icon: 'search' },
        { list: this.recentSearchesList, title: this.recentSearchesTitle, icon: null },
      ];
    },
  },

  watch: {
    searchQueryValue(value) {
      /* istanbul ignore next */
      if (
        // not falsy
        value &&
        // has min char
        value.length >= this.minQueryChar
      ) {
        // fetch suggestions
        this.getSuggestionsList(value);
      }
    },

    isActive(value) {
      /* istanbul ignore else */
      if (!value) {
        // reset suggestions list
        this.searchQueryValue = '';
      }
    },
  },

  mounted() {
    this.bindFormListener();
    this.bindActiveListener();
    this.bindEventBusListener();
    this.getRecentSearchesList();
    this.updateRecentSearchesList(this.activeSearchQuery);
  },

  methods: {
    getRecentSearchesList() {
      /* istanbul ignore else */
      if (!LOCAL_STORAGE_AVAILABLE) {
        return;
      }

      /* istanbul ignore next */
      this.recentSearchesList =
        JSON.parse(window.localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY)) || [];
    },

    updateRecentSearchesList(activeSearchQuery) {
      /* istanbul ignore else */
      if (!LOCAL_STORAGE_AVAILABLE) {
        return;
      }

      /* istanbul ignore next */
      if (activeSearchQuery) {
        let recentSearchesList =
          JSON.parse(window.localStorage.getItem(RECENT_SEARCHES_STORAGE_KEY)) || [];

        // remove active query from list if already in
        let filteredRecentSearchesList = recentSearchesList.filter(
          (query) => query !== activeSearchQuery
        );

        // add active query to the list
        filteredRecentSearchesList.unshift(activeSearchQuery);

        // only keep max numbers of items and set localStorage
        window.localStorage.setItem(
          RECENT_SEARCHES_STORAGE_KEY,
          JSON.stringify(filteredRecentSearchesList.slice(0, this.maxRecentSearchItems))
        );
      }
    },

    getSuggestionsList(value) {
      const url = new URL(this.suggestionsApiUrl, this.urlOrigin);
      const config = {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        cache: 'no-store',
      };

      url.searchParams.append('query', value);

      fetchData(url, config, (data) => {
        this.suggestionsList = data;
      });
    },

    selectSuggestion(query) {
      const searchInput = document.querySelector(SEARCH_INPUT_SELECTOR);
      const searchForm = document.querySelector(SEARCH_FORM_SELECTOR);

      searchInput.value = query;
      searchForm.submit();
    },

    bindFormListener() {
      const searchInput = document.querySelector(SEARCH_INPUT_SELECTOR);

      if (searchInput) {
        /* istanbul ignore next */
        searchInput.addEventListener(
          'keyup',
          throttle((event) => {
            this.isActive = document.activeElement === searchInput;
            this.searchQueryValue = event.target.value;
          }, SEARCH_SUGGESTIONS_THROTTLING)
        );
      }
    },

    bindActiveListener() {
      // It would be nicer for active to be a prop
      const searchCheckbox = document.querySelector(SEARCH_CHECKBOX_SELECTOR);

      /* istanbul ignore else */
      if (searchCheckbox) {
        this.isActive = searchCheckbox.checked;
        searchCheckbox.addEventListener('change', (event) => {
          this.isActive = event.target.checked;
        });
      }
    },

    bindEventBusListener() {
      eventBus.on('search-suggestions::close', () => {
        this.isActive = false;
        this.searchQueryValue = '';
        this.suggestionsList = [];
      });
    },

    removeTagWrapper(str) {
      return str.replace(/<.*?>/g, '');
    },
  },
};
</script>
