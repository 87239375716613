import 'whatwg-fetch';
import eventBus from '@loreal/eventbus-js';

export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

export const parseJSON = (response) => response.json();

export default {
  namespaced: true,
  state: {
    formSubmited: false,
    formData: undefined,
    typeValue: null,
  },

  getters: {},

  actions: {
    submitForm({ commit }, { formActionUrl, formData }) {
      fetch(formActionUrl, {
        method: 'POST',
        body: formData,
      })
        .then(checkStatus)
        .then(parseJSON)
        .then((response) => {
          const { type } = response;
          commit('setTriggerNewsletterForm', type);
        });
    },
  },

  mutations: {
    setTriggerNewsletterForm(state, typeValue) {
      state.typeValue = typeValue;
      eventBus.emit('newsletter-form::form-submited', typeValue);
    },
  },
};
