<template>
  <div class="logout_refresh"></div>
</template>

<script>
export default {
  name: 'LogoutRefresh',
  mounted() {
    let logOutCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('logoutCache='))
      ?.split('=')[1];
    if (logOutCookie == 'false') {
      location.reload(true);
    }
  },
};
</script>
