export const mixinTryPlayVideo = {
  methods: {
    /**
     * tryPlayVideo
     * @return {Promise}
     */
    tryPlayVideo(player, isPlayerSet = false, time = 500, autoplay = 1) {
      /* istanbul ignore next */
      return new Promise((resolve) => {
        try {
          if (autoplay) {
            player.playVideo();
          }
          isPlayerSet = true;
          resolve(isPlayerSet);
        } catch (e) {
          setTimeout(() => {
            if (autoplay) {
              player.playVideo();
            }
            resolve();
          }, time);
        }
      });
    },
  },
};
