const FORM_IMAGE_CLASS = 'oap-form-template__image';
const NO_IMAGE_FORM_CLASS = 'oap-form-template--no-image';

const FORM_COLUMNS_CLASS = 'oap-form-template__form--with-column';
const FULL_WIDTH_FORM_CLASS = 'oap-form-template--full-width';

const FORM_SECTIONS_CLASS = 'generic-form__section';
const FORM_SECTION_TITLE_CLASS = 'generic-form__section-title';
const FORM_MARGIN_TOP = 'oap-form-template--margin-top';

export const toggleImageclass = (el) => {
  const image = el.querySelector(`.${FORM_IMAGE_CLASS}`);

  if (!image) {
    el.classList.add(NO_IMAGE_FORM_CLASS);
  }
};

export const toggleFormWidth = (el) => {
  const image = el.querySelector(`.${FORM_IMAGE_CLASS}`);
  const formMultiColumn = el.querySelector(`.${FORM_COLUMNS_CLASS}`);

  if (!image && formMultiColumn) {
    el.classList.add(FULL_WIDTH_FORM_CLASS);
  }
};

export const fixContentDistance = (el) => {
  const sections = el.querySelectorAll(`.${FORM_SECTIONS_CLASS}`);
  let addMargin = false;
  sections.forEach((section) => {
    if (section.querySelector(`.${FORM_SECTION_TITLE_CLASS}`)) {
      addMargin = true;
    }
  });

  if (addMargin) el.classList.add(FORM_MARGIN_TOP);
};

export const mounted = (el) => {
  toggleImageclass(el);
  toggleFormWidth(el);
  fixContentDistance(el);
};

export const formModifier = {
  name: 'form-modifier',
  mounted,
};
