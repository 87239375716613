import { debounce, intersectionViewportObserver } from '@Foundation';

export const getBackground = (backgrounds, windowWidth) => {
  if (typeof backgrounds === 'string') {
    return backgrounds;
  }

  const currentWidth = windowWidth || document.documentElement.clientWidth;
  const keys = Object.keys(backgrounds).reverse();
  const lastMatchingKey = keys.filter((key) => currentWidth >= key).shift();
  return backgrounds[lastMatchingKey];
};

export const getPicSize = (url) => {
  const imgHQuery = url.match(/ch=(.*?)&/g) || null;
  const imgWQuery = url.match(/cw=(.*?)&/g) || null;
  let imgW = null;
  let imgH = null;

  /* istalbul ignore next */
  if (!!imgWQuery && !!imgHQuery) {
    imgH = imgHQuery[0].substring(3, imgHQuery[0].length - 1);
    imgW = imgWQuery[0].substring(3, imgWQuery[0].length - 1);
  }

  return imgW && imgH ? `(${imgW}/${imgH})` : null;
};

export const srcset = {
  name: 'srcset',
  mounted: (el, binding) => {
    const updateBackground = () => {
      const url = getBackground(binding.value);
      if (el.tagName.toLowerCase() !== 'img') {
        getPicSize(url) && el.classList.contains('image');
        el.style.backgroundImage = `url('${url}')`;
      } else {
        getPicSize(url) && el.parentElement.classList.contains('image');
        el.src = url;
      }
    };
    if (window.location && window.location.hash) {
      updateBackground();
      setTimeout(() => {
        el.classList.add('-loaded');
      }, 100);
    } else {
      intersectionViewportObserver(el, { checkIsVisible: false, threshold: [0.006] }).then(() => {
        updateBackground();

        el.listener = debounce(updateBackground, 200);
        window.addEventListener('resize', el.listener);

        setTimeout(() => {
          el.classList.add('-loaded');
        }, 100);
      });
    }
  },

  unmounted(el) {
    if (el.listener) {
      window.removeEventListener('resize', el.listener);
    }
  },
};
