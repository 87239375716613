<template>
  <section ref="trigger" class="oap-immersive-hotspots">
    <div class="oap-immersive-hotspots__copy">
      <div
        class="oap-immersive-hotspots__title"
        :style="`color: ${title.color}`"
        v-html="title.text"
      ></div>
      <div
        class="oap-immersive-hotspots__subtitle"
        :style="`color: ${subtitle.color}`"
        v-html="subtitle.text"
      ></div>
    </div>
    <OapPicture
      :class="['oap-immersive-hotspots__text', classToAnimate]"
      :mobile="hotspots.mobile"
      :desktop="hotspots.desktop"
    />
    <OapPicture
      class="oap-immersive-hotspots__model"
      :mobile="model.mobile"
      :desktop="model.desktop"
    />
  </section>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import OapPicture from '../../../../OapPicture/code/Scripts/components/OapPicture.vue';

export default {
  name: 'OapImmersiveHotspots',
  components: {
    OapPicture,
  },
  props: {
    // copy
    title: { type: Object, default: () => {} },
    subtitle: { type: Object, default: () => {} },

    // images
    hotspots: { type: Object, required: true },
    model: { type: Object, required: true },

    // scrollTrigger settings
    scrub: { type: Boolean, default: true },
    pin: { type: Boolean, default: true },
    start: { type: String, default: 'top top' },
    end: { type: String, default: '+=150%' },

    // GSAP settings
    opacity: { type: Number, default: 1 },
  },
  data() {
    return { classToAnimate: '-gsap-animate' };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    setTimeout(() => {
      gsap.to(`.${this.classToAnimate}`, {
        scrollTrigger: {
          trigger: this.$refs.trigger,
          scrub: this.scrub,
          pin: this.pin,
          start: this.start,
          end: this.end,
        },
        opacity: this.opacity,
      });
    }, 0);
  },
};
</script>
