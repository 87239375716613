/* eslint-disable no-console */
import { debug } from '../../env/env';

const style = 'padding: 2px 6px; background: #111; color: #eee';

/**
 * Logger
 */
export default {
  /**
   * Logs to the console when in debug mode
   * @param {string} text Message to log
   * @param {string} text Type of log (can be log, warn, error)
   * @param {object} data Optional data object to log
   * @param type
   */

  log(text, data, type = 'log', showData) {
    if (debug) {
      const types = [
        { type: 'log', color: '#68b6f1', state: 'LOG' },
        { type: 'warn', color: '#ff8800', state: 'WARNING' },
        { type: 'error', color: '#FF0000', state: 'ERROR' },
      ];

      const str = [
        `%c${types.find((t) => t.type === type).state} %c${text}`,
        `padding: 2px 6px; background: #111; color:${types.find((t) => t.type === type).color}`,
        style,
      ];

      console.group('AnalyticsHandler');
      console.log(...str);
      if (data && showData) {
        if (data.event === 'uaevent') {
          if (console.table) {
            console.table(data);
          }
          console.log('%cAssociated raw data:', style);
          console.log(JSON.stringify(data, null, 2));
        } else {
          console.log('%cAssociated raw data:', style);
          console.log(JSON.stringify(data, null, 2));
        }
      }
      console.groupEnd();
    }
  },

  info(text, data, showData = true) {
    this.log(text, data, 'log', showData);
  },

  warn(text, data, showData = true) {
    this.log(text, data, 'warn', showData);
  },

  error(text, data, showData = true) {
    this.log(text, data, 'error', showData);
  },
};
