import eventBus from '@loreal/eventbus-js';

const BODY_CLASSES = ['toolbar-tab-is-open', 'noScroll'];
const TRIGGER_STATE_ATTRIBUTE = 'data-state';
const ARIA_HIDDEN = 'aria-hidden';
const TRIGGER_TAB = {
  OPEN: '[data-tab-open]',
  OPEN_EXTERNAL: '[data-tab-open-external]',
  CLOSE: '[data-tab-close]',
};

class A11Y {
  toggleAriaHidden(selector, add) {
    const element = document.querySelector(selector);
    add ? element?.setAttribute(ARIA_HIDDEN, 'true') : element?.removeAttribute(ARIA_HIDDEN);
  }

  focusElement(parentElement, selector) {
    parentElement?.querySelector(selector)?.focus();
  }
}

class ToolbarTabs extends A11Y {
  constructor(el, config) {
    super();
    this.el = el;
    this.config = config;
    this.URLKey = 'zapper';
    this.openTriggers = document.querySelectorAll(TRIGGER_TAB.OPEN);
    this.closeTriggers = [
      ...document.querySelectorAll(TRIGGER_TAB.CLOSE),
      ...document.querySelectorAll(TRIGGER_TAB.OPEN_EXTERNAL),
    ];

    this.attachEvents = this.attachEvents.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.resetActiveTab = this.resetActiveTab.bind(this);
  }

  get activeTab() {
    return this.el.querySelector('li[role="tab"][aria-selected="true"]');
  }

  get activeTrigger() {
    if (this.activeTab) {
      return this.activeTab.querySelector(TRIGGER_TAB.OPEN);
    }
    return null;
  }

  get hasZapperInURL() {
    const url = window.location,
      searchString = url.search,
      searchParams = new URLSearchParams(searchString);

    return searchParams.has(this.URLKey);
  }

  setActiveTab(id) {
    const trigger = this.el.querySelector(`li[role="tab"][id="${id}"]`);
    const button = trigger?.querySelector(TRIGGER_TAB.OPEN);
    if (this.activeTab === trigger) {
      this.resetActiveTab();
      button?.setAttribute(TRIGGER_STATE_ATTRIBUTE, 'collapsed');
      document.body.classList.remove(...BODY_CLASSES);
      eventBus.emit('tab:close', id);
    } else {
      this.resetActiveTab();
      const triggerPanel = this.el.querySelector(`li[role="tabpanel"][id="${trigger.id}"]`);
      if (trigger) {
        trigger?.setAttribute('aria-selected', 'true');
        button?.setAttribute(TRIGGER_STATE_ATTRIBUTE, 'expanded');
      }
      if (triggerPanel) {
        triggerPanel?.setAttribute(ARIA_HIDDEN, 'false');
      }
      document.body.classList.add(...BODY_CLASSES);
      this.toggleAriaHidden('main', true);
      this.focusElement(triggerPanel, '[data-tab-a11y-focus]');
      eventBus.emit('tab:open', id);
    }
  }

  resetActiveTab() {
    if (this.activeTrigger) {
      this.activeTrigger.setAttribute(TRIGGER_STATE_ATTRIBUTE, 'collapsed');
    }
    if (this.activeTab) {
      eventBus.emit('tab:close', this.activeTab.id);
      this.activeTab.setAttribute('aria-selected', 'false');
    }
    const activeContent = this.el.querySelector(`li[role="tabpanel"][aria-hidden="false"]`);
    if (activeContent) {
      activeContent.setAttribute(ARIA_HIDDEN, 'true');
    }
    document.body.classList.remove(...BODY_CLASSES);
    this.toggleAriaHidden('main', false);
  }

  attachEvents() {
    this.closeTriggers.forEach((trigger) =>
      trigger.addEventListener('click', () => {
        this.resetActiveTab();
      })
    );
    this.openTriggers.forEach((trigger) =>
      trigger.addEventListener('click', () => {
        this.setActiveTab(trigger.dataset.tabOpen);
      })
    );

    eventBus.on('tab:open:external', this.setActiveTab);
    document.addEventListener('keydown', (event) => {
      if (this.activeTab) {
        if (event.key === 'Escape') {
          this.resetActiveTab();
        }

        if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
          const nextSibling = this.activeTab?.nextElementSibling;
          const previousSibling = this.activeTab?.previousElementSibling;
          if (nextSibling) {
            this.setActiveTab(nextSibling.id);
          }
          if (previousSibling) {
            this.setActiveTab(previousSibling.id);
          }
          this.focusElement(this.activeTab, TRIGGER_TAB.OPEN);
        }
      }
    });

    if (this.hasZapperInURL) {
      this.setActiveTab('zapper-tab');
    }
  }

  init() {
    this.attachEvents();
  }
}

export const toolbarTabs = {
  name: 'toolbar-tabs',

  mounted: (el, binding) => {
    const toolbarTabs = new ToolbarTabs(el, binding.value);
    toolbarTabs.init();
  },
};
