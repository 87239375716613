import getValueFromSelector from './getValueFromSelector';

/**
 * Get a data value from a Dom Node.
 * Looks for data tag on given Node first, if not found,
 * it does querySelector to find it in children.
 * @param {Node} domNode Node to query for data tag
 * @param {String} selector Query for selector
 * @param {String} field Name of data tag to search. Eg: data-tag-product-id.
 */
export default function getValue(domNode, selector, field) {
  if (!domNode) return;

  if (domNode.getAttribute(`data-tag-${field}`)) {
    return domNode.getAttribute(`data-tag-${field}`);
  }
  return getValueFromSelector(domNode.querySelector(selector), field);
}
