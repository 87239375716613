<template>
  <div class="carousel-navigation">
    <button
      class="carousel-navigation-button carousel-navigation-prev"
      :class="{ 'carousel-navigation--disabled': !canAdvanceBackward }"
      :aria-disabled="!canAdvanceBackward"
      :aria-label="labels.navigation.ariaLabelPrev"
      :disabled="!canAdvanceBackward"
      @click.prevent="triggerPageAdvance('backward')"
      v-on="!canAdvanceForward ? { blur: blurEvent, 'keyup.shift.tab': shiftTabKeyupEvent } : {}"
    >
      <svg class="icon" aria-hidden="true">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-prev" />
      </svg>
    </button>
    <button
      class="carousel-navigation-button carousel-navigation-next"
      :class="{ 'carousel-navigation--disabled': !canAdvanceForward }"
      :aria-disabled="!canAdvanceForward"
      :aria-label="labels.navigation.ariaLabelNext"
      :disabled="!canAdvanceForward"
      @click.prevent="triggerPageAdvance('forward')"
      @blur="blurEvent()"
      @keyup.shift.tab="shiftTabKeyupEvent"
    >
      <svg class="icon" aria-hidden="true">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#arrow-next" />
      </svg>
    </button>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '../../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'Navigation',
  props: {
    labels: { type: Object, required: true },
    taggingLabels: {
      type: Object,
      required: true,
      default: () => {
        return {
          action: 'carousel',
          type: 'userActionEvent',
        };
      },
    },
  },
  data() {
    return {
      parentContainer: this.$parent,
      shiftAndTabHappened: false,
    };
  },
  computed: {
    canAdvanceForward() {
      return this.parentContainer.canAdvanceForward || false;
    },
    canAdvanceBackward() {
      return this.parentContainer.canAdvanceBackward || false;
    },
  },
  methods: {
    emitTaggingEvent(direction) {
      const action = direction === 'forward' ? 'next' : 'previous';
      let { position } = this.taggingLabels.navigation
        ? this.taggingLabels.navigation
        : this.taggingLabels;
      if (direction === 'forward') position = Number.parseInt(position) + 1;
      else position = Number.parseInt(position) - 1;
      const data = this.taggingLabels.navigation
        ? this.taggingLabels.navigation
        : this.taggingLabels;
      try {
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          action: 'carousel',
          ...data,
          label: action,
          action: action,
          click_action: action,
          position: position ? position.toString() : null,
        });
      } catch (error) {
        console.error(error);
      }
    },
    triggerPageAdvance(direction) {
      this.emitTaggingEvent(direction);
      this.$parent.advancePage(direction, 'navigation');
    },
    blurEvent() {
      if (this.shiftAndTabHappened) {
        eventBus.emit('lastArrowBlurBack');
        this.shiftAndTabHappened = false;
      } else {
        eventBus.emit('lastArrowBlur');
      }
    },
    shiftTabKeyupEvent() {
      this.shiftAndTabHappened = true;
    },
  },
};
</script>
