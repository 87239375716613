<template>
  <div class="switcher">
    <div v-if="$slots.panel1 && $slots.panel2" class="navigation__panelSwitcher">
      <slot name="title"></slot>
      <ul role="tablist" class="navigation__panelSwitcherTabs">
        <li
          aria-hidden="true"
          class="navigation__panelSwitchIndicator"
          :class="{ '-left': tab1Selected, '-right': tab2Selected }"
        ></li>
        <li
          :id="`${which}-tab1`"
          ref="tab1"
          role="tab"
          :aria-controls="`${which}-panel1`"
          :aria-selected="tab1Selected"
          :tabindex="tab1Selected ? 0 : -1"
          :class="{ '-active': tab1Selected }"
          class="navigation__panelSwitch"
          @click="switchPanel(true, false, 1)"
          @keyup.39="switchPanel(false, true, 2)"
        >
          {{ switch1 }}
        </li>

        <li
          :id="`${which}-tab2`"
          ref="tab2"
          role="tab"
          :aria-controls="`${which}-panel2`"
          :aria-selected="tab2Selected"
          :tabindex="tab2Selected ? 0 : -1"
          :class="{ '-active': tab2Selected }"
          class="navigation__panelSwitch"
          @click="switchPanel(false, true, 2)"
          @keyup.37="switchPanel(true, false, 1)"
        >
          {{ switch2 }}
        </li>
      </ul>
    </div>
    <div
      :id="`${which}-panel1`"
      ref="panel1"
      role="tabpanel"
      :aria-labelledby="`${which}-tab1`"
      :class="{ '-active': tab1Selected }"
      class="navigation__panel"
    >
      <div :class="panel1WrapperClass">
        <slot name="panel1"></slot>
      </div>
    </div>

    <div
      :id="`${which}-panel2`"
      ref="panel2"
      role="tabpanel"
      :aria-labelledby="`${which}-tab2`"
      :class="{ '-active': tab2Selected }"
      class="navigation__panel"
    >
      <div :class="panel2WrapperClass">
        <slot name="panel2"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler';

export default {
  name: 'Switcher',
  props: {
    which: { type: String, required: true, default: 'skincare' },
    switch1: { type: String, required: true, default: 'Products' },
    switch2: { type: String, required: true, default: 'Tips & Trends' },
    panel1WrapperClass: { type: String, default: '' },
    panel2WrapperClass: { type: String, default: '' },
    taggingEvent: { type: Object, default: () => {} },
  },
  data() {
    return {
      tab1Selected: true,
      tab2Selected: false,
      tabIndex: 0,
    };
  },
  methods: {
    updateScroller(index) {
      if (index === this.tabIndex) {
        return;
      }
      var label = index == 1 ? this.switch1 : this.switch2;
      this.tabIndex = index;
      eventBus.emit('updateScroller');
      this.pushTaggingEvent(label);
    },
    switchPanel(tab1, tab2, idPanel) {
      this.tab1Selected = tab1;
      this.tab2Selected = tab2;
      if (tab1) {
        this.$refs.tab1.focus();
      } else {
        this.$refs.tab2.focus();
      }
      this.updateScroller(idPanel);
      eventBus.emit('switchPanel', this.tabIndex);
    },
    pushTaggingEvent(label) {
      if (!this.taggingEvent) {
        return;
      }
      try {
        /* istanbul ignore next */
        AnalyticsHandler.getAnalyticsHandler().push({
          type: 'userActionEvent',
          ecommerce: this.taggingEvent.ecommerce,
          category: this.taggingEvent.category,
          action: this.taggingEvent.action,
          label: label.toLowerCase(),
        });
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Could not push to dataLayer', e);
      }
    },
  },
};
</script>
