import { inViewport } from './inViewport';
import { isVisible } from './isVisible';
import { throttle } from './timing';

export function inViewportAndVisible(node, options, callback) {
  return throttle(() => {
    /* istanbul ignore else */
    if (
      (options.checkIsVisible && inViewport(node) && isVisible(node)) ||
      (!options.checkIsVisible && inViewport(node))
    ) {
      callback();
    }
  }, options.delay);
}

export function intersectionObserverFallback(node, delay) {
  return new Promise((resolve) => {
    const scrollListener = inViewportAndVisible(node, delay, () => {
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('resize', scrollListener);
      resolve();
    });

    window.addEventListener('scroll', scrollListener, false);
    window.addEventListener('resize', scrollListener, false);

    scrollListener();
  });
}

const defaultConditionCheckCallback = (change) => change.intersectionRatio > 0;

/**
 * Create a new IntersectionObserver (or a fallback) and return a Promise which will be resolved when the node is
 * visible in the viewport.
 *
 * @param {HTMLElement} node
 * @param options
 * @param {Boolean}      options.checkIsVisible
 * @param {Number}       options.delay
 * @param {String}       options.rootMargin             [rootMargin docs]{@link https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/rootMargin}
 * @param {Number|Array} options.threshold              [thresholds docs]{@link https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/thresholds}
 * @param {Boolean}      options.shouldUnobserve
 * @param {Function}     options.conditionCheckCallback
 * @param {Function}     options.callbackBeforeResolve
 */
export function intersectionViewportObserver(node, options = {}) {
  const {
    checkIsVisible = true,
    delay = 500,
    rootMargin = '150px 150px 150px 150px',
    threshold = [0],
    shouldUnobserve = true,
    conditionCheckCallback = defaultConditionCheckCallback,
    callbackBeforeResolve = null,
  } = options;

  /* istanbul ignore else */
  if ('IntersectionObserver' in window) {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(
        (changes) => {
          changes.forEach((change) => {
            if (conditionCheckCallback(change)) {
              if (callbackBeforeResolve && typeof callbackBeforeResolve === 'function')
                callbackBeforeResolve();
              if (shouldUnobserve) observer.unobserve(node);
              resolve();
            }
          });
        },
        { rootMargin, threshold }
      );

      observer.observe(node);
    });
  } else {
    return intersectionObserverFallback(node, { delay, checkIsVisible });
  }
}
