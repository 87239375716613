<template>
  <form autocomplete="off" method="POST" novalidate @submit.prevent="validateFormBeforeSubmit">
    <slot :validation="isEmailValid" :data="formData" />
  </form>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
export default {
  name: 'NewsletterSignin',

  props: {
    emailInvalid: {
      type: String,
      required: true,
      default: 'Email must be valid.',
    },
    emailError: {
      type: String,
      required: true,
      default: 'Email must be filled.',
    },
    formAction: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      formData: {
        email: true,
        validation: {
          email: true,
        },
      },
      validateState: {
        email: {
          notDefined: false,
          invalid: false,
        },
      },
    };
  },

  computed: {
    isEmailValid() {
      const error = [];
      this.validateState.email.notDefined && error.push(this.emailError);
      this.validateState.email.invalid && error.push(this.emailInvalid);

      return error;
    },
  },

  created() {
    this.formData.validation.email = this.isEmailValid;
  },
  mounted() {
    eventBus.on('inputValue', (value) => {
      let refInput = value.refField;
      this.formData[refInput] = value.inputValue;
    });
  },
  methods: {
    validateFormBeforeSubmit() {
      if (this.validateEmail()) {
        const newAction = new URL([location.protocol, location.host, this.formAction].join(''));
        newAction.searchParams.append('email', this.formData.email);

        window.location.href = newAction.href;
      }
    },
    validateEmail() {
      this.validateState.email.notDefined = false;
      this.validateState.email.invalid = false;

      if (!this.formData.email) {
        this.validateState.email.notDefined = true;
        return false;
      }

      if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/gm.test(this.formData.email)) {
        this.validateState.email.invalid = true;
        return false;
      }

      return true;
    },
  },
};
</script>
