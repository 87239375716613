<template>
  <transition name="slideUpDown">
    <div v-if="showFloatingBar" class="vtoFloatingBar">
      <div class="vtoFloatingBar__mask" @click="displayFloatingBar(false)"></div>
      <div class="vtoFloatingBar__content">
        <header class="vtoFloatingBar__header">
          <h3 class="vtoFloatingBar__title">{{ dictionary.saveThisLook }}</h3>
          <svg class="icon" @click="displayFloatingBar(false)">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close" />
          </svg>
        </header>
        <div class="vtoFloatingBar__buttons">
          <div v-if="enableFavorite && favoriteSettings" class="vtoActions__favorite">
            <OapFavorite
              v-bind="favoriteProperties"
              :additional-button-classes="['oap-button', '-primary', '-has-icon-on-top']"
            >
              <svg class="icon">
                <use
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xlink:href="#icon-favorite-black-in-circle-white"
                />
              </svg>
              <span class="oap-button__label">{{ dictionary.saveOnYourAccount }}</span>
            </OapFavorite>
          </div>
          <button
            v-if="actionEmailEnabled"
            class="oap-button -primary -has-icon-on-top"
            @click="onEmailMyLooks"
          >
            <svg class="icon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-email" />
            </svg>
            <span class="oap-button__label">{{ emailButtonText }}</span>
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import OapFavorite from '../../../../OapFavorite/code/Scripts/components/oap-favorite.vue';
import { mapActions, mapState, mapGetters } from '../store/helpers';

export default {
  name: 'VtoFloatingBar',

  components: {
    OapFavorite,
  },

  computed: {
    ...mapState([
      'enableFavorite',
      'favoriteSettings',
      'emailButtonText',
      'emailButtonUrl',
      'actionEmailEnabled',
      'dictionary',
      'showFloatingBar',
    ]),

    ...mapGetters(['getSelectedProducts', 'getSelectedProductItems', 'getMainCategory']),

    favoriteProperties() {
      const { favoriteSettings, getSelectedProducts } = this;
      const selectedProductsIds = Object.values(getSelectedProducts);
      const itemList = selectedProductsIds.map((id) => ({
        ItemId: id,
      }));

      return {
        ...favoriteSettings,
        item: itemList,
      };
    },
  },

  methods: {
    ...mapActions(['displayFloatingBar']),

    onEmailMyLooks() {
      const { href } = window.location;
      const newUrl = new URL(this.emailButtonUrl, href);
      newUrl.searchParams.append(
        'products',
        this.getSelectedProductItems.map((item) => item.upc).join(',')
      );
      newUrl.searchParams.append('category', this.getMainCategory);
      newUrl.searchParams.append(
        'product-id',
        this.getSelectedProductItems.map((item) => item.id).join(',')
      );
      window.open(newUrl.href);
    },
  },
};
</script>
