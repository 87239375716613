import { AnalyticsAddToCartObjectException } from '../errors/analyticsError';
import ProductObject from './ProductObject';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class AddToCartObjectObject extends DataObject {
  constructor(data) {
    if (data.eventtype) {
      /* istanbul ignore next */
      super(data.eventype, data);
    } else {
      super('addToCart', data);
    }
    if (!data.category) {
      Logger.error(
        "[AnalyticsAddToCartObjectException] Category is required for uaevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsAddToCartObjectException(
        "Category is required for uaevent (L'Oréal guideline)"
      );
    }

    if (!data.action) {
      Logger.error(
        "[AnalyticsAddToCartObjectException] Action is required for uaevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsAddToCartObjectException(
        "Action is required for uaevent (L'Oréal guideline)"
      );
    }

    if (!data.label) {
      Logger.error(
        "[AnalyticsAddToCartObjectObjectException] Label is required for uaevent (L'Oréal guideline)",
        data
      );
      throw new AnalyticsAddToCartObjectException(
        "Label is required for uaevent (L'Oréal guideline)"
      );
    }

    if (!data.ecommerce) {
      Logger.error('[AnalyticsAddToCartObjectException] ecommerce is required for AddToCart', data);
      throw new AnalyticsAddToCartObjectException('ecommerce is required for AddToCart');
    } else {
      this.result.ecommerce = data.ecommerce;

      if (!data.ecommerce.currencyCode) {
        Logger.error(
          '[AnalyticsAddToCartObjectException] currencyCode is required for AddToCart',
          data
        );
        throw new AnalyticsAddToCartObjectException('currencyCode is required for AddToCart');

        // unreachable code
        // if (!data.ecommerce.products || data.ecommerce.products.length === 0) {
        //   Logger.error('[AnalyticsAddToCartObjectException] Product is required for AddToCart', data);
        //   throw new AnalyticsAddToCartObjectException('Product is required for AddToCart');
        // }
      }
    }
  }

  set category(category = 'Ecommerce') {
    this.result.eventCategory = category;
  }

  get category() {
    return this.result.eventCategory;
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action);
  }

  get action() {
    return this.result.eventAction;
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label);
  }

  get label() {
    return this.result.eventLabel;
  }

  set currencyCode(currencyCode) {
    this.result.currencyCode = this.setProperty(currencyCode);
  }

  get currencyCode() {
    return this.result.ecommerce.currencyCode;
  }

  set products(products) {
    products = products.map((product) => new ProductObject(product).toObject());

    this.result.ecommerce.products = products;
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.products) || [];
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set event_name(event_name) {
    this.result.event_name = this.setProperty(event_name);
  }

  get event_name() {
    return this.result.event_name;
  }

  set productInfo(productInfo) {
    this.result.product_info = this.setProperty(productInfo);
  }

  get productInfo() {
    return this.result.product_info;
  }

  set storeName(storeName) {
    this.result.store_name = this.setProperty(storeName);
  }

  get storeName() {
    return this.result.store_name;
  }

  set step(step) {
    this.result.step = this.setProperty(step);
  }

  get step() {
    return this.result.step;
  }

  set price(price) {
    this.result.price = this.setProperty(price);
  }

  get price() {
    return this.result.price;
  }
}
