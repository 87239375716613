import { debug } from '../../env/env';
import eventBus from '@loreal/eventbus-js';
import { inViewport, isVisible, throttle } from '../../utilities';
import { AnalyticsHandler } from '../AnalyticsHandler';
import createDataObjectBasedOnDom from './createDataObjectBasedOnDom';

if (
  'IntersectionObserver' in window &&
  'IntersectionObserverEntry' in window &&
  'intersectionRatio' in window.IntersectionObserverEntry.prototype &&
  !('isIntersecting' in IntersectionObserverEntry.prototype)
) {
  Object.defineProperty(window.IntersectionObserverEntry.prototype, 'isIntersecting', {
    get() {
      return this.intersectionRatio > 0;
    },
  });
}

/**
 * Binds the event for the Google TagManager action to the DOM node. Click or Impression.
 * @param {String} type Type of event. Can be click or impression.
 * @param {Node} node Dom node to use for event handling.
 * @param {Object} data Data send by the component who wants to send an analytics event.
 * @param {Object} modifiers Modifiers passed from directive
 */
export default function handleEvent(type, node, data, modifiers = {}) {
  const isCurrentTarget = ({ target, currentTarget }) => target === currentTarget;
  const onEvent = (registerOnly = false, event) => {
    let dataToSend;

    if (modifiers.target && !isCurrentTarget(event)) {
      return;
    }

    dataToSend = modifiers.dom
      ? createDataObjectBasedOnDom(node.closest('[data-tag-wrapper]'), data)
      : data;
    if (modifiers.view) {
      dataToSend.viewPort = true;
    }
    try {
      AnalyticsHandler.getAnalyticsHandler().push(dataToSend, registerOnly);
    } catch (er) {
      if (debug) {
        node.setAttribute('data-error', er.message);
        node.classList.add('accessibilityError');
      }
      throw er;
    }
  };

  if (type === 'click') {
    node.addEventListener('click', (event) => {
      onEvent(false, event);
    });
  } else if (type === 'conditional') {
    onEvent(true);
    eventBus.on('conditions-met', onEvent);
  } else if ('IntersectionObserver' in window) {
    const observer = new IntersectionObserver(
      (changes) => {
        changes.forEach((change) => {
          if (change.isIntersecting) {
            onEvent();
            observer.unobserve(node);
          }
        });
      },
      {
        threshold: [0.006],
      }
    );

    observer.observe(node);
  } else {
    const scrollCallback = (event) => {
      /* istanbul ignore else */
      if (inViewport(node) && isVisible(node)) {
        onEvent(false, event);

        window.removeEventListener('scroll', throttle(scrollCallback, 500));
      }
    };

    window.addEventListener('scroll', throttle(scrollCallback, 500), false);
    scrollCallback();
  }
}
