import { AnalyticsPromotionClickObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import PromotionObject from './PromotionObject';

export default class PromotionClickObject extends DataObject {
  constructor(data) {
    super('promotionClick', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsPromotionClickObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${data.category}] was provided`,
        data,
        false
      );
    }

    this.action = 'Promotion Click';
    if (data.action) {
      Logger.warn(
        `[AnalyticsPromotionClickObjectException] Action always defaults to "Promotion Click" (L'Oréal guideline), currently [${data.action}] was provided`,
        data,
        false
      );
    }

    this.action = 'Promotion Click';
    if (data.label) {
      this.label = data.label;
    }

    if (!data.promotions || data.promotions.length === 0) {
      Logger.error(
        '[AnalyticsPromotionClickObjectException] promotion is required for promotionClick',
        data
      );
      throw new AnalyticsPromotionClickObjectException('promotion is required for promotionClick');
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
    this.result.ecommerce.promoClick = this.result.ecommerce.promoClick || {};
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.eventLabel = this.setProperty(label, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set promotions(promotions) {
    promotions = promotions.map((promotion) => new PromotionObject(promotion).toObject());

    this.initEcommerce();

    this.result.ecommerce.promoClick.promotions = promotions;
  }
}
