<template>
  <div :class="['oap-page-navigation', { '-has-desktop-view': hasDesktopView }]">
    <ul ref="pageNavigationList" class="oap-page-navigation__list">
      <li
        v-for="(item, index) in filteredItems"
        :key="index"
        :class="['oap-page-navigation__item', { '-is-button': item.isButton === true }]"
        @click.prevent="scrollToSection(item.anchor)"
      >
        <a
          :class="{
            '-active': activeSection === item.anchor,
          }"
          :href="item.anchor"
        >
          {{ item.title }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { debounce, intersectionViewportObserver } from '@Foundation';

const SCROLL_INDENT_FOR_NAVIGATION = 20; // 20px spacing between navigation and scrollable element

export default {
  name: 'OapPageNavigation',

  props: {
    items: { type: Array, default: () => [] },
    scrollToTop: { type: Boolean, default: true },
    hasDesktopView: { type: Boolean, default: false },
  },

  data() {
    return {
      activeSection: null,
      scrollPosition: 0,
      filteredItems: [],
    };
  },

  mounted() {
    window.addEventListener('scroll', debounce(this.highlightActiveSection, 300));
    window.addEventListener('resize', this.centerActiveNavigationItem);
    window.addEventListener('orientationchange', this.centerActiveNavigationItem);
    window.addEventListener('scrollend', this.centerActiveNavigationItem);

    this.$nextTick(() => {
      this.updateFilteredItems();
    });

    intersectionViewportObserver(this.$el, {
      delay: 0,
      rootMargin: '0px',
      conditionCheckCallback: this.toggleVisibilityClass,
    });
  },

  methods: {
    scrollToSection(sectionId) {
      if (!sectionId.startsWith('#')) {
        window.location.href = sectionId;
      }

      const targetSection = document.getElementById(this.cleanAnchor(sectionId));

      if (targetSection) {
        const header = document.querySelector('header');
        const pageNavigation = document.querySelector('.oap-page-navigation');
        const toolbar = document.querySelector('.oap-product-toolbar');
        let scrollTop = 0;

        if (this.scrollPosition >= targetSection.offsetTop) {
          scrollTop =
            targetSection.offsetTop -
            (header.getBoundingClientRect().height +
              pageNavigation.getBoundingClientRect().height +
              (this.hasDesktopView && toolbar ? toolbar.getBoundingClientRect().height : 0) +
              SCROLL_INDENT_FOR_NAVIGATION);
        } else {
          scrollTop =
            targetSection.offsetTop -
            pageNavigation.getBoundingClientRect().height -
            (this.hasDesktopView && toolbar ? toolbar.getBoundingClientRect().height : 0) -
            SCROLL_INDENT_FOR_NAVIGATION;
        }

        window.scrollTo({
          top: scrollTop,
          behavior: 'smooth',
        });

        this.activeSection = targetSection.id;
      }
    },

    highlightActiveSection() {
      this.scrollPosition = window.scrollY;

      const header = document.querySelector('header');
      const pageNavigation = document.querySelector('.oap-page-navigation');
      const scrollIndent =
        header.getBoundingClientRect().height +
        pageNavigation.getBoundingClientRect().height +
        SCROLL_INDENT_FOR_NAVIGATION;

      for (const section of this.filteredItems) {
        const sectionElement = document.getElementById(this.cleanAnchor(section.anchor));

        if (sectionElement) {
          const rect = sectionElement.getBoundingClientRect();

          if (Math.floor(Math.abs(rect.top) - scrollIndent) <= 0) {
            this.activeSection = section.anchor;
          }
        }
      }
    },

    centerActiveNavigationItem() {
      const pageNavigationList = this.$refs.pageNavigationList;

      if (pageNavigationList && this.activeSection) {
        const activeNavigationItem = pageNavigationList.querySelector('.-active');

        if (activeNavigationItem) {
          const navigationWidth = pageNavigationList.offsetWidth;
          const activeNavigationItemWidth = activeNavigationItem.offsetWidth;
          const scrollLeft =
            activeNavigationItem.offsetLeft - (navigationWidth - activeNavigationItemWidth) / 2;

          pageNavigationList.scrollTo({
            left: scrollLeft,
            behavior: 'smooth',
          });
        }
      }
    },

    updateFilteredItems() {
      this.filteredItems = this.items.filter((item) => this.elementExists(item));
    },

    elementExists(item) {
      return !item.anchor.startsWith('#') || document.getElementById(item.anchor.slice(1)) !== null;
    },

    cleanAnchor(anchor) {
      return anchor.replace(/#/g, '');
    },

    toggleVisibilityClass(entry) {
      this.$el.parentElement.classList.toggle('navigation-is-in-viewport', entry.isIntersecting);
    },
  },
};
</script>
