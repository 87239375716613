import { AnalyticsProductObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';

export default class ProductObject extends DataObject {
  constructor(data) {
    super(undefined, data);

    if (!this.name && !this.id) {
      Logger.error(
        '[AnalyticsProductObjectException] A Product Name or a Product ID is required for an Analytics Product Object',
        data
      );
      throw new AnalyticsProductObjectException(
        'A Product Name or a Product ID is required for an Analytics Product Object'
      );
    }

    this.dimensionKeys = Object.keys(data).filter((dimensionKey) => /dimension/.test(dimensionKey));
    if (this.dimensionKeys) {
      this.dimensionKeys.forEach((key) => {
        this.result[key] = this.setProperty(data[key]);
      });
    }
  }

  set name(name) {
    this.result.name = this.setProperty(name);
  }

  get name() {
    return this.result.name;
  }

  set id(id) {
    this.result.id = this.setProperty(id);
  }

  get id() {
    return this.result.id;
  }

  set price(price) {
    this.result.price = this.setProperty(price);
  }

  set brand(brand) {
    this.result.brand = this.setProperty(brand).toUpperCase();
  }

  set category(category) {
    this.result.category = this.setProperty(category);
  }

  set variant(variant) {
    this.result.variant = this.setProperty(variant);
  }

  set position(position) {
    this.result.position = Number(this.setProperty(String(position)));
  }

  set list(list) {
    this.result.list = this.setProperty(list);
  }

  setDynamicProp(prop, value) {
    this.result[prop] = this.setProperty(value);
  }

  getDynamicProp(prop) {
    return this.result[prop];
  }
}
