import { debug } from '../../../../../../Foundation/Core/code/Scripts/env/env';

export const actionLoggerPlugin = (store) => {
  const logStyles = ['background: transparent', 'color: #2ecc71', 'font-weight: bold'].join(';');

  const vtoDebug = (state) => debug || state.debug || window.vtoDebug;

  const logAction = ({ type, payload }, state) => {
    if (vtoDebug(state)) {
      const timestamp = '[' + new Date().toTimeString().split(' ')[0] + '] ',
        c = window['console'];

      c.group(`vuex%c action: ${type}`, logStyles, timestamp);
      c.log(payload);
      c.groupEnd();
    }
  };

  store.subscribeAction(logAction);
};
