import { AnalyticsException } from './errors/analyticsError';
import { TAGGING_TYPES, TAGGING_TYPES_ARRAY } from './models/TaggingTypes';
import Logger from './utils/analyticsLogger';

/* istanbul ignore next */
export const pushDataLayer = (data) => {
  waitFor(
    () => document.readyState === 'complete',
    () => window.dataLayer.push(data)
  );
};

export const waitFor = (condition, callback) => {
  if (!condition()) {
    window.setTimeout(waitFor.bind(null, condition, callback), 10);
  } else {
    callback();
  }
};

/**
 * AnalyticsHandler pushes structured data objects to the google GTM dataLayer
 * @class AnalyticsHandler
 */
export class AnalyticsHandler {
  /**
   * AnalyticsHandler constructor.
   * @constructs AnalyticsHandler
   */
  constructor() {
    Logger.info('debug enabled');

    /* istanbul ignore next */
    if (typeof window.dataLayer === 'undefined') {
      Logger.warn('dataLayer is not defined. Creating local dataLayer for debugging purposes.');
      window.dataLayer = [];
    }
  }

  /**
   * Returns the data object for GTM dataLayer based on given type.
   * @param {object} data data to send to factory classes for analytics types.
   */
  taggingObjectByType(data) {
    if (TAGGING_TYPES_ARRAY.indexOf(data.type) === -1) {
      const types = TAGGING_TYPES_ARRAY.join(', ');

      Logger.error(`[AnalyticsException] Wrong type given: [${data.type}]. Type options: ${types}`);
      throw new AnalyticsException(`Wrong type given: [${data.type}]. Type options: ${types}`);
    }

    const DataClass = TAGGING_TYPES[data.type];

    return new DataClass(data).toObject();
  }

  /**
   * Pushes data to GTM dataLayer
   * @param {object} data data to send to factory classes for analytics types.
   * @param registerOnly this is for conditional event, do not push until all conditions are met
   */
  push(data, registerOnly = false) {
    if (!data) {
      throw new AnalyticsException('No data given to AnalyticsHandler.push function');
    }

    const dataToPush = this.taggingObjectByType(data);
    Logger.info(`Pushing [${data.type}] to dataLayer`, dataToPush);

    if (!registerOnly) {
      try {
        pushDataLayer(dataToPush);
      } catch (error) {
        Logger.error('Error while pushing to dataLayer', error);
        throw new AnalyticsException(error.message);
      }
    }
    return this;
  }

  static getAnalyticsHandler() {
    this.$analyticsHandler = this.$analyticsHandler || new AnalyticsHandler();
    return this.$analyticsHandler;
  }
}
