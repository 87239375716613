<template>
  <ul>
    <li v-for="(article, $index) in items" :key="$index" :class="cssClass">
      <article class="oap-card oap-card--type-content" data-tag-wrapper>
        <div class="oap-card__thumbnail">
          <img
            v-srcset="article.src"
            :alt="article.alt"
            aria-hidden="true"
            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
          />
        </div>
        <header>
          <h3 class="oap-card__heading">
            <a
              v-tag:useractionevent.dom
              class="oap-card__link"
              :href="article.url"
              :title="article.title"
              :data-tag-category="tagCategory"
              :data-tag-action="tagAction"
              :data-tag-label="`select::${article.title}`"
              :data-tag-eventName="`body_button_click`"
              :data-tag-module-name="tagAction"
              :data-tag-cta-name="article.title"
              :data-tag-link-url="article.url"
              :data-tag-article-name="article.title"
            >
              <span class="oap-card__title">{{ article.title }}</span>
            </a>
          </h3>
        </header>
      </article>
    </li>
  </ul>
</template>

<script>
import { tag } from '../../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';
import { srcset } from '../../../../../../Foundation/Core/code/Scripts/directives/srcset/srcset';

export default {
  name: 'ArticleSliderModel',
  directives: {
    tag,
    srcset,
  },
  props: {
    items: {
      type: Array,
      require: false,
      default: () => {
        return [];
      },
    },
    tagAction: {
      type: String,
      required: true,
      default: 'related article',
    },
    tagCategory: {
      type: String,
      required: true,
      default: '',
    },
    cssClass: {
      type: String,
      required: false,
      default: 'oap-slider__item',
    },
  },
};
</script>
