export const ENABLE_CONTRAST_HIGHLIGHT = 'enable_contrast_highlight';
export const DISABLE_CONTRAST_HIGHLIGHT = 'disable_contrast_highlight';
export const TOGGLE_CONTRAST_HIGHLIGHT = 'toggle_contrast_highlight';
export const GET_CONTRAST_HIGHLIGHT = 'get_contrast_highlight';

export const ENABLE_SITEWIDE_ANIMATIONS = 'enable_sitewide_animations';
export const DISABLE_SITEWIDE_ANIMATIONS = 'disable_sitewide_animations';
export const TOGGLE_SITEWIDE_ANIMATIONS = 'toggle_sitewide_animations';
export const GET_SITEWIDE_ANIMATIONS = 'get_sitewide_animations';
export const ACCESSIBILITY_STORE_NAMESPACE = 'accessibility_store_namespace';

export const GETTERS = {
  [GET_CONTRAST_HIGHLIGHT]: (state) => state.isContrastHighlighEnabled,
  [GET_SITEWIDE_ANIMATIONS]: (state) => !state.isAnimationDisabled,
};

export const ACTIONS = {
  [ENABLE_CONTRAST_HIGHLIGHT]: ({ commit }) => {
    commit(TOGGLE_CONTRAST_HIGHLIGHT, true);
  },
  [DISABLE_CONTRAST_HIGHLIGHT]: ({ commit }) => {
    commit(TOGGLE_CONTRAST_HIGHLIGHT, false);
  },
  [ENABLE_SITEWIDE_ANIMATIONS]: ({ commit }) => {
    commit(TOGGLE_SITEWIDE_ANIMATIONS, false);
  },
  [DISABLE_SITEWIDE_ANIMATIONS]: ({ commit }) => {
    commit(TOGGLE_SITEWIDE_ANIMATIONS, true);
  },
};

export const MUTATIONS = {
  [TOGGLE_CONTRAST_HIGHLIGHT]: (state, value) => {
    state.isContrastHighlighEnabled = value;
  },
  [TOGGLE_SITEWIDE_ANIMATIONS]: (state, value) => {
    state.isAnimationDisabled = value;
  },
};

export const STATE = {
  isAnimationDisabled: false,
  isContrastHighlighEnabled: false,
};
