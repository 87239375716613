/* eslint-disable no-new,dot-notation */
import { AnalyticsHandler } from '../analyticsHandler/AnalyticsHandler';
import { debug } from '../env/env';
import eventBus from '@loreal/eventbus-js';
import InputHandlerService from '../inputHandler/InputHandler';
import MqHandler from '../mqHandler/MqHandler';

// Export globally the content of this modules
/* istanbul ignore next */
try {
  /**
   * @deprecated
   * @type {any & {debug: boolean, AnalyticsHandler: *} & any}
   */
  window.wsf = Object.assign(window.wsf || {}, {
    debug,
    /**
     * @deprecated
     */
    eventBus,
    /**
     * @deprecated
     * Initiate AnalyticsHandler helper class.
     * Is able to send events, product clicks and product impressions to google tag manager
     */
    AnalyticsHandler: AnalyticsHandler.getAnalyticsHandler(debug),
    /**
     * @deprecated
     * Initiate InputHandler helper class.
     * InputHandler listens to keyboard and mouse events on the document.
     * It adds a classname to the body while the user is using keyboard navigation.
     * It removes the keyboard using classname when the user uses the mouse again.
     * Attached to window so it can be referenced on feature and project level
     */
    InputHandler: InputHandlerService.getInputHandler(),
    /**
     * @deprecated
     * Initiate mqHander helper class.
     * Will use the eventBus to emit mediaquery changes based on matchMedia listeners
     * Also has functions to ask for the current media query or to get a matchMedia object by screensize
     * Attached to window so it can be referenced on feature and project level
     */
    MqHandler: MqHandler.getMqHandler(),
  });
} catch (er) {
  console.warn('unable to load wsf variable');
}
