import { intersectionViewportObserver } from '../../utilities/intersectionViewportObserver';

export const USER_INTERACTION_EVENT_TYPES_ALL = [
  'mousemove',
  'scroll',
  'keyup',
  'keydown',
  'touchstart',
  'click',
];
export const USER_INTERACTION_EVENT_SCROLL = ['scroll', 'touchstart'];

const USER_INTERACTION_EVENT_COMPLETE = 'interacted';

export const userInteractionEvent = (eventTypes, node, onComplete) => {
  eventTypes.forEach((event) => {
    window.addEventListener(event, onComplete);
  });
};

export const isElementVisible = (node, options) => {
  intersectionViewportObserver(node, options);
};

export const userInteraction = {
  name: 'user-interaction',
  beforeMount: (el, bindings, vnode) => {
    const { modifiers } = bindings;
    const done = () => {
      USER_INTERACTION_EVENT_TYPES_ALL.forEach((event) => {
        window.removeEventListener(event, done);
      });

      if (vnode.componentInstance) {
        vnode.componentInstance.$emit(USER_INTERACTION_EVENT_COMPLETE);
      } else {
        vnode.el.dispatchEvent(new CustomEvent(USER_INTERACTION_EVENT_COMPLETE));
      }
    };

    if (modifiers.scroll) {
      userInteractionEvent(USER_INTERACTION_EVENT_SCROLL, vnode, done);
    } else if (modifiers.visible) {
      const node = vnode.componentInstance || vnode.el;
      isElementVisible(node, { callbackBeforeResolve: done });
    } else {
      userInteractionEvent(USER_INTERACTION_EVENT_TYPES_ALL, vnode, done);
    }
  },
};
