import InjectScript from '../../loadscript/injectscript';
import {
  USER_INTERACTION_EVENT_TYPES_ALL,
  USER_INTERACTION_EVENT_SCROLL,
  userInteractionEvent,
} from '../userInteraction/userInteraction';
import { intersectionViewportObserver } from '../../utilities/intersectionViewportObserver';

const ON_SCRIPT_LOAD = 'loaded';

export const injectScriptFunction = (value, vnode) => {
  const { async, crossorigin, integrity, name, src, url } = value;

  InjectScript.injectScript(src || url, {
    async: async || true,
    crossorigin,
    integrity,
    name,
  }).then(() => {
    if (vnode.componentInstance) {
      vnode.componentInstance.$emit(ON_SCRIPT_LOAD);
    } else {
      vnode.elm?.dispatchEvent(new CustomEvent(ON_SCRIPT_LOAD));
    }
  });
};

export const lazyScript = {
  name: 'lazy-script',
  beforeMount: (el, bindings, vnode) => {
    const { value, modifiers } = bindings;
    const onComplete = () => {
      USER_INTERACTION_EVENT_TYPES_ALL.forEach((event) => {
        window.removeEventListener(event, onComplete);
      });

      injectScriptFunction(value, vnode);
    };

    if (modifiers.scroll) {
      userInteractionEvent(USER_INTERACTION_EVENT_SCROLL, vnode, onComplete);
    } else if (modifiers.visible) {
      intersectionViewportObserver(el, { checkIsVisible: true, threshold: [0.006] }).then(() => {
        injectScriptFunction(value, vnode);
      });
    } else if (modifiers.onload) {
      window.addEventListener('load', () => {
        injectScriptFunction(value, vnode);
        window.removeEventListener('load', onComplete);
      });
    } else {
      userInteractionEvent(USER_INTERACTION_EVENT_TYPES_ALL, vnode, onComplete);
    }
  },
};
