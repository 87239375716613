import eventBus from '@loreal/eventbus-js';

export const mixinSetupPlayerAndBindToEvents = {
  methods: {
    setupPlayerAndBindToEvents(extraPlayerVars = {}) {
      return new Promise((resolve) => {
        this.setupYouTube().then(() => {
          // eslint-disable-next-line no-undef
          this.player = new YT.Player(this.playerId, {
            playerVars: {
              /*
                if the rel parameter is set to 0,
                related videos will come from the same channel
                as the video that was just played.
                rel is no longer for disabling related videos.
               */
              rel: 0,
              modestbranding: 1,
              ...extraPlayerVars,
              ...(extraPlayerVars?.loop === 1 && {
                /*
                  This parameter has limited support in IFrame embeds.
                  To loop a single video, set the loop parameter
                  value to 1 and set the playlist parameter value to the
                  same video ID already specified in the Player API URL.
                 */
                playlist: this.videoId,
              }),
            },
            videoId: this.videoId,
            host: this.host,
            events: {
              onReady: () => {
                this.playerRendered = true;
                resolve();
              },
              onStateChange: (event) => {
                eventBus.emit('youtube::state-change', event);
              },
            },
          });
        });
      });
    },
  },
};
