<template>
  <img
    v-if="lazy"
    class="lazyload"
    :class="format ? `-${format}` : ''"
    :alt="alt"
    :data-srcset="srcset"
    :data-src="fallbackurl"
    :data-sizes="sizes"
    :aria-hidden="ariaHidden"
  />
  <img
    v-else
    :class="format ? `-${format}` : ''"
    :alt="alt"
    :srcset="srcset"
    :src="fallbackurl"
    :sizes="sizes"
    :aria-hidden="ariaHidden"
  />
</template>

<script>
// resolutions: [{width: 250, hash: ''}, {width: 300, hash: ''}, {width: 400, hash: ''}]
// fallback-size: {width: 150, hash: ''}

export default {
  name: 'MediaImage',
  props: {
    filename: { type: String, required: false },
    alt: { type: String, default: '' },
    resolutions: {
      type: Array,
      required: false,
      default: '{"width": 250, "hash": ""}, {"width": 300, "hash": ""}, {"width": 400, "hash": ""}',
    },
    lazy: { type: Boolean, default: false },
    sizes: { type: String, default: '100vw', required: false },
    format: { type: String, default: 'tall', required: false },
    fallbackSize: { type: Object, required: false, default: '{"width": 150, "hash": ""}' },
    ariaHidden: { type: Boolean, default: true, required: false },
  },

  computed: {
    srcset() {
      let srcset = '';

      if (this.resolutions === null || this.resolutions === undefined) {
        return false;
      }

      this.resolutions.forEach((resolution) => {
        srcset += `${this.getUrl(resolution.width, resolution.hash)} ${resolution.width}w,`;
      });

      srcset = srcset.slice(0, -1);
      return srcset;
    },
    fallbackurl() {
      if (this.fallbackSize === null || this.fallbackSize === undefined) {
        return false;
      }

      return this.getUrl(this.fallbackSize.width, this.fallbackSize.hash);
    },
  },
  methods: {
    getUrl(width, hash) {
      if (this.filename.includes('?')) {
        return `${this.filename}&w=${width}&hash=${hash}`;
      }
      return `${this.filename}?w=${width}&hash=${hash}`;
    },
  },
};
</script>
