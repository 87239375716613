import { throttle } from '@Foundation';
import eventBus from '@loreal/eventbus-js';

export const scrollPosition = () => {
  return Math.round(
    (window.scrollY / (document.documentElement.offsetHeight - window.innerHeight)) * 100
  );
};

export const scrollVisibilityTracker = {
  name: 'scroll-visibility-tracker',
  beforeMount: (el, { value }) => {
    el.classList.add('is-hidden');

    window.addEventListener(
      'scroll',
      throttle(() => {
        const position = scrollPosition();

        if (position >= value.scrollDepthPercentage && el.classList.contains('is-hidden')) {
          el.classList.remove('is-hidden');
          el.classList.add(value.classToShow ? value.classToShow : 'is-block');
          eventBus.emit('scroll-tracking:reached');
        }
      }, 200)
    );
  },
};
