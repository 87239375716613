<template>
  <div class="scrollbar">
    <slot></slot>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import Scroller from './scroller'; // eslint-disable-line

export default {
  name: 'Scroller',

  props: {
    scaleFactor: { type: Number, required: false, default: 1 },
    isVto: { type: Boolean, default: false },
  },

  data() {
    return {
      scrollbar: null,
      timer: 500,
    };
  },

  mounted() {
    this.scrollbar = new Scroller(this.$el, {
      scaleFactor: this.scaleFactor,
      parentNode: this.$parent,
      isVTO: this.isVto,
    });
    this.scrollbar.getContentElement().addEventListener('scroll', this.onScrollX);

    eventBus.on('updateScroller', () => {
      /* istanbul ignore next */
      if (this.scrollbar.el.parentNode.classList.contains('slider__mega-menu')) {
        this.scrollbar.trackX.style.opacity = 0;
        setTimeout(() => {
          this.scrollbar.render(true);
          this.scrollbar.trackX.style.opacity = 1;
        }, this.timer);
      }
    });

    setTimeout(() => {
      this.scrollbar.recalculate();
    }, this.timer * 2);
  },

  methods: {
    onScrollX(evt) {
      /* istanbul ignore next */
      this.$emit('scrollX', evt);
    },
  },
};
</script>
