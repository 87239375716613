<template>
  <div v-if="isVisible" class="vue-focus-loop">
    <div :tabindex="getTabindex" aria-hidden="true" @focus="handleFocusStart" />
    <div ref="focusLoop">
      <slot />
    </div>
    <div :tabindex="getTabindex" aria-hidden="true" @focus="handleFocusEnd" />
  </div>
</template>

<script>
const focusableElementsSelector = [
  ...['input', 'select', 'button', 'textarea'].map((field) => `${field}:not([disabled])`),
  'a[href]',
  'video[controls]',
  'audio[controls]',
  '[tabindex]:not([tabindex^="-"])',
  '[contenteditable]:not([contenteditable="false"])',
].join(',');

export default {
  name: 'OapFocusTrap',

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    isVisible: {
      type: Boolean,
      default: false,
    },

    autoFocus: {
      type: Boolean,
      default: true,
    },

    domNodesToHide: {
      type: Array,
      default: () => ['header', 'footer', '#content'],
    },

    handleBodyScroll: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      alreadyFocused: false,
    };
  },

  settings: {
    autofocusDelay: 200,
  },

  computed: {
    getTabindex() {
      return this.disabled ? -1 : 0;
    },
  },

  watch: {
    isVisible: 'init',
    disabled: 'init',
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      if (this.handleBodyScroll) {
        this.toggleBodyScroll();
      }

      this.domNodesToHide.forEach((node) => {
        let nodeInDom = document.querySelector(node);
        if (nodeInDom) {
          nodeInDom.setAttribute('aria-hidden', this.isVisible);
        }
      });

      this.$nextTick(() => {
        const active = this.isVisible && !this.disabled;
        !this.disabled && this.focusFirst(active && this.autoFocus);
        this.managePrevFocusElement(active);
      });
    },

    managePrevFocusElement(active) {
      if (!active && window.vflPrevFocusedElement) {
        return window.vflPrevFocusedElement.focus();
      }
      window.vflPrevFocusedElement = document.activeElement;
    },

    focusFirst(isAutoFocus) {
      if (isAutoFocus) {
        const elements = this.getFocusableElements();
        if (elements.length) {
          setTimeout(() => elements[0].focus(), this.$options.settings.autofocusDelay);
        }
      }
    },

    getFocusableElements() {
      const focusableElements = this.$refs.focusLoop.querySelectorAll(focusableElementsSelector);
      if (focusableElements && focusableElements.length) {
        return focusableElements;
      }
      return [];
    },

    handleFocusStart() {
      const elements = this.getFocusableElements();
      if (elements.length) {
        const index = this.alreadyFocused ? elements.length - 1 : 0;
        this.alreadyFocused = true;
        elements[index].focus();
      }
    },

    handleFocusEnd() {
      const elements = this.getFocusableElements();
      elements.length && elements[0].focus();
    },

    toggleBodyScroll() {
      const body = document.querySelector('body');
      this.isVisible
        ? body.classList.add('is-overflow-hidden')
        : body.classList.remove('is-overflow-hidden');
    },
  },
};
</script>
