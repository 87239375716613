<template>
  <button v-if="content != ''" class="vtoTip" v-on="$attrs" @click="$attrs.click && $attrs.click()">
    {{ content }}
  </button>
</template>

<script>
export default {
  name: 'VtoTip',
  props: {
    content: { type: String, required: true },
  },
};
</script>
