export const mixinSetupYouTube = {
  methods: {
    /**
     * setupYouTube
     * @return {Promise}
     */
    setupYouTube(time = 300, tries = 5) {
      return new Promise((resolve, reject) => {
        let interval = 0;
        let tryCount = 0;
        interval = setInterval(() => {
          // eslint-disable-next-line no-undef
          if (typeof YT !== 'undefined' && YT.loaded === 1 && typeof YT.Player !== 'undefined') {
            clearInterval(interval);
            // eslint-disable-next-line no-undef
            resolve(YT);
          } else if (tryCount >= tries) {
            clearInterval(interval);
            reject(new TypeError('YouTube player setup failed'));
          }

          ++tryCount;
        }, time);
      });
    },
  },
};
