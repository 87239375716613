import { debounce } from '../../utilities/timing';

const OAP_ACCOUNT_NAVIGATION_CLASS = '.oap-account-navigation';
const OAP_HEADER_CLASS = '.oap-header';
const OAP_SIGNED_IN_ELEMENTS_CLASS = '.oap-header__signed-elements';

export const OAP_HEADER_HEIGHT_CSS_VARIABLE = '--oap-header-height';
export const OAP_HEADER_TOTAL_HEIGHT_CSS_VARIABLE = '--oap-header-total-height';
export const OAP_HEADER_MAIN_HEIGHT_CSS_VARIABLE = '--oap-header-main-height';
const LANGUAGE_SWITCHER_CLASS = '.languageSwitchBanner';
const COOKIE_BANNER_CLASS = '.cookie-banner';
const LIVESTREAM_CLASS = '.oap-livestream';

const MINIMUM_NAVIGATION_HEIGHT = 72;

export const getElementCurrentHeight = (el) => {
  const elHeight = window.getComputedStyle(el).height;
  return elHeight === 'auto' ? el.getBoundingClientRect().height : elHeight;
};

export const updateCurrentNavigationHeight = (el) => {
  const newHeight = Number.parseInt(getElementCurrentHeight(el));
  let totalHeight = newHeight;
  let mainHeaderHeight = newHeight;
  const myAccountNavigation = el
    .closest(OAP_HEADER_CLASS)
    .querySelector(OAP_ACCOUNT_NAVIGATION_CLASS);
  const signedInElements = el.querySelector(OAP_SIGNED_IN_ELEMENTS_CLASS);
  const languageSwitcher = el.querySelector(LANGUAGE_SWITCHER_CLASS);
  const cookiesBanner = el.querySelector(COOKIE_BANNER_CLASS);
  const livestream = el.querySelector(LIVESTREAM_CLASS);

  if (signedInElements) {
    mainHeaderHeight =
      Number.parseInt(newHeight) - Number.parseInt(getElementCurrentHeight(signedInElements));
  }

  if (languageSwitcher) {
    mainHeaderHeight =
      mainHeaderHeight - Number.parseInt(getElementCurrentHeight(languageSwitcher));
  }
  if (cookiesBanner) {
    mainHeaderHeight = mainHeaderHeight - Number.parseInt(getElementCurrentHeight(cookiesBanner));
  }

  if (livestream) {
    mainHeaderHeight = mainHeaderHeight - Number.parseInt(getElementCurrentHeight(livestream));
  }

  if (myAccountNavigation) {
    totalHeight =
      Number.parseInt(newHeight) + Number.parseInt(getElementCurrentHeight(myAccountNavigation));
  }

  return {
    totalHeight: `${
      totalHeight > MINIMUM_NAVIGATION_HEIGHT ? totalHeight : MINIMUM_NAVIGATION_HEIGHT
    }px`,
    mainHeight: `${
      mainHeaderHeight > MINIMUM_NAVIGATION_HEIGHT ? mainHeaderHeight : MINIMUM_NAVIGATION_HEIGHT
    }px`,
    newHeight: `${newHeight > MINIMUM_NAVIGATION_HEIGHT ? newHeight : MINIMUM_NAVIGATION_HEIGHT}px`,
  };
};

export const updateVariablesFunction = (el) => {
  const { totalHeight, mainHeight, newHeight } = updateCurrentNavigationHeight(el);
  document.body.style.setProperty(OAP_HEADER_MAIN_HEIGHT_CSS_VARIABLE, mainHeight);

  document.body.style.setProperty(OAP_HEADER_HEIGHT_CSS_VARIABLE, newHeight);

  document.body.style.setProperty(OAP_HEADER_TOTAL_HEIGHT_CSS_VARIABLE, totalHeight);
};

export const mounted = (el) => {
  const languageSwitcher = el.querySelector(LANGUAGE_SWITCHER_CLASS);
  const cookiesBanner = el.querySelector(COOKIE_BANNER_CLASS);
  let resizeObserver;
  if (languageSwitcher && Number.parseInt(getElementCurrentHeight(languageSwitcher))) {
    resizeObserver = new ResizeObserver(
      debounce(() => {
        updateVariablesFunction(el);
      }),
      25
    );

    resizeObserver.observe(languageSwitcher);
  } else if (cookiesBanner && Number.parseInt(getElementCurrentHeight(cookiesBanner))) {
    resizeObserver = new ResizeObserver(
      debounce(() => {
        updateVariablesFunction(el);
      }, 200)
    );

    resizeObserver.observe(cookiesBanner);
  } else {
    updateVariablesFunction(el);
  }

  setTimeout(() => {
    updateVariablesFunction(el);
  }, 700);
};

export const navigationHeight = {
  name: 'navigation-height',
  mounted,
};
