import { debounce } from './timing';

const map = new Map();

const callbackHandler = (entries) => {
  for (let { target } of entries) {
    map.get(target)();
  }
};

const ro = new ResizeObserver(callbackHandler);

export const globalResizeObserver = {
  observe(el, callback, debounceDelay) {
    const cb = debounceDelay ? debounce(callback, debounceDelay) : callback;

    map.set(el, cb);
    ro.observe(el);
  },

  unobserve(el) {
    map.delete(el);
    ro.unobserve(el);
  },
};
