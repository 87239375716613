<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="vtoLine">
    <div class="vtoLine__main">
      <div class="vtoLine__packshot">
        <MediaImage
          :filename="starProductItem.image.fileName"
          :resolutions="starProductItem.image.resolutions"
          :fallback-size="starProductItem.image.fallbackSize"
          :sizes="starProductItem.image.sizes"
          :format="starProductItem.image.format"
          lazy
          aria-hidden
        />
      </div>

      <VtoLineVariants :product-ids="getProductsIdsOfSelectedLine" />

      <p class="vtoLine__title">{{ starProductItem.subCategory }}</p>
      <p class="vtoLine__name">{{ starProductItem.title }}</p>
    </div>

    <div class="vtoLine__footer">
      <div class="vtoLine__footer-inner">
        <VtoHotswapSave
          v-if="enableFavorite && favoriteSettings"
          icon="#icon-favorite-black-on-transparent-background"
        />
        <VtoHotswapEdit
          @click="editProductSelection(productTypesMap[productLine.productTypeId].id)"
        />
      </div>

      <a
        v-if="!areAllServicesDisabled"
        :href="getSelectedProductItem.href"
        class="oap-button -primary"
        target="_blank"
      >
        <span class="oap-button__label">
          {{ dictionary.buy }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from '../store/helpers';

import MediaImage from '../../../../MediaImage/code/Scripts/components/MediaImage.vue';
import VtoHotswapEdit from './VtoHotswapEdit.vue';
import VtoHotswapSave from './VtoHotswapSave.vue';
import VtoLineVariants from './VtoLineVariants.vue';

export default {
  name: 'VtoHotswapLine',

  components: {
    MediaImage,
    VtoHotswapEdit,
    VtoHotswapSave,
    VtoLineVariants,
  },

  props: {
    productLine: { type: Object, required: true },
  },

  computed: {
    ...mapGetters([
      'areAllServicesDisabled',
      'getProductsIdsOfSelectedLine',
      'getSelectedProductItem',
    ]),

    ...mapState(['dictionary', 'enableFavorite', 'favoriteSettings', 'productTypesMap']),

    starProductItem() {
      return this.productLine.starProductItem;
    },
  },

  methods: {
    ...mapActions(['editProductSelection']),
  },
};
</script>
