<template>
  <div
    :class="[
      'oap-slider',
      `oap-slider--layout-${layout || 'default'}`,
      theme ? `oap-slider--theme-${theme}` : null,
      engaged ? `oap-slider--is-engaged` : null,
      ctaMobileTop ? 'oap-slider--mobile-cta-to-top' : null,
    ]"
  >
    <a class="oap-slider__skip-link" :href="`#skip-slider-${sliderId}`">
      {{ skipLink.text }}
    </a>

    <div v-if="!!$slots.copy" class="oap-slider__copy">
      <slot name="copy" />
    </div>

    <div v-if="!!$slots.action" class="oap-slider__action">
      <slot name="action"></slot>
    </div>

    <oap-scroller @engaged="engage">
      <slot :slider-id="sliderId" />
    </oap-scroller>

    <slot name="controls" />

    <div v-if="!!$slots.link" class="oap-slider__link">
      <slot name="link" />
    </div>

    <span :id="`skip-slider-${sliderId}`"></span>
  </div>
</template>

<script>
import { uniqueId } from '@Foundation/utilities/uniqueId';

export default {
  name: 'OapSlider',

  props: {
    sliderId: {
      type: String,
      default: () => uniqueId(),
    },
    layout: {
      type: String,
    },
    theme: {
      type: String,
    },
    ctaMobileTop: {
      type: Boolean,
      default: false,
    },
    skipLink: {
      type: Object,
      default() {
        return {
          text: 'skip slider',
          skippedText: 'slider skipped',
        };
      },
    },
  },

  data() {
    return {
      engaged: false,
    };
  },
  methods: {
    engage(bool) {
      this.engaged = bool;
    },
  },
};
</script>
