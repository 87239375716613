export const clickextension = { // eslint-disable-line
  beforeMount: (el, binding) => {
    const element = el;
    const clickableElements = binding.value.split(',');
    let link = element.querySelector('a');

    // if there is no link - let's query for a button then
    if (!link) {
      link = element.querySelector('button');
    }

    el.style.cursor = 'pointer';

    clickableElements.forEach((elem) => {
      let currentElements;
      try {
        currentElements = Array.from(element.querySelectorAll(elem));

        if (!currentElements || currentElements.length === 0) return;

        currentElements.forEach((el) => {
          el.addEventListener('click', () => link.click());
        });
      } catch (ex) {
        // fail silently
      }
    });
  },
};
