<template>
  <div class="oap-immersive-before-after__copy">
    <div
      class="oap-immersive-before-after__title"
      :style="`color: ${titleColor}`"
      v-html="title"
    ></div>
    <div
      class="oap-immersive-before-after__subtitle"
      :style="`color: ${subtitleColor}`"
      v-html="subtitle"
    ></div>
  </div>
</template>
<script>
export default {
  name: 'OapImmersiveBeforeAfterCopy',

  props: {
    title: { type: String, default: '' },
    titleColor: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    subtitleColor: { type: String, default: '' },
  },
};
</script>
