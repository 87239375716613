<template>
  <div class="vtoMain vto__page">
    <VtoHeader v-if="show">
      <div class="vtoHeaderWrapper">
        <VtoHeaderCounter
          v-if="getSelectedProductsAmount && !isMainCategoryHairColor"
          :count="getSelectedProductsAmount"
        />
        <VtoProduct v-if="getSelectedProductItem" :product="getSelectedProductItem" />
      </div>
      <VtoBackButton @click.self="mfSendExit()" />
    </VtoHeader>

    <VtoBody v-if="showShade">
      <VtoSwitcher
        v-if="getOppositeCategory.productTypeIds.length"
        :category="getOppositeCategory"
        @click="onCategorySwitch"
      />
      <VtoTip
        v-if="
          !isRecommendedProduct &&
          (dictionary.notRecommendedLabel != null || dictionary.notRecommendedLabel != '')
        "
        :content="dictionary.notRecommendedLabel"
        @click="doShowSnackbar(true)"
      />

      <VtoBackButton
        v-if="!initialProductId"
        :label="dictionary.backToMenu"
        @click="goToPageEntitySelection"
      />

      <VtoCirclesContainer>
        <VtoCircle
          v-for="shade in getSelectedProductLineShades"
          :key="shade.id"
          :entity="shade"
          :checked="shade.id === selectedProductId"
          :class="{
            '-active': shade.id === selectedProductId,
            '-light': !shade.dark,
            '-dark': shade.dark,
          }"
          @click="
            onSelectShade(shade.id);
            vtoPushTaggingInfo(shade);
          "
        />
      </VtoCirclesContainer>
    </VtoBody>

    <VtoFooter v-if="showShade">
      <button class="oap-button -primary -theme-brand -fluid" @click="onEditAndBuyButtonClick">
        <span class="oap-button__label">{{ dictionary.editThisLook }}</span>
      </button>
    </VtoFooter>

    <transition name="slide">
      <VtoSnackbar v-if="showSnackbar">
        <VtoOverlay @click="doShowSnackbar(false)" />
        <div class="vtoSnackbar__wrapper">
          <div class="vtoSnackbar__close" @click="doShowSnackbar(false)">
            <svg class="icon">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close" />
            </svg>
          </div>
          <div class="vtoSnackbar__heading">{{ dictionary.notRecommendedLabel }}</div>
          <span class="vtoSnackbar__mainText">{{ dictionary.notRecommendedMessage }}</span>
        </div>
      </VtoSnackbar>
    </transition>
  </div>
</template>

<script>
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';
import { mapActions, mapGetters, mapState } from '../store/helpers';

import {
  FACET_ATTRIBUTES,
  PAGE_LANDING,
  TAGGING_INFO_PRODUCT_VARIANT_CLICK_TYPE,
  TYPE_PRODUCT,
} from '../settings';

import {
  DO_GO_TO_PAGE_CATALOG,
  DO_GO_TO_PAGE_ENTITY_SELECTION,
  DO_GO_TO_PAGE_HAIR_DETECTION,
  DO_MF_SEND_EXIT,
  DO_OPEN_CATALOG,
  DO_PUSH_TAGGING_INFO,
  DO_PUSH_TAGGING_INFO_SIMPLE,
  DO_RESET_SELECTED_LOOK,
  DO_SELECT_CATALOG_TYPE,
  DO_SELECT_SHADE,
  DO_SHOW_SNACKBAR,
  DO_UPDATE_FACETED_PRODUCTS_COLLECTION_AND_LISTS,
} from '../store/actions';

import VtoBackButton from './VtoBackButton.vue';
import VtoBody from './VtoBody.vue';
import VtoCircle from './VtoCircle.vue';
import VtoCirclesContainer from './VtoCirclesContainer.vue';
import VtoFooter from './VtoFooter.vue';
import VtoHeader from './VtoHeader.vue';
import VtoHeaderCounter from './VtoHeaderCounter.vue';
import VtoOverlay from './VtoOverlay.vue';
import VtoProduct from './VtoProduct.vue';
import VtoSnackbar from './VtoSnackbar.vue';
import VtoSwitcher from './VtoSwitcher.vue';
import VtoTip from './VtoTip.vue';

export default {
  name: 'VtoPageMain',

  components: {
    VtoBackButton,
    VtoBody,
    VtoCircle,
    VtoCirclesContainer,
    VtoFooter,
    VtoHeader,
    VtoHeaderCounter,
    VtoOverlay,
    VtoProduct,
    VtoSnackbar,
    VtoSwitcher,
    VtoTip,
  },

  data() {
    return {
      singletrigger: true,
    };
  },

  computed: {
    ...mapState([
      'dictionary',
      'productListsMap',
      'oppositeCategory',
      'productCategoriesMap',
      'previousPage',
      'initialProductId',
      'selectedProductId',
      'show',
      'showShade',
      'showSnackbar',
      'selectedLookFamilyId',
      'mainCategory',
    ]),

    ...mapGetters([
      'isMainCategoryHairColor',
      'isRecommendedProduct',
      'getCatalogType',
      'getOppositeCategory',
      'getSelectedProductItem',
      'getSelectedProductLineShades',
      'getSelectedProducts',
      'getSelectedProductsAmount',
      'getTaggingInfo',
      'getFacets',
      'getCurrentRouteComponent',
    ]),
  },

  beforeMount() {
    this.tryRedirectToHairColorDetection();
    /* istanbul ignore next */
    if (!this.productListsMap) this[DO_UPDATE_FACETED_PRODUCTS_COLLECTION_AND_LISTS]();
    /* istanbul ignore next */
    if (!this.selectedProductId) this[DO_GO_TO_PAGE_CATALOG]();
  },

  methods: {
    ...mapActions([
      DO_GO_TO_PAGE_CATALOG,
      DO_GO_TO_PAGE_ENTITY_SELECTION,
      DO_GO_TO_PAGE_HAIR_DETECTION,
      DO_MF_SEND_EXIT,
      DO_OPEN_CATALOG,
      DO_PUSH_TAGGING_INFO,
      DO_PUSH_TAGGING_INFO_SIMPLE,
      DO_RESET_SELECTED_LOOK,
      DO_SELECT_CATALOG_TYPE,
      DO_SELECT_SHADE,
      DO_SHOW_SNACKBAR,
      DO_UPDATE_FACETED_PRODUCTS_COLLECTION_AND_LISTS,
    ]),

    onEditAndBuyButtonClick() {
      // If the 'catalogType' is 'null', it means that 'vto-main-page'
      // is the first page with VTO experience user visits after the load.
      // Therefore there is a need to set catalog type for the HotSwap to work correctly
      if (!this.getCatalogType) {
        this[DO_SELECT_CATALOG_TYPE](TYPE_PRODUCT);
      }
      this[DO_OPEN_CATALOG](true);
    },

    onSelectShade(shadeId) {
      if (this.selectedLookFamilyId) this[DO_RESET_SELECTED_LOOK]();
      this[DO_SELECT_SHADE](shadeId);
    },

    onCategorySwitch() {
      this[DO_OPEN_CATALOG](false);
      this.vtoPushTaggingInfo();
    },

    tryRedirectToHairColorDetection() {
      /* istanbul ignore next */
      if (
        this.isMainCategoryHairColor &&
        this.previousPage === PAGE_LANDING &&
        this.getFacets[FACET_ATTRIBUTES.HAIR_COLOR_FAMILY].length === 0
      ) {
        this[DO_GO_TO_PAGE_HAIR_DETECTION]();
      }
    },

    vtoPushTaggingInfo(shade) {
      if (shade) {
        if (this.initialProductId && this.singletrigger) {
          const labelInfo = this.getTaggingInfo.products[0];

          this[DO_PUSH_TAGGING_INFO_SIMPLE]({
            action: 'select::shade',
            event_name: 'select_shade',
            label: `${labelInfo.dimension36}::${shade.upc}`,
            detail: `${labelInfo.dimension36}::${shade.upc}`,
            service_name: `virtualtryon`,
            service_category: `guide`,
          });

          this[DO_PUSH_TAGGING_INFO]({
            type: TAGGING_INFO_PRODUCT_VARIANT_CLICK_TYPE,
            list: 'others',
            label: `${labelInfo.name}::${shade.upc}::${labelInfo.dimension36}`,
          });
          this.setSingleTrigger();
        }
      } else {
        if (this.singletrigger) {
          AnalyticsHandler.getAnalyticsHandler().push({
            event_name: 'select_switch category',
            type: 'userActionEvent',
            category: `virtual try on::modiface::${
              this.productCategoriesMap[this.mainCategory].taggingTitle
            }`.toLowerCase(),
            action: 'select::switch category',
            label: this.productCategoriesMap[this.oppositeCategory].taggingTitle,
            service_name: `virtualtryon`,
            service_category: `guide`,
            service_version: `1.0`,
          });
          this.setSingleTrigger();
        }
      }
    },
    setSingleTrigger() {
      this.singletrigger = false;
      var self = this;
      setTimeout(() => {
        self.singletrigger = true;
      }, 1500);
    },
  },
};
</script>
