import includes from 'lodash.includes';

export function convertVariable(variable, variables) {
  if (variable && variables) {
    const tmplVariable = variable.toLowerCase().replace(/__/g, '');

    for (var key in variables) {
      if (tmplVariable.includes(key)) {
        return tmplVariable.replace(key, variables[key]);
      }
    }
  }

  return variable;
}

export function isVariable(variable) {
  if (typeof variable === 'string') {
    return includes(variable, '__');
  }

  return false;
}
