<template>
  <article
    :id="tabItemId"
    :class="['oap-tab__item', { 'oap-tab__item--active': isActiveTab }]"
    tabindex="0"
    role="tabpanel"
    :aria-labelledby="`trigger-${tabItemId}`"
  >
    <slot />
  </article>
</template>

<script>
import { mapGetters } from '../helpers';
import { GET_ACTIVE_TAB } from '../settings';

export default {
  name: 'OapTabItem',
  props: {
    tabComponentId: {
      type: String,
      required: true,
    },
    tabItemId: {
      type: String,
      required: true,
    },
    tabLabel: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters([GET_ACTIVE_TAB]),
    isActiveTab() {
      return this[GET_ACTIVE_TAB][this.tabComponentId] === this.tabItemId;
    },
  },
};
</script>
