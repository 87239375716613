<template>
  <div :class="{ 'videoComponentFullpage--immersive': isImmersive }">
    <slot></slot>
    <div ref="infoPanel" :aria-hidden="ariaHidden" class="videoComponent__infoPanel">
      <button
        v-if="videoId !== ''"
        ref="playButton"
        v-animate-on-inview="{
          class: `${isImmersive ? 'oap-animate--fade-in-bottom' : ''}`,
        }"
        :aria-label="playIconAltText"
        class="videoComponent__playButton"
        :class="isImmersive && ['oap-animate--initially-hidden', 'oap-animate--delay-500ms']"
        @click="
          toggleVideoPopin();
          loadAndSetupPlayer();
        "
      >
        <img :alt="playIconAltText" :src="iconSrc" class="videoComponent__playButtonIcon" />
      </button>
      <div
        v-if="header"
        v-animate-on-inview="{
          class: `${isImmersive ? 'oap-animate--fade-in-bottom' : ''}`,
        }"
        class="videoComponent__header"
        :class="isImmersive && ['oap-animate--initially-hidden', 'oap-animate--delay-700ms']"
      >
        {{ header }}
      </div>
      <div
        v-if="subheader"
        v-animate-on-inview="{
          class: `${isImmersive ? 'oap-animate--fade-in-bottom' : ''}`,
        }"
        class="videoComponent__subheader"
        :class="isImmersive && ['oap-animate--initially-hidden', 'oap-animate--delay-900ms']"
      >
        {{ subheader }}
      </div>
      <div v-if="author" class="videoComponent__author">
        {{ author }}
      </div>
    </div>
    <modal
      :modalidentifier="playerId"
      :class="['videoComponent__modal', { 'videoComponent__modal--has-transcript': hasTranscript }]"
    >
      <div class="embedResponsive--16by9">
        <div v-if="videoId !== ''" :id="playerId" class="embedResponsive__item"></div>
      </div>
      <slot name="transcript"></slot>
    </modal>
  </div>
</template>

<script>
import { YOUTUBE_SCRIPT_NAME, YOUTUBE_SCRIPT_URL } from './settings';
import { loadscript } from '../../../../../Foundation/Core/code/Scripts/directives/loadscript/loadscript';
import eventBus from '@loreal/eventbus-js';
import { mixinSetupYouTube } from './mixins/setupYouTube';
import { mixinToggleVideoPopin } from './mixins/toggleVideoPopin';
import { mixinTryPlayVideo } from './mixins/tryPlayVideo';
import { mixinSetupPlayerAndBindToEvents } from './mixins/setupPlayerAndBindToEvents';
import { animateOnInview } from '../../../../../Foundation/Core/code/Scripts/directives/animateOnInview/animateOnInview';
import modal from '../../../../Modal/code/Scripts/components/modal.vue';

/* eslint-disable */
export default {
  name: 'video-placeholder-fullpage',

  components: {
    modal,
  },

  directives: {
    animateOnInview
  },

  mixins: [
    mixinSetupYouTube,
    mixinTryPlayVideo,
    mixinToggleVideoPopin,
    mixinSetupPlayerAndBindToEvents,
  ],

  props: {
    header: { type: String, default: '' },
    isWhiteCopy: { type: Boolean, default: false },
    subheader: { type: String, default: '' },
    author: { type: String, default: '' },
    videoId: { type: String, default: '' },
    playIconAltText: { type: String, default: 'Play video' },
    host: { type: String, default: 'https://www.youtube.com' },
    hasTranscript: { type: Boolean, default: false },
    isInformative: { type: Boolean, default: false },
    isImmersive: { type: Boolean, default: false },
    videoTitle: { type: String, default: '' },
    hasBrandButton: { type: Boolean, default: false },
  },

  data() {
    return {
      playerId: `player-${this.videoId}-${Date.now()}`,
      infoPanel: null,
      player: null,
      ariaHidden: false,
      isPlayerSet: false,
    };
  },

  computed: {
    iconSrc() {
      return this.isWhiteCopy && !this.hasBrandButton
        ? '/frontend/static/images/play-dark.svg'
        : '/frontend/static/images/play.svg';
    },
  },
  methods: {
    /* istanbul ignore next */
    loadAndSetupPlayer() {
      if (this.videoId !== '') {
        loadscript.beforeMount(null, {
          value: {
            name: YOUTUBE_SCRIPT_NAME,
            url: YOUTUBE_SCRIPT_URL,
          },
        });

        this.infoPanel = this.$el.querySelector('.videoComponent__infoPanel');

        this.$nextTick(() => {
          if (!this.infoPanel) {
            this.setupPlayerAndBindToEvents();
          }
        });
      }
    },
  },

  mounted() {
    /* istanbul ignore next */
    eventBus.on('modal:closed', (modalId) => {
      if (modalId === this.playerId) {
        this.player.destroy();
        this.ariaHidden = false;

        setTimeout(() => {
          this.$refs.playButton.focus();
        }, 500);
      }
    });
  },
};
</script>
