<template>
  <svg
    width="29px"
    height="29px"
    viewBox="0 0 29 29"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    aria-hidden="true"
    class="oap-loader__icon"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(2.000000, 2.000000)">
        <path
          id="backPath"
          d="M7.5 25.4999V7.99988L19 7.99993C19 7.99993 23.5 8 23.5 13C23.5 13 23.5 17 18.5 17H7.5H17.5L22.5 24"
          stroke="#000"
          stroke-width="2.5"
          transform="translate(-1.8, -2)"
        />
        <path
          id="animatedPath"
          d="M7.5 25.4999V7.99988L19 7.99993C19 7.99993 23.5 8 23.5 13C23.5 13 23.5 17 18.5 17H7.5H17.5L22.5 24"
          stroke="#f2cd95"
          stroke-width="2.5"
          transform="translate(-1.8, -2)"
        />
        <circle
          id="backCircle"
          stroke-width="2.2"
          stroke-dasharray="80 80"
          stroke="#000000"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="rotate(-90, 12.5, 12.5)"
        />
        <circle
          id="animatedCircle"
          stroke-width="2.2"
          stroke-dasharray="78.0337 78.0337"
          stroke="#f2cd95"
          cx="12.5"
          cy="12.5"
          r="12.5"
          transform="rotate(-90, 12.5, 12.5)"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'OapLoader',
};
</script>
