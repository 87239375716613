<template>
  <div class="readMoreLess">
    <!-- eslint-disable vue/no-v-html -->
    <div v-html="getTruncatedText(content)"></div>
    <span v-if="!expanded && content.length > charLimit">...</span>
    <button id="textBtn" @click="showText()">
      {{ expanded ? readlessText : readmoreText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ReadMoreLess',

  props: {
    content: {
      type: String,
      required: true,
    },
    readmoreCharLimiter: {
      type: String,
      default: '',
    },
    readmoreText: {
      type: String,
      default: '',
    },
    readlessText: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      buttonText: '',
      expanded: false,
      charLimit: this.readmoreCharLimiter,
    };
  },

  methods: {
    showText() {
      this.expanded = !this.expanded;
    },
    getTruncatedText(text) {
      if (this.expanded || text.length <= this.charLimit) {
        return this.textToHTML(text);
      } else if (!this.expanded || text.length >= this.charLimit) {
        return this.textToHTML(text.slice(0, this.charLimit));
      } else {
        return this.textToHTML(text);
      }
    },
    textToHTML(str) {
      var dom = document.createElement('div');
      dom.innerHTML = str;
      return dom.innerHTML;
    },
  },
};
</script>
