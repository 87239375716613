<script>
import { mapGetters } from 'vuex';
import {
  ACCESSIBILITY_STORE_NAMESPACE,
  GET_CONTRAST_HIGHLIGHT,
  GET_SITEWIDE_ANIMATIONS,
} from '@Foundation/accessibilityStore/accessibilityStore';

export default {
  name: 'OapSustainability',
  methods: {
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_SITEWIDE_ANIMATIONS, GET_CONTRAST_HIGHLIGHT]),
  },
  computed: {
    animationsEnabled() {
      return this[GET_SITEWIDE_ANIMATIONS]();
    },
    isHighContrastEnabled() {
      return this[GET_CONTRAST_HIGHLIGHT]();
    },
  },
  render() {
    return this.$slots.default({
      animationsEnabled: this.animationsEnabled,
      highContrastEnabled: this.isHighContrastEnabled,
    });
  },
};
</script>
