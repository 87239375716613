<template>
  <div class="material-input__component" :class="computedClasses">
    <input
      v-if="type === 'email'"
      :id="id"
      v-model="valueCopy"
      type="email"
      class="material-input"
      :name="name"
      :placeholder="placeholder"
      :aria-describedby="`errors-${id}`"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :required="required"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @input="handleModelInput"
    />
    <input
      v-if="type === 'text'"
      :id="id"
      v-model="valueCopy"
      type="text"
      class="material-input"
      :name="name"
      :placeholder="placeholder"
      :aria-describedby="`errors-${id}`"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :minlength="minlength"
      :maxlength="maxlength"
      :required="required"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @input="handleModelInput"
    />
    <input
      v-if="type === 'password'"
      :id="id"
      v-model="valueCopy"
      type="password"
      class="material-input"
      :name="name"
      :placeholder="placeholder"
      :aria-describedby="`errors-${id}`"
      :readonly="readonly"
      :disabled="disabled"
      :autocomplete="autocomplete"
      :minlength="minlength"
      :maxlength="maxlength"
      :required="required"
      @focus="handleFocus(true)"
      @blur="handleFocus(false)"
      @input="handleModelInput"
    />
    <span class="material-input-bar"></span>

    <label v-show="isLabelVisible" class="material-label" :for="id">
      <slot></slot><span v-if="required" class="required-star">*</span>
    </label>

    <div v-show="errorMessages" :id="`errors-${id}`" class="material-errors">
      <div v-for="(error, key) in computedErrors" :key="key" class="material-error">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
export default {
  name: 'MaterialInput',
  props: {
    refInput: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    step: {
      type: Number,
      default: null,
    },
    minlength: {
      type: Number,
      default: null,
    },
    maxlength: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    errorMessages: {
      type: [Array, String],
      default: null,
    },
  },
  data() {
    return {
      valueCopy: null,
      focus: false,
      hadFocus: false,
      valid: true,
    };
  },
  computed: {
    computedErrors() {
      return typeof this.errorMessages === 'string' ? [this.errorMessages] : this.errorMessages;
    },
    materialHasErrors() {
      return Boolean(
        (this.hadFocus && !this.valid) || (this.errorMessages && this.errorMessages.length)
      );
    },
    computedClasses() {
      return {
        'material--active': this.focus,
        'material--disabled': this.disabled,
        'material--has-errors': this.materialHasErrors,
        'material--raised': Boolean(
          this.focus ||
            this.valueCopy || // has value
            (this.placeholder && !this.valueCopy)
        ), // has placeholder
      };
    },
    isLabelVisible() {
      return Boolean(!this.isEmpty(this.$slots.default()));
    },
  },
  watch: {
    value(newValue) {
      // This watch works from the code side of the 2-way-binding:
      this.copyValue(newValue);
    },
  },
  beforeMount() {
    // Here we are following the Vue2 convention on custom v-model:
    // https://github.com/vuejs/vue/issues/2873#issuecomment-223759341
    this.copyValue(this.value);
  },
  mounted() {
    this.refInput && eventBus.on(`input::${this.refInput}`, this.resetInput);
    if (this.refInput == 'email') {
      const currentURL = new URL(window.location.href);
      this.valueCopy = currentURL.searchParams.get('email');
    }
  },
  methods: {
    handleModelInput(event) {
      this.$emit('input', event.target.value, event);
      // eslint-disable-next-line prettier/prettier
      let data = {'inputValue': event.target.value, 'refField' : this.refInput };
      eventBus.$emit('inputValue', data);
      this.handleValidation();
    },
    handleFocus(focused) {
      this.focus = focused;
      this.hadFocus = true;

      if (!focused) {
        this.handleValidation();
      }
    },
    handleValidation() {
      this.valid = this.$el ? this.$el.querySelector('.material-input').validity.valid : this.valid;
    },
    copyValue(value) {
      this.valueCopy = value;
      this.handleValidation();
    },
    reset() {
      this.hadFocus = false;
      this.valid = true;
      this.focus = false;
    },
    isEmpty(value) {
      return typeof value === 'undefined' || value == null;
    },
    resetInput() {
      this.valueCopy = '';
    },
  },
};
</script>
