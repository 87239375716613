<template>
  <div
    :class="{
      'oap-tech-details--animations-enabled': shouldShowAnimations,
      'oap-tech-details--high-contrast-enabled': shouldShowHighContrast,
    }"
  >
    <slot
      :active-item="firstCheckbox"
      :show-text="canShowText"
      :animation-enabled="shouldShowAnimations"
      :current-src="currentVideoSrc"
      :component-on-screen="isComponentVisible"
      :is-android="isAndroid"
    ></slot>
    <div class="oap-tech-details__toggle">
      <div
        class="oap-tech-details__toggle__transition-group"
        :class="{ 'oap-tech-details__toggle__transition-group--left': !firstCheckbox }"
        :style="toggleStyle"
      >
        <button
          :for="`${uuid}-first-check-box`"
          class="oap-tech-details__toggle__checkbox-wrapper oap-tech-details__toggle__checkbox-wrapper--first"
          :class="{ 'oap-tech-details__toggle__checkbox-wrapper--active': firstCheckbox }"
          :aria-pressed="firstCheckbox"
          @click="playVideoBackwards"
        >
          {{ firstToggleLabel }}
        </button>
        <button
          :for="`${uuid}-second-check-box`"
          class="oap-tech-details__toggle__checkbox-wrapper oap-tech-details__toggle__checkbox-wrapper--second"
          :class="{ 'oap-tech-details__toggle__checkbox-wrapper--active': !firstCheckbox }"
          :aria-pressed="!firstCheckbox"
          @click="resumePlayingVideo"
        >
          {{ secondToggleLabel }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ACCESSIBILITY_STORE_NAMESPACE,
  GET_CONTRAST_HIGHLIGHT,
  GET_SITEWIDE_ANIMATIONS,
} from '../../../../../Foundation/Core/code/Scripts/accessibilityStore/accessibilityStore';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

export default {
  name: 'OapTechDetails',
  props: {
    firstToggleLabel: {
      type: String,
      required: true,
    },
    secondToggleLabel: {
      type: String,
      required: true,
    },
    videoStopTime: {
      type: Number,
      required: true,
      default: 2000,
    },
    minIntersectionRatio: {
      type: Number,
      required: true,
      default: 0.75,
    },
    taggingDetails: {
      type: Object,
      required: true,
    },
    videoSrc: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      uuid: '----',
      isVideoPlaying: false,
      canShowText: false,
      firstCheckbox: true,
      isComponentVisible: false,
      isAndroid: false,
    };
  },
  computed: {
    toggleStyle() {
      return {
        'pointer-events': !this.isVideoPlaying ? 'auto' : 'none',
        opacity: !this.isVideoPlaying ? '1' : '0.3',
      };
    },
    currentVideoSrc() {
      return this.isComponentVisible ? this.videoSrc : null;
    },
    shouldShowAnimations() {
      return this[GET_SITEWIDE_ANIMATIONS]();
    },
    shouldShowHighContrast() {
      return this[GET_CONTRAST_HIGHLIGHT]();
    },
  },
  mounted() {
    this.uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
      this.isAndroid = true;
    }

    this.init();
  },
  methods: {
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_SITEWIDE_ANIMATIONS, GET_CONTRAST_HIGHLIGHT]),
    init() {
      this.video = this.$el.querySelector('video');
      this.animationFrame = undefined;
      this.stopTime = 0;
      this.animationFrame = undefined;
      this.firstTextList = this.$el.querySelector('.oap-tech-details__slides--first');
      this.secondTextList = this.$el.querySelector('.oap-tech-details__slides--second');

      const videoLoadingPromise = new Promise((resolve) => {
        this.video.onloadedmetadata = () => {
          resolve();
        };
      });

      const intersectionObserverPromise = this.createHandleIntersectionPromise();

      Promise.all([videoLoadingPromise, intersectionObserverPromise]).then(() => {
        if (this.shouldShowAnimations) {
          this.playAnimation(false);
        } else {
          this.canShowText = true;
        }
      });
    },
    createHandleIntersectionPromise() {
      return new Promise((resolve) => {
        const handleIntersection = (entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio >= this.minIntersectionRatio) {
              this.isComponentVisible = true;
              observer.unobserve(this.$el);
              this.$nextTick(() => {
                resolve();
              });
            }
          });
        };

        const observer = new IntersectionObserver(handleIntersection, {
          threshold: [this.minIntersectionRatio],
          rootMargin: '10px 10px 10px 10px',
        });
        observer.observe(this.$el);
      });
    },
    playVideoBackwards(evt) {
      evt.stopImmediatePropagation();
      evt.preventDefault();
      this.emitTaggingEvent(this.firstToggleLabel);
      this.firstCheckbox = true;
      if (this.shouldShowAnimations && !this.isAndroid) {
        this.playBackwards();
      } else {
        this.canShowText = false;
        setTimeout(() => {
          this.canShowText = true;
        });
      }
    },
    resumePlayingVideo(evt) {
      evt.stopImmediatePropagation();
      evt.preventDefault();
      this.emitTaggingEvent(this.secondToggleLabel);
      this.firstCheckbox = false;
      if (this.shouldShowAnimations && !this.isAndroid) {
        this.playAnimation(true);
      } else {
        this.canShowText = false;
        setTimeout(() => {
          this.canShowText = true;
        });
      }
    },
    playAnimation(resumePlaying) {
      window.cancelAnimationFrame(this.animationFrame);
      this.isVideoPlaying = true;
      this.canShowText = false;
      let duration = this.video.seekable.end(0);
      const lastDurationFrame = duration - 0.1; // Avoids black screen on iOS because the latest frame is black.

      let start = null;
      let continuePlaying = true;

      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        let time = progress / 1000;
        time = resumePlaying ? time + this.stopTime : time;

        this.video.onseeked = () => {
          this.video.onseeked = null;
          if (continuePlaying) window.requestAnimationFrame(step);
        };

        this.video.currentTime = time > duration ? duration : time;
        const timeInSecond = time * 1000;

        if (timeInSecond > this.videoStopTime && (!resumePlaying || time >= lastDurationFrame)) {
          this.stopTime = time;
          continuePlaying = false;
          this.isVideoPlaying = false;
          this.canShowText = true;
          window.cancelAnimationFrame(this.animationFrame);
        }
      };

      this.animationFrame = window.requestAnimationFrame(step);
    },
    playBackwards() {
      this.video.onseeked = null;
      this.isVideoPlaying = true;
      this.canShowText = false;
      window.cancelAnimationFrame(this.animationFrame);
      let currentTime = this.video.currentTime;
      let start = null;

      const step = (timestamp) => {
        if (!start) start = timestamp;
        const elaspsedTime = timestamp - start;

        let time = elaspsedTime / 1000;
        time = currentTime - time * 1.4;
        this.video.onseeked = () => {
          this.video.onseeked = null;
          window.requestAnimationFrame(step);
        };

        this.video.currentTime = time;
        // loop
        if (time * 1000 <= this.videoStopTime) {
          this.video.onseeked = null;
          start = null;
          currentTime = 0;
          this.isVideoPlaying = false;
          this.canShowText = true;
          this.stopTime = time;
          window.cancelAnimationFrame(this.animationFrame);
        }
      };

      this.animationFrame = window.requestAnimationFrame(step);
    },
    emitTaggingEvent(label) {
      AnalyticsHandler.getAnalyticsHandler().push({
        ...this.taggingDetails,
        eventAction: 'select',
        eventLabel: label,
        product_tab: label,
      });
    },
  },
};
</script>
