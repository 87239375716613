<template>
  <button class="vtoHotSwap__save" @click="displayFloatingBar(true)">
    <svg class="oap-favorite__icon-image icon" role="img">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="icon" />
    </svg>
  </button>
</template>

<script>
import { mapActions } from '../store/helpers';

export default {
  name: 'VtoHotswapSave',

  props: {
    icon: { type: String, required: true },
  },

  methods: {
    ...mapActions(['displayFloatingBar']),
  },
};
</script>
