<template>
  <div v-show="parentContainer.pageCount > 1" class="carousel-pagination">
    <ul class="carousel-dot-container">
      <li
        v-for="(page, index) in parentContainer.pageCount"
        :key="index"
        class="carousel-dot"
        :class="{ 'carousel-dot--active': index === parentContainer.currentPage }"
        @click="parentContainer.goToPage(index, 'pagination')"
      >
        <button
          v-tag:useractionevent="{
            label: 'display',
            category: taggingLabels.pagination.category,
            action: 'carousel',
          }"
          class="carousel-dot-inner"
          :aria-label="labels.pagination.ariaLabel.replace('{0}', index)"
          :aria-current="index === parentContainer.currentPage ? true : ''"
        ></button>
      </li>
    </ul>
  </div>
</template>

<script>
import { tag } from '../../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';

export default {
  name: 'Pagination',
  directives: {
    tag,
  },
  props: {
    labels: {
      type: Object,
      required: true,
      default: undefined,
    },
    taggingLabels: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      parentContainer: this.$parent,
      shiftAndTabHappened: false,
    };
  },
};
</script>
