import VtoApp from './code/Scripts/components/VtoApp.vue';
import VtoAppLook from './code/Scripts/components/VtoAppLook.vue';
import VtoStore from './code/Scripts/store';
import { actionLoggerPlugin } from './code/Scripts/store/plugins';

export default {
  components: {
    VtoApp,
    VtoAppLook,
  },
  modules: {
    vto: VtoStore,
  },
  plugins: [actionLoggerPlugin],
};
