import { nextTick } from 'vue';

export const scrollCallback = (node, className, threshold = window.innerHeight / 2) => {
  const rect = node.getBoundingClientRect();

  if (rect.height < threshold) {
    threshold = window.innerHeight / 5;
  }

  if (rect.bottom < threshold) {
    node.classList.add(className);
  } else {
    node.classList.remove(className);
  }
};

export const animateOnScroll = {
  name: 'animate-on-scroll',
  beforeMount: (el, binding) => {
    const className = binding.value.class; // eslint-disable-line

    // TODO: is this correct or should async/await be used?
    nextTick(() => {
      scrollCallback(el, className);
    });

    window.addEventListener('scroll', () => {
      /* istanbul ignore next */
      scrollCallback(el, className);
    });
  },
};
