<template>
  <div
    :class="[
      'oap-media-carousel__controls',
      { '--animated': hasControlsAnimation && isControlAnimated },
      `--${controlsType}`,
    ]"
    @animationend="isControlAnimated = false"
  >
    <button v-if="isPlayButtonShown" @click.prevent="togglePlaying">
      <span class="is-sr-only">{{ isPlaying ? 'pause' : 'play' }}</span>
      <svg aria-hidden="true" class="icon">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="`#${playingIcon}`"></use>
      </svg>
    </button>
    <ul class="oap-media-carousel__time">
      <li
        v-for="(_, index) in carouselLength"
        :key="index"
        class="oap-media-carousel__time-item"
        :class="{ '--active': index === currentTimeItem }"
        @click="toggleSlide(index)"
      >
        <div
          v-if="!isControlsTypeDots"
          class="oap-media-carousel__time-fill"
          :class="[
            { '-play-progress': index === currentTimeItem },
            { '-pause-progress': index === currentTimeItem && !isPlaying },
            { '-full-progress': index < currentTimeItem },
            { '-play-infinitely': carouselLength === 1 },
          ]"
          :style="index === currentTimeItem ? progressAnimation : null"
        ></div>
      </li>
    </ul>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { AnalyticsHandler } from '@Foundation';

const EVENT = {
  showNextSlide: 'show-next-slide',
  pauseSlide: 'pause-slide',
  currentTime: 'current-time',
};

export default {
  name: 'OapVideoCarouselControls',
  props: {
    carouselLength: { type: Number, default: 1 },
    durationsList: { type: Array, default: () => [] },
    swipeDistanceToSlide: { type: Number, default: 60 },
    controlsType: { type: String, default: 'dots' },
    currentVideoHasAutoplay: { type: Boolean, default: false },
    hasControlsAnimation: { type: Boolean, default: true },
    autoplay: { type: Boolean, default: true },
    analytics: { type: Object, default: () => {} },
    videoTitle: { type: String, default: '' },
  },
  data() {
    return {
      isPlaying: false,
      currentTimeItem: 0,
      timer: 0,
      startTimer: 0,
      endTimer: 0,
      newDuration: 0,
      tmpDuration: 0,
      isControlAnimated: true,
      dragDistance: 0,
      currentVideoHasAutoplayProxy: false,
    };
  },
  computed: {
    progressAnimation() {
      return {
        'animation-duration': `${Object.values(this.durationsList[this.currentTimeItem])}ms`,
      };
    },

    playProgressAnimation() {
      return '-play-progress';
    },

    pauseProgressAnimation() {
      return this.isPlaying ? null : '-pause-progress';
    },

    playingIcon() {
      return this.isPlaying ? 'media-pause-icon' : 'media-play-icon';
    },

    isControlsTypeDots() {
      return this.controlsType === 'dots';
    },

    isPlayButtonShown() {
      return this.isControlsTypeDots
        ? Object.keys(this.durationsList[this.currentTimeItem]).includes('video')
        : true;
    },
  },
  watch: {
    currentTimeItem: {
      handler(newTimeItem) {
        this.isControlAnimated = true;
        this.startTimer = new Date().getTime();
        this.tmpDuration = 0;
        this.newDuration = 0;
        this.$emit('toggle-slide', newTimeItem);
        this.resetTimer();
        this.play();
      },
      immediate: true,
    },

    isPlaying(playingState) {
      this.$emit(EVENT.pauseSlide, playingState);

      // there is no need to start the timer if we use dots
      if (this.isControlsTypeDots) return;

      if (playingState) {
        this.play();
        this.startTimer = new Date().getTime();
        this.newDuration = 0;
      } else {
        this.endTimer = new Date().getTime();
        this.pause();
      }
    },

    currentVideoHasAutoplay: {
      handler(value) {
        this.currentVideoHasAutoplayProxy = value;
        this.isPlaying = value;
      },
      immediate: true,
    },
  },

  mounted() {
    if ('ontouchstart' in window) {
      this.$el.parentElement.addEventListener('touchstart', this.handleMouseDown);
      this.$el.parentElement.addEventListener('touchend', this.handleMouseUp);
    } else {
      this.$el.parentElement.addEventListener('mousedown', this.handleMouseDown);
      this.$el.parentElement.addEventListener('mouseup', this.handleMouseUp);
    }

    if (!this.isControlsTypeDots) {
      eventBus.on('media-carousel::pause', () => {
        this.isPlaying = false;
      });

      eventBus.on('media-carousel::play', () => {
        this.isPlaying = true;
      });
    }
  },
  methods: {
    play() {
      if (!this.autoplay) return;

      if (this.newDuration > 0) {
        this.timer = setTimeout(this.changeItem, this.newDuration);
      } else {
        this.timer = setTimeout(
          this.changeItem,
          Object.values(this.durationsList[this.currentTimeItem])
        );
      }
    },

    changeItem() {
      // there is no need to stop carousel autoplay if we pause video
      if (!this.isPlaying && !this.isControlsTypeDots) return;

      this.currentTimeItem++;
      this.isPlaying = this.currentVideoHasAutoplay;

      this.$emit(EVENT.showNextSlide, true);

      if (this.currentTimeItem >= this.carouselLength) {
        this.currentTimeItem = 0;
      }
    },

    toggleSlide(index) {
      if (index === this.currentTimeItem && !this.isControlsTypeDots) {
        this.togglePlaying();
      } else {
        this.isPlaying = this.currentVideoHasAutoplay;
        this.$emit(EVENT.pauseSlide, true);
      }

      if (index < this.currentTimeItem) {
        this.$emit(EVENT.showNextSlide, false);
      }

      this.currentTimeItem = index;
    },

    togglePlaying() {
      this.pushAnalytics();
      this.isPlaying = !this.isPlaying;

      const timeFillElement = this.$el.querySelector(
        '.oap-media-carousel__time-item.--active .oap-media-carousel__time-fill'
      );
      if (!this.isPlaying && timeFillElement) {
        const currentTime = timeFillElement.getAnimations()[0]?.currentTime || 0;
        this.$emit(EVENT.currentTime, currentTime);
      }
    },

    pause() {
      this.tmpDuration = this.tmpDuration + Math.floor(this.endTimer - this.startTimer);
      this.newDuration = Object.values(this.durationsList[this.currentTimeItem]) - this.tmpDuration;
      this.resetTimer();
    },

    resetTimer() {
      clearTimeout(this.timer);
    },

    handleMouseDown(e) {
      this.dragDistance = 0;

      if (!e.touches) {
        e.preventDefault();
      }

      this.dragDistance = e.touches ? e.touches[0].pageX : e.pageX;
    },

    handleMouseUp(e) {
      if (!e.touches) {
        e.preventDefault();
      }

      this.dragDistance =
        this.dragDistance - (e.changedTouches ? e.changedTouches[0].pageX : e.pageX);

      this.handleMouseMove(this.dragDistance);

      if (e.target.closest('.oap-media-carousel__controls')) {
        return;
      }

      if (Math.abs(this.dragDistance) < 10) {
        this.togglePlaying();
      }
    },

    handleMouseMove(dragValue) {
      if (dragValue < 0 && Math.abs(dragValue) >= this.swipeDistanceToSlide) {
        this.currentTimeItem--;
        this.$emit(EVENT.showNextSlide, false);
        if (this.currentTimeItem < 0) {
          this.currentTimeItem = this.carouselLength - 1;
        }
      } else if (dragValue > 0 && Math.abs(dragValue) >= this.swipeDistanceToSlide) {
        this.currentTimeItem++;
        this.$emit(EVENT.showNextSlide, true);
        if (this.currentTimeItem >= this.carouselLength) {
          this.currentTimeItem = 0;
        }
      }
    },

    pushAnalytics() {
      const state = this.isPlaying ? 'pause' : 'play';
      let tag = this.analytics;

      tag.type = 'userActionEvent';
      tag.action = `${state}::video_header`;
      tag.video_title = this.videoTitle;

      try {
        AnalyticsHandler.getAnalyticsHandler().push(tag);
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Could not push to dataLayer', e);
      }
    },
  },
};
</script>
