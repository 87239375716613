<template>
  <button class="vtoSwitcher" v-on="$attrs" @click="$attrs.click && $attrs.click()">
    <img alt="" :src="category.icon" class="icon" />
    <span class="vtoSwitcher__title">{{ category.title }}</span>
  </button>
</template>

<script>
export default {
  name: 'VtoSwitcher',
  props: {
    category: { type: Object },
  },
};
</script>
