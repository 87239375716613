<template>
  <div
    :is="$props.tagname"
    ref="collapseContainer"
    class="collapsable"
    :class="[
      { 'collapsable--social': social },
      { collapsable__contentExpanded: !collapsed && (isMobileView || hasTranscript) },
    ]"
    :aria-labelledby="identifier"
  >
    <template v-if="isMobileView || hasTranscript">
      <button
        class="collapsable__titleWrapper"
        :aria-expanded="`${collapsed ? 'false' : 'true'}`"
        @click="onCollapse"
      >
        <slot name="indicator"></slot>
        <slot name="title"></slot>
      </button>
      <slot name="optional"></slot>
      <div
        class="collapsable__contentWrapper"
        :class="{ collapsable__contentCollapsed: collapsed }"
      >
        <slot name="content"></slot>
      </div>
    </template>
    <template v-else>
      <slot name="title"></slot>
      <slot name="optional"></slot>
      <slot name="content"></slot>
    </template>
  </div>
</template>

<script>
import { MqHandler } from '../../../../../Foundation/Core/code/Scripts/mqHandler';
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'Collapsable',
  props: {
    tagname: { type: String, default: 'div' },
    social: { type: Boolean, default: false },
    identifier: { type: String, required: true },
    forceMobile: { type: Boolean, default: false },
    hasTranscript: { type: Boolean, default: false },
    isCollapsed: { type: Boolean, default: true },
    currentIndex: { type: String, default: '' },
  },
  data() {
    return {
      smallScreen:
        MqHandler.getMqHandler().getMqForSize('small').selected ||
        MqHandler.getMqHandler().getMqForSize('medium').selected,
      collapsed: true,
    };
  },

  computed: {
    isMobileView() {
      return this.smallScreen || this.forceMobile;
    },
  },

  watch: {
    isCollapsed: {
      handler(value) {
        this.collapsed = value;
      },
      immediate: true,
    },
  },

  mounted() {
    eventBus.on('mediaquery::changed', (mq) => {
      this.smallScreen = !!(mq.size === 'small' || mq.size === 'medium');
      this.collapsed = !this.smallScreen;
    });

    eventBus.on('collapse', (index) => {
      if (index !== this.currentIndex) {
        this.collapsed = true;
      }
    });
  },

  methods: {
    onCollapse() {
      this.collapsed = !this.collapsed;
      eventBus.emit('collapse', this.currentIndex);
    },
  },
};
</script>
