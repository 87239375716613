<template>
  <div :class="['oap-scroller', isDrag ? 'oap-scroller--drag' : null]">
    <div
      ref="scrollable"
      class="oap-scroller__scrollable"
      @scroll="scrollPosition = $event.target.scrollLeft"
      @mousedown="drag"
    >
      <slot :scroller-id="scrollerId" />
    </div>
    <div v-if="offset > 0" class="oap-scroller__nav">
      <input
        ref="range"
        v-model="scrollPosition"
        type="range"
        class="oap-scroller__range"
        min="0"
        :max="offset"
        :step="step"
        :style="`--thumb-width:${thumbWidth}%`"
        aria-hidden="true"
        aria-label="scroll"
        tabindex="-1"
      />
    </div>
  </div>
</template>

<script>
import { globalResizeObserver } from '../../../../../Foundation/Core/code/Scripts/utilities/globalResizeObserver';
import { uniqueId } from '../../../../../Foundation/Core/code/Scripts/utilities/uniqueId';

const REF_RANGE = 'range';
const REF_SCROLLABLE = 'scrollable';

export default {
  name: 'OapScroller',

  props: {
    scrollerId: {
      type: String,
      default: () => uniqueId(),
    },

    engageThreshold: {
      type: Number,
      default: 50,
    },
    microMoveMargin: {
      type: Number,
      default: 15,
    },
  },

  data() {
    return {
      scrollPosition: 0,
      isDrag: false,
      thumbWidth: 0,
      offset: 0,
    };
  },

  computed: {
    step() {
      return (
        this.offset / this.$refs[this.$refs[REF_RANGE] ? REF_RANGE : REF_SCROLLABLE].clientWidth
      );
    },
  },

  watch: {
    scrollPosition(newVal, oldVal) {
      this.$refs[REF_SCROLLABLE].scrollLeft = newVal;
      this.isEngaged(newVal, oldVal);
    },
  },

  mounted() {
    globalResizeObserver.observe(this.$refs[REF_SCROLLABLE], this.setSizes, 200);
  },

  beforeUnmount() {
    globalResizeObserver.unobserve(this.$refs[REF_SCROLLABLE]);
  },

  methods: {
    isEngaged(newPos, oldPos) {
      const t = this.engageThreshold;

      if ((oldPos <= t && newPos > t) || (newPos <= t && oldPos > t))
        this.$emit('engaged', newPos > t);
    },

    setSizes() {
      const { scrollWidth, clientWidth } = this.$refs[REF_SCROLLABLE];

      this.offset = scrollWidth - clientWidth;
      this.thumbWidth = (clientWidth / scrollWidth) * 100;
    },

    drag(e) {
      const pos = {
        left: this.$refs[REF_SCROLLABLE].scrollLeft,
        x: e.clientX,
      };

      const move = (e) => {
        e.preventDefault();
        let dx = e.clientX - pos.x;
        this.isDrag = this.isDrag || Math.abs(dx) > this.microMoveMargin;
        this.$refs[REF_SCROLLABLE].scrollLeft = pos.left - dx;
      };

      const release = () => {
        this.isDrag = false;
        document.removeEventListener('mousemove', move);
        document.removeEventListener('mouseup', release);
      };

      document.addEventListener('mousemove', move);
      document.addEventListener('mouseup', release);
    },
  },
};
</script>
