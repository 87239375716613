const generateArrayOf = (n, item, settings = {}) =>
  Array.from({ length: n }, (_, index) => item(index, settings));

const requireAll = (r) => {
  r.keys().forEach(r);
};

module.exports = {
  generateArrayOf,
  requireAll,
};
