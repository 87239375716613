<template>
  <div class="vtoActions">
    <button
      v-if="!actionBuyDisabled"
      class="vtoActions__buy oap-button -primary -theme-brand"
      @click="handleBuyClick"
    >
      <span class="oap-button__label">{{ buyButtonLabel }}</span>
    </button>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from '../store/helpers';
import { DO_PUSH_TAGGING_INFO } from '../store/actions';
import { mixinParseURL } from '../../../../../Foundation/Core/code/Scripts/mixins/parseURL/parseURL';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler';

const COMMERCE_CONNECTOR_URL_PARAM_BUY_KEY = 'buy';
const COMMERCE_CONNECTOR_URL_PARAM_UTM_MEDIUM_KEY = 'utm_medium';
const COMMERCE_CONNECTOR_URL_PARAM_UTM_MEDIUM_VTO_VALUE = 'vto';
const VTO_REDIRECT_URL_PARAM = 'vto-redirect';

export default {
  name: 'VtoActions',

  mixins: [mixinParseURL],

  props: {
    selectedProduct: { type: Object, required: true },
    buyLabel: { type: String, default: '' },
  },

  computed: {
    ...mapGetters([
      'MCTBButton',
      'channelAdvisorButton',
      'singleERetailerButton',
      'commerceConnector',
    ]),

    ...mapState(['actionBuyDisabled', 'dictionary']),

    buyButtonLabel() {
      if (this.buyLabel) return this.buyLabel;
      return this.dictionary.buyOnline;
    },
  },

  methods: {
    ...mapActions([DO_PUSH_TAGGING_INFO]),

    openServiceInNewTab(url, params = {}) {
      const redirectURL = new URL(url, document.location.origin);
      Object.entries(params).forEach((param) => redirectURL.searchParams.append(...param));

      window.open(redirectURL, '_blank');
    },

    handleBuyClick() {
      AnalyticsHandler.getAnalyticsHandler().push({
        type: 'userActionEvent',
        category: 'Ecommerce',
        action: 'virtual_try_now::buy_now',
        label: `${this.selectedProduct.name}::${this.selectedProduct.upc}`.toLowerCase(),
        event_name: 'virtual_try_now::buy_now',
        productInfo: `${this.selectedProduct.name}::${this.selectedProduct.upc}`.toLowerCase(),
      });
      // if there is no 'url' key in 'selectedProduct' prop object,
      // it means a star product was passed to the component;
      // otherwise it is a look - so far we don't have any tagging for it
      if (!this.selectedProduct.url) {
        this[DO_PUSH_TAGGING_INFO]({
          type: 'productClick',
          list: 'shop-the-look',
        });
      }

      if (this.selectedProduct.href) {
        // it is from hotswap
        this.openServiceInNewTab(this.selectedProduct.href);
        return;
      }

      /* istanbul ignore else */
      if (this.selectedProduct.url !== window.location.pathname) {
        this.openServiceInNewTab(this.selectedProduct.url, {
          [COMMERCE_CONNECTOR_URL_PARAM_BUY_KEY]: true,
          [COMMERCE_CONNECTOR_URL_PARAM_UTM_MEDIUM_KEY]:
            COMMERCE_CONNECTOR_URL_PARAM_UTM_MEDIUM_VTO_VALUE,
        });
      } else {
        if (this.singleERetailerButton) {
          this.openServiceInNewTab(this.singleERetailerButton.href);
        }

        if (this.MCTBButton || this.commerceConnector || this.channelAdvisorButton) {
          this.openServiceInNewTab(`${window.location.origin}${window.location.pathname}`, {
            [COMMERCE_CONNECTOR_URL_PARAM_BUY_KEY]: true,
            [VTO_REDIRECT_URL_PARAM]: true,
          });
        }
      }
    },
  },
};
</script>
