<template>
  <div class="vtoHeaderCounter">
    {{ count }}
  </div>
</template>

<script>
export default {
  name: 'VtoHeaderCounter',
  props: {
    count: { type: Number, required: true },
  },
};
</script>
