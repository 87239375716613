<template>
  <div
    :id="carouselIdentifier"
    class="carousel"
    :class="[carouselStyle, { '-stacked': stackOnMobile && isMobile() }]"
  >
    <template v-if="stackOnMobile && isMobile()">
      <div class="carousel-wrapper -image">
        <div class="carousel-inner" :style="carouselInnerStyle">
          <slot name="image-only" />
        </div>
      </div>
      <div class="carousel-controls-wrapper">
        <Navigation v-if="navigationEnabled" :labels="labels" :tagging-labels="taggingLabels" />
        <Pagination
          v-if="paginationEnabled && pageCount > 0"
          :labels="labels"
          :tagging-labels="taggingLabels"
        />
        <Indexing v-if="showIndexing" :current="currentPage + 1" :from="pageCount" />
      </div>
      <div class="carousel-wrapper -copy">
        <div class="carousel-inner" :style="carouselInnerStyle">
          <slot name="copy-only" />
        </div>
      </div>
    </template>
    <template v-else>
      <Navigation
        v-if="navigationEnabled"
        :labels="labels"
        :tagging-labels="taggingLabels.navigation"
      />
      <div class="carousel-wrapper">
        <div class="carousel-inner" :style="carouselInnerStyle">
          <slot />
        </div>
      </div>
      <Pagination
        v-if="paginationEnabled && pageCount > 0"
        :labels="labels"
        :tagging-labels="taggingLabels"
      />
      <Indexing v-if="showIndexing" :current="currentPage + 1" :from="pageCount" />
    </template>
  </div>
</template>

<script>
import { debounce } from '../../../../../../Foundation/Core/code/Scripts';
import eventBus from '@loreal/eventbus-js';
import Navigation from '../navigation/navigation.vue';
import Pagination from '../pagination/pagination.vue';
import Indexing from '../indexing/indexing.vue';

export default {
  name: 'Carousel',
  components: {
    Navigation,
    Pagination,
    Indexing,
  },

  props: {
    // enables mobile variant
    stackOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },

    // Copy labels for translation
    labels: {
      type: Object,
      required: false,
      default: () => ({
        pagination: {
          ariaLabel: 'Slide {0}',
        },
        navigation: {
          ariaLabelPrev: 'Go to the previous slide',
          ariaLabelNext: 'Go to the next slide',
        },
      }),
    },

    // Copy labels for tagging
    taggingLabels: {
      type: Object,
      required: false,
      default: () => ({
        pagination: {
          category: 'product pictures',
        },
        navigation: {
          category: 'product pictures',
        },
      }),
    },

    // Slide transition easing. any valid CSS transition easing accepted
    easing: { type: String, default: 'ease' },

    // Minimum distance for the swipe to trigger a slide advance
    minSwipeDistance: { type: Number, default: 20 },

    // Flag to render the navigation component (next/prev buttons)
    navigationEnabled: { type: Boolean, default: false },

    // Flag to render pagination component
    paginationEnabled: { type: Boolean, default: true },

    // Maximum number of slides displayed on each page
    perPage: { type: Number, default: 2 },

    // Vertical instead of horizontal
    vertical: { type: Boolean, default: false },

    // the offset between items
    slideSpacing: {
      type: Object,
      default: () => ({}),
    },

    // padding for the slides
    slidePadding: { type: Number, default: 0 },

    /**
     * Configure the number of visible slides with a particular browser width.
     * This will be an array of arrays, ex. [[320, 2], [1199, 4]]
     * Formatted as [x, y] where x=browser width, and y=number of slides displayed.
     * ex. [1199, 4] means if (window <= 1199) then show 4 slides per page
     */
    perPageCustom: {
      type: Array,
      default: undefined,
    },

    // Scroll per page, not per item
    scrollPerPage: { type: Boolean, default: false },

    // Slide transition speed. Number of milliseconds accepted
    speed: { type: Number, default: 400 },

    // Start at slide
    startAt: { type: Number, default: 0 },

    // Carousel ID
    carouselIdentifier: {
      type: String,
      default: () => {
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });

        return `carousel-${uuid}`;
      },
    },

    // disable or enable swipe on desktop for Sitecore edit mode
    swipeOnDesktop: { type: Boolean, default: true },

    // Show or hide page indexing indicator
    showIndexing: { type: Boolean, default: false },
  },

  data() {
    return {
      browserWidth: null,
      carouselSize: null,
      currentPage: this.startAt,
      prevPage: this.startAt,
      dragOffset: 0,
      dragStartX: 0,
      mousedown: false,
      slideCount: 0,
      maxSwipeAngle: 60,
      angle: 0,
      mobileScreenWidthEnd: 811,
    };
  },

  computed: {
    /**
     * Given a viewport width, find the number of slides to display
     * @param  {Number} width Current viewport width in pixels
     * @return {Number}       Number of slides to display
     */
    breakpointSlidesPerPage() {
      if (!this.perPageCustom) {
        return this.perPage;
      }

      const breakpointArray = this.perPageCustom;
      const width = this.browserWidth;

      const breakpoints = breakpointArray.sort((a, b) => (a[0] > b[0] ? -1 : 1));

      // Reduce the breakpoints to entries where the width is in range
      // The breakpoint arrays are formatted as [widthToMatch, numberOfSlides]
      const matches = breakpoints.filter((breakpoint) => width >= breakpoint[0]);

      // If there is a match, the result should return only
      // the slide count from the first matching breakpoint
      const match = matches[0] && matches[0][1];

      return match || this.perPage;
    },

    /**
     * @return {Boolean} Can the slider move forward?
     */
    canAdvanceForward() {
      return this.currentPage < this.pageCount - 1;
    },

    /**
     * @return {Boolean} Can the slider move backward?
     */
    canAdvanceBackward() {
      return this.currentPage > 0;
    },

    /**
     * Number of slides to display per page in the current context.
     * This is constant unless responsive perPage option is set.
     * @return {Number} The number of slides per page to display
     */
    currentPerPage() {
      return !this.perPageCustom || this.$isServer ? this.perPage : this.breakpointSlidesPerPage;
    },

    /**
     * The horizontal distance the inner wrapper is offset while navigating.
     * @return {Number} Pixel value of offset to apply
     */
    currentOffset() {
      const page = this.currentPage;
      const width = this.slideWidth;
      const dragged = this.dragOffset;
      let slidespage;
      if (document.querySelectorAll('.wow-carousel--honorees .carousel-navigation-next').length) {
        let fitSize = this.currentPerPage * this.pageCount;
        let counts = fitSize - this.slideCount;
        if (counts && this.currentPage === this.pageCount - 1) {
          slidespage = page * width * this.currentPerPage - counts * width;
        } else {
          slidespage = page * width * this.currentPerPage;
        }
      } else {
        slidespage = page * width * this.currentPerPage;
      }

      // The offset distance depends on whether the scrollPerPage option is active.
      // If this option is active, the offset will be determined per page rather than per item.
      const offset = this.scrollPerPage ? slidespage : page * width;
      const currentOffset = (offset + dragged) * -1;
      const slideSpacing = this.getSlideSpacing();

      return currentOffset + slideSpacing;
    },

    flexBasis() {
      return `${this.slideWidth}px`;
    },

    isHidden() {
      return this.carouselSize <= 0;
    },

    /**
     * Calculate the number of pages of slides
     * @return {Number} Number of pages
     */
    pageCount() {
      const slideCount = this.slideCount; // eslint-disable-line prefer-destructuring
      const perPage = this.currentPerPage;

      if (this.scrollPerPage) {
        const pages = Math.ceil(slideCount / perPage);
        return pages < 1 ? 1 : pages; // Be sure to not round down to zero pages
      }

      return slideCount - (this.currentPerPage - 1);
    },

    /**
     * Calculate the width of each slide
     * @return {Number} Slide width
     */
    slideWidth() {
      const width = this.carouselSize;
      const perPage = this.currentPerPage;
      const slideWidth = width / perPage;

      return slideWidth - this.getSlideSpacing() * 2;
    },

    transitionStyle() {
      return `${this.speed / 1000}s ${this.easing} transform`;
    },

    carouselInnerStyle() {
      return {
        transform: `translate${this.vertical ? 'Y' : 'X'}(${this.currentOffset}px)`,
        transition: this.transitionStyle,
        flexBasis: this.flexBasis,
        msFlexPreferredSize: this.flexBasis,
        visibility: `${this.slideWidth ? 'visible' : 'hidden'}`,
      };
    },

    carouselStyle() {
      return {
        'carousel-vertical': this.vertical,
        'carousel-text-selectable': !this.swipeOnDesktop,
      };
    },
  },

  beforeUpdate() {
    this.computeCarouselSize();
  },

  mounted() {
    /* istanbul ignore else */
    if (!this.$isServer) {
      window.addEventListener('resize', debounce(this.computeCarouselSize, 16));

      if ('ontouchstart' in window) {
        this.$el.addEventListener('touchstart', this.handleMousedown);
        this.$el.addEventListener('touchend', this.handleMouseup);
        this.$el.addEventListener('touchmove', this.handleMousemove);
      }

      /* istanbul ignore else */
      if (this.swipeOnDesktop) {
        this.$el.addEventListener('mousedown', this.handleMousedown);
        this.$el.addEventListener('mouseup', this.handleMouseup);
        this.$el.addEventListener('mousemove', this.handleMousemove);
      }
    }

    eventBus.on(`${this.carouselIdentifier}-gotoPage`, (page) => {
      this.goToPage(page);
      eventBus.emit(`${this.carouselIdentifier}-pageChange`, page);
    });

    window.addEventListener('resize', this.isMobile);
    window.addEventListener('resize', this.adjustControlsPosition);

    this.attachMutationObserver();
    this.computeCarouselSize();
    this.adjustControlsPosition();
  },

  /* istanbul ignore next */
  beforeUnmount() {
    /* istanbul ignore else */
    if (!this.$isServer) {
      this.detachMutationObserver();
      window.removeEventListener('resize', this.getBrowserWidth);
      if ('ontouchstart' in window) {
        this.$el.removeEventListener('touchmove', this.handleMousemove);
      } else {
        this.$el.removeEventListener('mousemove', this.handleMousemove);
      }
    }
    window.removeEventListener('resize', this.isMobile);
    window.removeEventListener('resize', this.adjustControlsPosition);
  },
  methods: {
    /**
     * Increase/decrease the current page value
     * @param  {String} direction (Optional) The direction to advance
     */
    advancePage(direction, from = false) {
      if (direction && direction === 'backward' && this.canAdvanceBackward) {
        this.goToPage(this.currentPage - 1, from);
      } else if (
        (!direction || (direction && direction !== 'backward')) &&
        this.canAdvanceForward
      ) {
        this.goToPage(this.currentPage + 1, from);
      }
    },

    /**
     * A mutation observer is used to detect changes to the containing node
     * in order to keep the magnet container in sync with the height its reference node.
     * Note: This does not work for ie10.
     */
    /* istanbul ignore next */
    attachMutationObserver() {
      const MutationObserver =
        window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;

      if (MutationObserver) {
        const config = { attributes: true, data: true };
        this.mutationObserver = new MutationObserver(() => {
          this.$nextTick(() => {
            this.computeCarouselSize();
          });
        });
        if (this.$parent.$el) {
          this.mutationObserver.observe(this.$parent.$el, config);
        }
      }
    },

    getSlideSpacing() {
      let value = 0;
      for (const key in this.slideSpacing) {
        if (
          Object.prototype.hasOwnProperty.call(this.slideSpacing, key) &&
          key <= this.getBrowserWidth()
        ) {
          value = this.slideSpacing[key];
        }
      }
      return value;
    },

    /**
     * Stop listening to mutation changes
     */
    /* istanbul ignore next */
    detachMutationObserver() {
      if (this.mutationObserver) {
        this.mutationObserver.disconnect();
      }
    },

    /**
     * Get the current browser viewport width
     * @return {Number} Browser"s width in pixels
     */
    getBrowserWidth() {
      this.browserWidth = window.innerWidth;
      return this.browserWidth;
    },

    /**
     * Get the width of the carousel DOM element
     * @return {Number} Width of the carousel in pixels
     */
    getCarouselSize() {
      if (this.vertical) {
        this.carouselSize = (this.$el && this.$el.clientHeight) || 0; // Assign globally
      } else {
        this.carouselSize = (this.$el && this.$el.clientWidth) || 0; // Assign globally
      }

      return this.carouselSize;
    },

    /**
     * Filter slot contents to slide instances and return length
     * @return {Number} The number of slides
     */
    getSlideCount() {
      this.slideCount =
        this?.$slots
          ?.default()
          ?.filter((slot) => slot.type && slot.type.name?.toLowerCase() === 'slide').length || 0;
    },

    /**
     * Set the current page to a specific value
     * This function will only apply the change if the value is within the carousel bounds
     * @param  {Number} page The value of the new page number
     * @param from
     */
    goToPage(page, from = false) {
      /* istanbul ignore else */
      if (page >= 0 && page <= this.pageCount) {
        this.currentPage = page;
        this.$emit('pageChange', this.currentPage);
        eventBus.emit(
          `${this.carouselIdentifier}-pageChange`,
          this.currentPage,
          this.prevPage,
          from
        );
        this.prevPage = this.currentPage;
      }
    },

    /**
     * Trigger actions when mouse is pressed
     * @param  {Object} e The event object
     */
    handleMousedown(e) {
      if (!e.touches) {
        e.preventDefault();
      }

      this.mousedown = true;
      this.dragStart = e.touches ? e.touches[0] : e;
    },

    /**
     * Trigger actions when mouse is released
     * @param  {Object} e The event object
     */
    handleMouseup() {
      this.mousedown = false;
      this.dragOffset = 0;
    },

    /**
     * We know the main Distance swiped and the sub direction distance swiped
     * based on that we can calculate the swipe angle
     * @param {number} Opposite
     * @param {number} Adjacent
     * @returns {number} the angle based on Tangent: tan(Î¸) = Opposite / Adjacent
     */
    swipeAngle(main, sub) {
      return Math.atan(Math.abs(sub) / Math.abs(main)) * (180 / Math.PI);
    },

    /**
     * Trigger actions when mouse is pressed and then moved (mouse drag)
     * @param  {Object} e The event object
     */
    handleMousemove(e) {
      if (!this.mousedown) {
        return;
      }

      const event = e.touches ? e.touches[0] : e;

      let mainDirectionDragDistance = 0;
      let subDirectionDragDistance = 0;

      if (this.vertical) {
        mainDirectionDragDistance = this.dragStart.clientY - event.clientY;
        subDirectionDragDistance = this.dragStart.clientX - event.clientX;
      } else {
        mainDirectionDragDistance = this.dragStart.clientX - event.clientX;
        subDirectionDragDistance = this.dragStart.clientY - event.clientY;
      }

      this.angle = this.swipeAngle(mainDirectionDragDistance, subDirectionDragDistance);

      /**
       * If the swipe angle is less then the max swipe angle then
       * the user is probably using the slideshow to see the next slide
       *
       * We want to use prevent default to prevent the page from scrolling when switching to a new slide.
       */
      if (this.angle < this.maxSwipeAngle) {
        e.preventDefault();
      }

      this.dragOffset = mainDirectionDragDistance;

      if (this.dragOffset > this.minSwipeDistance) {
        this.handleMouseup();
        this.advancePage();
      } else if (this.dragOffset < -this.minSwipeDistance) {
        this.handleMouseup();
        this.advancePage('backward');
      }
    },

    /**
     * Re-compute the width of the carousel and its slides
     */
    /* istanbul ignore next */
    computeCarouselSize() {
      this.getSlideCount();
      this.getBrowserWidth();
      this.getCarouselSize();
      this.setCurrentPageInBounds();
    },

    /**
     * When the current page exceeds the carousel bounds, reset it to the maximum allowed
     */
    setCurrentPageInBounds() {
      if (!this.canAdvanceForward) {
        const setPage = this.pageCount - 1;
        this.currentPage = setPage >= 0 ? setPage : 0;
      }
    },

    isMobile() {
      return window.innerWidth <= this.mobileScreenWidthEnd;
    },

    adjustControlsPosition() {
      setTimeout(() => {
        const copySlot = this.$el.querySelector('.-copy');
        if (!copySlot) return;
        const copySlides = copySlot.querySelectorAll('.carousel-slide');
        let carouselHasText = false;
        copySlides.forEach((slide) => {
          if (slide.children.length) carouselHasText = true;
        });
        if (!carouselHasText) copySlot.style.marginBottom = 0;
      }, 100);
    },
  },
};
</script>
