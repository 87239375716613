import { nextTick } from 'vue';
const mqlMap = new Map();
const entriesMap = new Map();
const handlers = { i: -1, arr: [] };
const screenSmallMax = '(max-width: 31.9375rem)';
const defaultConfig = {
  mql: screenSmallMax,
  group: 'default',
};

const updateDom = (e) => {
  const leftIn =
    e.force !== undefined
      ? e.force
      : e.boundingClientRect.left >= 0 && e.boundingClientRect.left <= e.rootBounds.width;
  const rightIn =
    e.force !== undefined
      ? e.force
      : e.boundingClientRect.right >= 0 && e.boundingClientRect.right <= e.rootBounds.width;

  e.target.classList.toggle(`is-intersecting`, leftIn && rightIn);
};

let observer;

const mqlHandler = (mql, entries) => {
  observer =
    observer ||
    new IntersectionObserver(
      (entries) => {
        for (let e of entries) {
          updateDom(e);
          // requestAnimationFrame(() => updateDom(e))
          // requestIdleCallback(() => requestAnimationFrame(() => updateDom(e)));
        }
      },
      { threshold: [1] }
    );

  for (let el of entries) {
    if (mql.matches) {
      observer.observe(el);
    } else {
      observer.unobserve(el);
      updateDom({ target: el, force: false });
    }
  }
};

const beforeMount = (el, { value = {} }) => {
  const { mql = defaultConfig.mql, group = defaultConfig.group } = value;
  const key = JSON.stringify({ mql, group });

  if (!('IntersectionObserver' in window) || value.placeholder) return;

  if (!mqlMap.get(key)) {
    handlers.i++;
    mqlMap.set(key, window.matchMedia(mql));
    entriesMap.set(key, []);
    handlers.arr.push(mqlHandler.bind(null, mqlMap.get(key), entriesMap.get(key)));
    mqlMap.get(key).addListener(handlers.arr[handlers.i]);
    nextTick(handlers.arr[handlers.i]);
  }

  entriesMap.get(key).push(el);
};

const unmounted = (el, { value = {} }) => {
  const { mql = defaultConfig.mql, group = defaultConfig.group } = value;
  const key = JSON.stringify({ mql, group });

  if (!('IntersectionObserver' in window) || value.placeholder) return;

  if (mqlMap.get(key)) {
    mqlMap.get(key).removeListener(handlers.arr[handlers.i]);
    mqlMap.delete(key);
    entriesMap.delete(key);
    handlers.arr.splice(handlers.i, 1);
    handlers.i--;
  }
};

export const isIntersectingX = {
  name: 'is-intersecting-x',
  beforeMount,
  unmounted,
};
