// eslint-disable-next-line sonarjs/no-duplicate-string
const ARIA_SELECTED = 'aria-selected',
  TABINDEX = 'tabindex';

let labels;
let currentLabelIndex = 0;

export const toggleTab = (index, labels) => {
  labels.forEach((label) => {
    label.setAttribute(TABINDEX, '-1');
    label.setAttribute(ARIA_SELECTED, false);
  });
  labels[index].setAttribute(TABINDEX, 0);
  labels[index].setAttribute(ARIA_SELECTED, true);
  labels[index].focus();
};

export const setChecked = (id) => {
  const elem = document.querySelector(`#${id}`);
  elem.checked = true;
};

export const labelFocusHandler = (event) => {
  event.target.addEventListener('keyup', (event) => {
    /* istanbul ignore next */
    if (event.code === 'ArrowLeft') {
      if (currentLabelIndex !== 0) {
        currentLabelIndex--;
      } else if (currentLabelIndex === 0) {
        currentLabelIndex = labels.length - 1;
      }
    } else if (event.code === 'ArrowRight') {
      if (currentLabelIndex !== labels.length - 1) {
        currentLabelIndex++;
      } else if (currentLabelIndex === labels.length - 1) {
        currentLabelIndex = 0;
      }
    }
    /* istanbul ignore next */
    setChecked(labels[currentLabelIndex].getAttribute('data-for'));
    /* istanbul ignore next */
    toggleTab(currentLabelIndex, labels);
  });
};

export const faqToggleActive = {
  name: 'faq-toggle-active',
  beforeMount: (elem) => {
    labels = elem.querySelectorAll('li');
    labels[0].setAttribute(ARIA_SELECTED, 'true');
    labels.forEach((label) => {
      /* istanbul ignore next */
      label.addEventListener('click', (event) => {
        const eventTargetIndex = Array.prototype.indexOf.call(labels, event.target),
          offset = 30,
          tabOffset = labels[eventTargetIndex].offsetLeft;

        toggleTab(eventTargetIndex, labels);
        setChecked(event.target.getAttribute('data-for'));
        labels[eventTargetIndex].parentNode.scrollLeft = tabOffset ? tabOffset - offset : 0;
      });
      label.addEventListener('focus', labelFocusHandler);
      /* istanbul ignore next */
      label.addEventListener('blur', () => {
        label.removeEventListener('focus', labelFocusHandler);
        document.removeEventListener('keyup', labelFocusHandler);
      });
    });
  },
};
