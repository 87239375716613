import eventBus from '@loreal/eventbus-js';
import { makeVideoAccessible } from '../../../../../../Foundation/Core/code/Scripts/utilities/makeVideoAccessible';

export const mixinToggleVideoPopin = {
  methods: {
    toggleVideoPopin(youtubeExtraParams) {
      eventBus.emit(`modal:${this.playerId}`, {
        showModal: true,
      });

      /* istanbul ignore next */
      if (!this.isPlayerSet) {
        this.setupPlayerAndBindToEvents({
          enablejsapi: 1,
          ...youtubeExtraParams,
        }).then(() => {
          this.tryPlayVideo(this.player, this.isPlayerSet);

          const videoIframe = this.player.getIframe();
          makeVideoAccessible(videoIframe, this.isInformative, this.videoTitle);
        });
      } else {
        this.tryPlayVideo(this.player, this.isPlayerSet);

        const videoIframe = this.player.getIframe();
        makeVideoAccessible(videoIframe, this.isInformative, this.videoTitle);
      }
    },
  },
};
