import { AnalyticsProductClickObjectException } from '../errors/analyticsError';
import Logger from '../utils/analyticsLogger';
import DataObject from './DataObject';
import ProductObject from './ProductObject';

export default class ProductClickObject extends DataObject {
  constructor(data) {
    super('productClick', data);

    this.category = 'Ecommerce';
    if (data.category) {
      Logger.warn(
        `[AnalyticsProductClickObjectException] Category always defaults to "Ecommerce" (L'Oréal guideline), currently [${data.category}] was provided`,
        data,
        false
      );
    }

    this.action = 'Product Click';
    if (data.action) {
      Logger.warn(
        `[AnalyticsProductClickObjectException] Action always defaults to "Product Click" (L'Oréal guideline), currently [${data.action}] was provided`,
        data,
        false
      );
    }

    this.label = 'Product Click';
    if (data.label) {
      this.label = data.label;
    }

    if (data.currency) {
      this.currency = data.currency;
    }

    this.name = 'select_item';
    if (data.name) {
      this.name = data.name;
    }

    this.eventLabel ? (this.product_info = this.eventLabel) : (this.product_info = '');

    if (!data.products || data.products.length === 0) {
      Logger.error(
        '[AnalyticsProductClickObjectException] Product is required for productClick',
        data
      );
      throw new AnalyticsProductClickObjectException('Product is required for productClick');
    }
  }

  initEcommerce() {
    this.result.ecommerce = this.result.ecommerce || {};
    this.result.ecommerce.click = this.result.ecommerce.click || {};
    this.result.event_name = 'select_item';
  }

  set category(category) {
    this.result.eventCategory = this.setProperty(category, false);
  }

  set action(action) {
    this.result.eventAction = this.setProperty(action, false);
  }

  set label(label) {
    this.result.product_info = this.result.eventLabel = this.setProperty(label, false);
  }

  set currency(currency) {
    this.result.currencyCode = this.setProperty(currency, false);
  }

  set name(name) {
    this.result.event_name = this.setProperty(name, false);
  }

  set value(value) {
    this.result.eventValue = this.setProperty(value);
  }

  set products(products) {
    Object.keys(products).map(function (key) {
      if (!products[key].price) {
        products[key].price = 'none';
      }
    });
    products = products.map((product) => new ProductObject(product).toObject());

    this.initEcommerce();

    this.result.ecommerce.click.products = products;
  }

  get products() {
    return (this.result.ecommerce && this.result.ecommerce.click.products) || [];
  }

  set list(list) {
    this.initEcommerce();

    this.result.ecommerce.click.actionField = { list: this.setProperty(list) };
  }
}
