<template>
  <div
    v-if="isVisible"
    :class="['oap-account-navigation', { 'oap-account-navigation--open': opened }]"
  >
    <div :class="['oap-account-navigation__wrapper']">
      <button
        ref="accountButtonExpand"
        :aria-expanded="opened ? 'true' : 'false'"
        :aria-hidden="!smallScreen ? 'true' : 'false'"
        aria-controls="my-account-menu"
        class="oap-account-navigation__title"
        @click="toggle"
      >
        <span v-if="enableNewLayout" class="oap-account-navigation-greeting" v-html="greeting" />
        <slot name="navigation-name" />
        <svg class="icon" role="img">
          <title v-text="expandIconAlt" />
          <use xlink:href="#arrow-back" xmlns:xlink="http://www.w3.org/1999/xlink" />
        </svg>
      </button>
      <div
        id="my-account-menu"
        class="oap-account-navigation__list"
        :aria-hidden="opened || !smallScreen ? 'false' : 'true'"
      >
        <span v-if="enableNewLayout" class="oap-account-navigation-greeting" v-html="greeting" />
        <slot name="navigation-links" />
      </div>
    </div>
    <div v-if="opened" class="overlay overlay--active overlay--open" @click="toggle"></div>
    <logout-refresh></logout-refresh>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import { MqHandler } from '../../../../../../Foundation/Core/code/Scripts/mqHandler';
import logoutRefresh from '../../../../../logoutCookies/code/Scripts/components/logoutRefresh.vue';

export default {
  name: 'OapAccountNavigation',
  components: {
    logoutRefresh,
  },
  props: {
    expandIconAlt: { type: String, required: false, default: 'Expand' },
    loggedGreeting: { type: String, required: false, default: 'Hi' },
    enableNewLayout: { type: Boolean, required: false, default: false },
    authorizedRoutes: { type: Array, required: true, default: () => [] },
  },
  data() {
    return {
      opened: false,
      smallScreen: MqHandler.getMqHandler().getMqForSize('small').selected,
    };
  },
  computed: {
    greeting() {
      if (!window.uname) {
        return this.loggedGreeting;
      }
      return `${this.loggedGreeting} <strong>${window.uname}!</strong>`;
    },
    isVisible() {
      const currentPath = window.location.pathname;
      const enabledRoute = this.authorizedRoutes.includes(currentPath);
      return (
        window.uname &&
        (!this.authorizedRoutes.length ||
          !this.enableNewLayout ||
          (this.enableNewLayout && enabledRoute))
      );
    },
  },
  mounted() {
    /* istanbul ignore next */
    eventBus.on('mediaquery::changed', (mq) => {
      this.smallScreen = mq.size === 'small';
    });
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
      this.updateScroll();
    },
    updateScroll() {
      if (!this.smallScreen) {
        return;
      }
      if (this.opened) {
        document.body.classList.add('noScroll', 'overlay--active');
        this.setAria(false);
      } else {
        document.body.classList.remove('noScroll', 'overlay--active');
        this.setAria(false);
      }
    },
    setAria(status) {
      this.setAttributeTo('header', status);
      this.setAttributeTo('footer', status);
      this.setAttributeTo('#content', status);
    },
    setAttributeTo(el, status) {
      const elem = document.querySelector(el);

      if (document.querySelector(elem)) {
        elem.setAttribute('aria-hidden', status);
      }
    },
  },
};
</script>
