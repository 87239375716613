import eventBus from '@loreal/eventbus-js';

export const bus = {
  name: 'bus',
  beforeMount: (el, { modifiers, value }) => {
    if (!modifiers || 'object' !== typeof modifiers) return;

    Object.keys(modifiers).forEach((eventName) => {
      if (eventName !== 'prevent') {
        el.addEventListener(eventName, (e) => {
          if (eventName === 'click' && modifiers.prevent) {
            e.preventDefault();
          }
          if (value.eventName) {
            modifiers.withevent
              ? eventBus.emit(value.eventName, { event: e, payload: value.payload })
              : eventBus.emit(value.eventName, value.payload);
          } else {
            modifiers.withevent ? eventBus.emit(value, e) : eventBus.emit(value);
          }
        });
      }
    });
  },
};
