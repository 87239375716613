import { SET_ACTIVE_TAB, GET_ACTIVE_TAB, REGISTER_TAB } from './settings';

export default {
  namespaced: true,
  state: {
    tabs: {},
  },
  getters: {
    [GET_ACTIVE_TAB]: (state) => {
      return state.tabs;
    },
  },
  mutations: {
    [REGISTER_TAB]: (state, id) => {
      state.tabs[id] = '';
    },
    [SET_ACTIVE_TAB]: (state, { parent, tab }) => {
      state.tabs[parent] = tab;
    },
  },
  actions: {
    [REGISTER_TAB]: ({ commit }, { id }) => {
      commit(REGISTER_TAB, id);
    },
    [SET_ACTIVE_TAB]: ({ commit, getters }, { parent, tab }) => {
      if (!getters[GET_ACTIVE_TAB][parent]) {
        commit(REGISTER_TAB, parent);
      }
      commit(SET_ACTIVE_TAB, { parent, tab });
    },
  },
};
