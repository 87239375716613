import { lazyScript } from './directives/lazy-script/lazy-script';
import { svgA11yHandler } from './directives/svgHandler/svgA11yHandler';
import { tag } from './analyticsHandler/directives/tag';
import { mixinFetchData } from './mixins/fetchData/fetchData';
import { mixinParseURL } from './mixins/parseURL/parseURL';
import { background } from './directives/background/background';
import { srcset } from './directives/srcset/srcset';
import { imageloaded } from './directives/imageloaded/imageloaded';
import { loadscript } from './directives/loadscript/loadscript';
import { recommendedProducts } from './directives/helpers/recommendedProducts';
import { faqToggleActive } from './directives/faqToggleActive/faqToggleActive';
import { animateOnInview } from './directives/animateOnInview/animateOnInview';
import { animateOnScroll } from './directives/animateOnScroll/animateOnScroll';
import { changeStyleOnHover } from './directives/changeStyleOnHover/changeStyleOnHover';
import { clickextension } from './directives/clickextension/clickextension';
import { stickyOffset } from './directives/sticky-offset/sticky-offset';
import { viewPositionHelper } from './directives/view-position-helper/view-position-helper';
import { isIntersectingX } from './directives/is-intersecting-x/is-intersecting-x';
import { bus } from './directives/bus/bus';
import { gifA11y } from './directives/gifA11y/gifA11y';
import { resizeListener } from './directives/resize-listener/resize-listener';
import { scrollIntoView } from './directives/scrollIntoView/scrollIntoView';
import { scrollVisibilityTracker } from './directives/scrollVisibilityTracker/scrollVisibilityTracker';
import { toggleAnimationWithEvent } from '@Foundation/directives/toggleAnimationWithEvent/toggle-animation-with-event';
import { toggleClass } from './directives/toggle-class/toggle-class';
import { toolbarTabs } from './directives/toolbar-tabs/toolbar-tabs';
import { collapsibleList } from './directives/collapsible-list/collapsible-list';
import selectbox from './directives/selectbox/selectbox';
import { navigationHeight } from './directives/navigation-height/navigation-height';
import { formModifier } from './directives/form-modifier/form-modifier';
import { showOnCookieExpiration } from './directives/showOnCookieExpiration/show-on-cookie-expiration';
import { userInteraction } from './directives/userInteraction/userInteraction';
import { anchorTo } from './directives/anchorTo/anchorTo';
import { highlightedProducts } from './directives/highlighted-products/highlighted-products';
import './wsf/wsf';

import OapVideo from './OapVideo/OapVideo.vue';
import OapLightningoutScheduler from './OapLightningoutScheduler/OapLightningoutScheduler.vue';
import taggingWrapper from './taggingWrapper/taggingWrapper.vue';
import ReadMoreLess from './ReadMoreLess/ReadMoreLess.vue';

import ThirdPartyLoader from './ThirdPartyLoader/third-party-loader.vue';

export * from './env/env';
export * from './analyticsHandler';
export * from './mqHandler';
export * from './utilities';
export * from './storage';

export default {
  directives: {
    changeStyleOnHover,
    lazyScript,
    tag,
    background,
    srcset,
    imageloaded,
    loadscript,
    clickextension,
    stickyOffset,
    viewPositionHelper,
    animateOnInview,
    animateOnScroll,
    recommendedProducts,
    faqToggleActive,
    bus,
    gifA11y,
    resizeListener,
    scrollIntoView,
    scrollVisibilityTracker,
    toggleAnimationWithEvent,
    toggleClass,
    collapsibleList,
    isIntersectingX,
    selectbox,
    navigationHeight,
    formModifier,
    userInteraction,
    showOnCookieExpiration,
    toolbarTabs,
    anchorTo,
    highlightedProducts,
    svgA11yHandler,
  },

  components: {
    OapVideo,
    OapLightningoutScheduler,
    taggingWrapper,
    ReadMoreLess,
    ThirdPartyLoader,
  },

  mixins: {
    mixinFetchData,
    mixinParseURL,
  },

  filters: {
    // ...
  },

  plugins: {
    // KeySiteCore
  },
};
