<template>
  <div>
    <slot :show-notification="showNotification" />
  </div>
</template>

<script>
export default {
  name: 'ToastedNotification',
  methods: {
    showNotification(type, message) {
      // eslint-disable-next-line no-console
      console.log('Show notification');
      this.$toasted[type](message, {
        action: {
          icon: '✕',
          onClick: (e, toastObject) => {
            toastObject.goAway(0);
          },
        },
      });
    },
  },
};
</script>
