<template>
  <transition name="modal">
    <div
      v-if="show"
      :class="[
        'oap-beauty-genius-wrapper',
        `oap-beauty-genius-wrapper--${type}`,
        { '-has-close-bar': hasCloseBar },
        position,
      ]"
    >
      <div v-if="hasCloseBar" class="oap-beauty-genius-wrapper__close-wrapper">
        <svg class="oap-beauty-genius-wrapper__close-icon" aria-hidden="true" @click="close">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
        </svg>
      </div>
      <template v-if="isIframe">
        <div class="oap-beauty-genius-wrapper__overlay" @click="close"></div>
        <iframe
          :title="iframeTitle"
          :src="iframeUrl"
          :allow="iframeAllow"
          class="oap-iframe is-overflow-hidden"
          ref="iframe"
        >
        </iframe>
      </template>

      <template v-if="isSlot">
        <slot></slot>
      </template>
    </div>
  </transition>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { AnalyticsHandler, urlHasKey } from '@Foundation';
import eventBus from '@loreal/eventbus-js';
import { BEAUTY_GENIUS_TYPE_IN_TAGGING_EVENT } from '@Feature/OapBeautyGeniusWrapper/code/Scripts/settings';

const props = defineProps({
  type: { type: String, required: true },

  iframeBaseUrl: { type: String, default: 'https://vbc.beauty.tech/' },
  iframeBaseChatUrl: { type: String, default: 'https://vbc.beauty.tech/chat' },
  iframeDomainUrlParameter: { type: String, default: 'parentDomain' },
  iframeDomainUrl: { type: String, required: true },
  iframePromptUrlParameter: { type: String, default: 'prompt' },
  iframeSendUrlParameter: { type: String, default: 'send' },
  iframeSendUrl: { type: Boolean, default: true },
  iframeTitle: { type: String, default: 'Beauty Genius' },
  iframeAllow: { type: String, default: 'camera; microphone' },
  iframeOpenEventName: { type: String, default: 'beauty-genius-iframe:open' },
  iframeCloseEventName: { type: String, default: 'beauty-genius-iframe:close' },
  iframeWindowEventName: { type: String, default: 'message' },
  iframeWindowCloseEventType: { type: String, default: 'command' },
  iframePosition: { type: String, default: 'right' },

  modalOpenEventName: { type: String, default: 'beauty-genius-modal:open' },
  modalCloseEventName: { type: String, default: 'beauty-genius-modal:close' },

  logoAnimationStartEventName: { type: String, default: 'beauty-genius-logo-animation:start' },
  logoAnimationStopEventName: { type: String, default: 'beauty-genius-logo-animation:stop' },

  modalTopOpenEventName: { type: String, default: 'beauty-genius-modal-top:open' },

  bannerCookieName: { type: String, default: 'oap-beauty-genius-banner' },
  bannerCookieValue: { type: Number, default: 1 },
  bannerCookiePath: { type: String, default: '/' },
  bannerCookieExpiration: { type: String, default: '' },
  bannerVisibilityDelay: { type: Number, default: 10 },

  hasCloseBar: { type: Boolean, default: true },

  openServiceUrlParam: { type: String, default: 'bg-app' },

  analytics: {
    type: Object,
    default: () => {},
  },
});

const isBanner = computed(() => props.type === 'banner');
const isIframe = computed(() => props.type === 'iframe');
const isModalTop = computed(() => props.type === 'modal-top');

const isSlot = computed(
  () => props.type === 'banner' || props.type === 'modal' || props.type === 'modal-top'
);

const isNonBannerModal = computed(() => isSlot.value && !isBanner.value);
const isBannerModal = computed(() => isSlot.value && isBanner.value);

const position = computed(() => {
  return isIframe.value ? `oap-beauty-genius-wrapper--position-${props.iframePosition}` : '';
});

const parentDomainPair = `${props.iframeDomainUrlParameter}=${props.iframeDomainUrl}`;

const iframeUrl = ref(`${props.iframeBaseUrl}?${parentDomainPair}`);

// No need to make it reactive, therefore no ref() here
const iframeChatUrl = `${props.iframeBaseChatUrl}?${parentDomainPair}`;

const show = ref(false);

function pushAnalytics(type) {
  if (props.analytics) {
    const tag = { ...props.analytics };
    tag.action = props.analytics[type].action;
    tag.category = props.analytics[type].category;
    tag.event_name = props.analytics[type].event_name;
    tag.label = props.analytics[type].label;

    if (props.analytics[type].service_category) {
      tag.service_category = props.analytics[type].service_category;
    }

    try {
      AnalyticsHandler.getAnalyticsHandler().push(tag);
    } catch (e) {
      /* istanbul ignore next */
      console.warn('Could not push to dataLayer', e);
    }
  }
}

function open() {
  show.value = true;
  eventBus.emit(props.logoAnimationStopEventName);
  pushAnalytics('open');

  if (isIframe.value) {
    window.addEventListener(props.iframeWindowEventName, (event) => {
      if (event.data.type === props.iframeWindowCloseEventType) {
        close();
      }

      if (event.data.type === 'event') {
        window.dataLayer.push(event.data.data);
      }
    });

    if (window.Optanon) {
      setTimeout(() => {
        sendOptanonConsentIdMessage();

        if (!optanonGroups) {
          optanonGroups = checkPermissionGroups();
        }

        sendOptanonGroupsMessage(optanonGroups);
      }, 3000);
    }
  }
}

function close() {
  show.value = false;
  eventBus.emit(props.logoAnimationStartEventName);
  pushAnalytics('close');

  if (isNonBannerModal.value) {
    eventBus.emit(props.modalCloseEventName);
  }

  if (isIframe.value) {
    eventBus.emit(props.iframeCloseEventName);

    window.removeEventListener(props.iframeWindowEventName);
  }
}

function openBannerOnCookieAbsence() {
  if (!document.cookie.includes(props.bannerCookieName)) {
    open();
    eventBus.on(props.iframeOpenEventName, close);
    document.cookie = `${props.bannerCookieName}=${props.bannerCookieValue}${props.bannerCookieExpiration}; path=${props.bannerCookiePath}`;
  }
}

let iframe = ref(null);

function sendOptanonConsentIdMessage() {
  if (iframe.value) {
    iframe.value.contentWindow.postMessage({
      type: 'optanon_consentId',
      data: { optanon_consentId: window.Optanon.getDataSubjectId() },
    }, props.iframeBaseUrl);
  }
}

let optanonGroups;

function getCookie(name) {
  let cookie = {};
  document.cookie.split(';').forEach(function(el) {
    let split = el.split('=');
    cookie[split[0].trim()] = split.slice(1).join('=');
  });
  return cookie[name];
}

function extractNonZeroValues(str) {
  return str.split(',')
    .filter(pair => pair.split(':')[1] !== '0')
    .map(pair => pair.split(':')[0]);
}

function checkPermissionGroups() {
  const cookieValue = getCookie('OptanonConsent');
  const decodedcookieValue = decodeURIComponent(cookieValue);
  const keyValuePairs = decodedcookieValue.split('&');
  const groupsString = 'groups=';

  return extractNonZeroValues(keyValuePairs.find((pair) => pair.startsWith(groupsString)).slice(groupsString.length));
}

function sendOptanonGroupsMessage(groupsData) {
  if (iframe.value) {
    iframe.value.contentWindow.postMessage({
      type: 'optanon_groups',
      data: { optanon_groups: groupsData },
    }, props.iframeBaseUrl);
  }
}

function optanonGroupsUpdateListener(eventName) {
  window.addEventListener(eventName, (event) => {
    optanonGroups = event.detail;
    sendOptanonGroupsMessage(optanonGroups);
  });
}

onMounted(() => {
  optanonGroupsUpdateListener('OneTrustGroupsUpdated');

  if (isBannerModal.value) {
    setTimeout(openBannerOnCookieAbsence, props.bannerVisibilityDelay * 1000);
  }

  if (isNonBannerModal.value) {
    eventBus.on(props.iframeOpenEventName, close);

    if (isModalTop.value) {
      eventBus.on(props.modalTopOpenEventName, open);
    } else {
      // For all the other modals (non banner and non modal top - so called transitional banner)
      eventBus.on(props.modalOpenEventName, (event) => {
        open();

        nextTick(() => {
          if (event) {
            eventBus.emit(BEAUTY_GENIUS_TYPE_IN_TAGGING_EVENT, event);
          }
        });
      });
    }
  }

  if (isIframe.value) {
    eventBus.on(props.iframeOpenEventName, (event) => {
      if (event) {
        iframeUrl.value = new URL(
          `${iframeChatUrl}&${props.iframePromptUrlParameter}=${event.payload}&${props.iframeSendUrlParameter}=${props.iframeSendUrl}`
        );
      }
      open();
    });

    if (urlHasKey(props.openServiceUrlParam)) {
      open();
    }
  }
});
</script>
