<template>
  <div v-if="show" class="cookie-banner">
    <div class="container">
      <div class="cookie-notification">
        <div class="cookie-notification__wrap">
          <slot name="text"></slot>
          <a v-if="showLearnMore" :href="learnMoreUrl" class="cookie-learn-more">{{
            learnMoreButtonText
          }}</a>
        </div>
        <button
          v-if="showClose"
          :aria-label="cookieCloseLabel"
          class="cookie-close"
          @click="close()"
        >
          x
        </button>
        <button v-if="buttonText" class="cookie-submit-button" @click="close()">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CookieBanner',
  props: {
    editMode: { type: Boolean, default: false },
    showLearnMore: { type: Boolean, default: false },
    showClose: { type: Boolean, default: false },
    buttonText: { type: String, required: false },
    learnMoreUrl: { type: String, required: false },
    learnMoreButtonText: { type: String, required: false },
    cookieCloseLabel: { type: String, required: false },
  },
  data() {
    return {
      show: !this.editMode,
    };
  },
  created() {
    this.getCookie('cookie-notification');
  },
  methods: {
    close() {
      this.createCookie('cookie-notification', 1, 365);
      this.show = false;

      /* istanbul ignore next */
      /*if (window.eventBus) {
        window.eventBus.emit('headerHeight.updated', true);
        window.eventBus.emit('cookie.gone', true);
      }*/
    },

    createCookie(name, value, days) {
      let expires = '';
      /* istanbul ignore else */
      // test will not reach else.
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toGMTString()}`;
      } else {
        expires = '';
      }
      document.cookie = `${name}=${value}${expires}; path=/`;
    },
    getCookie(name) {
      const existcookie = document.cookie
        .split(';')
        .filter((item) => item.trim().startsWith(`${name}=`)).length;
      if (existcookie) {
        this.show = false;
      } else {
        this.show = true;
      }
    },
  },
};
</script>
