<template>
  <div class="oap-contrast-switcher">
    <label class="oap-contrast-switcher__toggle" :for="`${uuid}-toggle-contrast`">
      <input
        :id="`${uuid}-toggle-contrast`"
        v-model="selected"
        type="checkbox"
        class="oap-toggle-input"
      />
      <span class="oap-toggle-track">
        <svg class="icon" aria-hidden="true">
          <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#tick" />
        </svg>
        <span class="oap-toggle-indicator"></span>
      </span>

      <span
        class="oap-contrast-switcher__label"
        :class="{ 'oap-contrast-switcher--is-enabled': !selected }"
      >
        {{ switcherLabel }}
      </span>
    </label>
  </div>
</template>

<script>
const BODY_CONTRAST_CLASS = 'oap-theme--a11y-contrast';
const CONTRAST_ENABLED = 'contrastEnabled';
const CONTRAST = 'contrast';

import { mapActions, mapGetters } from 'vuex';
import {
  ENABLE_CONTRAST_HIGHLIGHT,
  DISABLE_CONTRAST_HIGHLIGHT,
  GET_CONTRAST_HIGHLIGHT,
  GET_SITEWIDE_ANIMATIONS,
  ACCESSIBILITY_STORE_NAMESPACE,
  ENABLE_SITEWIDE_ANIMATIONS,
  DISABLE_SITEWIDE_ANIMATIONS,
} from '@Foundation/accessibilityStore/accessibilityStore';

export default {
  name: 'OapContrastSwitcher',

  props: {
    labelEnabled: { type: String, default: '' },
    labelDisabled: { type: String, default: '' },
    switcherType: { type: String, default: CONTRAST },
  },

  data() {
    return {
      uuid: undefined,
      selected: false,
    };
  },

  computed: {
    switcherLabel() {
      return this.selected ? this.labelDisabled : this.labelEnabled;
    },

    isSwitchEnabled() {
      return this.switcherType === CONTRAST
        ? this[GET_CONTRAST_HIGHLIGHT]()
        : this[GET_SITEWIDE_ANIMATIONS]();
    },
  },

  watch: {
    selected(isSelected) {
      if (this.switcherType === CONTRAST) {
        if (isSelected) {
          document.querySelector('body').classList.add(BODY_CONTRAST_CLASS);
          this[ENABLE_CONTRAST_HIGHLIGHT]();
        } else {
          document.querySelector('body').classList.remove(BODY_CONTRAST_CLASS);
          this[DISABLE_CONTRAST_HIGHLIGHT]();
        }

        localStorage.setItem(CONTRAST_ENABLED, isSelected);
      } else {
        if (isSelected) {
          this[ENABLE_SITEWIDE_ANIMATIONS]();
        } else {
          this[DISABLE_SITEWIDE_ANIMATIONS]();
        }
      }
    },
  },
  mounted() {
    this.uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

    if (this.switcherType === CONTRAST) {
      const isEnabled = JSON.parse(localStorage.getItem(CONTRAST_ENABLED));
      this.selected = isEnabled;
      if (isEnabled) this[ENABLE_CONTRAST_HIGHLIGHT]();
      else this[DISABLE_CONTRAST_HIGHLIGHT]();
    } else {
      const isEnabled = !(
        window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
        window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true
      );
      this.selected = isEnabled;
      if (!isEnabled) this[DISABLE_SITEWIDE_ANIMATIONS]();
      else this[ENABLE_SITEWIDE_ANIMATIONS]();
    }
  },

  methods: {
    ...mapActions(ACCESSIBILITY_STORE_NAMESPACE, [
      ENABLE_CONTRAST_HIGHLIGHT,
      DISABLE_CONTRAST_HIGHLIGHT,
      ENABLE_SITEWIDE_ANIMATIONS,
      DISABLE_SITEWIDE_ANIMATIONS,
    ]),
    ...mapGetters(ACCESSIBILITY_STORE_NAMESPACE, [GET_CONTRAST_HIGHLIGHT, GET_SITEWIDE_ANIMATIONS]),
  },
};
</script>
