<template>
  <div class="vtoRating">
    <span
      v-for="star in ratingLimit"
      :key="star"
      class="vtoRating__star"
      :class="{
        '-isSelected': parseInt(rating, 10) >= star && parseInt(rating, 10) != null,
        '-isDisabled': false,
      }"
    >
      <svg
        width="13px"
        height="13px"
        viewBox="0 0 42 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
      >
        <g>
          <path
            d="M32.1840261,37.568991 L28.3704261,25.1809655 L39.231509,16.2587574 L25.798253,16.1621556 L21,2.93285568 L16.201747,16.1621556 L2.76849099,16.2587574 L13.6295739,25.1809655 L9.81597389,37.568991 L21,29.957571 L32.1840261,37.568991 Z"
          ></path>
        </g>
      </svg>
    </span>
    <span class="vtoRating__average"> {{ rating === null ? 0 : rating }}/{{ ratingLimit }} </span>
  </div>
</template>

<script>
export default {
  name: 'VtoRating',

  props: {
    rating: { type: String, required: true },
    ratingLimit: { type: Number, default: 5 },
  },
};
</script>
