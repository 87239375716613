import { nextTick } from 'vue';
import { debounce } from '../../utilities';

export const getOnViewState = (e) => {
  const topIn = e.boundingClientRect.top >= 0 && e.boundingClientRect.top <= e.rootBounds.height;
  const bottomIn =
    e.boundingClientRect.bottom >= 0 && e.boundingClientRect.bottom <= e.rootBounds.height;

  return {
    // Not intersecting
    'is-below': !e.isIntersecting && e.boundingClientRect.top > 0,
    'is-above': !e.isIntersecting && e.boundingClientRect.top < 0,
    // Intersecting
    'is-on-view': e.isIntersecting,
    'is-entering': e.isIntersecting && topIn && !bottomIn,
    'is-covering':
      e.isIntersecting && e.target.offsetHeight >= e.rootBounds.height && !topIn && !bottomIn,
    'is-contained':
      e.isIntersecting && e.target.offsetHeight < e.rootBounds.height && topIn && bottomIn,
    'is-leaving': e.isIntersecting && !topIn && bottomIn,
  };
};

export const updateClassList = (prefix, node, state, context) => {
  Object.entries(state).map(([key, value]) => {
    node.classList.toggle(`${context !== 'element' ? prefix : ''}--${key}`, value);
  });
};

export const createObserver = function (el, config) {
  const threshold = [0, 1];
  const screenElRatio =
    Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) / el.offsetHeight;
  // add steps to threshold so it observe when element covers the screen
  if (screenElRatio < 1) threshold.push(screenElRatio);

  const observer = new IntersectionObserver(
    ([e]) => {
      const state = getOnViewState(e);

      Object.entries(config.classHelpers).map(([context, option]) => {
        if (option.is === true) updateClassList(config.prefix, option.selector, state, context);
      });
    },
    { threshold }
  );

  const resizeCallback = () => {
    observer.unobserve(el);
    window.removeEventListener('resize', resizeCallback);
  };

  observer.observe(el);
  window.addEventListener('resize', resizeCallback);
};

export const viewPositionHelper = {
  beforeMount: (el, binding) => {
    const config = { prefix: binding.value || el.id };

    if (!config.prefix && binding.modifiers.body)
      throw `${binding.name}: 'custom-identifier'|id is needed to prefix body classes`;

    config.classHelpers = {
      element: {
        is: binding.modifiers.el,
        selector: el,
      },
      body: {
        is: binding.modifiers.body,
        selector: document.body,
      },
    };

    // TODO: is this correct or should async/await be used?
    nextTick(createObserver.bind(null, el, config));

    window.addEventListener('resize', debounce(createObserver.bind(null, el, config), 200));
  },
};
