<template>
  <div class="oap-immersive-before-after__content-wrapper">
    <div class="oap-immersive-before-after__content">
      <div
        :class="['oap-immersive-before-after__label', labelClassName]"
        :style="`color: ${color}`"
        v-html="text"
      ></div>
      <template v-if="hasBrackets">
        <div class="oap-immersive-before-after__bracket-left"></div>
        <div class="oap-immersive-before-after__bracket-right"></div>
      </template>
      <div class="oap-immersive-before-after__image-container">
        <img alt="" class="oap-immersive-before-after__image lazyload" :src="model" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OapImmersiveBeforeAfterContent',

  props: {
    text: { type: String, default: '' },
    color: { type: String, default: '' },
    model: { type: String, default: '' },
    labelClassName: { type: String, default: '' },
    hasBrackets: { type: Boolean, default: true },
  },
};
</script>
