<template>
  <div class="vtoShare vto__page">
    <VtoHeader>
      <VtoBackButton @click="mfSendExit()" />
    </VtoHeader>
  </div>
</template>

<script>
import { mapActions } from '../store/helpers';
import { DO_MF_SEND_EXIT } from '../store/actions';
import VtoBackButton from './VtoBackButton.vue';
import VtoHeader from './VtoHeader.vue';

export default {
  name: 'VtoPageShare',

  components: {
    VtoBackButton,
    VtoHeader,
  },

  methods: {
    ...mapActions([DO_MF_SEND_EXIT]),
  },
};
</script>
