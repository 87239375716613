<template>
  <div v-if="notificationsProxy.length" class="oap-toast-notification">
    <transition-group appear name="oap-toast-notification__item" tag="ul">
      <!-- //NOSONAR UL to be rendered with the tag prop transition-group  . -->
      <li
        v-for="notification in notificationsProxy"
        :id="`toast-notification-${notification.id}`"
        :key="notification.id"
        :class="[notification.type ? `oap-toast-notification__item--${notification.type}` : '']"
        class="oap-toast-notification__item"
        role="alert"
      >
        <button
          class="oap-toast-notification__close-icon"
          @click="closeNotification(notification.id)"
        >
          <svg>
            <use xlink:href="#close"></use>
          </svg>
        </button>
        <span class="oap-toast-notification__icon">
          <svg><use :xlink:href="`#${notification.iconName}`"></use></svg>
        </span>
        <div class="oap-toast-notification__message">
          <span>{{ notification.message }}</span>
          <a
            v-if="notification.cta"
            :href="notification.cta.url"
            :target="notification.cta.target"
            >{{ notification.cta.label }}</a
          >
        </div>
      </li>
    </transition-group>
  </div>
</template>

<script>
import eventBus from '@loreal/eventbus-js';

export default {
  name: 'OapToastNotification',
  props: {
    cta: {
      type: Object,
      default: () => {},
    },
    notifications: {
      type: Array,
      default: () => [],
    },
    isSingleNotification: {
      type: Boolean,
      default: true,
    },
  },
  settings: {
    NOTIFICATION_TYPES: {
      SUCCESS: 'success',
      ERROR: 'error',
    },
  },
  data() {
    return {
      notificationsProxy: [],
    };
  },
  watch: {
    notificationsProxy(notificationsArray) {
      window.removeEventListener('scroll', this.handleScroll);
      if (notificationsArray.length) {
        window.addEventListener('scroll', this.handleScroll);
      }
    },
    immediate: true,
  },
  created() {
    this.notificationsProxy = this.notifications
      .map((notification) => {
        this.processNotification(notification);
        return notification;
      })
      .filter((notification) => notification.type && notification.message);

    if (this.isSingleNotification) {
      this.setLastNotification();
    }
    eventBus.on('oap-toast-notification:open', this.addNotification);
  },
  beforeDestroy() {
    eventBus.off('oap-toast-notification:open', this.addNotification);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    addNotification(notification) {
      if (!(notification && notification.type && notification.message)) return;
      this.processNotification(notification);

      if (this.isSingleNotification) {
        this.setLastNotification(notification);
      } else {
        if (
          this.notificationsProxy.findIndex(
            (item) => item.message === notification.message && item.type === notification.type
          ) === -1
        ) {
          this.notificationsProxy.push(notification);
        }
      }
    },
    processNotification(notification) {
      notification.id = Math.random().toString(36).substr(2, 9);
      this.setIcon(notification);

      if (notification.timeout && Number.isInteger(notification.timeout)) {
        setTimeout(() => {
          this.closeNotification(notification.id);
        }, notification.timeout);
      }
    },
    closeNotification(id) {
      const removeIndex = this.notificationsProxy.findIndex((item) => item.id === id);
      this.notificationsProxy.splice(removeIndex, 1);
    },
    setIcon(notification) {
      notification.iconName =
        notification.type === this.$options.settings.NOTIFICATION_TYPES.SUCCESS
          ? 'tick'
          : 'exclamation';
    },
    setLastNotification(notification) {
      if (notification) {
        this.notificationsProxy = [notification];
      } else {
        if (this.notifications.length) {
          this.notificationsProxy = [this.notifications[this.notifications.length - 1]];
        }
      }
      this.notificationsProxy = this.notificationsProxy.filter(
        (notification) => notification.type && notification.message
      );
    },
    handleScroll() {
      const activeNotifications = this.$el.querySelectorAll('.oap-toast-notification__item');
      activeNotifications.forEach((notification) => this.closeNotification(notification.id));
    },
  },
};
</script>
