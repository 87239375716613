<template>
  <div class="vtoPageCatalog vto__page">
    <transition :name="getCatalogViewTransition">
      <component :is="getCatalogView" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from '../store/helpers';

import { DO_CATALOG_VIEW_INITIAL } from '../store/actions';

import VtoCatalog from './VtoCatalog.vue';
import VtoHotswap from './VtoHotswap.vue';
import VtoHotswapLooks from './VtoHotswapLooks.vue';
import VtoHotswapHaircolor from './VtoHotswapHaircolor.vue';

export default {
  name: 'VtoPageCatalog',

  components: {
    VtoCatalog,
    VtoHotswap,
    VtoHotswapLooks,
    VtoHotswapHaircolor,
  },

  computed: {
    ...mapGetters(['getCatalogView', 'getCatalogViewTransition']),
  },

  methods: {
    ...mapActions([DO_CATALOG_VIEW_INITIAL]),
  },

  /* istanbul ignore next */
  beforeMount() {
    /* istanbul ignore next */
    this[DO_CATALOG_VIEW_INITIAL]();
  },
};
</script>
