<template>
  <section
    :class="[
      triggerTemplate,
      'oap-immersive-before-after',
      { '--is-text-vertical-on-mobile': isTextVerticalOnMobile },
    ]"
  >
    <div class="oap-immersive-before-after__wrapper">
      <picture class="oap-immersive-before-after__background">
        <source media="(min-width: 999px)" :srcset="background.desktop" />
        <source :srcset="background.mobile" />
        <img
          class="oap-immersive-before-after__background-image lazyload"
          :src="background.mobile"
          alt=""
        />
      </picture>

      <OapImmersiveBeforeAfterContent
        :class="'-before'"
        :text="before.text"
        :color="before.color"
        :model="model.before"
        :has-brackets="hasBrackets"
      >
      </OapImmersiveBeforeAfterContent>

      <OapImmersiveBeforeAfterContent
        :class="'-after'"
        :text="after.text"
        :color="after.color"
        :model="model.after"
        :has-brackets="hasBrackets"
        label-class-name="-after"
      >
      </OapImmersiveBeforeAfterContent>

      <div ref="copyWrapper" class="oap-immersive-before-after__copy-wrapper">
        <OapImmersiveBeforeAfterCopy
          :class="'-first'"
          v-bind="firstCopy"
        ></OapImmersiveBeforeAfterCopy>

        <OapImmersiveBeforeAfterCopy
          :class="'-second'"
          v-bind="secondCopy"
        ></OapImmersiveBeforeAfterCopy>
      </div>

      <div ref="divider" class="oap-immersive-before-after__divider"></div>
    </div>
  </section>
</template>

<script>
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import OapImmersiveBeforeAfterContent from './OapImmersiveBeforeAfterContent.vue';
import OapImmersiveBeforeAfterCopy from './OapImmersiveBeforeAfterCopy.vue';
import { uniqueId } from '@Foundation/utilities/uniqueId';

const MEDIUM_SCREEN_START = 750;
const LARGE_SCREEN_START = 1150;

export default {
  name: 'OapImmersiveBeforeAfter',
  components: {
    OapImmersiveBeforeAfterContent,
    OapImmersiveBeforeAfterCopy,
  },

  props: {
    // copy
    before: {
      type: Object,
      default: () => {},
    },
    after: {
      type: Object,
      default: () => {},
    },
    firstCopy: {
      type: Object,
      default: () => {},
    },
    secondCopy: {
      type: Object,
      default: () => {},
    },

    // images
    background: { type: Object, required: true },
    model: { type: Object, required: true },

    // scrollTrigger settings
    scrub: { type: Boolean, default: true },
    pin: { type: Boolean, default: true },
    start: { type: String, default: 'top top' },
    end: { type: String, default: '+=150%' },

    // GSAP settings
    opacity: { type: Number, default: 1 },

    hasBrackets: { type: Boolean, default: true },
    isTextVerticalOnMobile: { type: Boolean, default: false },
  },

  computed: {
    triggerTemplate() {
      return `gsap-trigger-${uniqueId()}`;
    },

    trigger() {
      return `.${this.triggerTemplate}`;
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    setTimeout(this.bindAnimation, 0);
  },

  methods: {
    bindAnimation() {
      const before = document.querySelector(
        `${this.trigger} .oap-immersive-before-after__content-wrapper.-before`
      );
      const afterLabel = document.querySelector(
        `${this.trigger} .oap-immersive-before-after__label.-after`
      );
      const copyFirst = document.querySelector(
        `${this.trigger} .oap-immersive-before-after__copy.-first`
      );
      const copySecond = document.querySelector(
        `${this.trigger} .oap-immersive-before-after__copy.-second`
      );

      let startFirst, endFirst, startSecond, endSecond, startAfterLabel, endAfterLabel;

      if (this.isTextVerticalOnMobile && window.innerWidth < MEDIUM_SCREEN_START) {
        startFirst = 70;
        endFirst = 85;
        startSecond = 5;
        endSecond = 15;
        startAfterLabel = 35;
        endAfterLabel = 40;
      } else if (window.innerWidth < LARGE_SCREEN_START) {
        startFirst = 5;
        startSecond = 5;
        endFirst = 15;
        endSecond = 15;
        startAfterLabel = 35;
        endAfterLabel = 40;
      } else {
        startFirst = 40;
        startSecond = 40;
        endFirst = 60;
        endSecond = 60;
        startAfterLabel = 25;
        endAfterLabel = 35;
      }

      gsap.to(`.${this.triggerTemplate}`, {
        scrollTrigger: {
          trigger: this.trigger,
          scrub: true,
          pin: true,
          start: this.start,
          end: this.end,
          onUpdate: (event) => {
            let progress = Math.round(event.progress * 100);
            this.$refs.divider.style.top = `${100 - progress}%`;
            before.style.height = `${100 - progress}%`;

            this.updateElementOpacity(progress, startAfterLabel, endAfterLabel, afterLabel);
            this.updateElementOpacity(progress, startFirst, endFirst, copyFirst);
            this.updateElementOpacity(progress, startSecond, endSecond, copySecond);
          },
        },
      });
    },

    updateElementOpacity(progress, start, end, element) {
      if (progress >= start && progress < end) {
        element.style.opacity = (progress - start) / (end - start);
      } else if (progress >= end) {
        element.style.opacity = 1;
      } else {
        element.style.opacity = 0;
      }
    },
  },
};
</script>
