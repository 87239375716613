<template>
  <div>
    <slot :click-event="clickEvent" />
  </div>
</template>

<script>
import { MqHandler } from '../mqHandler/index';
import { AnalyticsHandler } from '../analyticsHandler/AnalyticsHandler';

export default {
  name: 'TaggingWrapper',
  props: {
    mobileTagging: {
      type: [Object, Array],
      required: true,
    },
    defaultTagging: {
      type: [Object, Array],
      required: true,
    },
    eventType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMobile:
        MqHandler.getMqHandler().getMqForSize('medium').selected ||
        MqHandler.getMqHandler().getMqForSize('small').selected,
    };
  },
  methods: {
    clickEvent() {
      const dataToPush = this.isMobile ? this.mobileTagging : this.defaultTagging;
      AnalyticsHandler.getAnalyticsHandler().push({ ...dataToPush, type: this.eventType });
    },
  },
};
</script>
