import { intersectionViewportObserver } from '@Foundation';

import { ScrollSnapSlider } from 'scroll-snap-slider';

const IS_ACTIVE_CLASS = 'is-active';
const IS_HIDDEN_CLASS = 'is-hidden';

const DEFAULT_BREAKPOINT = {
  itemsShown: 9,
  resolution: 1920,
};

const BREAKPOINTS = [
  {
    itemsShown: 3,
    resolution: 650,
  },
  {
    itemsShown: 5,
    resolution: 999,
  },
  {
    itemsShown: 7,
    resolution: 1440,
  },
  DEFAULT_BREAKPOINT,
];

const DIRECTIONS = {
  FORWARD: 'FORWARD',
  BACKWARDS: 'BACKWARDS',
};

class SwipeEventDispatcher {
  constructor(element) {
    this.eventMap = {
      SWIPE_LEFT: [],
      SWIPE_UP: [],
      SWIPE_DOWN: [],
      SWIPE_RIGHT: [],
    };

    this.xDown = null;
    this.yDown = null;
    this.element = element;

    element.addEventListener('touchstart', (event) => this.handleTouchStart(event), false);
    element.addEventListener('touchend', (event) => this.handleTouchEnd(event), false);
  }

  on(event, callback) {
    this.eventMap[event].push(callback);
  }

  off(event, lastCallback) {
    this.eventMap[event] = this.eventMap[event].filter((callback) => callback !== lastCallback);
  }

  trigger(event, data) {
    this.eventMap[event].map((handler) => handler(data));
  }

  handleTouchStart(event) {
    this.xDown = event.touches[0].clientX;
    this.yDown = event.touches[0].clientY;
  }

  handleTouchEnd(event) {
    const deltaX = event.changedTouches[0].clientX - this.xDown;
    const deltaY = event.changedTouches[0].clientY - this.yDown;

    if (Math.abs(deltaX) > Math.abs(deltaY)) {
      deltaX < 0 ? this.trigger('SWIPE_LEFT') : this.trigger('SWIPE_RIGHT');
    } else {
      deltaY > 0 ? this.trigger('SWIPE_UP') : this.trigger('SWIPE_DOWN');
    }
  }
}

class HighlightedProducts {
  constructor(el, config) {
    this.el = el;
    this.config = config;
    this.list = el.querySelector('.oap-highlighted-products__list');
    this.titleList = el.querySelector('.oap-highlighted-products__title-list');
    this.listElements = Array.from(el.querySelectorAll('.oap-highlighted-products__item'));
    this.nextButton = el.querySelector('.oap-highlighted-products__next');
    this.previousButton = el.querySelector('.oap-highlighted-products__previous');

    this.activeIndex = 0;
    this.mainSlider = null;
    this.titleSlider = null;

    this.attachEvents = this.attachEvents.bind(this);
    this.setActiveElements = this.setActiveElements.bind(this);
    this.onNext = this.onNext.bind(this);
    this.onPrevious = this.onPrevious.bind(this);
    this.scrollToMiddleElement = this.scrollToMiddleElement.bind(this);

    this.textPaths = el.querySelectorAll('svg textPath');
    this.onMoveWithOffset = this.onMoveWithOffset.bind(this);

    this.attachEvents();
    this.scrollToMiddleElement();
  }

  get currentBreakpoint() {
    return BREAKPOINTS.find((x) => window.innerWidth <= x.resolution) || DEFAULT_BREAKPOINT;
  }

  get isInline() {
    return this.config.value.isInline;
  }

  get isAnimate() {
    return this.config.value.isAnimate;
  }

  setActiveElements(event) {
    event.target.classList.remove(IS_ACTIVE_CLASS);
    if (event.isIntersecting) event.target.classList.add(IS_ACTIVE_CLASS);

    return event.isIntersecting;
  }

  scrollToMiddleElement() {
    let middleIndex = Math.floor(this.currentBreakpoint.itemsShown / 2);

    if (this.listElements.length <= this.currentBreakpoint.itemsShown) {
      middleIndex = Math.floor(this.listElements.length / 2);
    }

    this.mainSlider.slideTo(middleIndex);
    if (this.isInline) {
      this.titleSlider?.slideTo(middleIndex);
    } else {
      if (this.isAnimate) {
        const activeTextPath = this.textPaths[middleIndex];
        activeTextPath.querySelector('animate').beginElement();
      }
    }
    this.activeIndex = middleIndex;
  }

  onNext() {
    if (this.activeIndex >= 0 && this.activeIndex < this.mainSlider.element.children.length - 1) {
      this.activeIndex = this.activeIndex + 1;

      this.mainSlider.slideTo(this.activeIndex);

      if (this.isInline) {
        this.titleSlider?.slideTo(this.activeIndex);
      } else {
        if (this.isAnimate) {
          this.onMoveWithOffset(DIRECTIONS.FORWARD);
        }
      }
    }
  }

  onPrevious() {
    if (this.activeIndex > 0 && this.activeIndex <= this.mainSlider.element.children.length) {
      this.activeIndex = this.activeIndex - 1;

      this.mainSlider.slideTo(this.activeIndex);

      if (this.isInline) {
        this.titleSlider?.slideTo(this.activeIndex);
      } else {
        if (this.isAnimate) {
          this.onMoveWithOffset(DIRECTIONS.BACKWARDS);
        }
      }
    }
  }

  onMoveWithOffset(DIRECTION = DIRECTIONS.FORWARD) {
    const previousElement = this.textPaths[this.activeIndex].previousElementSibling;
    const nextElement = this.textPaths[this.activeIndex].nextElementSibling;

    if (DIRECTION === DIRECTIONS.FORWARD) {
      previousElement?.querySelector('animate').setAttribute('from', '50%');
      previousElement?.querySelector('animate').setAttribute('to', '-250%');
      previousElement?.querySelector('animate').beginElement();

      this.textPaths[this.activeIndex].querySelector('animate').setAttribute('from', '250%');
      this.textPaths[this.activeIndex].querySelector('animate').setAttribute('to', '50%');
      this.textPaths[this.activeIndex].querySelector('animate').beginElement();
    } else {
      nextElement?.querySelector('animate').setAttribute('from', '50%');
      nextElement?.querySelector('animate').setAttribute('to', '250%');
      nextElement?.querySelector('animate').beginElement();

      this.textPaths[this.activeIndex].querySelector('animate').setAttribute('from', '-250%');
      this.textPaths[this.activeIndex].querySelector('animate').setAttribute('to', '50%');
      this.textPaths[this.activeIndex].querySelector('animate').beginElement();
    }
  }

  attachIntersectionObserver() {
    this.listElements.forEach((item) => {
      intersectionViewportObserver(item, {
        rootMargin: '40% -45% 40% -45%',
        threshold: [0],
        shouldUnobserve: false,
        conditionCheckCallback: this.setActiveElements,
      });
    });
  }

  attachSwipeDispatcher() {
    this.dispatcher = new SwipeEventDispatcher(this.list);

    this.dispatcher.on('SWIPE_RIGHT', () => {
      this.onPrevious();
    });

    this.dispatcher.on('SWIPE_LEFT', () => {
      this.onNext();
    });
  }

  attachEvents = () => {
    this.attachIntersectionObserver();
    this.attachSwipeDispatcher();
    this.nextButton.addEventListener('click', this.onNext);
    this.previousButton.addEventListener('click', this.onPrevious);

    this.mainSlider = new ScrollSnapSlider({
      element: this.list,
    });

    this.mainSlider.addEventListener('slide-stop', () => {
      this.nextButton.classList.remove(IS_HIDDEN_CLASS);
      this.previousButton.classList.remove(IS_HIDDEN_CLASS);
      if (this.activeIndex === this.mainSlider.element.children.length - 1) {
        this.nextButton.classList.add(IS_HIDDEN_CLASS);
      }

      if (this.activeIndex === 0) {
        this.previousButton.classList.add(IS_HIDDEN_CLASS);
      }
    });

    if (this.isInline) {
      this.titleSlider = new ScrollSnapSlider({
        element: this.titleList,
      });
    }
  };
}

export const highlightedProducts = {
  name: 'highlighted-products',

  mounted: (el, config) => {
    new HighlightedProducts(el, config);
  },
};
