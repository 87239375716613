<template>
  <ul class="vtoVariants">
    <li
      v-for="productId of productIdsForVariants"
      :key="productId"
      class="vtoVariant"
      :style="{ backgroundColor: productItemsMap[productId].hex }"
    ></li>
    <li v-if="productIds.length > productVariantsLimit" class="vtoVariant -plus">
      <svg role="img">
        <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#plus" />
      </svg>
    </li>
  </ul>
</template>

<script>
import { mapState } from '../store/helpers';

import { PRODUCT_LINE_VARIANTS_LIMIT } from '../settings';

export default {
  name: 'VtoLineVariants',

  props: {
    productIds: { type: Array, required: true },
  },

  computed: {
    ...mapState(['productItemsMap']),

    productIdsForVariants() {
      return this.productIds.slice(0, PRODUCT_LINE_VARIANTS_LIMIT);
    },

    productVariantsLimit() {
      return PRODUCT_LINE_VARIANTS_LIMIT;
    },
  },
};
</script>
