<template>
  <div class="oap-lightningout-scheduler">
    <slot :click="click" name="cta" />
    <OapPopup
      :open="openModal"
      :hide-overflow="true"
      class="oap-lightningout-scheduler__modal"
      @closed="close"
    >
      <iframe
        v-if="openModal"
        class="oap-lightningout-scheduler__iframe"
        title="Lightning scheduler"
        :height="height"
        :src="iframePath"
      ></iframe>
      <template #close>
        <button :aria-label="closeButtonLabel" class="channel-advisor-modal__exit" @click="close">
          <svg class="icon" aria-hidden="true">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#close"></use>
          </svg>
        </button>
      </template>
    </OapPopup>
  </div>
</template>

<script>
import OapPopup from '../../../../../Feature/OapPopup/code/Scripts/components/OapPopup.vue';

export default {
  name: 'OapLightningoutScheduler',
  components: {
    OapPopup,
  },
  props: {
    closeButtonLabel: {
      type: String,
      required: false,
      default: 'Close modal',
    },
    iframePath: {
      type: String,
      required: true,
    },
    height: {
      type: [Number, String],
      required: false,
      default: 640,
    },
  },
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    click(e) {
      e.preventDefault();
      if (!e.isTrusted) return;
      this.openModal = true;
      return false;
    },
    close() {
      this.openModal = false;
    },
  },
};
</script>
