<template>
  <a
    v-tag:useractionevent="item.tagging"
    :href="item.cta.link"
    class="oap-promo-cards"
    :style="{ order: position }"
  >
    <div class="oap-promo-cards__background">
      <picture class="oap-promo-cards__picture">
        <img :alt="item.image.alt" aria-hidden="true" :src="item.image.default.src" />
      </picture>
    </div>
    <div class="oap-promo-cards__wrapper">
      <div class="oap-promo-cards__content">
        <span v-if="item.eyebrow" class="oap-promo-cards__eyebrow">{{ item.eyebrow }}</span>
        <img
          v-if="item.logo"
          :alt="item.logo.alt"
          aria-hidden="true"
          :src="item.logo.src"
          class="oap-promo-cards__logo"
        />
        <p class="oap-promo-cards__title">{{ item.title }}</p>
        <p v-if="item.description" class="oap-promo-cards__description">{{ item.description }}</p>
      </div>
      <div class="oap-promo-cards__action">
        <button class="oap-promo-cards__cta oap-button -primary">
          <span>{{ item.cta.label }}</span>
        </button>
      </div>
    </div>
  </a>
</template>

<script>
import { tag } from '../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';

export default {
  name: 'OapPromoCard',
  directives: {
    tag,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: String,
      required: false,
    },
  },
};
</script>
