<template>
  <div class="vtoProduct" v-on="$attrs" @click="$attrs.click && $attrs.click()">
    <div class="vtoProduct__packshotWrapper">
      <img
        class="vtoProduct__packshot"
        :src="product.image"
        :alt="`${product.brand} ${product.subBrand}`"
      />
    </div>
    <div class="vtoProduct__infoContainer">
      <p class="vtoProduct__infoName">{{ product.brand }} {{ product.subBrand }}</p>
      <p class="vtoProduct__infoShade">
        <span class="vtoProduct__infoColor" :style="{ backgroundColor: product.hex }"></span>
        {{ product.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VtoProduct',
  props: {
    product: { type: Object, required: true },
  },
};
</script>
