<template>
  <div v-if="show" class="vtoPageEntitySelection vto__page">
    <VtoHeader class="-column -clickThrough">
      <VtoHeaderDescription :text="headerDescriptionText" />
      <VtoHeaderLabel :text="headerLabelText" />
    </VtoHeader>

    <VtoBody>
      <VtoSwitcher
        v-if="selectedProductId || selectedLookFamilyId"
        :category="getOppositeCategory"
        @click="openCatalog(false)"
      />
      <component :is="getEntitySelectionView" />
    </VtoBody>

    <VtoFooter v-if="shouldShowFooter">
      <div class="vtoActions">
        <button class="vtoActions__buy oap-button -primary -theme-brand" @click="openCatalog(true)">
          <span class="oap-button__label">{{ dictionary.editThisLook }}</span>
        </button>
      </div>
    </VtoFooter>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from '../store/helpers';

import { VIEW_LOOK_SELECTION } from '../settings';

import VtoBody from './VtoBody.vue';
import VtoEntitySelection from './VtoEntitySelection.vue';
import VtoFooter from './VtoFooter.vue';
import VtoHeader from './VtoHeader.vue';
import VtoHeaderDescription from './VtoHeaderDescription.vue';
import VtoHeaderLabel from './VtoHeaderLabel.vue';
import VtoLookSelection from './VtoLookSelection.vue';
import VtoSubentitySelection from './VtoSubentitySelection.vue';
import VtoSwitcher from './VtoSwitcher.vue';

export default {
  name: 'VtoPageEntitySelection',

  components: {
    VtoBody,
    VtoEntitySelection,
    VtoFooter,
    VtoHeader,
    VtoHeaderDescription,
    VtoHeaderLabel,
    VtoLookSelection,
    VtoSubentitySelection,
    VtoSwitcher,
  },

  computed: {
    ...mapState([
      'dictionary',
      'show',
      'productItemsMap',
      'productLinesMap',
      'selectedLookFamilyId',
      'selectedLookId',
      'selectedProductId',
      'selectedTypeId',
    ]),
    ...mapGetters(['getEntitySelectionView', 'getSelectedTypeTabLabel', 'getOppositeCategory']),

    shouldShowFooter() {
      return this.selectedLookFamilyId && this.getEntitySelectionView === VIEW_LOOK_SELECTION;
    },

    shouldShowDictionaryTextInHeaderDescription() {
      return !this.selectedLookFamilyId || (this.selectedLookFamilyId && !this.selectedLookId);
    },

    shouldShowSelectedLookFamilyInHeaderDescription() {
      return this.selectedLookFamilyId && this.selectedLookId;
    },

    shouldShowDictionaryTextInHeaderLabel() {
      return !this.selectedTypeId && !this.selectedLookId;
    },

    shouldShowSelectedTypeInHeaderLabel() {
      return this.selectedTypeId && !this.selectedLookId;
    },

    headerDescriptionText() {
      if (this.shouldShowDictionaryTextInHeaderDescription) {
        return this.dictionary.selectBelow;
      } else if (this.shouldShowSelectedLookFamilyInHeaderDescription) {
        return this.productLinesMap[this.selectedLookFamilyId].starProductItem.title;
      }

      return this.dictionary.selectBelow;
    },

    headerLabelText() {
      if (this.shouldShowDictionaryTextInHeaderLabel) {
        return this.dictionary.lookOrProduct;
      } else if (this.shouldShowSelectedTypeInHeaderLabel) {
        return this.getSelectedTypeTabLabel;
      }

      return this.productItemsMap[this.selectedLookId].name;
    },
  },

  methods: {
    ...mapActions(['openCatalog']),
  },
};
</script>
