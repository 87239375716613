<template>
  <div
    ref="container"
    v-view-position-helper.body="'oap-immersive-hero'"
    class="oap-immersive-parallax-hero"
  >
    <OapPicture
      class="oap-immersive-parallax-hero__start-background"
      :mobile="startBackground.mobile"
      :desktop="startBackground.desktop"
      media="(min-width: 651px)"
    />
    <OapPicture
      class="oap-immersive-parallax-hero__end-background"
      :mobile="endBackground.mobile"
      :desktop="endBackground.desktop"
      media="(min-width: 651px)"
    />
    <OapPicture
      class="oap-immersive-parallax-hero__start-hero-left"
      :mobile="startHeroImgLeft"
      :desktop="startHeroImgLeft"
      media="(min-width: 651px)"
    />
    <OapPicture
      class="oap-immersive-parallax-hero__start-hero-right"
      :mobile="startHeroImgRight"
      :desktop="startHeroImgRight"
      media="(min-width: 651px)"
    />
    <div class="oap-immersive-parallax-hero__packshot">
      <OapPicture
        class="oap-immersive-parallax-hero__packshot-top"
        :mobile="packshotImage.top"
        :desktop="packshotImage.top"
        media="(min-width: 651px)"
      />
      <OapPicture
        class="oap-immersive-parallax-hero__packshot-middle"
        :mobile="packshotImage.middle"
        :desktop="packshotImage.middle"
        media="(min-width: 651px)"
      />
      <OapPicture
        class="oap-immersive-parallax-hero__packshot-bottom"
        :mobile="packshotImage.bottom"
        :desktop="packshotImage.bottom"
        media="(min-width: 651px)"
      />
    </div>
    <div class="oap-immersive-parallax-hero__copy">
      <img :src="logoSrc" class="oap-immersive-parallax-hero__logo" alt="" />
      <div class="oap-immersive-parallax-hero__title">{{ title }}</div>
      <div class="oap-immersive-parallax-hero__subtitle">{{ subtitle }}</div>
    </div>
    <div class="oap-immersive-parallax-hero__arrow-wrapper">
      <div class="oap-immersive-parallax-hero__arrow-text">{{ scrollLabel }}</div>
      <img class="oap-immersive-parallax-hero__arrow-img" :src="iconArrow" alt="" />
    </div>
    <div class="oap-immersive-parallax-hero__text">
      <div class="oap-immersive-parallax-hero__text-start-left" v-html="startTextLeft"></div>
      <div class="oap-immersive-parallax-hero__text-start-right" v-html="startTextRight"></div>
      <div class="oap-immersive-parallax-hero__text-end-left" v-html="endTextLeft"></div>
      <div class="oap-immersive-parallax-hero__text-end-right" v-html="endTextRight"></div>
    </div>
  </div>
</template>

<script>
import OapPicture from '../../../../OapPicture/code/Scripts/components/OapPicture.vue';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { viewPositionHelper } from '../../../../../Foundation/Core/code/Scripts/directives/view-position-helper/view-position-helper';

const LARGE_SCREEN_BREAKPOINT = 999;
const LARGE_SCREEN_BREAKPOINT_ALT = 1200;
const ANIMATION_LABEL = {
  logo: 'logoLabel',
  title: 'titleLabel',
  copy: 'copyLabel',
  endText: 'endTextLabel',
  startTextMobile: 'startTextMobileLabel',
  endFirstScene: 'endFirstSceneLabel',
  startThirdScene: 'startThirdSceneLabel',
  heroText: 'heroTextLabel',
};

let timeline = null;

export default {
  name: 'OapImmersiveParallaxHero',

  components: {
    OapPicture,
  },

  directives: {
    viewPositionHelper,
  },

  props: {
    logoSrc: { type: String, default: '' },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    scrollLabel: { type: String, default: '' },

    // images
    startBackground: { type: Object, required: true },
    endBackground: { type: Object, required: true },
    startHeroImgLeft: { type: String, default: '' },
    startHeroImgRight: { type: String, default: '' },
    packshotImage: { type: Object, required: true },
    iconArrow: { type: String, default: '' },

    // appearing text
    startTextLeft: { type: String, default: '' },
    startTextRight: { type: String, default: '' },
    endTextLeft: { type: String, default: '' },
    endTextRight: { type: String, default: '' },

    animationEnd: { type: String, default: '+4000px' },
  },

  data() {
    return {
      isCssAnimated: false,
    };
  },

  computed: {
    isSmallScreen() {
      return window.innerWidth < LARGE_SCREEN_BREAKPOINT;
    },

    isAltLargeScreen() {
      return (
        window.innerWidth > LARGE_SCREEN_BREAKPOINT &&
        window.innerWidth <= LARGE_SCREEN_BREAKPOINT_ALT
      );
    },
  },

  watch: {
    isCssAnimated(isAnimated) {
      if (isAnimated) {
        this.playAnimation();
      }
    },
  },

  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    this.$nextTick(() => {
      this.playAnimationForAppearing();
      this.bindAnimation();
    });
  },

  methods: {
    bindAnimation() {
      timeline = gsap.timeline({
        scrollTrigger: {
          trigger: `.${this.$refs.container.className}`,
          scrub: true,
          pin: `.${this.$refs.container.className}`,
          start: 'top top',
          end: this.animationEnd,
        },
      });
    },

    playAnimationForAppearing() {
      const firstTimeline = gsap.timeline({
        onComplete: () => {
          this.isCssAnimated = true;
          timeline.scrollTrigger.refresh();
        },
      });

      firstTimeline
        .to('.oap-immersive-parallax-hero__logo', { opacity: 1 }, ANIMATION_LABEL.logo)
        .to('.oap-immersive-parallax-hero__title', { opacity: 1 }, `${ANIMATION_LABEL.logo}+=10%`)
        .addLabel(ANIMATION_LABEL.title)
        .to('.oap-immersive-parallax-hero__subtitle', { opacity: 1 })
        .to('.oap-immersive-parallax-hero__arrow-wrapper', { opacity: 1 }, ANIMATION_LABEL.title)
        .to(
          '.oap-immersive-parallax-hero__start-background',
          { opacity: 0.94 },
          ANIMATION_LABEL.title
        )
        .to(
          '.oap-immersive-parallax-hero__start-hero-left',
          { duration: 1, left: this.isAltLargeScreen ? '-20%' : 0 },
          ANIMATION_LABEL.logo
        )
        .to(
          '.oap-immersive-parallax-hero__start-hero-right',
          { duration: 1, right: this.isAltLargeScreen ? '-20%' : 0 },
          ANIMATION_LABEL.logo
        )
        .to(
          '.oap-immersive-parallax-hero__packshot',
          { duration: 1, bottom: this.isSmallScreen ? '-15px' : '0%' },
          ANIMATION_LABEL.logo
        );
    },

    playAnimation() {
      // FIRST SCENE is handled with GSAP in playAnimationForAppearing() method
      timeline
        // SECOND SCENE is handled with GSAP below
        .to(
          '.oap-immersive-parallax-hero__copy',
          { duration: 0.1, opacity: 0 },
          ANIMATION_LABEL.copy
        )
        .to(
          '.oap-immersive-parallax-hero__start-background',
          { opacity: 0 },
          `${ANIMATION_LABEL.copy}+=5%`
        )
        .to(
          '.oap-immersive-parallax-hero__end-background',
          { opacity: 1 },
          `${ANIMATION_LABEL.copy}+=5%`
        )
        .to(
          '.oap-immersive-parallax-hero__start-hero-left',
          { duration: 1, left: '-100%' },
          ANIMATION_LABEL.copy
        )
        .to(
          '.oap-immersive-parallax-hero__start-hero-right',
          { duration: 1, right: '-100%' },
          ANIMATION_LABEL.copy
        )
        .to(
          '.oap-immersive-parallax-hero__arrow-wrapper',
          { opacity: 0 },
          `${ANIMATION_LABEL.copy}+=10%`
        )
        .to(
          '.oap-immersive-parallax-hero__packshot-bottom',
          { scale: this.isSmallScreen ? 2 : 3, y: '50%' },
          `${ANIMATION_LABEL.copy}+=10%`
        )
        .to(
          '.oap-immersive-parallax-hero__packshot-middle',
          this.isSmallScreen ? { scale: 2, y: '-145%' } : { scale: 3, y: '-160%' },
          `${ANIMATION_LABEL.copy}+=10%`
        )
        .to(
          '.oap-immersive-parallax-hero__packshot-top',
          this.isSmallScreen ? { scale: 2, y: '-145%' } : { scale: 3, y: '-160%' },
          `${ANIMATION_LABEL.copy}+=10%`
        )
        .to('.oap-immersive-parallax-hero__text', this.isSmallScreen ? { y: '-50%' } : {})
        .to(
          '.oap-immersive-parallax-hero__text-start-left',
          { display: 'block', opacity: 1 },
          this.isSmallScreen ? ANIMATION_LABEL.startTextMobile : `${ANIMATION_LABEL.copy}+=70%`
        )
        .to(
          '.oap-immersive-parallax-hero__text-start-right',
          { display: 'block', opacity: 1 },
          this.isSmallScreen ? ANIMATION_LABEL.startTextMobile : `${ANIMATION_LABEL.copy}+=85%`
        )
        // THIRD SCENE is handled with GSAP below
        .to(
          '.oap-immersive-parallax-hero__text-start-left',
          { display: 'none', opacity: 0 },
          ANIMATION_LABEL.startThirdScene
        )
        .to(
          '.oap-immersive-parallax-hero__text-start-right',
          { display: 'none', opacity: 0 },
          ANIMATION_LABEL.startThirdScene
        )
        .to(
          '.oap-immersive-parallax-hero__text',
          this.isSmallScreen ? { y: '70%' } : {},
          this.isSmallScreen ? ANIMATION_LABEL.heroText : ''
        )
        .to(
          '.oap-immersive-parallax-hero__packshot-middle',
          { scale: this.isSmallScreen ? 4 : 5, y: '-180%' },
          this.isSmallScreen
            ? `${ANIMATION_LABEL.heroText}+=40%`
            : `${ANIMATION_LABEL.startThirdScene}+=100%`
        )
        .to(
          '.oap-immersive-parallax-hero__packshot-bottom',
          { scale: 3, y: '110%' },
          this.isSmallScreen
            ? `${ANIMATION_LABEL.heroText}+=40%`
            : `${ANIMATION_LABEL.startThirdScene}+=100%`
        )
        .to(
          '.oap-immersive-parallax-hero__text-end-left',
          { display: 'block', opacity: 1 },
          this.isSmallScreen
            ? `${ANIMATION_LABEL.heroText}+=100%`
            : `${ANIMATION_LABEL.startThirdScene}+=100%`
        )
        .to(
          '.oap-immersive-parallax-hero__text-end-right',
          { display: 'block', opacity: 1 },
          this.isSmallScreen
            ? `${ANIMATION_LABEL.heroText}+=100%`
            : `${ANIMATION_LABEL.startThirdScene}+=100%`
        );
    },
  },
};
</script>
