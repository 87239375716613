import eventBus from '@loreal/eventbus-js';

export default {
  // eslint-disable-line
  name: 'search',
  beforeMount: (el, binding) => {
    const ACTIVE = '-active';
    const ACTIVE_NAVIGATION_BURGER = '.navigation__burger.-active';
    const ACTIVE_NAVIGATION_LIST = '.navigationList.-active';
    const ACTIVE_NAVIGATION_PANEL_WRAPPER = '.navigation__panelWrapper.-active';
    const OPENED_OVERLAY = '.overlay.overlay--open';
    const DISABLED = 'disabled';
    const OVERLAY_ACTIVE = 'overlay--active';
    const NO_SCROLL = 'noScroll';
    const MENU_IS_ACTIVE = 'menu--is-active';
    const HEADER_WRAPPER = '.oap-header__wrapper';
    const OPAQUE = '-opaque';
    const SEARCH_IS_ACTIVE = 'search--is-active';

    // Classes for A11Y checks
    const SEARCH_FORM_ITEM = 'oap-search__form-item';
    const SEARCH_SUGGESTIONS_LINK = 'oap-search-suggestions-list__link';

    // eventBus events
    const SEARCH_SUGGESTIONS_CLOSE_EVENT = 'search-suggestions::close';

    // DOM structure shared between header-search.hbs and main-search.hbs
    const searchForm = el.querySelector('.oap-search__form');
    const searchInput = el.querySelector('.oap-search__input');
    const searchButton = el.querySelector('.oap-search__button');

    // DOM structure that is only specific to header-search.hbs
    const openSearch = el.querySelector('.oap-search__label.-open');
    const closeSearch = el.querySelector('.oap-search__label.-close');
    const clickOverlay = el.querySelector('.oap-click-overlay');
    const searchCheckbox = el.querySelector('.oap-search__checkbox');

    const scrollTransparentHeaderPosition = 500;

    // remove native browser validation from search input
    searchInput.removeAttribute('pattern');

    // disable the search button to add JS validation
    searchButton.setAttribute(DISABLED, true);

    // add our own simple JS validation to the form
    searchInput.addEventListener('keyup', () => {
      /* istanbul ignore next */
      if (searchInput.value.length > binding.value - 1) {
        searchButton.removeAttribute(DISABLED);
        return;
      }

      if (searchInput.value.length < binding.value && !searchButton.hasAttribute(DISABLED)) {
        searchButton.setAttribute(DISABLED, true);
      }
    });

    searchInput.addEventListener('click', () => {
      setTimeout(() => {
        document.body.classList.add('headerIsShown');
      }, 500);
    });

    searchForm.addEventListener('submit', (event) => {
      if (searchInput.value.length < binding.value) {
        event.preventDefault();
      }
    });

    /* istanbul ignore next */
    if (openSearch) {
      openSearch.addEventListener('click', () => {
        eventBus.emit('closeNav', false);
        const oBody = document.querySelector('body');
        oBody.classList.remove(OVERLAY_ACTIVE, NO_SCROLL, MENU_IS_ACTIVE);
        searchCheckbox.checked = true;
        document.querySelector(HEADER_WRAPPER).classList.add(SEARCH_IS_ACTIVE);

        if (document.body.getBoundingClientRect().top !== 0) {
          document.querySelector(HEADER_WRAPPER).classList.add(OPAQUE);
        }

        if (document.querySelector(ACTIVE_NAVIGATION_LIST)) {
          document.querySelector(ACTIVE_NAVIGATION_LIST).classList.remove(ACTIVE);
        }

        if (document.querySelector(ACTIVE_NAVIGATION_PANEL_WRAPPER)) {
          document.querySelector(ACTIVE_NAVIGATION_PANEL_WRAPPER).classList.remove(ACTIVE);
          document.querySelector('.navigationList__item.-active').classList.remove(ACTIVE);
        }

        if (document.querySelector(ACTIVE_NAVIGATION_BURGER)) {
          document.querySelector(ACTIVE_NAVIGATION_BURGER).classList.remove(ACTIVE);
        }

        if (document.querySelector(OPENED_OVERLAY)) {
          document.querySelector(OPENED_OVERLAY).classList.remove('overlay--open');
        }

        setTimeout(() => {
          searchInput.focus();
          document.addEventListener('keyup', onKeyupHandler);
        }, 300);
      });
    }

    function onKeyupHandler(event) {
      const { code, target } = event;
      if (
        code === 'Tab' &&
        !target.classList.contains(SEARCH_FORM_ITEM) &&
        !target.classList.contains(SEARCH_SUGGESTIONS_LINK)
      ) {
        searchInput.value = '';
        searchInput.blur();
        searchCheckbox.checked = false;
        document.removeEventListener('keyup', onKeyupHandler);
      }
    }

    function onTimeout(checkbox, input, event) {
      if (event) {
        event.preventDefault();
      }
      setTimeout(() => {
        input.value = '';
        input.blur();
        checkbox.checked = false;
        openSearch.focus();
      }, 300);
    }

    /* istanbul ignore next */
    if (closeSearch) {
      closeSearch.addEventListener('click', (event) => {
        onTimeout(searchCheckbox, searchInput, event);
        eventBus.emit(SEARCH_SUGGESTIONS_CLOSE_EVENT);
        document.querySelector(HEADER_WRAPPER).classList.remove(SEARCH_IS_ACTIVE);

        if (
          Math.abs(document.body.getBoundingClientRect().top) <= scrollTransparentHeaderPosition
        ) {
          document.querySelector(HEADER_WRAPPER).classList.remove(OPAQUE);
        }
      });
    }

    /* istanbul ignore next */
    if (clickOverlay && searchCheckbox) {
      clickOverlay.addEventListener('click', () => {
        onTimeout(searchCheckbox, searchInput);
        eventBus.emit(SEARCH_SUGGESTIONS_CLOSE_EVENT);
        document.querySelector(HEADER_WRAPPER).classList.remove(SEARCH_IS_ACTIVE);

        if (
          Math.abs(document.body.getBoundingClientRect().top) <= scrollTransparentHeaderPosition
        ) {
          document.querySelector(HEADER_WRAPPER).classList.remove(OPAQUE);
        }
      });
    }
  },
};
