<template>
  <transition name="modal">
    <div v-show="showModal" class="modal__mask">
      <div v-if="focusTrap" ref="topFocusGuard" tabindex="0"></div>
      <button
        v-if="hasCloseIcon"
        ref="closeButtonSmallScreen"
        class="modal__close -smallScreen"
        @blur="closeBtnBlurEvent"
        @click="closeModal"
        @keyup.esc="closeModal"
        @keyup.shift.tab="shiftTabKeyupEvent"
      >
        <svg :aria-labelledby="modalIdentifierSmall" class="icon" role="img">
          <title :id="modalIdentifierSmall">Close</title>
          <use xlink:href="#close" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
        </svg>
      </button>

      <div class="modal__layer" @click="backdropClick"></div>

      <div class="modal__wrapper">
        <div class="modal__wrapperLayer" @click="backdropClick"></div>
        <div class="modal__container">
          <button
            v-if="hasCloseIcon"
            ref="closeButtonLargeScreen"
            class="modal__close -largeScreen"
            @blur="closeBtnBlurEvent"
            @click="closeModal"
            @keyup.esc="closeModal"
            @keyup.shift.tab="shiftTabKeyupEvent"
          >
            <svg :aria-labelledby="modalIdentifierLarge" class="icon" role="img">
              <title :id="modalIdentifierLarge">Close</title>
              <use xlink:href="#close" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
            </svg>
          </button>

          <div class="modal__body">
            <slot />
          </div>
        </div>
      </div>
      <div v-if="focusTrap" ref="bottomFocusGuard" tabindex="0"></div>
    </div>
  </transition>
</template>

<script>
import eventBus from '@loreal/eventbus-js';
import MqHandler from '../../../../../Foundation/Core/code/Scripts/mqHandler/MqHandler';

export default {
  name: 'Modal',
  props: {
    hasBackdropClick: {
      default: true,
      type: Boolean,
    },
    modalidentifier: { type: String, default: 'default-modal-id' },
    focusTrap: { type: Boolean, default: true },
    hasCloseIcon: { type: Boolean, default: true },
    domNodesToHide: { type: Array, default: () => ['header', 'footer', '#content'] },
    isInZapper: { type: Boolean, default: false },
  },
  data() {
    return {
      showModal: false,
      smallScreen: MqHandler.getMqHandler().getMqForSize('small').selected,
      modalId: this.modalidentifier,
      modalIdentifierSmall: `close-modal-icon-small-${Math.random()}`,
      modalIdentifierLarge: `close-modal-icon-large-${Math.random()}`,
    };
  },
  mounted() {
    /* istanbul ignore next */
    const defaultPayload = {
      isFromUrlParam: false,
      showModal: true,
    };
    eventBus.on(`modal:${this.modalId}`, ({ isFromUrlParam, showModal } = defaultPayload) => {
      this.showModal = showModal;

      if (this.showModal) {
        if (!this.isInZapper || isFromUrlParam) {
          document.querySelector('body').classList.add('noScroll');
        }

        this.setAriaHidden('true');

        window.addEventListener('keyup', (e) => {
          if (e.keyCode === 27) {
            this.closeModal();
          }
        });
      } else {
        this.closeModal();
      }

      this.$nextTick(() => {
        if (this.showModal) {
          this.setButtonFocus();
        }
      });
    });

    /* istanbul ignore next */
    eventBus.on('mediaquery::changed', (mq) => {
      this.smallScreen = mq.size === 'small';
    });

    // right arrow in carousel blur event, focus on cross again.
    /* istanbul ignore next */
    eventBus.on('lastArrowBlur', () => {
      this.setButtonFocus();
    });

    if (this.focusTrap) {
      this.focusGuard();
      this.toggleDomNodes(true);
    }
  },

  beforeDestroy() {
    window.removeEventListener('keyup');

    if (this.focusTrap) {
      this.$refs.topFocusGuard.removeEventListener('focus');
      this.$refs.bottomFocusGuard.removeEventListener('focus');
    }
  },

  methods: {
    backdropClick() {
      if (!this.hasBackdropClick) return;

      this.closeModal();
    },

    setAriaHidden(value) {
      const ariaHidden = 'aria-hidden';
      const DOCUMENT_SELECTORS = {
        BODY: document.querySelector('body'),
        HEADER: document.querySelector('header'),
        FOOTER: document.querySelector('footer'),
        CONTENT: document.querySelector('#content'),
      };

      DOCUMENT_SELECTORS.HEADER ? DOCUMENT_SELECTORS.HEADER.setAttribute(ariaHidden, value) : false;
      DOCUMENT_SELECTORS.FOOTER ? DOCUMENT_SELECTORS.FOOTER.setAttribute(ariaHidden, value) : false;
      DOCUMENT_SELECTORS.CONTENT
        ? DOCUMENT_SELECTORS.CONTENT.setAttribute(ariaHidden, value)
        : false;
    },
    closeModal() {
      this.showModal = false;

      if (document.querySelector('.noScroll') && !this.isInZapper) {
        document.querySelector('body').classList.remove('noScroll');
      }

      this.setAriaHidden('false');

      eventBus.emit('modal:closed', this.modalidentifier);

      this.toggleDomNodes(false);
    },

    closeBtnBlurEvent() {
      if (this.shiftAndTabHappened && this.$el.querySelector('.carousel-navigation-button')) {
        let buttonToFocus = this.$el.querySelector('.carousel-navigation-next');

        if (buttonToFocus.disabled) {
          buttonToFocus = this.$el.querySelector('.carousel-navigation-prev');
        }

        buttonToFocus.focus();

        this.shiftAndTabHappened = false;
      }
    },

    shiftTabKeyupEvent() {
      this.shiftAndTabHappened = true;
    },

    focusGuard() {
      this.$refs.topFocusGuard.addEventListener('focus', () => {
        this.setButtonFocus();
      });
      this.$refs.bottomFocusGuard.addEventListener('focus', () => {
        this.setButtonFocus();
      });
    },

    toggleDomNodes(value) {
      this.domNodesToHide.forEach((node) => {
        let nodeInDom = document.querySelector(node);
        if (nodeInDom) {
          nodeInDom.setAttribute('aria-hidden', value);
        }
      });
    },

    setButtonFocus() {
      if (this.hasCloseIcon) {
        this.smallScreen
          ? this.$refs.closeButtonSmallScreen?.focus()
          : this.$refs.closeButtonLargeScreen?.focus();
      }
    },
  },
};
</script>
