import {
  SET_API_DATA,
  SET_CATALOG_TYPE,
  SET_CATALOG_VIEW,
  SET_CURRENT_PAGE,
  SET_ENABLE_FAVORITE,
  SET_FAVORITE_SETTINGS,
  SET_FRAME_READY,
  SET_HOTSWAP_REMOVE_TYPE_ID,
  SET_MAIN_CATALOG,
  SET_MAIN_CATALOG_SWITCH,
  SET_MAIN_CATEGORY,
  SET_OPPOSITE_CATEGORY,
  SET_RATING,
  SET_MODAL_DISPLAY,
  SET_MODIFACE_DATA,
  SET_INITIAL_PRODUCT_ID,
  SET_SHOW_FLOATING_BAR,
  SET_SHOW_SHADE,
  SET_SHOW_SNACKBAR,
  SET_SELECTED_LOOK_FAMILY_ID,
  SET_SELECTED_LOOK_ID,
  SET_SELECTED_PRODUCT_ID,
  SET_SELECTED_PRODUCTS,
  SET_SELECTED_TYPE_ID,
  SET_RESET_SELECTED_LOOK_FAMILY_ID,
  SET_RESET_SELECTED_LOOK_ID,
  SET_RESET_SELECTED_TYPE_ID,
  SET_REMOVE_SELECTED_PRODUCTS,
  SET_PRODUCT_LISTS_MAP,
  SET_UPDATE_FACET,
  SET_NEW_FACET,
  SET_FACET_CONFIG,
  SET_UPDATE_FACETED_PRODUCTS_COLLECTION,
  SET_INCREMENT_HISTORY_DEPTH,
  SET_RESET_STORE,
  SET_FACET_PRESET,
  SET_HISTORY_DEPTH,
  SET_ORIGIN_LOCATION,
  SET_BUY_DISABLED,
  SET_CIRCLES_ANIMATION_STATUS,
} from './mutations';

import {
  CATALOG_MODE_LOOK,
  CATALOG_MODE_SINGLE,
  CATALOG_MODE_MULTI,
  FRAME_NAME,
  MF_EVENT_CUSTOM_LOOK_CHANGE,
  MF_EVENT_EXIT,
  MF_EVENT_MODIFY_CTA_DISPLAY,
  VTO_API_URL,
  PAGE_CATALOG,
  PAGE_DEFAULT,
  PAGE_ENTITY_SELECTION,
  PAGE_HAIR_DETECTION,
  PAGE_LANDING,
  PAGE_MAIN,
  FACETS_SETTINGS,
  MF_CTA_DISPLAY_DEFAULT,
  MF_CTA_SETTINGS,
  CATEGORY_HAIR_COLOR,
  CATEGORY_MAKE_UP,
  VTO_TRIGGER_URL_PARAM,
  FACET_STATE,
  FACET_MAPS,
  FACET_CONFIGS,
  VTO_HISTORY_DEPTH_PARAM,
  VTO_HAIRCOLOR_URL_PARAM,
  FACET_ATTRIBUTES,
  VTO_URL_PARAM_UTM_SOURCE_POS_VALUE,
  VTO_URL_PARAM_UTM_SOURCE_KEY,
  TAGGING_INFO_VTO_DETAIL_TYPE,
  TYPE_LOOK,
  SET_EMAIL_ENABLED,
  EMAIL_BUTTON_TEXT,
  EMAIL_BUTTON_URL,
  VIEW_CATALOG,
  VIEW_HOTSWAP,
  VIEW_HOTSWAP_LOOKS,
  VIEW_HOTSWAP_HAIRCOLOR,
} from '../settings';

import {
  findObjectKeyByPropertyValue,
  getCustomLookChange,
  getDefaultLists,
  getFacetedLists,
  getFacetedProductIdsCollection,
  getInitialHistoryDepth,
  getProductTypeId,
  getSelectedTypeId,
  getUPCChange,
  isSingleMode,
  mapEventsToPage,
  toggleArrayValue,
  unique,
  URLGetParam,
  URLHasKey,
} from './helpers';

import { fetchData } from '../../../../../Foundation/Core/code/Scripts/mixins/fetchData/fetchData';
import { AnalyticsHandler } from '../../../../../Foundation/Core/code/Scripts';

// action types
export const DO_ADD_HOTSWAP_REMOVE_TYPE_ID = 'addHotSwapRemoveTypeId',
  DO_RESET_HOTSWAP_REMOVE_TYPE_ID = 'resetHotSwapRemoveTypeId',
  DO_FRAME_READY = 'frameReady',
  DO_INIT_STORE = 'initStore',
  DO_LOAD_DATA = 'loadData',
  DO_UPDATE_INITIAL_DATA = 'updateInitialData',
  DO_EVENT_TRACKING = 'eventTracking',
  DO_PUSH_TAGGING_INFO = 'pushTaggingInfo',
  DO_PUSH_TAGGING_INFO_SIMPLE = 'pushTaggingInfoSimple',
  DO_GO_TO_PAGE = 'goToPage',
  DO_GO_TO_PAGE_CATALOG = 'goToPageCatalog',
  DO_GO_TO_PAGE_ENTITY_SELECTION = 'goToPageEntitySelection',
  DO_GO_TO_PAGE_HAIR_DETECTION = 'goToPageHairDetection',
  DO_GO_TO_PAGE_MAIN = 'goToPageMain',
  DO_GO_TO_PAGE_LANDING = 'goToPageLanding',
  DO_TOGGLE_MODAL = 'toggleModal',
  DO_OPEN_CATALOG = 'openCatalog',
  DO_REMOVE_PRODUCT = 'removeProduct',
  DO_APPLY_SELECTED_LOOK = 'applySelectedLook',
  DO_RESET_SELECTED_LOOK = 'resetSelectedLook',
  DO_RESET_SELECTED_LOOK_ID = 'resetSelectedLookId',
  DO_SELECT_LOOK_FAMILY = 'selectLookFamily',
  DO_SELECT_LOOK_ID = 'selectLookId',
  DO_DISPLAY_FLOATING_BAR = 'displayFloatingBar',
  DO_DISPLAY_SHADE_OVERLAY = 'displayShadeOverlay',
  DO_SELECT_SHADE = 'selectShade',
  DO_SELECT_TYPE = 'selectType',
  DO_EDIT_PRODUCT_SELECTION = 'editProductSelection',
  DO_MF_SEND_CHANGE = 'mfSendChange',
  DO_MF_SEND_REMOVE_CUSTOM_LOOK = 'mfSendRemoveCustomLook',
  DO_MF_SEND_EXIT = 'mfSendExit',
  DO_MF_SEND_CTA_DISPLAY = 'mfSendCtaDisplay',
  DO_MF_SHOW_FOR_YOU_LOADER = 'mfShowForYouLoader',
  DO_MF_HAIR_COLOR_DETECTED = 'mfHairColorDetected',
  DO_MF_ERROR = 'mfError',
  DO_SELECT_CATALOG_TYPE = 'selectCatalogType',
  DO_CATALOG_PAGE_OR_VIEW_CHANGE = 'catalogPageOrViewChange',
  DO_CATALOG_VIEW_CHANGE = 'catalogViewChange',
  DO_CATALOG_VIEW_INITIAL = 'catalogViewInitial',
  DO_MAIN_CATALOG_SWITCH = 'doMainCatalogSwitch',
  DO_SHOW_SNACKBAR = 'doShowSnackbar',
  DO_ENTER = 'enter',
  DO_EXIT = 'exit',
  DO_RESET = 'reset',
  DO_INCREMENT_HISTORY_DEPTH = 'incrementHistoryDepth',
  DO_HISTORY_PUSH_STATE = 'historyPushState',
  DO_HISTORY_GO = 'historyGo',
  DO_HISTORY_BACK = 'historyBack',
  DO_UPDATE_ORIGIN_LOCATION = 'updateOriginLocation',
  // Facets
  DO_SET_FACETS = 'setFacets',
  DO_UPDATE_FACET = 'updateFacet',
  DO_TOGGLE_FACET = 'toggleFacet',
  DO_SET_INITIAL_HAIRCOLOR_FACET = 'setInitialHaircolorFacet',
  DO_UPDATE_FACETED_PRODUCTS_COLLECTION_AND_LISTS = 'updateFacetedProductsCollectionAndLists',
  DO_UPDATE_FACETED_PRODUCTS_COLLECTION = 'updateFacetedProductsCollection',
  DO_UPDATE_FACETED_LISTS = 'updateFacetedLists',
  // Appearing circles animation
  DO_UPDATE_CIRCLES_ANIMATION_STATUS = 'updateCirclesAnimationStatus';

export default {
  // store reusable initial data
  [DO_INIT_STORE](
    { commit, dispatch },
    {
      category,
      enableFavorite,
      favoriteSettings,
      initialProduct,
      rating,
      haircolorFamilies,
      enableEmailMyLookForm,
      emailMyLookFormPageCtaLabel,
      emailMyLookFormPageUrl,
    }
  ) {
    const oppositeCategory =
      category === CATEGORY_HAIR_COLOR ? CATEGORY_MAKE_UP : CATEGORY_HAIR_COLOR;

    commit(SET_ENABLE_FAVORITE, enableFavorite);
    commit(SET_FAVORITE_SETTINGS, favoriteSettings);
    commit(SET_MAIN_CATEGORY, category);
    commit(SET_OPPOSITE_CATEGORY, oppositeCategory);

    /* istanbul ignore else */
    if (initialProduct) {
      commit(SET_INITIAL_PRODUCT_ID, initialProduct.id);
      commit(SET_SELECTED_PRODUCT_ID, initialProduct.id);
    }

    commit(SET_RATING, rating);
    // actions
    commit(
      SET_BUY_DISABLED,
      URLGetParam(VTO_URL_PARAM_UTM_SOURCE_KEY) === VTO_URL_PARAM_UTM_SOURCE_POS_VALUE
    );
    commit(SET_EMAIL_ENABLED, enableEmailMyLookForm);
    commit(EMAIL_BUTTON_TEXT, emailMyLookFormPageCtaLabel);
    commit(EMAIL_BUTTON_URL, emailMyLookFormPageUrl);
    // facets
    dispatch(DO_SET_FACETS, FACETS_SETTINGS);
    dispatch(DO_SET_INITIAL_HAIRCOLOR_FACET, {
      initialHaircolor: URLGetParam(VTO_HAIRCOLOR_URL_PARAM),
      haircolorFamilies: haircolorFamilies,
    });
    // history
    dispatch(
      DO_INCREMENT_HISTORY_DEPTH,
      getInitialHistoryDepth(URLHasKey(VTO_TRIGGER_URL_PARAM), URLGetParam(VTO_HISTORY_DEPTH_PARAM))
    );
  },

  [DO_SET_INITIAL_HAIRCOLOR_FACET]({ commit }, { initialHaircolor, haircolorFamilies }) {
    if (!haircolorFamilies.includes(initialHaircolor)) return;

    commit(SET_FACET_PRESET, {
      attribute: FACET_ATTRIBUTES.HAIR_COLOR_FAMILY,
      value: initialHaircolor,
    });
    commit(SET_UPDATE_FACET, {
      attribute: FACET_ATTRIBUTES.HAIR_COLOR_FAMILY,
      facetIds: [initialHaircolor],
    });
  },

  // get data from api
  [DO_LOAD_DATA]({ commit, dispatch }) {
    /* istanbul ignore next */
    const config = {
      method: 'POST',
      body: {},
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      cache: 'no-store',
    };

    /* istanbul ignore next */
    fetchData(VTO_API_URL, config, (data) => {
      commit(SET_API_DATA, data);
      dispatch(DO_UPDATE_INITIAL_DATA);
    });
  },

  [DO_UPDATE_INITIAL_DATA]: ({ commit, state }) => {
    const id = state.selectedProductId;

    /* istanbul ignore else */
    if (id) {
      commit(SET_SELECTED_PRODUCTS, {
        id,
        typeId: getProductTypeId(state, id),
        singleMode: isSingleMode(state.mainCategory),
      });
    }
  },

  [DO_TOGGLE_MODAL]({ commit, state }) {
    commit(SET_MODAL_DISPLAY, !state.modalDisplay);
  },

  // handles logic when user enter the experience
  [DO_ENTER]({ commit, dispatch, state }) {
    dispatch(DO_TOGGLE_MODAL);
    dispatch(DO_UPDATE_ORIGIN_LOCATION);
    dispatch(DO_INCREMENT_HISTORY_DEPTH, 1);
    dispatch(DO_HISTORY_PUSH_STATE);

    /* istanbul ignore else */
    if (state.reset) {
      if (state.initialProductId) commit(SET_SELECTED_PRODUCT_ID, state.initialProductId);
      dispatch(DO_UPDATE_INITIAL_DATA);
      dispatch(DO_MF_SEND_CHANGE);
    }
  },

  [DO_UPDATE_ORIGIN_LOCATION]({ commit }, location = { ...window.location }) {
    commit(SET_ORIGIN_LOCATION, location);
  },

  [DO_HISTORY_PUSH_STATE]() {
    /* istanbul ignore next */
    let parms;
    if (
      /[?&]tryon=/.test(window.location.search) &&
      /[?&]utm_source=/.test(window.location.search)
    ) {
      parms = window.location.search;
    } else {
      parms = `?${VTO_TRIGGER_URL_PARAM}=true`;
    }
    window.history.pushState(null, document.title, parms);
  },

  [DO_HISTORY_BACK]({ commit }, fromIndex) {
    /* istanbul ignore next */
    if (typeof fromIndex === 'number') commit(SET_HISTORY_DEPTH, fromIndex);
    window.history.back();
  },

  [DO_HISTORY_GO]({ state }) {
    /* istanbul ignore next */
    if (state.historyDepth > 0) window.history.go(-state.historyDepth);
  },

  // handles logic when user exit the experience
  [DO_EXIT]({ dispatch }) {
    // TODO may need to be wrapped into state.frameready to avoid unexpected behavior
    dispatch(DO_HISTORY_GO);
    dispatch(DO_UPDATE_ORIGIN_LOCATION, null);
    dispatch(DO_TOGGLE_MODAL);
    dispatch(DO_GO_TO_PAGE, PAGE_DEFAULT);
    // TODO avoid sending exit if called from 'exit' listener. idea: check action caller
    dispatch(DO_MF_SEND_EXIT);
    dispatch(DO_RESET);
  },

  [DO_RESET]({ commit }) {
    commit(SET_RESET_STORE);
  },

  // handles logic when user exit the experience
  [DO_MF_SEND_EXIT]() {
    /* istanbul ignore next */
    window.MF_CHANNEL_PARENT.send({ event: MF_EVENT_EXIT, data: {} }, FRAME_NAME);
    if (document.querySelectorAll('.vtoShare').length) {
      document.getElementsByClassName('vtoShare')[0].style.display = 'none';
    }
  },

  [DO_FRAME_READY]({ commit }, data) {
    commit(SET_MODIFACE_DATA, data);
    commit(SET_FRAME_READY);
    window.vtoActivated = true;
  },

  [DO_GO_TO_PAGE]({ commit, dispatch, state }, requestedPage) {
    /* istanbul ignore else */
    if (requestedPage && state.currentPage !== requestedPage) {
      dispatch(DO_MF_SEND_CTA_DISPLAY, {
        ...MF_CTA_DISPLAY_DEFAULT,
        ...MF_CTA_SETTINGS[requestedPage],
      });
      commit(SET_CURRENT_PAGE, requestedPage);
    }
  },

  [DO_GO_TO_PAGE_CATALOG]({ dispatch }) {
    dispatch(DO_GO_TO_PAGE, PAGE_CATALOG);
  },

  [DO_GO_TO_PAGE_ENTITY_SELECTION]({ dispatch }) {
    dispatch(DO_GO_TO_PAGE, PAGE_ENTITY_SELECTION);
  },

  [DO_GO_TO_PAGE_HAIR_DETECTION]({ dispatch }) {
    dispatch(DO_GO_TO_PAGE, PAGE_HAIR_DETECTION);
  },

  [DO_GO_TO_PAGE_MAIN]({ dispatch }) {
    dispatch(DO_GO_TO_PAGE, PAGE_MAIN);
  },

  [DO_GO_TO_PAGE_LANDING]({ dispatch }) {
    dispatch(DO_GO_TO_PAGE, PAGE_LANDING);
  },

  [DO_EVENT_TRACKING]({ dispatch, getters, state }, data) {
    // this is a heart of VTO routing
    dispatch(DO_GO_TO_PAGE, mapEventsToPage(state, data));

    if (
      ['liveCameraCtaLoaded', 'uploadPhotoCtaLoaded'].includes(data.trackingType) &&
      state.initialProductId
    ) {
      // TODO - check how to track analytics on categories/looks flow
      /* istanbul ignore else */
      const labelInfo = getters.getTaggingInfo.products[0];

      dispatch(DO_PUSH_TAGGING_INFO, {
        type: TAGGING_INFO_VTO_DETAIL_TYPE,
        label: `${labelInfo.name}::${labelInfo.dimension48}::${labelInfo.dimension36}`,
      });

      /**
       * TODO: Globally-exposed eventBus doesn't seem to be available here
       */
      window.eventBus.emit('activate-vto-component');
      window.localStorage.setItem('isVTOUpload', true);

      var handleBuyClickCc = document.getElementsByClassName('commerceConnector__item');
      for (var i = 0; i < handleBuyClickCc.length; i++) {
        handleBuyClickCc[i].classList.add('handleBuy__eRetailer');
      }

      let singleBuyNowRetailer = document.querySelectorAll('a.oap-button');
      if (singleBuyNowRetailer.length > 0) {
        Array.prototype.forEach.call(singleBuyNowRetailer, (item) => {
          item.setAttribute('data-tag-product-dimension49', 'activated');
        });
      }
    }
  },

  [DO_PUSH_TAGGING_INFO]({ getters }, taggingInfoUpdate) {
    const labelInfo = getters.getTaggingInfo.products[0];
    const extendedTaggingInfoUpdate = {
      ...taggingInfoUpdate,
      label: `${labelInfo.name.toLowerCase()}::${labelInfo.id}::${labelInfo.variant.toLowerCase()}`,
      event_name: 'view_item',
      productInfo: `${labelInfo.name.toLowerCase()}::${
        labelInfo.id
      }::${labelInfo.variant.toLowerCase()}`,
      event_detail: 'variant selection',
    };
    /* istanbul ignore next */
    AnalyticsHandler.getAnalyticsHandler().push({
      ...getters.getTaggingInfo,
      ...extendedTaggingInfoUpdate,
    });
  },

  [DO_PUSH_TAGGING_INFO_SIMPLE]({ state }, taggingInfoUpdate) {
    /* istanbul ignore next */
    AnalyticsHandler.getAnalyticsHandler().push({
      type: 'userActionEvent',
      category: `virtual try on::modiface::${
        state.productCategoriesMap[state.mainCategory].taggingTitle
      }`,
      ...taggingInfoUpdate,
    });
  },

  // Only in multi mode
  [DO_REMOVE_PRODUCT]({ commit, dispatch, state }, id) {
    let fallBackProductId;

    commit(SET_REMOVE_SELECTED_PRODUCTS, getProductTypeId(state, id));

    fallBackProductId = Object.values(state.selectedProducts)[0] || null;

    dispatch(fallBackProductId ? DO_MF_SEND_CHANGE : DO_MF_SEND_REMOVE_CUSTOM_LOOK);

    /* istanbul ignore else */
    if (state.selectedProductId === id) commit(SET_SELECTED_PRODUCT_ID, fallBackProductId);
  },

  // Looks
  [DO_APPLY_SELECTED_LOOK]({ state, getters }) {
    /* istanbul ignore next */
    window.MF_CHANNEL_PARENT.send(
      {
        event: MF_EVENT_CUSTOM_LOOK_CHANGE,
        data: {
          look: getters.getSelectedLookProductIds.map((lookId) => ({
            category: state.productItemsMap[lookId].category,
            upc: state.productItemsMap[lookId].upc,
          })),
        },
      },
      FRAME_NAME
    );
  },

  [DO_RESET_SELECTED_LOOK]({ commit }) {
    commit(SET_RESET_SELECTED_LOOK_FAMILY_ID);
    commit(SET_RESET_SELECTED_LOOK_ID);
    commit(SET_RESET_SELECTED_TYPE_ID);
  },

  [DO_RESET_SELECTED_LOOK_ID]({ commit }) {
    commit(SET_RESET_SELECTED_LOOK_ID);
  },

  [DO_SELECT_LOOK_FAMILY]({ commit, dispatch, getters }, id) {
    commit(SET_SELECTED_LOOK_FAMILY_ID, id);

    // set first look of selected looks family by default
    commit(SET_SELECTED_LOOK_ID, getters.getSelectedLookFamilyLookIds[0]);
    dispatch(DO_APPLY_SELECTED_LOOK);
  },

  [DO_SELECT_LOOK_ID]({ commit }, id) {
    commit(SET_SELECTED_LOOK_ID, id);
  },

  [DO_DISPLAY_FLOATING_BAR]({ commit }, bool) {
    commit(SET_SHOW_FLOATING_BAR, bool);
  },

  [DO_DISPLAY_SHADE_OVERLAY]({ commit }, payload) {
    commit(SET_SHOW_SHADE, payload);
  },

  [DO_SELECT_SHADE]({ commit, dispatch, state, getters }, id) {
    /* istanbul ignore next */
    if (state.currentPage === PAGE_CATALOG && getters.getCatalogMode === 'switch') {
      // if we are in the switcher catalog - redirect with trigger query param
      let productUrl = '';

      productUrl += state.productItemsMap[id].url;
      productUrl += '?' + VTO_TRIGGER_URL_PARAM + '=' + 'true';
      productUrl += '&' + VTO_HISTORY_DEPTH_PARAM + '=' + state.historyDepth;
      return (window.location.href = productUrl);
    }

    commit(SET_SELECTED_PRODUCT_ID, id);
    commit(SET_SELECTED_PRODUCTS, {
      id,
      typeId: getProductTypeId(state, id),
      singleMode: isSingleMode(state.mainCategory),
    });

    // Send mf event logic
    dispatch(DO_MF_SEND_CHANGE);

    // Navigate to main
    dispatch(DO_GO_TO_PAGE, PAGE_MAIN);
  },

  [DO_MF_SEND_CHANGE]({ state }) {
    // TODO if debug log send payload
    /* istanbul ignore next */
    window.MF_CHANNEL_PARENT.send(
      isSingleMode(state.mainCategory) ? getUPCChange(state) : getCustomLookChange(state),
      FRAME_NAME
    );
  },

  [DO_MF_SEND_REMOVE_CUSTOM_LOOK]() {
    /* istanbul ignore next */
    window.MF_CHANNEL_PARENT.send(
      { event: MF_EVENT_CUSTOM_LOOK_CHANGE, data: { remove: true } },
      FRAME_NAME
    );
  },

  [DO_MF_SEND_CTA_DISPLAY](_, data) {
    /* istanbul ignore next */
    window.MF_CHANNEL_PARENT.send({ event: MF_EVENT_MODIFY_CTA_DISPLAY, data }, FRAME_NAME);
  },

  [DO_OPEN_CATALOG]({ commit, dispatch }, isMainCategory) {
    commit(SET_MAIN_CATALOG, isMainCategory);
    dispatch(DO_GO_TO_PAGE, PAGE_CATALOG);
  },

  [DO_SELECT_CATALOG_TYPE]({ commit }, type) {
    commit(SET_CATALOG_TYPE, type);
  },

  [DO_CATALOG_VIEW_CHANGE]({ commit }, view) {
    commit(SET_CATALOG_VIEW, view);
  },

  [DO_CATALOG_VIEW_INITIAL]({ dispatch, getters }) {
    switch (getters.getCatalogMode) {
      case CATALOG_MODE_MULTI:
        dispatch(DO_CATALOG_VIEW_CHANGE, VIEW_HOTSWAP);
        break;
      case CATALOG_MODE_LOOK:
        dispatch(DO_CATALOG_VIEW_CHANGE, VIEW_HOTSWAP_LOOKS);
        break;
      case CATALOG_MODE_SINGLE:
        dispatch(DO_CATALOG_VIEW_CHANGE, VIEW_HOTSWAP_HAIRCOLOR);
        break;
      default:
        dispatch(DO_EDIT_PRODUCT_SELECTION);
    }
  },

  [DO_CATALOG_PAGE_OR_VIEW_CHANGE]({ dispatch, getters, state }) {
    // when the user switched category to the opposite one
    if (!state.isMainCatalog) {
      dispatch(DO_MAIN_CATALOG_SWITCH);

      if (state.previousPage === PAGE_ENTITY_SELECTION) {
        dispatch(DO_GO_TO_PAGE_ENTITY_SELECTION);
        return;
      }

      if (state.previousPage === PAGE_MAIN) {
        dispatch(DO_GO_TO_PAGE_MAIN);
        return;
      }
    }

    if (getters.getCatalogMode === CATALOG_MODE_MULTI) {
      if (getters.getCatalogType === TYPE_LOOK) {
        dispatch(DO_CATALOG_VIEW_CHANGE, VIEW_HOTSWAP_LOOKS);
      } else {
        dispatch(DO_CATALOG_VIEW_CHANGE, VIEW_HOTSWAP);
      }
    } else if (
      getters.getCatalogMode === CATALOG_MODE_SINGLE &&
      getters.getCatalogCategory === CATEGORY_HAIR_COLOR
    ) {
      dispatch(DO_CATALOG_VIEW_CHANGE, VIEW_HOTSWAP_HAIRCOLOR);
    }
  },

  [DO_MAIN_CATALOG_SWITCH]({ commit }) {
    commit(SET_MAIN_CATALOG_SWITCH);
  },

  [DO_ADD_HOTSWAP_REMOVE_TYPE_ID]({ commit, dispatch, state }, id) {
    if (state.hotSwapRemoveTypeId === null || state.hotSwapRemoveTypeId !== id) {
      commit(SET_HOTSWAP_REMOVE_TYPE_ID, id);
    } else {
      dispatch(DO_PUSH_TAGGING_INFO_SIMPLE, {
        action: 'remove::product',
        label: state.productItemsMap[state.selectedProducts[id]].name,
      });
      dispatch(DO_REMOVE_PRODUCT, state.productItemsMap[state.selectedProducts[id]].id);
      dispatch(DO_RESET_HOTSWAP_REMOVE_TYPE_ID);
    }
  },

  [DO_RESET_HOTSWAP_REMOVE_TYPE_ID]({ commit }) {
    commit(SET_HOTSWAP_REMOVE_TYPE_ID, null);
  },

  [DO_SHOW_SNACKBAR]({ commit }, bool) {
    commit(SET_SHOW_SNACKBAR, bool);
  },

  [DO_EDIT_PRODUCT_SELECTION]({ commit, dispatch, state, getters }, typeId) {
    // set selected type id
    commit(SET_SELECTED_TYPE_ID, typeId || getSelectedTypeId(state, getters));
    // open view catalog
    dispatch(DO_CATALOG_VIEW_CHANGE, VIEW_CATALOG);
  },

  [DO_SELECT_TYPE]({ commit }, typeId) {
    commit(SET_SELECTED_TYPE_ID, typeId);
  },

  [DO_INCREMENT_HISTORY_DEPTH]({ commit }, historyDepthInc) {
    commit(SET_INCREMENT_HISTORY_DEPTH, historyDepthInc);
  },

  // Facets
  [DO_SET_FACETS]({ commit }, facets) {
    facets.forEach(({ attribute, config }) => {
      /* istanbul ignore else */
      if (attribute) commit(SET_NEW_FACET, attribute);
      /* istanbul ignore else */
      if (attribute && config) commit(SET_FACET_CONFIG, { attribute, config });
    });
  },

  [DO_UPDATE_FACETED_PRODUCTS_COLLECTION_AND_LISTS]({ dispatch }) {
    dispatch(DO_UPDATE_FACETED_PRODUCTS_COLLECTION);
    dispatch(DO_UPDATE_FACETED_LISTS);
  },

  [DO_UPDATE_FACET]({ commit, dispatch, state }, { attribute, facetIds }) {
    /* istanbul ignore else */
    if (state[FACET_STATE][attribute] !== facetIds) {
      commit(SET_UPDATE_FACET, { attribute, facetIds });
      dispatch(DO_UPDATE_FACETED_PRODUCTS_COLLECTION_AND_LISTS);
    }
  },

  [DO_TOGGLE_FACET]({ dispatch, state }, { attribute, value }) {
    const getFacetId = findObjectKeyByPropertyValue,
      // Toggle the value of the facet id within the array
      facetIds = toggleArrayValue(
        [...state[FACET_STATE][attribute]],
        getFacetId(state[FACET_MAPS][attribute], 'value', value)
      );

    dispatch(DO_UPDATE_FACET, { attribute, facetIds });
  },

  [DO_UPDATE_FACETED_PRODUCTS_COLLECTION]({ commit, state }) {
    const facetedProductIdsCollection = getFacetedProductIdsCollection(
      state[FACET_MAPS],
      state[FACET_STATE]
    );
    commit(SET_UPDATE_FACETED_PRODUCTS_COLLECTION, facetedProductIdsCollection);
  },

  [DO_UPDATE_FACETED_LISTS]({ commit, state, getters }) {
    const map = Object.values(state.productTypesMap).reduce(
      (map, { id: typeId, productCategoryId }) => {
        const listsFacetAttributes = unique(state[FACET_CONFIGS].perCategory[productCategoryId]);

        map[typeId] =
          getters.hasFacets && listsFacetAttributes.length
            ? getFacetedLists(state, typeId, productCategoryId, listsFacetAttributes)
            : getDefaultLists(state, typeId);

        return map;
      },
      {}
    );

    commit(SET_PRODUCT_LISTS_MAP, map);
  },

  // used for debug log
  [DO_MF_ERROR]() {},

  // used for subscription as events
  [DO_MF_SHOW_FOR_YOU_LOADER]() {},
  [DO_MF_HAIR_COLOR_DETECTED]() {},

  // Appearing circles animation
  [DO_UPDATE_CIRCLES_ANIMATION_STATUS]({ commit }, status) {
    commit(SET_CIRCLES_ANIMATION_STATUS, status);
  },
};
