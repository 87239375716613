export const DEFAULT_MODIFIERS = {
  ELEMENT: 'element',
  CLASS: 'class',
};

export const searchOnDom = (selector, expression) => {
  return document.querySelector(`${selector}${expression}`);
};

export const updateItem = (element, id) => {
  element.setAttribute('href', `#${id}`);
  element.classList.remove('anchor-to');
};

export const getSelector = (arg) => {
  let selector = '#';

  switch (arg) {
    case DEFAULT_MODIFIERS.ELEMENT:
      selector = '';
      break;
    case DEFAULT_MODIFIERS.CLASS:
      selector = '.';
      break;
    default:
      selector = '#';
      break;
  }

  return selector;
};

/**
 * Modifiers: id, class, element
 */
export const anchorTo = {
  name: 'anchor-to',
  mounted: (el, binding) => {
    const { arg, value } = binding;

    if (el.tagName === 'A') {
      const item = searchOnDom(getSelector(arg), value);

      if (item) {
        let { id } = item;

        if (id) {
          updateItem(el, id);
        }
      }
    }
  },
};
