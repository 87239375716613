<template>
  <!-- Recommended -->
  <div class="slider__content">
    <Slider :activate="true" :is-faded-out="false" :scale-factor="0.36">
      <ul class="slider__inner">
        <li v-for="(product, $key) in items" :key="$key" class="slider__item">
          <div
            v-tag:productimpression.dom="{ label: '__pageCategory__' }"
            class="productTile hasTextCentered -tall"
            data-tag-wrapper
          >
            <div class="productTile__wrapper">
              <div v-if="product.imageSet" class="productTile__imageWrapper">
                <img
                  v-srcset="product.imageSet"
                  :alt="product.name"
                  aria-hidden="true"
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                />
              </div>
              <div class="productTile__infoWrapper">
                <a
                  v-tag:productclick.dom="{ label: `${product.eventLabel}` }"
                  :href="product.Url"
                  data-tag-product-identifier
                  :data-tag-product-name="product.name"
                  :data-tag-product-id="product.id"
                  :data-tag-product-brand="product.brand"
                  :data-tag-product-price="product.price"
                  :data-tag-product-category="product.category"
                  :data-tag-product-currency="product.currency"
                  :data-tag-product-position="$key + 1"
                  :data-tag-product-variant="product.variant"
                  data-tag-product-list="recommended"
                  :data-tag-product-dimension35="product.size"
                  :data-tag-product-dimension36="product.shade"
                  :data-tag-product-dimension38="product.rating"
                  data-tag-product-dimension48=""
                  :data-tag-product-dimension49="product.isModifaceActivated"
                  data-tag-product-dimension91="none"
                  :data-tag-product-dimension50="product.initialPrice"
                >
                  <p class="productTile__brandName" v-html="product.brand"></p>
                  <span class="productTile__productName" data-tag-product-name>{{
                    product.name
                  }}</span>
                </a>
              </div>
            </div>
            <div class="productTile__ratings">
              <div class="ratingsList">
                <span
                  v-for="(rating, $index) in Number.parseInt(productRatingMaximum)"
                  :key="$index"
                  class="ratingsList__star"
                  :class="{ '-isSelected': rating <= product.rating }"
                >
                  <svg
                    width="13px"
                    aria-hidden="true"
                    height="13px"
                    viewBox="0 0 42 40"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                      <path
                        d="M32.1840261,37.568991 L28.3704261,25.1809655 L39.231509,16.2587574 L25.798253,16.1621556 L21,2.93285568 L16.201747,16.1621556 L2.76849099,16.2587574 L13.6295739,25.1809655 L9.81597389,37.568991 L21,29.957571 L32.1840261,37.568991 Z"
                        stroke-width="2"
                      ></path>
                    </g>
                  </svg>
                </span>
              </div>
              <span class="isSrOnly">{{ avarageRatingLabel }}</span>
              <div class="ratingsAverage">{{ product.rating }}/{{ productRatingMaximum }}</div>
            </div>
            <div class="productTile__priceWrapper">
              <p class="productTile__price">{{ viewProductLabel }}</p>
            </div>
          </div>
        </li>
      </ul>
    </Slider>
  </div>
</template>

<script>
import { tag } from '../../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';
import { srcset } from '../../../../../../Foundation/Core/code/Scripts/directives/srcset/srcset';
import Slider from '../../../../../../Feature/Slider/code/Scripts/components/slider.vue';

export default {
  name: 'ProductSliderModel',
  directives: {
    tag,
    srcset,
  },
  components: {
    Slider,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    viewProductLabel: {
      type: String,
      required: true,
    },
    avarageRatingLabel: {
      type: String,
      required: false,
      default: 'Average rating:',
    },
    productRatingMaximum: {
      type: [Number, String],
      required: false,
      default: 5,
    },
  },
};
</script>
