<template>
  <button class="vtoHotSwap__edit" @click="$attrs.click && $attrs.click()">
    <svg class="icon">
      <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-edit-black" />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'VtoHotswapEdit',
};
</script>
