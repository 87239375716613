<template>
  <div class="third-party-loader">
    <LoadScript
      v-if="shouldLoadScript"
      :src="scriptToLoad"
      :name="scriptName"
      @loaded="setScriptLoaded"
    ></LoadScript>
    <slot v-if="isScriptLoaded" />
  </div>
</template>

<script>
import LoadScript from '../loadscript/loadscript.vue';
import { intersectionViewportObserver } from '@Foundation';

export default {
  name: 'ThirdPartyLoader',
  components: [LoadScript],
  props: {
    defaultScriptName: {
      type: String,
      required: true,
    },
    defaultScriptUrl: {
      type: String,
      required: true,
    },
    globalScriptName: {
      type: String,
      required: true,
    },
    globalScriptUrl: {
      type: String,
      required: true,
    },
    commonScriptClassName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scriptToLoad: this.defaultScriptUrl,
      scriptName: this.defaultScriptName,
      shouldLoadScript: false,
      isScriptLoaded: false,
    };
  },
  mounted() {
    const thridParties = document.querySelectorAll(`.${this.commonScriptClassName}`);

    if (thridParties.length > 1) {
      this.scriptToLoad = this.globalScriptUrl;
      this.scriptName = this.globalScriptName;
    }

    intersectionViewportObserver(this.$el, { checkIsVisible: true, threshold: [0.006] }).then(
      () => {
        this.shouldLoadScript = true;
      }
    );
  },
  methods: {
    setScriptLoaded() {
      this.isScriptLoaded = true;
    },
  },
};
</script>
