import Toasted from 'vue-toasted/dist/toasted';
import ToastComponent from 'vue-toasted/dist/vue-toasted';

const ToastedPlugin = {
  install(app, options) {
    if (!options) {
      options = {};
    }

    const Toast = new Toasted(options);
    app.component('Toasted', ToastComponent);
    app.config.globalProperties.$toasted = Toast;
  },
};

export default ToastedPlugin;
